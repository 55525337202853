import { TextInput } from "../../../layout/Form";
import {  Row, Text } from "../../../layout/Layout";

export default function YoutubeUrl({url, onChange}){

    return(
        <Row align={'center'}>
            <Text>URL do Youtube</Text>
            <TextInput type={'text'} width={60} defaultValue={url} placeholder={'Cole o endereço do vídeo aqui'} onChange={(e) => onChange(e.target.value)}/>
        </Row>
    )
}