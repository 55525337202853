export const CalculateStars = (ratings) => {

    let total = 0
    let votes = 0
    
    ratings.forEach((element, index) => {
        total += element*index*25
        votes += element
    });

    if(total > 0) return Math.round((total / votes)/20)
    else return 0
}

export const CalculateVotes = (ratings) => {

    let votes = 0
    
    ratings.forEach((element) => {
        votes += element
    });

    return votes
}