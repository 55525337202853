import styled from "styled-components";

export const Header = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 75px;
    background-color: white;
    padding: 0 20px;
`

export const HeaderLeft = styled.span`
    display: flex;
    align-items: center;
    height: 100%;
`

export const HeaderRight = styled.span`
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
`