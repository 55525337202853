import styled from "styled-components";

const Checkbox = styled.div`
    display: flex;
    flex-direction: column;

    span{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    label{
        font-size: 12px;
        font-weight: 400;
        margin-left: 5px;
    }
`

const Input = styled.input`
    accent-color: #00BFA5;
`

function CheckBoxList({id, options, checks, onSelect}){
    
    function getChecks(checking){
        let checked = checks
       
        for(let i = 0; i < checked.length; i++) {                
            if(checked[i] === checking) return true
        }           
        return false
    }
    
    return(
        <Checkbox>
            {
                options.map((option, index) => {
                    return (
                        <span key={index}>
                            <Input id={option+id} name={option} checked={getChecks(option)} type="checkbox" onChange={(e) => onSelect(e.target.checked, e.target.name)}/>
                            <label htmlFor={option+id}>{option}</label>
                        </span>
                    )
                })
            } 
        </Checkbox>
    )
}

export default CheckBoxList