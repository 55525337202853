import { toast } from "react-toastify"
import { Column, Row, Icon, Spacer, Text, P } from "../../layout/Layout"
import { Check, Label, TextInput } from "../../layout/Form"
import { AiOutlineUserAdd } from 'react-icons/ai'
import { validateEmailString } from '../../../methods/validateEmailString'
import { validateNewName } from '../../../methods/validateNewName'
import { useContext } from 'react'
import { DataContext } from '../../../contexts/DataProvider'
import { useState } from "react"
import { Button } from "../../buttons/Button"
import HelpIcon from "../../others/HelpIcon"
import { permissionConfig, permissionDashboard, permissionDevices, permissionFinances, permissionPasswords, permissionUsers } from '../../../content/tooltips/userAdminTooltips'

export default function NewAdminModal({onAdd}){

    const { admins } = useContext(DataContext)

    const [newAdmin, setNewAdmin] = useState({
        permissions: []
    })

    const handleChange = (property, value) => setNewAdmin(prev => ({...prev, [property]: value}))

    const permissionList = ['Dashboard', 'Dispositivos', 'Configurações', 'Emissão de Senhas', 'Usuários', 'Financeiro']
    const permissionHelps = [permissionDashboard, permissionDevices, permissionConfig, permissionPasswords, permissionUsers, permissionFinances]

    const checkData = () => {
        if(!newAdmin.name || !newAdmin.email || !newAdmin.role){
            toast.error(`Preencha corretamente as informações do novo administrador.`, {containerId: 'alert'})
            return 0
        }
        if(!validateNewName(admins, newAdmin.name)) {
            toast.error(`O nome ${newAdmin.name} já está sendo utilizado.`, {containerId: 'alert'})
            return 0
        }
        if(!validateEmailString(newAdmin.email)) {
            toast.error(`O e-mail informado é inválido. Insira um endereço válido.`, {containerId: 'alert'})
            return 0
        }
        if(newAdmin.permissions.length === 0) {
            toast.error(`Selecione ao menos uma permissão para este novo Administrador`, {containerId: 'alert'})
            return 0
        }

        return 1
    }

    const handleConfirm =  () => {
        
        if(!checkData()) return 

        onAdd(newAdmin)
    }
    
    const handleCheck = (check, option) => {
        
        const temp = [...newAdmin.permissions]
    
        if(check) temp.push(option)
        else{
            const index = temp.findIndex(temp => temp === option)
            if(index >= 0) temp.splice(index, 1)
        }

        handleChange('permissions', temp)   
    } 

    return (
        <Column width={'38rem'}>
            <Row align={'center'}>
                <Icon size={32} color={'#00BFA5'}>
                    <AiOutlineUserAdd/>
                </Icon>
                <Text weight={600} size={14} color={'#455A64'}>Novo Administrador</Text>
            </Row>
            <Spacer top={1}/>
            <P>Para incluir um novo administrador do sistema, informe os dados e permissões que ele terá. <strong>Importante:</strong> o e-mail pode ser pessoal ou profissional, desde que seja válido, e será utilizado para fazer o login no sistema.</P>
            <Spacer top={1}/>
            <Row top={20}>
                <Column paddingRight={25}>
                    <Text>Nome do Administrador</Text>
                    <TextInput
                        type={'text'}
                        placeholder={'Digite o Nome'}
                        width={'100%'}
                        onChange={(e) => handleChange("name", e.target.value)}
                    />
                    <Spacer top={1}/>
                    <Text>E-mail do Administrador</Text>
                    <TextInput
                        type={'text'}
                        placeholder={'Digite o E-mail'}
                        width={'100%'}
                        onChange={(e) => handleChange("email", e.target.value)}
                    />
                    <Spacer top={1}/>
                    <Text>Cargo do Administrador</Text>
                    <TextInput
                        type={'text'}
                        placeholder={'Digite o Cargo'}
                        width={'100%'}
                        onChange={(e) => handleChange("role", e.target.value)}
                    />
                </Column>
                <Column borderLeft paddingLeft={25}>
                    <Text weight={600}>Permissões</Text>
                    {
                        permissionList.map((permission, index) => {
                            return (
                                <Row key={index} align={'center'} top={6} botton={6}>
                                    <Check id={permission} checked={newAdmin.permissions?.includes(permission)} type={'checkbox'} onChange={(event) => handleCheck(event.target.checked, permission)}></Check>
                                    <Label id={permission} htmlFor={permission}>{permission}</Label>
                                    <HelpIcon content={permissionHelps[index]}/>
                                </Row>
                            )
                        })
                    }
                </Column>
            </Row>
            <Spacer top={2}/>
            <P><strong>Obs.:</strong> após a inclusão do novo administrador, será enviado um e-mail para o mesmo com um link para criação da sua senha e instruções para acessar o sistema.</P>
            <Spacer top={3}/>
            <Row justify={'center'}>
                <Button width={80} height={25} radius={15} onClick={() => handleConfirm()}>Adicionar</Button>
            </Row>
        </Column>
    )
}

