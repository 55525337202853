import alert01 from './alerts/alert-01.wav'
import alert02 from './alerts/alert-02.mp3'
import alert03 from './alerts/alert-03.mp3'
import alert04 from './alerts/alert-04.mp3'
import alert05 from './alerts/alert-05.mp3'
import alert06 from './alerts/alert-06.mp3'
import alert07 from './alerts/alert-07.mp3'
import alert08 from './alerts/alert-08.mp3'
import alert09 from './alerts/alert-09.mp3'
import alert10 from './alerts/alert-10.mp3'
import alert11 from './alerts/alert-11.mp3'
import alert12 from './alerts/alert-12.mp3'
import alert13 from './alerts/alert-13.mp3'
import alert14 from './alerts/alert-14.mp3'
import alert15 from './alerts/alert-15.mp3'
import alert16 from './alerts/alert-16.mp3'
import alert17 from './alerts/alert-17.mp3'
import alert18 from './alerts/alert-18.mp3'
import alert19 from './alerts/alert-19.mp3'
import alert20 from './alerts/alert-20.mp3'
import alert21 from './alerts/alert-21.mp3'
import alert22 from './alerts/alert-22.mp3'
import alert23 from './alerts/alert-23.mp3'


export const alerts = [
    {name: 'Alerta 1', audio: new Audio(alert01)},  
    {name: 'Alerta 2', audio: new Audio(alert02)},  
    {name: 'Alerta 3', audio: new Audio(alert03)},  
    {name: 'Alerta 4', audio: new Audio(alert04)},  
    {name: 'Alerta 5', audio: new Audio(alert05)},  
    {name: 'Alerta 6', audio: new Audio(alert06)},  
    {name: 'Alerta 7', audio: new Audio(alert07)},  
    {name: 'Alerta 8', audio: new Audio(alert08)},  
    {name: 'Alerta 9', audio: new Audio(alert09)},  
    {name: 'Alerta 10', audio: new Audio(alert10)},  
    {name: 'Alerta 11', audio: new Audio(alert11)},  
    {name: 'Alerta 12', audio: new Audio(alert12)},  
    {name: 'Alerta 13', audio: new Audio(alert13)},  
    {name: 'Alerta 14', audio: new Audio(alert14)},  
    {name: 'Alerta 15', audio: new Audio(alert15)},  
    {name: 'Alerta 16', audio: new Audio(alert16)},  
    {name: 'Alerta 17', audio: new Audio(alert17)},  
    {name: 'Alerta 18', audio: new Audio(alert18)},  
    {name: 'Alerta 19', audio: new Audio(alert19)},  
    {name: 'Alerta 20', audio: new Audio(alert20)},  
    {name: 'Alerta 21', audio: new Audio(alert21)},  
    {name: 'Alerta 22', audio: new Audio(alert22)},  
    {name: 'Alerta 23', audio: new Audio(alert23)},  
]
