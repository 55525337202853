import { Column, Row, Spacer, Text, Border, Icon } from "../layout/Layout";
import { MdOutlineVerifiedUser } from 'react-icons/md'
import { AiOutlineCrown } from 'react-icons/ai'
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import ChangeAdminPassword from './ChangeAdminPassword'

export default function AdminAccountBox(){

    const { userAdmin } = useContext(AuthContext)

    return(
        <Border border={'#B0BEC5'} left={40}>
            <Row align={'center'}>
                <Icon size={22} color={'#78909C'} margin={'0'}>
                    <MdOutlineVerifiedUser/>
                </Icon>
                <Text left={"5px"} size={16} weight={500} color={'#546E7A'}>Administrador Logado</Text>
            </Row>
            <Spacer top={2}/>
            <Text size={12} weight={300}>Nome</Text>
            <Row align={'center'}>
                <Text size={14} weight={500}>{userAdmin.name}</Text>
                {
                    userAdmin.master &&
                    <Icon size={18} color={'#FF8F00'}>
                        <AiOutlineCrown/>
                    </Icon>
                }
            </Row>
            <Spacer top={1}/>
            <Text size={12} weight={300}>E-mail</Text>
            <Text size={14} weight={500}>{userAdmin.email}</Text>
            <Spacer top={1}/>
            <Text size={12} weight={300}>Senha</Text>
            <Column width={'20%'}>
                <ChangeAdminPassword/>
            </Column>
        </Border>
    )
}