export const placeInfo = <p style={{textAlign: 'left'}}>
Caso você tenha mais de um Estabelecimento, selecione aquele onde este <strong>Painel será instalado.</strong>.<br/><br/>
Para saber mais sobre <strong>Locais</strong>, vá em Locais no Menu Lateral.
</p>

export const servicesInfo = <p style={{textAlign: 'left'}}>
Caso você tenha mais de um Serviço de atendimento no Estabelecimento selecionado, <strong>escolha para quais este Painel irá exibir as senhas chamadas</strong>.<br/><br/>
Para saber mais sobre <strong>Serviços</strong>, vá em Serviços no Menu Lateral.
</p>

export const interfacesInfo = <p style={{textAlign: 'left'}}>
<strong>Interfaces</strong> são o conjunto de parâmetros que compõe o <strong>design de cada Painel</strong> bem como o <strong>conteúdo que ele irá exibir</strong> na tela.<br/><br/>
Todo Painel possui uma <strong>Interface Padrão</strong> que não poderá ser removida, e será utilizada sempre que não houver outra configurada para ser exibida. 
Além dessa, você poderá configurar <strong>Interfaces extras</strong> para serem <strong>utilizadas em períodos específicos</strong> conforme a sua vontade. <br/><br/>
Para editar uma Interface basta clicar no ícone com um lápis à direita de cada uma, e personalizá-la de acordo com o seu gosto, escolhendo a <strong>cor, alerta e conteúdos que deseja exibir</strong>.
</p>

export const newConfigInfo = <p style={{textAlign: 'left'}}>
Para adicionar uma <strong>Nova Interface</strong> neste Painel, indique quando ela deverá ser utilizada.<br/><br/>
Você poderá optar entre três opções:<br/><br/>
<strong>Por período:</strong> define um intervalo de dias no qual esta Interface será utilizada. Ideal para promover campanhas temporárias durante um período.<br/><br/>
<strong>Por horário:</strong> define um horário do dia no qual esta Interface será utilizada. Ideal para diferenciar a Interface em diferentes períodos de um dia.<br/><br/>
<strong>Por dias da semana:</strong> define quais dias da semana esta Interface será utilizada. Ideal caso você possua atendimentos diferenciados em dias específicos.<br/><br/>
<strong>Obs.: </strong>A Interface Padrão sempre será utilizada caso não possua nenhuma outra. 
</p>

export const colorInfo = <p style={{textAlign: 'left'}}>
A <strong>Cor do Tema</strong> é a cor que compõem o design do Painel. Procure escolher uma cor que combine com a cor da sua empresa, ou se desejar deixe a cor padrão.
</p>

export const alertInfo = <p style={{textAlign: 'left'}}>
O <strong>Alerta Sonoro</strong> do Painel é tocado ao chamar uma senha. Você pode escolher entre diversas opções de sinais auditivos ou optar por uma voz humana para realizar a chamada. Também é possível utilizar ambas as opções, onde o sinal auditivo será tocado antes da voz.<br/><br/>
<strong>Obs.: </strong>Se você não deseja que nenhum áudio seja reproduzido durante a chamada de senhas, desative ambas as opções.
</p>

export const conteudoInfo = <p style={{textAlign: 'left'}}>
Se <strong>Exibir Data e Hora</strong> estiver ativo, o Painel exibirá-las em sua interface. Recomenda-se deixar ativo.<br/><br/>
O <strong>Texto Rotativo</strong> se ativo, exibirá na tela do Painel o seu conteúdo de forma rotativa. Você pode adicionar frases, divulgar serviços ou produtos, exibir alertas para seus clientes, etc. 
</p>

export const exhibitionInfo = <p style={{textAlign: 'left'}}>
Este é o conteúdo que será exibido no Painel, como forma de entertenimento ou divulgação de algo para seus clientes. Você pode exibir fotos e imagens, vídeos institucionais ou propagandas, etc.<br/><br/>
Caso queira exibir algum conteúdo próprio, basta selecionar <strong>Galeria de Imagens</strong> ou <strong>Galeria de Vídeos</strong> e carregar os arquivos que desejar.<br/><br/>
Para exibir um vídeo do Youtube selecione <strong>Vídeo do Youtube</strong> e copie seu link no campo ao lado. <br/><br/>
Se selecionar <strong>Não exibir nada</strong>, nenhum conteúdo será exibido.
</p>