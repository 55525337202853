export const patterns = {
    "Azul Básico": {
        name: 'Azul Básico',
        bgType: 'color',
        bgUrl: '',
        bgColor: '#145876',
        gradientColors: [],
        gradientAngle: 0,
        showTopText: true,
        topText: 'Escolha sua Senha!',
        topTextColor: '#ffffff',
        topTextSize: 13,
        topTextWeight: 6,
        showBottomText: true,
        bottomText: 'e aguarde!',
        bottomTextColor: '#ffffff',
        bottomTextSize: 12,
        bottomTextWeight: 4,
        width: 80,
        height: 10,
        stroke: false,
        strokeWidth: 0,
        radius: 10,
        strokeColor: '#ffffff',
        bg: true,
        buttonColor: '#ffffff',
        buttonOpacity: 10,
        textSize: 12,
        textWeight: 9,
        textColor: '#145876'
    },
    "Azul Clean": {
        name: "Azul Clean",
        bgType: "gradient",
        bgUrl: "",
        bgColor: "",
        gradientColors: ["#00d0ff","#092a3e"],
        gradientAngle: 200,
        showTopText: false,
        topText: "",
        topTextColor: "",
        topTextSize: null,
        topTextWeight: null,
        showBottomText: false,
        bottomText: "",
        bottomTextColor: "",
        bottomTextSize: null,
        bottomTextWeight: null,
        width: 70,
        height: 10,
        stroke: true,
        strokeWidth: 1,
        radius: 20,
        strokeColor: "#ffffff",
        bg: false,
        buttonColor: "",
        buttonOpacity: 0,
        textSize: 12,
        textWeight: 1,
        textColor: "#ffffff"
    },
    "Cinza e Verde": {
        name: "Cinza e Verde",
        bgType: "gradient",
        bgUrl: "",
        bgColor: "",
        gradientColors: ["#bdbdbd","#eeeeee"],
        gradientAngle: 0,
        showTopText: true,
        topText: "Qual serviço você deseja?",
        topTextColor: "#00BFA5",
        topTextSize: 10,
        topTextWeight: 5,
        showBottomText: true,
        bottomText: "Volte Sempre!",
        bottomTextColor: "#00BFA5",
        bottomTextSize: 10,
        bottomTextWeight: 5,
        width: 70,
        height: 10,
        stroke: false,
        strokeWidth: 1,
        radius: 0,
        strokeColor: "",
        bg: true,
        buttonColor: "#00BFA5",
        buttonOpacity: 10,
        textSize: 10,
        textWeight: 5,
        textColor: "#ffffff"
    },
    "Medical 1": {
        name: "Medical 1",
        bgType: "image",
        bgUrl: "https://uploads-rhomi.s3.amazonaws.com/2ff111db4a00516051e2aecf52a164e8-bg_medical_1.jpg",
        bgColor: "",
        gradientColors: ["#bdbdbd","#eeeeee"],
        gradientAngle: 0,
        showTopText: true,
        topText: "Como podemos ajudá-lo?",
        topTextColor: "#ffffff",
        topTextSize: 10,
        topTextWeight: 6,
        showBottomText: true,
        bottomText: "Obrigado por sua presença!",
        bottomTextColor: "#ffffff",
        bottomTextSize: 10,
        bottomTextWeight: 6,
        width: 70,
        height: 10,
        stroke: false,
        strokeWidth: 1,
        radius: 20,
        strokeColor: "",
        bg: true,
        buttonColor: "#0d47a1",
        buttonOpacity: 7,
        textSize: 10,
        textWeight: 5,
        textColor: "#ffffff"
    },
    "Medical 2": {
        name: "Medical 2",
        bgType: "image",
        bgUrl: "https://uploads-rhomi.s3.sa-east-1.amazonaws.com/7efbd6fb3a23ee9f55f9027be19e68fc-bg_medical_2.jpg",
        bgColor: "",
        gradientColors: ["#bdbdbd","#eeeeee"],
        gradientAngle: 0,
        showTopText: true,
        topText: "Como podemos ajudá-lo?",
        topTextColor: "#00897b",
        topTextSize: 10,
        topTextWeight: 5,
        showBottomText: true,
        bottomText: "Obrigado por sua presença!",
        bottomTextColor: "#00897b",
        bottomTextSize: 10,
        bottomTextWeight: 5,
        width: 80,
        height: 10,
        stroke: false,
        strokeWidth: 1,
        radius: 10,
        strokeColor: "",
        bg: true,
        buttonColor: "#1DE9B6",
        buttonOpacity: 5,
        textSize: 10,
        textWeight: 6,
        textColor: "#00897b"
    },
    "Metropolitan": {
        name: "Metropolitan",
        bgType: "image",
        bgUrl: "https://uploads-rhomi.s3.sa-east-1.amazonaws.com/075166b02810dfa192d9e4d4c8eac3a5-bg_bancos.jpg",
        bgColor: "",
        gradientColors: ["#bdbdbd","#eeeeee"],
        gradientAngle: 0,
        showTopText: true,
        topText: "Bem-Vindo!",
        topTextColor: "#ffffff",
        topTextSize: 10,
        topTextWeight: 2,
        showBottomText: true,
        bottomText: "Obrigado pela preferência!",
        bottomTextColor: "#ffffff",
        bottomTextSize: 8,
        bottomTextWeight: 2,
        width: 80,
        height: 10,
        stroke: true,
        strokeWidth: 1,
        radius: 10,
        strokeColor: "#ffffff",
        bg: true,
        buttonColor: "#ffffff",
        buttonOpacity: 2,
        textSize: 10,
        textWeight: 2,
        textColor: "#ffffff"
    }
}

export const patternOptions = Object.keys(patterns)