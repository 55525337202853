import { AuthContext } from "../contexts/AuthProvider"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function PermissionChecker({service, children}){

    const { userAdmin } = useContext(AuthContext)
    const [allowed, setAllowed] = useState(false)

    const navigate = useNavigate()

    const checkPermission = () => {
        if(!userAdmin.permissions.includes(service)) return 1
        return 0
    }
    
    useEffect(() => {
        if(checkPermission()) navigate('../')
        else setAllowed(true)
    },[service])

    return(
        <>
            {allowed && children}
        </>
    )
}