export const decimalTimeToString = (decimal) => {
    const hours = Math.floor(decimal).toString().padStart(2, '0')
    const minutes = getDecimalPartInMinutes(decimal).toString().padStart(2, '0')
    
    return `${hours}:${minutes}`
}

export const getDecimalPartInMinutes = (number) => {
        
    if(Number.isInteger(number)) return 0
    
    let string = number.toString().split('.')[1]
    string = `0.${string}`
    const decimal = parseFloat(string)
    
    return Math.floor(decimal * 60)
}

export const hoursIntervalInDecimal = (start, end) => {
    
    const start_dec = timeStringToDecimal(start)
    const end_dec = timeStringToDecimal(end)
    const interval_dec = end_dec - start_dec
    
    return interval_dec
}

export const timeStringToDecimal = (timeString) => {
    const hours = parseInt(timeString.substring(0,2))
    const minutes = parseInt(timeString.substring(3,5))

    return hours + (minutes/60)
}