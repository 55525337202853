import ShrinkBox2 from "../../shrinkBox2";
import { MdOutlineWorkspaces } from 'react-icons/md'
import ServiceBoxPasswords from "./ServiceBoxPasswords";
import ToggleLabel from "../../form/ToggleLabel";
import { Spacer } from "../../layout/Layout";
import { destroy, update } from "../../../API/queries";
import { useContext } from "react";
import { DataContext } from "../../../contexts/DataProvider";
import { toast } from "react-toastify";
import { PlaceContext } from "../../../contexts/PlaceContext";

export default function ServiceBox({service}){

    const { placeId } = useContext(PlaceContext)
    const { services, setServices } = useContext(DataContext)

    const checkServicesStatus = () => {
        const status = services.filter(el => el.placeId === placeId && el.status)
        
        return status.length !== 1
    }

    const handleServiceStatusChange = (status) => {

        if(!checkServicesStatus() && !status) return toast.error('É necessário manter pelo menos um serviço habilitado', {containerId: 'alert'})

        update('service', service._id, {status}).then((res) => {

            const temp = [...services]
            let index = temp.findIndex(temp => temp._id === service._id)

            if(res.status === 200){
                setServices( prev => {
                    prev[index] = {...prev[index], status}
                    return [...prev]
                })
            }
            else toast.error(res, {containerId: 'alert'})
        }).catch((err) => {
            toast.error(err, {containerId: 'alert'})
        })
    }

    const handleDeleteService = () => {
        const temp = [...services]
        let index = temp.findIndex(temp => temp._id === service._id)

        if(index >= 0 && temp.length>1){
            
            toast.promise(
                destroy('service', service._id).then((res) => {
                    temp.splice(index, 1)
                    setServices(temp)
                }),
                {
                    pending: 'Excluindo Serviço...',
                    success: 'Serviço excluído com sucesso!',
                    error: 'Erro: Não foi possível excluir o Serviço'
                }, {containerId: 'alert'}
            )
        }
        else toast.error('É necessário manter pelo menos um Serviço', {containerId: 'alert'})
    }

    return (    
        <ShrinkBox2
            icon={<MdOutlineWorkspaces/>}
            title={`${service.name} (${service.prefix})`}
            headerContent={
                <>
                    <Spacer left={3}/> 
                    <ToggleLabel
                        id={service._id}
                        text={'Habilitar Serviço'}
                        state={service.status}
                        help={'Habilite o serviço para disponibilizá-lo para atendimento. Caso esteja desabilitado não será possível emitir senhas do mesmo.'}
                        onToggle={(status) => handleServiceStatusChange(status)}
                    />
                </>
            } 
            onDelete={() => handleDeleteService()}
        >
            <ServiceBoxPasswords
                service={service}
            />
        </ShrinkBox2>
    )
}