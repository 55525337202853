import { Row, Icon, Text } from '../../../layout/Layout'
import { AiOutlinePlusCircle } from 'react-icons/ai'

export default function AddConfigButton({onClick}){
    
    return(
        <Row align={'center'}>
            <Icon pointer color={'#78909C'} size={18} onClick={() => onClick()}>
                <AiOutlinePlusCircle/>
            </Icon>
            <Text height={'35px'} align={'center'} color={'#1565C0'} decoration={'underline'} pointer onClick={() => onClick()}>Adicionar Nova Interface</Text>
        </Row>
    )
}