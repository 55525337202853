import styled from "styled-components"

const Select = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.1rem;
    padding: 0 1rem;
    margin-right: 1rem;
    border: 1px solid #00BFA5;
    border-radius: 0.6rem;
    background-color: ${props => props.state ? '#00BFA5' : 'white'};
    color: ${props => props.state ? 'white' : '#37474F'};
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
`

export default function ServiceSelect({service, state, onClick}){
    
    return(
        <Select state={state} onClick={() => onClick()}>
            {service}
        </Select>
    )
}