export const priorRulesInfo = <p style={{textAlign: 'left'}}>
Define a <strong>Regra</strong> que será utilizada para chamar <strong>Senhas Prioritárias.</strong><br/><br/>
Você pode definir <strong>até 5 regras</strong>, que serão utilizadas para configurar os <strong>Tipos de Senhas</strong> que o Estabelecimento disponibilizará para atendimento.<br/><br/>
O sistema disponibiliza <strong>4 tipos de regras</strong> para serem configuradas, são elas:<br/><br/>
<strong>Atendimento imediato: </strong>A senha será chamada imediatamente no próximo atendimento.<br/><br/>
<strong>Por tempo de espera: </strong>Define um tempo máximo de espera para chamar a senhas prioritárias.<br/><br/>
<strong>Por intervalo de senhas: </strong>Define uma quantidade de senhas não-prioritárias a serem chamadas antes da próxima senha prioritária. <br/><br/>
<strong>Por proporção máxima: </strong> Define uma proporção máxima de senhas não-prioritárias que deve haver para chamar a próxima senha prioritária.<br/><br/>
Para <strong>adicionar uma Nova Regra</strong>, configure as suas opções na caixa à direita, e depois clique em <strong>Adicionar</strong>. Você também pode <strong>excluir regras</strong> já criadas clicando no ícone de excluir ao lado de cada uma.<br/><br/>
<strong>Obs.:</strong> O sistema já vem <strong>com duas Regras como padrão</strong>, para facilitar a sua operação, que se aplicam à maioria dos casos. Porém você poderá excluí-las e configurar as suas próprias regras como desejar.
</p>