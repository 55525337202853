import { Text, Row, Icon } from "../../../layout/Layout"
import { IoMdAdd } from "react-icons/io"

export default function AddButton({text, onClick}){

    return(
        <Row align={'center'} justify={'flex-end'}>
            <Icon pointer color={'#555'} margin={'2px'} size={14} onClick={() => onClick()}>
                <IoMdAdd/>
            </Icon>
            <Text height={'35px'} align={'center'} color={'#1565C0'} decoration={'underline'} pointer onClick={() => onClick()}>{text}</Text>
        </Row>
    )
}