import { Column, Row } from "../../../../layout/Layout"
import { getColor } from "../../../../../content/Colors"
import { SideBox, Spacer, Text } from "./styles"
import Clock from "./Clock"

export default function SidePanel3({config}){

    return(
        <Column>
            <SideBox height={config.show_time ? 37 : 39} color={getColor(config.theme, 600)}>
                <Text size={0.9} weight={300}>Senha</Text>
                <Text size={1.3} weight={600}>NR004</Text>
                <Spacer top={0.3}/>
                <Text size={0.9} weight={300}>Guichê</Text>
                <Text size={1.3} weight={600}>Guichê 1</Text>
            </SideBox>
            <SideBox height={7} color={getColor(config.theme, 900)}>
                <Text size={0.7} weight={400}>Últimas Chamadas</Text>
            </SideBox>
            <SideBox height={config.show_time ? 15 : 18} color={getColor(config.theme, 500)}>
                <Row>
                    <Column>
                        <Text size={0.7} weight={300}>Senha</Text>
                        <Text size={1} weight={500}>NR003</Text>
                    </Column>
                    <Column>
                        <Text size={0.7} weight={300}>Guichê</Text>
                        <Text size={1} weight={500}>Guichê 3</Text>
                    </Column>
                </Row>
            </SideBox>
            <SideBox height={config.show_time ? 15 : 18} color={getColor(config.theme, 400)}>
                <Row>
                    <Column>
                        <Text size={0.7} weight={300}>Senha</Text>
                        <Text size={1} weight={500}>NR002</Text>
                    </Column>
                    <Column>
                        <Text size={0.7} weight={300}>Guichê</Text>
                        <Text size={1} weight={500}>Guichê 2</Text>
                    </Column>
                </Row>
            </SideBox>
            <SideBox height={config.show_time ? 15 : 18} color={getColor(config.theme, 300)}>
                <Row>
                    <Column>
                        <Text size={0.7} weight={300}>Senha</Text>
                        <Text size={1} weight={500}>NR001</Text>
                    </Column>
                    <Column>
                        <Text size={0.7} weight={300}>Guichê</Text>
                        <Text size={1} weight={500}>Guichê 1</Text>
                    </Column>
                </Row>
            </SideBox>
            {
                config.show_time &&
                <SideBox height={11}>
                    <Clock width={100}/>
                </SideBox>
            }
        </Column>
    )
}