import { Box, Text, Icon, Row, Spacer, Column } from "../layout/Layout";
import styled from "styled-components";
import { BsCalendar2Plus, BsCalendar2Check, BsX } from 'react-icons/bs'
import Select from "../form/Select";
import WeekdaySelector from "../others/WeekdaySelector";
import { useState } from "react";
import { toast } from "react-toastify";

const Section = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};
    align-items: center;
    width: 100%;
    margin-top: 20px;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 30px;
    border: 1px solid #37474F;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 400;
    background-color: white;
    margin-top: 20px;
    color: #37474F;
    cursor: pointer;

    svg{
        font-size: 16px;
        margin-right: 5px;
    }

    &:hover{
        border: 1px solid #00BFA5;
    }

    &:active{
        background-color: #00BFA5;
        color: white;

        svg{
            color: white;
        }
    }
`

const QuitIcon = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
`

export default function AddInterfaceModal({interfaces, onAdd, onClose}){
    
    const [selectedId, setSelectedId] = useState(null)
    const [selectedType, setSelectedType] = useState(null)
    
    const [selectedSeasonStart, setSelectedSeasonStart] = useState(null)
    const [selectedSeasonEnd, setSelectedSeasonEnd] = useState(null)
    const [selectedTimeStart, setSelectedTimeStart] = useState(null)
    const [selectedTimeEnd, setSelectedTimeEnd] = useState(null)
    const [selectedWeekdays, setSelectedWeekdays] = useState([])
    
    const getOptions = () => {
        const array = []

        interfaces.forEach(el => array.push(el.name))

        return array
    }

    const handleOptSelect = (option) => {
        setSelectedId(interfaces.find(el => el.name === option)._id)
    }

    const validateSeasonInterval = () => {
        const startYear = parseInt(selectedSeasonStart.slice(0,4))
        const endYear = parseInt(selectedSeasonEnd.slice(0,4))
        const startMonth = parseInt(selectedSeasonStart.slice(5,7))
        const endMonth = parseInt(selectedSeasonEnd.slice(5,7))
        const startDay = parseInt(selectedSeasonStart.slice(8))
        const endDay = parseInt(selectedSeasonEnd.slice(8))

        if(startYear > endYear) return 0
        if(startMonth > endMonth) return 0
        if(startDay >= endDay) return 0
        return 1
    }

    const validateTimeInterval = () => {
        const startHour = parseInt(selectedTimeStart.slice(0,3))
        const endHour = parseInt(selectedTimeEnd.slice(0,3))

        if(startHour > endHour) return 0
        return 1
    }

    const checkData = () => {

        if(selectedId === null){
            toast.error('Selecione uma Interface para adicionar', {containerId: 'alert'})
            return 0
        }
        if(selectedType === null){
            toast.error(`Selecione um tipo de agendamento`, {containerId: 'alert'})
            return 0
        }
        if(selectedType === 'season' && (selectedSeasonStart === null || selectedSeasonEnd === null)){
            console.log('season')
            toast.error('Preencha corretamente as datas de início e fim do agendamento', {containerId: 'alert'})
            return 0
        } 
        if(selectedType === 'season' && !validateSeasonInterval()){
            toast.error('A data de início não pode ser maior ou igual à data de fim', {containerId: 'alert'})
            return 0
        } 
        if(selectedType === 'time' && (selectedTimeStart === null || selectedTimeEnd === null)){
            toast.error('Preencha corretamente o horário de início e fim do agendamento', {containerId: 'alert'})
            return 0
        } 
        if(selectedType === 'time' && !validateTimeInterval()){
            toast.error('A hora de início não pode ser maior ou igual à hora de fim', {containerId: 'alert'})
            return 0
        } 
        if(selectedType === 'weekday' && selectedWeekdays.length === 0){
            toast.error('Selecione pelo menos um dia da semana', {containerId: 'alert'})
            return 0
        } 

        return 1
    }

    const handleCreate = () => {
        
        if(!checkData()) return

        const data = {
            default: false,
            use_id: selectedId,
            schedule:{
                type: selectedType,
                season: {
                    start: selectedType === 'season' ? selectedSeasonStart : null, 
                    end: selectedType === 'season' ? selectedSeasonEnd : null, 
                },
                weekday: selectedType === 'weekday' ? selectedWeekdays : null,
                time: {
                    start: selectedType === 'time' ? selectedTimeStart : null, 
                    end: selectedType === 'time' ? selectedTimeEnd : null, 
                }
            }
        }

        onAdd(data)
        onClose()
    }

    return(
        <Box width={'35%'}>
            <Column marginLeft={15} marginRight={15}>
                <Row align={'center'}>
                    <Icon size={20} color={'#00BFA5'}>
                        <BsCalendar2Plus/>
                    </Icon>
                    <Text wrap={'pre'} size={14}>Adicionar um Design de Interface</Text>
                </Row>
                <Spacer top={2}/>
                <Select label={'Design da Interface'} width={40} placeholder={'Selecione o Design'} options={getOptions()} help={'fsfdsvsvr'} onChange={(value) => handleOptSelect(value)}/>
                <Select label={'Quando utilizar esta Interface?'} width={40} placeholder={'Selecione o tipo'} values={['season', 'time', 'weekday']} options={['por período', 'por horário', 'por dia da semana']} help={'fsfdsvsvr'} onChange={(value) => setSelectedType(value)}/>
                <Section show={selectedType === 'season'}>
                    <Column justify={'center'} align={'center'}>
                        <Text weight={600}>{`Utilizar esta Interface entre o período de`}</Text>
                        <Spacer top={1}/>
                        <Row justify={'center'} align={'center'}>
                            <input type={'date'} onChange={(e) => setSelectedSeasonStart(e.target.value)}/>
                            <Text wrap={'pre'}>{'  até  '}</Text>
                            <input type={'date'} onChange={(e) => setSelectedSeasonEnd(e.target.value)}/>
                        </Row>
                    </Column>
                </Section>
                <Section show={selectedType === 'time'}>
                    <Column justify={'center'} align={'center'}>
                        <Text weight={600}>{`Utilizar esta Interface entre os horários de`}</Text>
                        <Spacer top={1}/>
                        <Row justify={'center'} align={'center'}>
                            <input type={'time'} onChange={(e) => setSelectedTimeStart(e.target.value)}/>
                            <Text wrap={'pre'}>{'  até  '}</Text>
                            <input type={'time'} onChange={(e) => setSelectedTimeEnd(e.target.value)}/>
                        </Row>
                    </Column>
                </Section>
                <Section show={selectedType === 'weekday'}>
                    <Column justify={''} align={'center'}>
                        <Text weight={600}>{`Utilizar esta Interface entre os horários de`}</Text>
                        <WeekdaySelector selected={selectedWeekdays} onSelect={(weekday) => setSelectedWeekdays(weekday)}/>
                    </Column>
                </Section>
                <Row justify={'center'}>
                    <Button onClick={() => handleCreate()}>
                        <BsCalendar2Check/>
                        Adicionar
                    </Button>
                </Row>
            </Column>
            <QuitIcon onClick={() => onClose()}>
                <Icon pointer size={24} color={'#90A4AE'} hoverColor={'#888'}>
                    <BsX/>
                </Icon>
            </QuitIcon>
        </Box>
    )
}