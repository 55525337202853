import { Row, Text, Spacer, Column, Icon } from "../../layout/Layout"
import { Input, Checkbox, Label } from "../../layout/Form"
import { BiPause, BiPlay } from 'react-icons/bi'
import { IoClose } from 'react-icons/io5'
import { HiPencil, HiCheck } from 'react-icons/hi'
import { useState, useContext } from "react"
import { toast } from "react-toastify"
import { AiOutlineCrown } from 'react-icons/ai'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import ConfirmationTooltip from "../../others/ConfirmationTooltip"
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';
import { validateNewNameExcluding } from "../../../methods/validateNewName"
import { validateEmailString } from "../../../methods/validateEmailString"
import { update } from "../../../API/queries"
import { DataContext } from "../../../contexts/DataProvider"
import UsersAdminRowPermissionDisplay from "./UsersAdminRowPermissionDisplay"

function UsersAdminRow({data, onDelete}){
    
    const { admins, setAdmins } = useContext(DataContext)

    const [editEnable, setEditEnable] = useState(false)
    const [deleteConfirm, setDeleteConfirm] = useState(false)

    const [tempData, setTempData] = useState(null)

    const permissionList = ['Dashboard', 'Dispositivos', 'Configurações', 'Emissão de Senhas', 'Usuários', 'Financeiro']
    
    const updateAdminData = (data) => {        
        
        const temp = [...admins]
        let index = temp.findIndex(temp => temp._id === data._id)

        setAdmins( prev => {
            prev[index] = data
            return [...prev]
        })
    }

    const updateAdminStatus = (status) => {
        const temp = [...admins]
        let index = temp.findIndex(temp => temp._id === data._id)

        setAdmins( prev => {
            prev[index] = {...prev[index], active: status}
            return [...prev]
        })
    }
    
    const handleStatusChange = (status) => {
        
        update('admin', data._id, {active: status}).then(res => {
            if(res.data.active === status){
                updateAdminStatus(status)
                if(status) toast.success('O Administrador está Ativo agora!', {containerId: 'alert'})
                else toast.warn('O Administrador está Inativo agora!', {containerId: 'alert'})
            }
        }).catch(err => {
            toast.error(`Não foi possível modificar o status do Administrador. Error: ${err.message}`, {containerId: 'alert'})
        })
    }

    const checkEmail = () => {
        
        const _admins = admins.filter(el => el._id !== data._id)
        
        return _admins.every(el => el.email !== tempData.email)
    }

    const checkTempData = () => {
        if(!tempData.name) {
            toast.error('Insira corretamente o Nome do Administrador', {containerId: 'alert'})
            return 0
        }

        if(!tempData.role) {
            toast.error('Insira corretamente o Cargo do Administrador', {containerId: 'alert'})
            return 0
        }

        if(!tempData.email) {
            toast.error('Insira corretamente o E-mail do Administrador', {containerId: 'alert'})
            return 0
        }
        
        if(!validateNewNameExcluding(admins, tempData.name, data.name)) {
            toast.error(`O nome ${tempData.name} já está sendo utilizado.`, {containerId: 'alert'})
            return 0
        }
        
        if(!validateEmailString(tempData.email)) {
            toast.error('O e-mail informado é inválido', {containerId: 'alert'})
            return 0
        }

        if(!checkEmail()) {
            toast.error(`O e-mail ${tempData.email} já está sendo utilizado.`, {containerId: 'alert'})
            return 0
        }

        if(tempData.name.length < 4) {
            toast.error('O Nome do Administrador deve conter pelo menos 4 dígitos', {containerId: 'alert'})
            return 0
        }

        if(tempData.role.length < 4) {
            toast.error('O Cargo do Administrador deve conter pelo menos 4 dígitos', {containerId: 'alert'})
            return 0
        }
        
        if(tempData.permissions.length === 0) {
            toast.error('Selecione pelo menos uma permissão para este Administrador', {containerId: 'alert'})
            return 0
        }

        return 1
    }

    const handleConfirm =  () => {
        document.body.removeEventListener('click', disableEdit, true)

        const updateData = {...tempData}

        if(!checkTempData(updateData)) return

        update('admin', data._id, updateData).then(res => {
            updateAdminData(res.data)
            setEditEnable(false)
            toast.success('Os dados do Administrador foram atualizados com sucesso!', {containerId: 'alert'})
        }).catch(err => {
            console.log(err)
            setEditEnable(false)
            toast.error(`Não foi possível atualizar os dados do Administrador. Error: ${err.message}`, {containerId: 'alert'})
        })
    }

    const handleCancel = () => {
        document.body.removeEventListener('click', disableEdit, true)
        setTempData(null)
        setEditEnable(false)
    }
    
    const disableEdit = (e) => {

        if(e.target.id.includes(data._id) || e.target.parentElement.id.includes(data._id)) return

        handleCancel()
    }

    const handleEditEnable = () => {
        document.body.addEventListener('click', disableEdit, true)

        const { name, role, email, permissions } = data

        setTempData({name, role, email, permissions})
        setEditEnable(true)
    }

    function handleKeyPress(key){
        if(key === 'Enter') handleConfirm()
        else if(key === 'Escape') handleCancel()
    }

    const handleCheck = (check, option) => {
        
        const temp = [...tempData.permissions]
    
        if(check) temp.push(option)
        else{
            const index = temp.findIndex(temp => temp === option)
            if(index >= 0) temp.splice(index, 1)
        }

        setTempData(tempData => ({...tempData, permissions: temp}))   
        
    } 
    
    return (
        <Row borderBtmWidth={1} borderBtmColor={'#CFD8DC'} borderBtmStyle={'solid'} >
            <Column>
                <Row align={'center'} height={40}>
                    <Column>
                        <Row align={'center'}>
                            {
                                data?.master && 
                                <Icon size={16} color={'#F9A825'}>
                                    <AiOutlineCrown/>
                                </Icon>
                            }
                            {
                                !editEnable ? 
                                <Text weight={600}>
                                    {!data.name ? 'Administrador' : data.name}
                                </Text>
                                :
                                <Input id={'nome-'+data._id} width={90} maxLength="30" error={!tempData.name} valid={tempData.name?.length >= 4} value={tempData.name} onChange={(e) => setTempData(tempData => ({...tempData, name: e.target.value}))} onKeyDown={(e) => handleKeyPress(e.key)}></Input>
                            }
                        </Row>
                    </Column>
                    <Column width={60}>
                        {
                            !editEnable ? 
                            <Text>
                                {!data.role ? 'Cargo' : data.role}
                            </Text>
                            :
                            <Input id={'cargo-'+data._id} width={90} maxLength="20" error={!tempData.role} valid={tempData.role?.length >= 4} value={tempData.role} onChange={(e) => setTempData(tempData => ({...tempData, role: e.target.value}))} onKeyDown={(e) => handleKeyPress(e.key)}></Input>
                        }
                    </Column>
                    <Column width={60}>
                        <Text>
                            {new Date(data.since).toLocaleString([], {day: '2-digit', month:'2-digit', year:'2-digit'})}
                        </Text>
                    </Column>
                    <Column>
                        {
                            !editEnable ? 
                            <Text>
                                {!data.email ? 'E-mail' : data.email}
                            </Text>
                            :
                            <Input id={'cargo-'+data._id} width={90} maxLength="50" error={!validateEmailString(tempData.email)} valid={validateEmailString(tempData.email)} value={tempData.email} onChange={(e) => setTempData(tempData => ({...tempData, email: e.target.value}))} onKeyDown={(e) => handleKeyPress(e.key)}></Input>
                        }
                    </Column>
                    <Column width={60}>
                        {
                            !data.verified ?
                            <Text wrap={'wrap'} weight={600} color={'#fb9800'}>Aguardando confirmação<br/>de e-mail</Text>
                            :
                            data.active ?
                            <Text weight={600} color={'#00C853'}>Ativo</Text>
                            :
                            <Text weight={600} color={'#F34236'}>Inativo</Text>
                        }
                    </Column>
                    <Column width={80}>
                        {
                            !data.master &&
                            <Row align={'center'} justify={'space-around'}>
                                {
                                    !editEnable ? 
                                    <Tippy content='Editar'>
                                        <Icon id={'pencil'+data._id} size={15} color={'#0091EA'} pointer={true} onClick={() => handleEditEnable()}>
                                            <HiPencil id={data._id}/>
                                        </Icon>
                                    </Tippy>
                                    :
                                    <Tippy content='Confirmar'>
                                        <Icon id={data._id} size={20} color={'#00C853'} pointer={true} onClick={() => handleConfirm()}>
                                                <HiCheck id={data._id}/>
                                        </Icon>
                                    </Tippy>
                                }
                                {
                                    !data.verified ?
                                    <Tippy content='Inativar Administrador'>
                                        <Icon size={23} color={'#B0BEC5'} pointer={true} onClick={() => {}}>
                                            <BiPause/>
                                        </Icon> 
                                    </Tippy> 
                                    :
                                    data.active ? 
                                    <Tippy content='Inativar Administrador'>
                                        <Icon size={23} color={'#fb9800'} pointer={true} onClick={() => handleStatusChange(false)}>
                                            <BiPause/>
                                        </Icon> 
                                    </Tippy> 
                                    :
                                    <Tippy content='Ativar Administrador'>
                                        <Icon size={23} color={'#00C853'} pointer={true} onClick={() => handleStatusChange(true)}>
                                            <BiPlay/>
                                        </Icon>
                                    </Tippy>
                                }
                                <Tooltip theme="light" trigger="click" open={deleteConfirm} interactive={true} onRequestClose={() => setDeleteConfirm(false)} html={(<ConfirmationTooltip text={'Deseja Excluir?'} onYes={() => {onDelete(data._id); setDeleteConfirm(false)}} onNo={() => setDeleteConfirm(false)}/>)}>
                                    <Tippy content='Excluir Administrador'>
                                        <Icon size={18} color={'#F34236'} pointer={true} onClick={() => setDeleteConfirm(true)}>
                                            <IoClose/>
                                        </Icon>
                                    </Tippy>
                                </Tooltip>
                            </Row>
                        }
                    </Column>
                </Row>
                {
                    !data.master &&
                    <Row align={'center'} height={40} bottom={10}>
                        <Column>
                            <Row>
                                <Spacer left={0.5}/>
                                {
                                    !editEnable ?
                                    <UsersAdminRowPermissionDisplay permissions={data.permissions}/>
                                    :
                                    permissionList.map((e, i) => {
                                        return (
                                            <span  key={i}>
                                                <Checkbox id={e+data._id} checked={tempData.permissions.includes(e)} type={'checkbox'} onChange={(event) => handleCheck(event.target.checked, e)}></Checkbox>
                                                <Label id={e+data._id} htmlFor={e+data._id}>{e}</Label>
                                            </span>
                                        )
                                    })                                  
                                }
                            </Row>
                        </Column>
                    </Row>
                }
            </Column>  
        </Row>
    )
}

export default UsersAdminRow

