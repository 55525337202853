import styled from 'styled-components'
import { IoClose } from 'react-icons/io5'

const But = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid #F34236;
    border-radius: 1rem;
    cursor: ${props => props.status && 'pointer'};

    &:hover{
        background-color: #F34236;
    
        svg{
            color: white;
        }
    
    }

    svg{
        font-size: 1.5rem;
        color: #F34236;
    }
`

function No({status, onClick}){
    
    return(
        <But status={status} onClick={onClick}>
            <IoClose/>
        </But>
    )
}

export default No