import { LoggedAccountWrapper, Avatar, AccountInfo, AccountName, AccountLink, LogoutIcon, UserName } from "./style"
import { FiLogOut } from 'react-icons/fi'
import { AuthContext } from "../../contexts/AuthProvider"
import { useContext } from "react"
import Modal from '../modals/Modal'
import LogoutConfirmationModal from "../modals/LogoutConfirmationModal"
import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tippy";

export default function LoggedAccount({show, onAnyAction}){

    const { business, userAdmin } = useContext(AuthContext)
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false)

    const handleLogoutClick = () => {
        onAnyAction()
        setShowLogoutConfirm(true)
    }

    const handleLogoutConfirm = () => {
        window.location.replace('https://login.phila.com.br')
    }

    const navigate = useNavigate()

    const navigateToAccount = () => {
        onAnyAction()
        navigate('../account')
    }

    return(
        <LoggedAccountWrapper show={show}>
            <Avatar url={business.avatar_url} onClick={navigateToAccount}/>
            <AccountInfo show={show}>
                <AccountName>
                    {business?.name}
                </AccountName>
                <UserName>
                    {userAdmin?.name}
                </UserName>
                <AccountLink onClick={navigateToAccount}>
                    Minha Conta
                </AccountLink>
            </AccountInfo>
            <LogoutIcon show={show} onClick={() => handleLogoutClick()}>
                <Tooltip arrow={true} theme={'light'} title='Sair do Sistema'>
                    <FiLogOut/>
                </Tooltip>
            </LogoutIcon>
            <Modal isOpen={showLogoutConfirm}>
                <LogoutConfirmationModal onNo={() => setShowLogoutConfirm(false)} onYes={() => handleLogoutConfirm()}/>
            </Modal>
        </LoggedAccountWrapper>
    )
}