import './App.css';
import {Layout, SidebarContainer, Content } from './AppStyle';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideMenu from './components/sidemenu';
import { AuthProvider,  } from './contexts/AuthProvider';
import { NotifProvider } from './contexts/NotifContext'
import { ConfigProvider } from "./contexts/ConfigContext";
import { DataProvider } from './contexts/DataProvider';
import { CallsProvider } from './contexts/CallsProvider';
import { PlaceProvider } from './contexts/PlaceContext';
import PermissionChecker from './methods/PermissionChecker';

//---------------- PAGES -----------------//
import Home from './pages/home';
import MyAccount from './pages/MyAccount';

import DashboardGeneral from './pages/DashboardGeneral'
import DashboardQueue from './pages/DashboardQueue'
import DashboardCalls from './pages/DashboardCalls'
import DashboardDesks from './pages/DashboardDesks'
import DashboardServices from './pages/DashboardServices';

import Terminals from './pages/Terminals'
import Interfaces from './pages/Interfaces'
import Panels from './pages/Panels'
import PwTypes from './pages/PwTypes'
import PwPriorRules from './pages/PwPriorRules';
import PwPrintLayouts from './pages/PwPrintLayouts';
import UsersAtend from './pages/UsersAtend'
import UsersAdmin from './pages/UsersAdmin'
import Config from './pages/Config';
import DashboardAttendantPerformance from './pages/dashboardAttendantPerformance';
import ServicesAnalyticsPage from './pages/servicesAnalyticsPage';

import { AttendantPerformanceProvider } from './pages/dashboardAttendantPerformance/AttendantPerformanceProvider';


function App() {

  return (
    <Layout>
      <AuthProvider>
        <DataProvider>
          <PlaceProvider>
            <ConfigProvider>
              <CallsProvider>
                <Router>
                  <NotifProvider>
                    <SidebarContainer>
                      <SideMenu/>
                    </SidebarContainer>
                    <Content>
                        <Routes>
                          <Route path='/' element={<Home/>} />
                          <Route path='account' element={<MyAccount/>} />
                          <Route path='geral' element={<PermissionChecker service={'Dashboard'}><DashboardGeneral/></PermissionChecker>} />
                          <Route path='fila' element={<PermissionChecker service={'Dashboard'}><DashboardQueue/></PermissionChecker>} />
                          <Route path='atendimentos' element={<PermissionChecker service={'Dashboard'}><DashboardCalls/></PermissionChecker>} />
                          <Route path='guiches' element={<PermissionChecker service={'Dashboard'}><DashboardDesks/></PermissionChecker>} />
                          <Route path='services' element={<PermissionChecker service={'Dashboard'}><DashboardServices/></PermissionChecker>} />
                          <Route path='services-analytics' element={<PermissionChecker service={'Dashboard'}><ServicesAnalyticsPage/></PermissionChecker>} />
                          <Route path='attendant-performance' element={<PermissionChecker service={'Dashboard'}>
                            <AttendantPerformanceProvider>
                              <DashboardAttendantPerformance />
                            </AttendantPerformanceProvider>
                          </PermissionChecker>} />
                          <Route path='terminais' element={<PermissionChecker service={'Dispositivos'}><Terminals/></PermissionChecker>} />
                          <Route path='interfaces' element={<PermissionChecker service={'Dispositivos'}><Interfaces/></PermissionChecker>} />
                          <Route path='panels' element={<PermissionChecker service={'Dispositivos'}><Panels/></PermissionChecker>} />
                          <Route path='senhas/regras' element={<PermissionChecker service={'Emissão de Senhas'}><PwPriorRules/></PermissionChecker>} />
                          <Route path='senhas/layout' element={<PermissionChecker service={'Emissão de Senhas'}><PwPrintLayouts/></PermissionChecker>} />
                          <Route path='senhas/tipos' element={<PermissionChecker service={'Emissão de Senhas'}><PwTypes/></PermissionChecker>} />
                          <Route path='usersAtend' element={<PermissionChecker service={'Usuários'}><UsersAtend/></PermissionChecker>} />
                          <Route path='usersAdmin' element={<PermissionChecker service={'Usuários'}><UsersAdmin/></PermissionChecker>} />
                          <Route path='config' element={<PermissionChecker service={'Configurações'}><Config/></PermissionChecker>} />
                        </Routes>
                    </Content>
                  </NotifProvider>  
                </Router>
              </CallsProvider>
            </ConfigProvider>  
          </PlaceProvider>
        </DataProvider>
      </AuthProvider>
      <ToastContainer enableMultiContainer containerId={'alert'} position="top-center" autoClose={3000}/>
      <ToastContainer enableMultiContainer containerId={'notify'} position="bottom-right" autoClose={10000}/>
    </Layout>
  )
}

export default App;
