import { Text, Column, Icon, Row, VertLine, Position } from "../../../../layout/Layout";
import { MdAirplay } from 'react-icons/md'
import { FiTrash2, FiEdit } from 'react-icons/fi'
import { HiCheck } from 'react-icons/hi'
import { BsCalendar2Date, BsCalendar2Day, BsClock } from 'react-icons/bs'
import AlertStamp from "./stamps/AlertStamp";
import ThemeStamp from "./stamps/ThemeStamp";
import ShowTimeStamp from "./stamps/ShowTimeStamp";
import ShowMarqueeStamp from "./stamps/ShowMarqueeStamp";
import ExhibitionStamp from "./stamps/ExhibitionStamp";
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import { Spacer } from "../preview/styles";
import ConfigBox from "./ConfigBox";
import { useState } from "react";
import ConfirmationTooltip from "../../../../others/ConfirmationTooltip";
import { weekdayString, seasonString, timeString } from "../../../../../services/schedulesStrings";

export default function ConfigRow({id, config, setConfig, expand, setExpand, onDelete}){
    
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    
    const nameString = () => {
        if(config.default) return 'Interface Padrão'

        if(config.schedule.type === 'weekday') return weekdayString(config.schedule.weekday)
        if(config.schedule.type === 'season') return seasonString(config.schedule.season.start, config.schedule.season.end)
        if(config.schedule.type === 'time') return timeString(config.schedule.time.start, config.schedule.time.end)
    }
    
    return(
        <>
            <Row borderBtmStyle={'solid'} borderBtmWidth={1} borderBtmColor={'#CFD8DC'} bottom={10} top={10} hoverColor={'#ECEFF1'} pointer onClick={() => setExpand()}>
                <Column>
                    <Row align={'center'} position={'relative'}>
                        <Column width={15}>
                            <Row align={'center'}>
                                {
                                    config.default ?
                                    <Icon size={20} color={'#00BFA5'}>
                                        <MdAirplay/>
                                    </Icon>
                                    :
                                    config.schedule.type === 'season' ?
                                    <Icon size={17} color={'#00BFA5'}>
                                        <BsCalendar2Date/>
                                    </Icon>
                                    :
                                    config.schedule.type === 'time' ?
                                    <Icon size={17} color={'#00BFA5'}>
                                        <BsClock/>
                                    </Icon>
                                    :
                                    config.schedule.type === 'weekday' &&
                                    <Icon size={17} color={'#00BFA5'}>
                                        <BsCalendar2Day/>
                                    </Icon>
                                }
                                <Text weight={600}>{nameString()}</Text>
                            </Row>
                        </Column>
                        <VertLine space={2}/>
                        <Column width={12}>
                            <AlertStamp state={config.alert_enable}/>
                        </Column>
                        <VertLine space={2}/>
                        <Column width={9}>
                            <ThemeStamp theme={config.theme}/>
                        </Column>
                        <VertLine space={2}/>
                        <Column width={12}>
                            <ShowTimeStamp state={config.show_time}/>
                        </Column>
                        <VertLine space={2}/>
                        <Column width={12}>
                            <ShowMarqueeStamp state={config.show_marquee}/>
                        </Column>
                        <VertLine space={2}/>
                        <Column width={'fit-content'}>
                            <ExhibitionStamp type={config.exhibition} urls={config.exhibition === 'images' ? config.gallery_urls : config.exhibition === 'videos' ? config.videos_urls : []}/>
                        </Column>
                        <Position right={10}>
                            <Row align={'center'} justify={'flex-end'}>
                                {
                                    expand ? 
                                    <Tooltip title='Concluir Personalização' arrow={true} followCursor={false} theme={'light'}>
                                        <Icon pointer size={22} color={'#00C853'} onClick={() => setExpand()}>
                                            <HiCheck/>
                                        </Icon>
                                    </Tooltip>
                                    :
                                    <Tooltip title='Personalizar' arrow={true} followCursor={false} theme={'light'}>
                                        <Icon pointer size={16} color={'#78909C'} hoverColor={'#00BFA5'} onClick={() => setExpand()}>
                                            <FiEdit/>
                                        </Icon>
                                    </Tooltip>
                                }
                                {
                                    !config.default &&
                                    <>
                                        <Spacer left={1}/>
                                        <Tooltip theme="light" trigger="click" open={deleteConfirm} interactive={true} onRequestClose={() => setDeleteConfirm(false)} html={(<ConfirmationTooltip text={'Deseja Excluir?'} onYes={() => {onDelete(); setDeleteConfirm(false)}} onNo={() => setDeleteConfirm(false)}/>)}>
                                            <Tooltip title='Excluir' arrow={true} followCursor={false} theme={'light'}>
                                                <Icon pointer size={16} color={'#78909C'} hoverColor={'#F34236'} onClick={() => setDeleteConfirm(true)}>
                                                    <FiTrash2/>
                                                </Icon>
                                            </Tooltip>
                                        </Tooltip>
                                    </>
                                }
                            </Row>
                        </Position>
                    </Row>
                </Column>
            </Row>
            {
                expand && 
                <Row borderBtmStyle={'solid'} borderBtmWidth={1} borderBtmColor={'#CFD8DC'}>
                    <ConfigBox id={id} config={config} setConfig={setConfig}/>
                </Row>
            }
        </>
    )
}