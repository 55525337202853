import { Spacer, Wrapper, Row, Title, Line } from '../../../layout/Layout'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import HelpIcon from "../../../others/HelpIcon";
import { interfacesInfo } from '../../../../content/tooltips/displayTooltips'
import ConfigRow from './configs/ConfigRow'
import AddConfigButton from './AddConfigButton'
import NewConfigRowSchedule from './configs/NewConfigRowSchedule'
import { newPanelConfig } from '../../../../resources/newPanel'
import ServicesConfig from './ServicesConfig';

export default function PanelBox({data, setValue}){

    const {_id: id, configs, services } = data

    const [expands, setExpands] = useState([])
    const [addNewConfig, setAddNewConfig] = useState(false)

    useEffect(() => {
        
        const array = []

        for (let i = 0; i < data.configs.length; i++) array.push(false)
        setExpands(array)
    },[])

    const handleExpands = (index) => {

        const _expands = [...expands]

        const state = _expands[index]

        for(let i = 0; i < _expands.length; i++) _expands[i] = false
        
        _expands[index] = !state

        setExpands(_expands)
    }

    const handleConfigChange = (index, newConfig) => {
        const _configs = [...configs]

        _configs[index] = newConfig

        setValue(id, _configs, 'configs')
    }

    const handleNewConfig = (schedule) => {
        setAddNewConfig(false)

        const _configs = [...configs]

        _configs.push(newPanelConfig(schedule))
        
        setValue(id, _configs, 'configs')
    }

    const handleDelete = (index) => {
        const _configs = [...configs]

        _configs.splice(index, 1)

        setValue(id, _configs, 'configs')
    }

    const handleAddNewConfig = () => {
        if(configs.length === 5) return toast.error('Um Painel pode possuir no máximo 5 Interfaces.', {containerId: 'alert'})
        setAddNewConfig(true)
    }

    return(
        <Wrapper>
            <ServicesConfig services={services} onChange={(value) => setValue(id, value, 'services')}/>
            <Spacer top={3}/>
            <Row align={'center'}>
                <Title>Interfaces</Title>
                <HelpIcon content={interfacesInfo}/>
            </Row>
            <Spacer top={1}/>
            <Line/>
            {
                configs.map((e,i) => {
                    return <ConfigRow
                        key={i}
                        id={id}
                        config={e}
                        setConfig={(newConfig) => handleConfigChange(i, newConfig)}
                        expand={expands[i]}
                        setExpand={() => handleExpands(i)}
                        onDelete={() => handleDelete(i)}
                    />
                })
            }
            <Spacer top={1}/>
            {
                addNewConfig &&
                <>
                    <NewConfigRowSchedule onCreate={(schedule) => handleNewConfig(schedule)} onCancel={() => setAddNewConfig(false)}/>
                    <Spacer top={1}/>
                </>
            }
            <AddConfigButton onClick={() => handleAddNewConfig()}/>
        </Wrapper>
    )
}