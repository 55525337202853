import styled from "styled-components"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px 0 30px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 70%;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #CFD8DC;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }

`

const Row = styled.span`
    display: flex;
    align-items: center;
    justify-content: right;
    height: 13px;
    min-width: 150px;
`

const Text = styled.h1`
    font-size: 0.9rem;
    font-weight: 400;
    white-space: nowrap;
`

const LineWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 5px;
`

const LineTop = styled.span`
    border-bottom: 1px solid ${props => props.color};
    height: 50%;
    width: 100%;
`

const LineBottom = styled.span`
    height: 50%;
`

function LinesLegend({typeColors, calls}){

    const showType = (type) => {
        return calls.every(call => call.type !== type)
    }

    return (
        <Wrapper>
            <Row>
                <LineWrapper>
                    <LineTop color={'#01579B'}/>
                    <LineBottom/>
                </LineWrapper>
                <Text>Total</Text>
            </Row>
            {
                Object.keys(typeColors).map((e, i) => {
                    if(!showType(e)){
                        return <Row key={i}>
                                <LineWrapper>
                                    <LineTop color={typeColors[e]}/>
                                    <LineBottom/>
                                </LineWrapper>
                                <Text>{e}</Text>
                            </Row>
                        }
                })
            }
        </Wrapper>
    )
}

export default LinesLegend