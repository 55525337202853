import ToggleLoad from '../../form/ToggleLoad'
import FormTitle from '../../form/FormTitle'
import { Wrapper, Row, Spacer, VerticalLine, Column } from '../../layout/Layout'
import ToggleInput from '../../form/ToggleInput'
import ToggleLabel from '../../form/ToggleLabel'
import LabelInput from '../../form/LabelInput'
import PrintLayoutPreview from './PrintLayoutPreview'
import { printLayoutLogoInfo } from '../../../content/tooltips/printLayoutsTooltips'
import { create } from '../../../API/queries'
import { toast } from 'react-toastify'
import { useState } from 'react'

function PwPrintLayoutBox({data, onChange}){

    const [tempData, setTempData] = useState(data)
    
    const readLogoFile = (e) => {

        const reader = new FileReader()

        reader.readAsDataURL(e.target.files[0])

        return new Promise((resolve, reject) => {
            reader.onerror = () => reject('error')
            reader.onload = (e) => resolve(e.target.result)
        })
    }

    const validateLogoDimensions = (file) => {

        const logo = new Image()

        logo.src = file        

        return new Promise((resolve, reject) => {
            logo.onload = () => {
                const { height, width } = logo

                if(height < 98 || height > 102) reject()
                if(width < 298 || width > 302) reject()

                resolve()
            }
        })
    }
    
    const handleLogoUpload = async (e) => {

        e.preventDefault()
        const formData = new FormData();

        formData.append('file', e.target.files[0])

        const logoFile = await readLogoFile(e)

        try {
            await validateLogoDimensions(logoFile)
            console.log('Aprovada')

        } catch (error) {
            console.log('Desaprovada')
            return toast.error('A Logo deve ter uma resolução de 300 x 100 pixels', {containerId: 'alert'})
        }

        create('upload/logo', formData).then(res => {
            console.log('upload')

            onChange(data._id, res.data.url, 'logo_url')
            toast.success('A Logo for carregada com sucesso', {containerId: 'alert'})
        }).catch(err => {
            toast.error(`Não foi possível carregar a Logo. Error: ${err}`, {containerId: 'alert'})
        })

        e.target.value = ''
    }

    const handleToggleChange = (state, property) => {
        setTempData(prev => ({...prev, [property]: state}))

        onChange(data._id, state, property)
    }

    return(
        <Wrapper>
            <Row>
                <Column width={35}>
                    <FormTitle>Conteúdo</FormTitle>
                    <Row align={'center'}>
                        <ToggleLoad
                            id={'show_logo-'+data._id}
                            text={'Logo'} help={printLayoutLogoInfo}
                            state={data.show_logo}
                            onToggle={(state) => handleToggleChange(state, 'show_logo')}
                            onLoad={(e) => handleLogoUpload(e)}/>
                    </Row>
                    <Spacer top={1.8}/>
                    <ToggleInput
                        id={'show_title-'+data._id}
                        text={'Nome'}
                        max={16}
                        value={tempData.title}
                        state={data.show_title}
                        onToggle={(state) => handleToggleChange(state, 'show_title')}
                        input={(input) => setTempData(prev => ({...prev, title: input}))}
                        onBlur={(input) => onChange(data._id, input, 'title')}
                    />
                    <ToggleInput
                        id={'show_slogan-'+data._id} 
                        text={'Slogan'}
                        max={32}
                        value={tempData.slogan}
                        state={data.show_slogan}
                        onToggle={(state) => handleToggleChange(state, 'show_slogan')}
                        input={(input) => setTempData(prev => ({...prev, slogan: input}))}
                        onBlur={(input) => onChange(data._id, input, 'slogan')}
                    />
                    <Spacer top={1.8}/>
                    <ToggleLabel
                        id={'show_time-'+data._id}
                        text={'Data e Hora'}
                        state={data.show_time}
                        onToggle={(state) => handleToggleChange(state, 'show_time')}
                    />
                    <ToggleLabel
                        id={'show_pw_type-'+data._id}
                        text={'Tipo de Senha'}
                        state={data.show_pw_type}
                        onToggle={(state) => handleToggleChange(state, 'show_pw_type')}
                    />
                    <ToggleLabel
                        id={'invert-'+data._id}
                        text={'Inverter Cores da Senha'}
                        state={data.invert}
                        onToggle={(state) => handleToggleChange(state, 'invert')}
                    />
                </Column>
                <VerticalLine/>
                <Column width={35}>
                    <Spacer top={3}/>
                    <ToggleLabel
                        id={'show_text-'+data._id}
                        bold={true}
                        text={'Texto após senha'}
                        state={data.show_text}
                        onToggle={(state) => handleToggleChange(state, 'show_text')}
                    />
                    <LabelInput
                        margin={40}
                        value={tempData.line1}
                        state={data.show_text}
                        text={'Linha 1'}
                        max={32}
                        input={(input) => setTempData(prev => ({...prev, line1: input}))}
                        onBlur={(input) => onChange(data._id, input, 'line1')}
                    />
                    <LabelInput
                        margin={40}
                        value={tempData.line2}
                        state={data.show_text}
                        text={'Linha 2'}
                        max={32}
                        input={(input) => setTempData(prev => ({...prev, line2: input}))}
                        onBlur={(input) => onChange(data._id, input, 'line2')}
                    />
                    <Spacer top={1.8}/>
                    <ToggleLabel
                        id={'show_QR-'+data._id}
                        bold={true}
                        text={'Imprimir QR Code'}
                        state={data.show_QR}
                        onToggle={(state) => handleToggleChange(state, 'show_QR')}
                    />
                    <LabelInput
                        margin={40}
                        value={tempData.QR_title}
                        state={data.show_QR}
                        text={'Título'}
                        max={32}
                        input={(input) => setTempData(prev => ({...prev, QR_title: input}))}
                        onBlur={(input) => onChange(data._id, input, 'QR_title')}
                    />
                    <LabelInput
                        margin={40}
                        value={tempData.QR_code}
                        text={'QR Code'}
                        max={64}
                        state={data.show_QR}
                        input={(input) => setTempData(prev => ({...prev, QR_code: input}))}
                        onBlur={(input) => onChange(data._id, input, 'QR_code')}
                    />
                    <Spacer top={1.8}/>
                    <ToggleLabel
                        id={'show_thanks-'+data._id}
                        bold={true}
                        text={'Frase de Agradecimento'}
                        state={data.show_thanks}
                        onToggle={(state) => handleToggleChange(state, 'show_thanks')}
                    />
                    <LabelInput
                        margin={40}
                        text={'Texto'}
                        max={32}
                        state={data.show_thanks}
                        value={tempData.thanks_text}
                        input={(input) => setTempData(prev => ({...prev, thanks_text: input}))}
                        onBlur={(input) => onChange(data._id, input, 'thanks_text')}
                    />
                </Column>
                <VerticalLine/>
                <Column width={30}>
                    <PrintLayoutPreview data={tempData}/>
                </Column>
            </Row>
        </Wrapper>
    )
}

export default PwPrintLayoutBox