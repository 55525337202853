import styled from "styled-components"
import { Column, Row, Text, Icon } from '../../../layout/Layout'
import { useEffect, useState } from "react"
import { BsBookmarkPlus } from 'react-icons/bs'
import { Number, SelectField, Option, TextInput } from '../../../layout/Form'
import { useRef } from "react"
import { TbArrowBigRight } from 'react-icons/tb'

const Table = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #90A4AE;
    border-radius: 10px;
    padding: 0 15px;
`

function NewRuleBox({selectedRule, added, setValue}){

    const options = ['Atendimento imediato', 'Por tempo de espera', 'Por intervalo de senhas']
    const values = ['immediate', 'by_time', 'by_interval']

    const [ruleProps, setRuleProps] = useState({})
    const [defaultValue, setDefaultValue] = useState(0)

    const nameInputRef = useRef('nameInput')
    const ruleInputRef = useRef('ruleInput')

    const rulesDefault = {
        immediate: {
            text: 'As senhas prioritárias serão chamadas imediatamente no próxima atendimento'
        },
        by_time: {
            text: `As senhas prioritárias serão chamadas em até ${defaultValue} minutos após a sua emissão`,
            unit: 'minutos',
            min: 1,
            max: 20,
            default: 5
        },
        by_interval: {
            text: `As senhas prioritárias serão chamadas a cada ${defaultValue} senhas não-prioritárias`,
            unit: 'senhas',
            min: 1,
            max: 20,
            default: 3
        },
        by_proportion: {
            text: `As senhas prioritárias serão chamadas quando houver uma proporção máxima de ${defaultValue}% das senhas não-prioritárias`,
            unit: '%',
            min: 1,
            max: 100,
            default: 20
        }
    }

    const handleValueChange = (value) => {
        setDefaultValue(value)
        setValue(value, 'value')
    }

    useEffect(() => {
        setRuleProps(rulesDefault[selectedRule])
        handleValueChange(rulesDefault[selectedRule]?.default)
    },[selectedRule])

    useEffect(() => {
        setRuleProps(rulesDefault[selectedRule])
    },[defaultValue])

    useEffect(() => {
        if(added) {
            nameInputRef.current.value = ''
            ruleInputRef.current.value = ''
        }
    }, [added])
    
    return(
        <Table>
            <Row align={'center'} height={50}>
                <Icon size={22} color={'#00BFA5'}>
                    <BsBookmarkPlus/>
                </Icon>
                <Text size={13} weight={600}>Nova Regra</Text>
            </Row>
            <Row height={35} align={'center'}>
                <Text>Nome</Text>
                <TextInput ref={nameInputRef} placeholder={'Digite o nome da Regra'} onChange={(e) => setValue(e.target.value, 'name')}/>
            </Row>
            <Row height={35} align={'center'}>
                <Text>Regra</Text>
                <SelectField color={ruleInputRef.current.value === '' && '#767676'} ref={ruleInputRef} onChange={(e) => setValue(e.target.value, 'rule')}>
                    <Option value={""} hidden disabled selected>{'Selecione a Regra'}</Option>
                    {
                        options.map((e,i) => {
                            return <Option key={i} value={values[i]}>{e}</Option>
                        })
                    }
                </SelectField>
            </Row>
            <Row height={35} align={'center'}>
                {
                    selectedRule !== 'immediate' && selectedRule !== '' &&
                    <>
                        <Text>Valor</Text>
                        <Number type={'number'} left={'auto'} right={'5px'} valid width={15} value={defaultValue} min={ruleProps?.min} max={ruleProps?.max} onChange={e => handleValueChange(e.target.value)}/>
                        <Text>{ruleProps?.unit}</Text>
                    </>
                }
            </Row>
            <Row height={70} align={'center'}>
                {
                    selectedRule !== '' ?
                    <>
                        <Column width={'fit-content'}>
                            <Icon size={24} color={'#00BFA5'}>
                                <TbArrowBigRight/>
                            </Icon>
                        </Column>
                        <Column>
                            <Text wrap={'Normal'} weight={600}>{ruleProps?.text}</Text>
                        </Column>
                    </>
                    :
                    <Column height={100} justify={'flex-start'} align={'center'}>
                        <Text size={13} color={'#78909C'} weight={500}>Preencha os campos</Text>
                        <Text size={13} color={'#78909C'} weight={500}> para adicionar uma Nova Regra</Text>
                    </Column>
                }
            </Row>
        </Table>
    )
}

export default NewRuleBox