import styled from "styled-components";
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { CalculateStars, CalculateVotes } from "../../services/Ratings";

const Starts = styled.span`
    display: flex;
    align-items: center;
    color: #ffc82a;

    h1{
        font-size: 11px;
        font-weight: 300;
        color: #888;
        margin-left: 2px;
    }
`

function RatingStars({ratings}){


    const starsFill = (n) => {
        return [...Array(n)].map((e, i) => <AiFillStar key={i}/>)
    }

    const starsOutline = (n) => {
        return [...Array(n)].map((e, i) => <AiOutlineStar key={i}/>)
    }

    return(
        <Starts>
            {starsFill(CalculateStars(ratings))}
            {starsOutline(5 - CalculateStars(ratings))}
            <h1>{'('+CalculateVotes(ratings)+')'}</h1>
        </Starts>
    )
}

export default RatingStars
