export const configAtendPermissionsInfo = <p style={{textAlign: 'left'}}>
Aqui você definirá as permissões que os <strong>Atendentes</strong> terão ao operar o <strong>Painel de Atendimento.</strong><br/><br/>
<strong>Voltar senhas: </strong>Permite que o <strong>Atendente</strong> volte a chamar senhas que já foram chamadas. Útil em casos que acidentalmente algum cliente ficou sem ser atendido por discuido, ou que sua senha foi pulada por algum motivo<br/><br/>
<strong>Atender senhas aleatórias: </strong>Permite que o <strong>Atendente</strong> chame senhas que não estão na ordem de atendimento normal. <br/><br/>
<strong>Cancelar Senhas: </strong>Permite que o <strong>Atendente</strong> cancele senhas emitidas, em casos de desistência do cliente ou qualquer outro motivo, e evite que ela seja chamada na sua vez.<br/><br/>
<strong>Tocar sinal sonoro do display: </strong> Permite que o <strong>Atendente</strong> acione o <strong>Sinal Sonoro</strong> do Display manualmente enquanto o cliente não comparecer no guichê para atendimento. <br/><br/>
<strong>Atender outros tipos: </strong> Permite que o Guichê atenda outros tipos de senhas quando os tipos designados para ele tiverem acabado. <strong>Exemplo:</strong> Um guichê que foi escolhido para atender apenas <strong>Senhas Preferencias</strong> poderá chamar <strong>Senhas Normais</strong> quando não houver preferenciais para serem atendidas. 
</p>

export const configAtendRestartInfo = <p style={{textAlign: 'left'}}>
O número das senhas emitidas são definidos de forma sequencial de acordo com o intervalo definido na confuguração de cada Tipo de Senha. Este número pode ser reiniciado de acordo com as seguintes opções: <br/><br/>
<strong>Reiniciar contagem apenas quando atingir o final do intervalo definido:</strong> Reinicia a contagem quando o número chegar ao final do intervalo definido. 
<strong> Exemplo:</strong> Foi definido um intervalo para o Tipo de Senha de "200" a "399", quando a senha de número "399" for emitida, a próxima será a de número "200". <br/><br/>
<strong>Reiniciar contagem no início do expediente:</strong> Reinicia a contagem para o primeiro número do intervalo definido a cada novo dia de expediente, independentemente da última senha do dia anterior, ou seja, todos os dias a primeira senha a ser emitida será o primeiro número definido no intervalo. 
<strong> Exemplo:</strong> Foi definido um intervalo para o Tipo de Senha de "200" a "399", neste cado a primeira senha de cada dia será a de número "200". Porém independentemente disso, a contagem será reiniciada caso extropole o intervalo definido.
</p>

export const notifConfigNotifAtendInfo = <p style={{textAlign: 'left'}}>
Ative para que os <strong>Atendentes</strong> também recebam notificações no <strong>Painel de Atendimento</strong>.
</p>

export const openingHoursConfigInfo = <p style={{textAlign: 'left'}}>
Configura o <strong>horário de atendimento</strong> do estabelecimento, informando a hora de abertura e de fechamento, bem como o horário de intervalo se houver, como pausa para almoço.
</p>