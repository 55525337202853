import styled from "styled-components"

const Status = styled.span`
    display: flex;
    align-items: center;

    div{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 6px;
        border-radius: 10px;
        background-color: ${props => props.stat === 'active' && '#00C853'};
        background-color: ${props => props.stat === 'paused' && '#FB9800'};
        background-color: ${props => props.stat === 'inactive' && '#F34236'};
        font-size: 10px;
        font-weight: 600;
        color: white;
    }
`

function StatusStampMin({stat}){

    return(
        <Status stat={stat}>
            {stat === 'active' && <div>Ativo</div>}
            {stat === 'paused' && <div>Pausado</div>}
            {stat === 'inactive' && <div>Inativo</div>}
        </Status>
    )
}

export default StatusStampMin