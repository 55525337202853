import styled from "styled-components";

export const Panel = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 85% 15%;
    aspect-ratio: 18/9;
    width: 100%;
    border: 1px solid #CFD8DC;
`

export const Side = styled.div`
    display: flex;
    grid-column: 2/3;
    grid-row: 1/2;
`

export const Show = styled.div`
    display: flex;
    aspect-ratio: 16/9;
    grid-column: 1/2;
    grid-row: 1/2;
`

export const Side2 = styled.div`
    display: flex;
    grid-column: 2/3;
    grid-row: 1/3;
`

export const ShowFrame = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 12/8;
    grid-column: 1/2;
    grid-row: 1/3;
    background-color: ${props => props.bg === 'black' ? 'black' : 'white'};
`

export const Show2 = styled.div`
    display: flex;
    aspect-ratio: 16/9;
    width: 100%;
`

export const FooterContainer = styled.div`
    display: flex;
    grid-column: 1/3;
    grid-row: 2/3;
`

export const Text = styled.h1`
    text-align: center;
    color: ${props => props.color ? props.color : 'white'};
    font-size: ${props => props.size}rem;
    font-weight: ${props => props.weight};
    white-space: nowrap;
`

export const SideBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.color};
    height: ${props => props.height}%;
    width: 100%;
`

export const ClockContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #37474F;
    height: 100%;
    width: ${props => props.width}%;
`

export const MarqueeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:  ${props => props.color};
    height: 100%;
    width: ${props => props.width}%;
`

export const ShowContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #CFD8DC;
    height: 100%;
    width: 100%;
`

export const NoExhibitionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 100%;
    width: 100%;
`

export const Image = styled.div`
    display: flex;
    background-image: url(${props => props.url});
    background-size: cover;
    width: 100%;
    height: 100%;
    -webkit-transition: background-image 1s ease-in-out;
    -moz-transition: background-image 1s ease-in-out;
    -o-transition: background-image 1s ease-in-out;
    transition: background-image 1s ease-in-out;
`

export const Video = styled.video`
    display: flex;
    width: 100%;
    height: 100%;
`

export const Spacer = styled.div`
    margin-left: ${props => props.left}rem;
    margin-right: ${props => props.right}rem;
    margin-top: ${props => props.top}rem;
    margin-bottom: ${props => props.bottom}rem;
`

