import { Border, Spacer, Text } from "../../../../layout/Layout";



export default function PreviewWarning(){
    return(
        <Border padding={15} border={'#CFD8DC'}>
            <Text size={11} weight={600} decoration={'underline'}>Observação:</Text>
            <Spacer top={0.5}/>
            <Text size={11} wrap={'wrap'}>As senhas exibidas neste preview são apenas exemplos ilustrativos. No Painel real serão exibidas as senhas configuradas de fato a medida que forem sendo chamadas.</Text>
        </Border>
    )
}