import BusinessAvatar from "./BusinessAvatar";
import { Column, Row, Spacer, Text, TextLink, Border, Icon, Link } from "../layout/Layout";
import { BiInfoCircle } from 'react-icons/bi'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { TbPackageImport, TbPackage } from 'react-icons/tb'
import { Button } from "../buttons/Button";
import EmailBusiness from "./EmailBusiness";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";

export default function BusinessAccountBox({}){

    const { business } = useContext(AuthContext)

    return(
        <Border border={'#B0BEC5'}>
            <Row>
                <Column width={'fit-content'} paddingLeft={30} paddingRight={40}>
                    <BusinessAvatar/>
                </Column>
                <Column width={'fit-content'} paddingLeft={40} paddingRight={70} borderLeft>
                    <Row align={'center'}>
                        <Icon size={20} color={'#78909C'} margin={'0'}>
                            <BiInfoCircle/>
                        </Icon>
                        <Text left={"5px"} size={16} weight={500} color={'#546E7A'}>Empresa</Text>
                    </Row>
                    <Spacer top={2}/>
                    <Text size={12} weight={300}>Nome</Text>
                    <Text size={14} weight={500}>{business.name}</Text>
                    <Spacer top={1}/>
                    <Text size={12} weight={300}>E-mail</Text>
                    <EmailBusiness email={business.email}/>
                </Column>
                <Column width={'fit-content'} paddingLeft={40} paddingRight={70} borderLeft>
                    <Row align={'center'}>
                        <Icon size={20} color={'#78909C'} margin={'0'}>
                            <IoDocumentTextOutline/>
                        </Icon>
                        <Text left={"5px"} size={16} weight={500} color={'#546E7A'}>Meu Contrato</Text>
                    </Row>
                    <Spacer top={2}/>
                    <Text size={12} weight={300}>Data de adesão</Text>
                    <Text size={14} weight={500}>{business.contract.date}</Text>
                    <Spacer top={2}/>
                    <Text size={12} weight={300}>Versão</Text>
                    <Text size={14} weight={500}>{business.contract.version}</Text>
                    <Spacer top={2}/>
                    <Link href={business.contract.url} target="_blank" rel="noreferrer">Baixar o contrato em PDF</Link>
                </Column>
                <Column width={'fit-content'} paddingLeft={40} paddingRight={70} borderLeft>
                    <Row align={'center'}>
                        <Icon size={22} color={'#78909C'} margin={'0'}>
                            <TbPackage/>
                        </Icon>
                        <Text left={"5px"} size={16} weight={500} color={'#546E7A'}>Meu Plano</Text>
                    </Row>
                    <Spacer top={2}/>
                    <Text size={12} weight={300}>Plano</Text>
                    <Text size={14} weight={500}>Plano Master Anual</Text>
                    <Spacer top={2}/>
                    <TextLink>O que está incluso no meu plano?</TextLink>
                    <Spacer top={2}/>
                    <Button padding={5} radius={10} background={'transparent'}>
                        <Icon size={28} color={'#00BFA5'} margin={'10px'}>
                            <TbPackageImport/>
                        </Icon>
                        <Column>
                            <Text weight={500}>Fazer um upgrade</Text>
                            <Text size={14} weight={300}>no Meu Plano</Text>
                        </Column>
                    </Button>
                </Column>
            </Row>
        </Border>
    )
}