import styled from "styled-components";
import FormTitle from "../../../form/FormTitle";
import { Column, Row, Text } from "../../../layout/Layout";
import ImageThumb from "./ImageThumb";

const Wrapper = styled.div`
    display: flex;
    overflow-x: auto;
    max-width: 100%;

    &::-webkit-scrollbar{
        width: 10px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #CFD8DC;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`

export default function ImagesPreview({urls, onChange}){

    const handleDelete =(index) => {
        const _urls = [...urls]

        _urls.splice(index, 1)

        onChange(_urls)
    }

    return(
        <Column>
            <FormTitle>Imagens que serão exibidas</FormTitle>
            {
                urls.length === 0 ?
                <Text>Nenhuma Imagem carregada</Text>
                :
                <Wrapper>
                    {
                        urls.map((url, index) => {
                            return <ImageThumb key={index} url={url} onDelete={() => handleDelete(index)}/>
                        })
                    }
                </Wrapper>
            }
        </Column>
    )
}