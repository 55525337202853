import { Image, ShowContainer, Text } from "../styles"
import { MdOutlineImageNotSupported } from 'react-icons/md'
import { Icon } from "../../../../../layout/Layout"
import { useState, useEffect, useRef } from "react"

export default function ImageExhibition({urls}){

    const [_urls, _setUrls] = useState([])

    const [index, _setIndex] = useState(0)
    const indexRef = useRef(0)
    const intervalRef = useRef()

    const setIndex = (index) => {
        indexRef.current = index
        _setIndex(index)
    }

    const startInterval = () => {
        intervalRef.current = setInterval(() => {
            if(indexRef.current === _urls.length-1) setIndex(0)
            else setIndex(indexRef.current + 1)
        }, 5000);
    }

    useEffect(() => {
        if(urls.length !== _urls.length) _setUrls(urls)
    },[urls])

    useEffect(() => {
        if(_urls.length > 0) {
            startInterval()
            setIndex(0)
        }

        return () => clearInterval(intervalRef.current)
    },[_urls])

    return(
        <ShowContainer>
            {
                _urls.length === 0 ? 
                <>
                    <Icon size={40} color={'#90A4AE'}>
                        <MdOutlineImageNotSupported/>
                    </Icon>
                    <Text size={1.3} color={'#90A4AE'} weight={600}>Carregue as Imagens</Text>
                    <Text size={1.3} color={'#90A4AE'} weight={600}>para exibir</Text>
                </>
                :
                <Image url={_urls[indexRef.current]}/>
            }
        </ShowContainer>
    )
}