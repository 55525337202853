export const panelsHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        <strong>Paineis</strong> são os equipamentos que farão a chamada das senhas emitidas, geralmente televisões. Aqui você poderá gerenciá-los, incluindo novos ou excluindo os existentes, e ainda modificar e personalizar todas as suas configurações.<br/><br/>
        <strong>Dica: </strong> Você não deve necessariamente possuir uma televisão para cada Painel cadastrado aqui, ou seja, poderá cadastrar múltiplos Paineis e utilizar as suas configurações no equipamento que você possui em momentos diferentes como desejar.<br/><br/>
        Além disso, você pode cadastrar um Painel e utilizá-lo em diversas televisões diferente, no caso de possuir mais de uma em um único setor de atendimento.<br/><br/>
        <strong>Obs: </strong> A seleção do Painel a ser utilizada é feita no próprio equipamento ao ligá-lo. 
    </p>
</> 

export const dashboardPlacesHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        Nesta secção você poderá visualizar os <strong>Locais cadastrados</strong> no sistema, incluindo seus dados e estatísticas de cada um. bem como cadastrar <strong>novos locais</strong> se necessário.<br/><br/>
        Um local é dividido em duas partes:<br/><br/>
        <strong>Estabelecimento:</strong> representa o local propriamente dito onde a empresa está instalada. De forma figurada pode-se dizer que é o endereço da empresa, ou o prédio ou loja que ela se encontra. Por exemplo: <strong>"Matriz"</strong>, <strong>"Filial de São Paulo"</strong>, <strong>"Agência do Centro"</strong>, etc.<br/><br/>
        <strong>Ambiente:</strong> representa o ambiente dentro de cada estabelecimento que possui um atendimento específico. Pode-se dizer que é tipo de atendimento, dentro de um estabelecimento, que é prestado. Por exemplo: <strong>"Recepção"</strong>, <strong>"Abertura de Contas"</strong>, <strong>"Jurídico"</strong>, <strong>"Financeiro"</strong>, <strong>"Retirada de Laudos"</strong>, etc.<br/><br/>
        Desta forma o atendimento em cada Estabelecimento será dividido entre os Ambientes, e cada um possuirá um fila de atendimento diferente, com suas senhas específicas.<br/><br/> 
        Para incluir um novo Estabelecimento, basta clicar em <strong>Novo Estabelecimento</strong> e inserir as suas informações.<br/><br/>
        <strong>Obs.:</strong> Para <strong>exibir os dados e estatísticas</strong> de cada estabelecimento, basta expandí-lo clicando na seta à direita de cada um.
    </p>
</> 

export const dashboardServicesHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        Serviços
    </p>
</> 

export const dashboardDesksHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        Nesta secção você terá controle em <strong>tempo real</strong> da atividade de todos os Guichês cadastrados nos sistema, bem como acompanhar suas atividades, controlar seus atendimentos, saber se estão ativos, ver suas estatísticas e muito mais. <br/><br/>
        O <strong>status de um Guichê</strong> representa o seu estado atual, que podem ser os seguintes:<br/><br/>
        <strong>Ativo:</strong> o Guichê está funcionando e atendendo, com um Atendente logado nele.<br/><br/>
        <strong>Pausado:</strong> um Atendente está logado no Guichê, porém ele não está em atividade ou pausou o seu atendimento por algum motivo.<br/><br/>
        <strong>Ativo:</strong> não há um Atendente logado do Guichê, e ele não está operando.<br/><br/>
        Além disso é possível também cadastrar <strong>Novos Guichês</strong> no sistema. Para isso basta clicar em <strong>Novo Guichê</strong> e seguir as instruções do card que aparecerá.<br/><br/>
        <strong>Obs.:</strong> por padrão o sistema exibe os Guichês de todos os locais, porém algumas informações só serão exibidas ao especificar um local nos menus do cabeçalho.
    </p>
</> 

export const dashboardCallsHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        Nesta secção é possível acompanhar todos os <strong>atendimentos realizados</strong> durante o expediente atual em tempo real. <br/><br/>
        Para exibir os atendimentos é necessário <strong>selecionar o Local</strong>. Em seguida você pode filtrar os resultados <strong>pelo tipo de senha</strong> ou <strong>pelo atendente</strong> que realizou o atendimento.<br/><br/>
        É possível ainda exibir ou ocultar as <strong>senha não atendidas</strong>, que são aquelas que foram chamadas pelo atendente porém por algum motivo, o indivíduo a ser atendido não compareceu no guichê. 
    </p>
</> 

export const dashboardAttendanceHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        Nesta secção são apresentados <strong>dados e estatísticas gerais</strong> da operação da empresa, incluido atendimentos, operação dos atendentes, fluxo de pessoas, notificações do sistema e muito mais.<br/><br/>
        Você poderá vizualizar as informações de <strong>toda a empresa</strong> ou <strong>especificar um local</strong> para o qual deseja exibir os dados, selecionando-o no menu do cabeçalho. <br/><br/>
        Além disso pode-se escolher entre exibir as estatísticas em <strong>Tempo Real</strong>, ou selecionar um <strong>período específico</strong>, sendo possível em seguida gerar um relatório em PDF com as estatísticas do período selecionado. <br/><br/>
        No <strong>Card de Atendentes</strong> você visualizará os Atendentes de cada local, acompanhando seus dados e podendo filtrar pelo status de cada.<br/><br/>
        No <strong>Card de Notificações</strong> são exibídas as Notificações do sistema em tempo real, e você pode minimizá-lo para visualizar melhor as outras informações. Nele é possível também <strong>desativar o sinal sonoro</strong> das notificações. 
    </p>
</> 


export const terminaisHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        <strong>Terminais</strong> são os equipamentos que farão a emissão das senhas. Aqui você poderá gerenciá-los, incluindo novos ou excluindo os existentes, e ainda modificar e personalizar todas as suas configurações.<br/><br/>
        <strong>Dica: </strong> Você não deve necessariamente possuir um equipamento físico para cada Terminal cadastrado aqui, ou seja, poderá cadastrar múltiplos Terminais e utilizar as suas configurações no equipamento que você possui em momentos diferentes como desejar.<br/><br/>
        <strong>Exemplos: </strong> Você pode incluir um Terminal para utilizar em determinado dia da semana, que deseja oferecer uma promoção ou divulgar algo, e modificar suas configurações para isso, utilizando-o apenas no momento que desejar.
        Ou ainda, caso deseje, cadastrar Terminais que emitem senhas diferentes um do outro, e utilizá-los em dias ou horários diferentes, conforme o cronograma de atendimento do seu estabelecimento.<br/><br/>
        <strong>Obs: </strong> A seleção do Terminal a ser utilizada é feita no próprio equipamento ao ligá-lo, e qualquer modificação feita aqui só será aplicada no equipamento na sua próxima inicialização. 
    </p>
</> 

export const interfacesHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        <strong>Interfaces</strong> são os layouts que serão exibidos na tela touch-screen dos Terminais de Senhas. Aqui você poderá criar diversas Interfaces e personalizá-las de acordo com seu gosto, alterando cores, botões, textos e fundo de tela.<br/><br/>
        <strong>Dica: </strong> No 'Fundo de Tela' você pode colocar uma imagem com sua logo ou que representa a imagem da empresa. Se preferir pode apenas selecionar uma cor de fundo que combine com as cores da empresa. <br/><br/>
        <strong>Obs.: </strong> Para ativar a Interface em um terminal, basta escolher o Terminal que você deseja na aba 'Terminais', e no campo 'Layout da Interface', selecionar a Interface que deseja ativar através do seu nome. 
    </p>
</> 

export const pwTypesHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        Nesta secção você poderá gerenciar os <strong>Tipos de Senhas</strong> que disponibilizará para o atendimento.
        Será possível incluir novos tipos, alterar ou excluir tipos já existentes e ainda modificar o status de cada um conforme a sua vontade.<br/><br/>
        <strong>Dica: </strong> Passe o mouse sob os títulos da tabela para saber o que cada item significa.
    </p>
</> 

export const printLayoutsHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        Nesta secção você poderá gerenciar os <strong>Layouts de Impressão de Senhas</strong> que serão utilizados pelos <strong>Terminais de Emissão</strong>.
        Será possível incluir novos Layouts, alterar suas configurações e excluir Layouts já existentes, e ainda acompanhar como será a impressão através do 'Preview' no lado direito<br/><br/>
        <strong>Dica: </strong> Você pode escolher o que deseja imprimir ativando ou desativando cada item. Porém atente-se para o comprimento do papel impresso (abaixo do preview). Quanto mais itens selecionar para imprimir, maior será o seu comprimento<br/><br/>
        <strong>Obs.: </strong> Para ativar um Layout em um Terminal, basta escolher o Terminal que você deseja em 'Dispositivos/Terminais', e no campo 'Layout da Impressão', selecionar o Layout que deseja ativar através do seu nome.
    </p>
</> 

export const priorRulesHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
        Nesta secção você poderá gerenciar as <strong>Regras de Atendimento Prioritário</strong> que serão utilizadas pelo sistema para definir o lugar em uma fila de atendimento que uma senha será colocada no momento da sua emissão.<br/><br/>
        Nas tabelas abaixo você pode visualizar as Regras já inclusas, bem como excluí-las e cadastrar novas conforme a sua necessidade. 
    </p>
</> 

export const userAtendHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
    <strong>Atendentes</strong> são usuários que têm acesso apenas ao <strong>'Painel de Atendimento'</strong> e não podem vizualizar, alterar ou editar quaisquer informações deste sistema. Suas permissões restrigem-se a vizualizar senhas emitidas, fila de espera e chamado de senhas.<br/><br/> 
    Nesta secção você pode incluir, excluir a alterar dados dos atendentes, bem como modifcar o seu status para Ativo ou Inativo.<br/><br/> 
    <strong>Dica: </strong> Passe o mouse sob os títulos da tabela para saber o que cada item significa.
    </p>
</> 

export const userAdminHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
    <strong>Administradores</strong> são usuários que têm acesso a este painel de controle, podendo controlar informações, vizualizar dados e editar configurações. Você pode definir permissões de acesso para cada Administrador de acordo com suas atribuições na empresa.<br/><br/>  
    Para cadastrar um <strong>Novo Administrador</strong> basta clicar no botão de incluir e informar os dados do usuário que será adicionado, bem como as permissões que ele terá no sistema. <strong>Importante: </strong> O e-mail do novo Administrador deverá ser válido, pois o mesmo será utilizado para realizar o login na plataforma, bem como para validações e comunicações importantes com este novo usuário.<br/><br/>
    Assim que um novo Administrador for adicionado, será enviado um e-mail para ele, através do qual ele terá instruções para criar a sua senha e acessar o sistema.<br/><br/> 
    <strong>Obs.: </strong> O Administrador Master (primeiro da lista) tem acesso irrestrito ao sistema, não podendo ser exluído ou inativado.<br/><br/> 
    <strong>Dica: </strong> Passe o mouse sob os títulos da tabela para saber o que cada item significa.
    </p>
</>

export const filaHeaderInfo = <> 
    <p style={{textAlign: 'left'}}>
    Nesta secção você terá controle de todas as <strong>senhas que foram emitidas até o momento</strong> e que ainda estão na <strong>fila de espera</strong>. 
    Podendo vizualizar seus dados, incluindo a hora que foram emitidas e o tempo que estão na fila de espera.<br/><br/>
    Nos campos do cabeçalho é possível <strong>filtar e reorganizar as senhas</strong> exibidas para um melhor controle das mesmas.<br/><br/>
    Caso seja necessário, é possível ainda <strong>cancelar uma senha</strong> emitida, excluindo-a da fila de espera. <br/><br/>
    <strong>Obs.: </strong>A próxima senha a ser chamada sempre estará destacada na cor verde.
    </p>
</>