import { useRef } from "react"
import { useState } from "react"
import { toast } from "react-toastify"
import { TextInput } from "../layout/Form"
import { Text, Icon, TextLink, Position, Row } from "../layout/Layout"
import { HiCheck } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import { useEffect } from "react"

export default function EmailBusiness({email, onChange}){

    const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)

    const [newEmail, setNewEmail] = useState(null)
    const [confirmEmail, setConfirmEmail] = useState(null)

    const [step, setStep] = useState('idle')

    const emailInputRef = useRef()

    const handleChange = (value) => {
        if(step === 'new') setNewEmail(value)
        else if(step === 'confirm') setConfirmEmail(value)
    }

    const handleConfirmation = () => {
        if(step === 'new') {
            if(!emailRegex.test(newEmail)) return toast.error('Insira um e-mail válido', {containerId: 'alert'})
     
            setStep('confirm')
            emailInputRef.current.value = ''
        
            return
        }

        if(step === 'confirm') {
            if(newEmail !== confirmEmail) return toast.error('O e-mail informado na confirmação deve ser igual ao e-mail informado anteriormente', {containerId: 'alert'})
        
            // enviar alteração do email
            setStep('idle')
            setNewEmail(null)
            setConfirmEmail(null)
        }

    }

    useEffect(() => {
        if(step === 'idle') {
            setNewEmail(null)
            setConfirmEmail(null)
        }
        else if(step === 'new' || step === 'confirm') emailInputRef.current.focus()
    },[step])

    return(
        <>
            <Text size={14} weight={500}>{email}</Text>
            {/*
                step === 'idle' ?
                <TextLink onClick={() => setStep('new')}>Alterar E-mail</TextLink>
                :
                <Row position={'relative'} marginTop={10}>
                    <TextInput ref={emailInputRef} type={'text'} placeholder={step === 'new' ? 'Novo e-mail' : step === 'confirm' && 'Confirme o e-mail'} width={'100%'} background={'#ECEFF1'} onChange={e => handleChange(e.target.value)} onKeyDown={e => {e.key === 'Enter' && handleConfirmation()}}/>
                    <Position right={-45} top={3}>
                        <Row align={'center'}>
                            <Icon pointer margin={'0'} size={18} color={'#00C853'} onClick={() => handleConfirmation()}>
                                <HiCheck/>
                            </Icon>
                            <Icon pointer margin={'0'} size={18} color={'#F34236'} onClick={() => setStep('idle')}>
                                <IoClose/>
                            </Icon>
                        </Row>
                    </Position>
                </Row>
    */}
        </>
    )
}