import { useContext, useState } from "react";
import { openingHoursConfigInfo } from "../../content/tooltips/configTooltips";
import FormTitle from "../form/FormTitle";
import ToggleLabel from "../form/ToggleLabel";
import { Column, Row, Spacer, Text } from "../layout/Layout";
import { ConfigContext } from "../../contexts/ConfigContext";
import { useEffect } from "react";
import { decimalTimeToString, hoursIntervalInDecimal, timeStringToDecimal } from "../../services/hoursCalculation";
import { toast } from 'react-toastify'

export default function OpeningHoursConfig(){

    const { config, updateConfig } = useContext(ConfigContext)
    const { opening_hours = {} } = config
    const { start, end, interval, interval_start, interval_end } = opening_hours

    const [totalTime, setTotalTime] = useState(null)
    
    const calculateTotalHours = (config) => {

        if(!config?.start || !config?.end) return null

        if(!config?.interval || !config?.interval_start || !config?.interval_end) return hoursIntervalInDecimal(config.start, config.end)
        return hoursIntervalInDecimal(config.start, config.end) - hoursIntervalInDecimal(config.interval_start, config.interval_end)
    }

    useEffect(() => {
        setTotalTime(calculateTotalHours(opening_hours))
    },[config])

    const checkOpeningHours = (config) => {

        const { start, end } = config
        
        if(!start || !end) return 1

        if(timeStringToDecimal(start) >= timeStringToDecimal(end)){
            toast.error('A hora de abertura não pode ser maior que a hora de fechamento', {containerId: 'alert'})
            return 0
        }

        return 1        
    }

    const checkOpeningHoursInterval = (config) => {

        const { interval_start, interval_end } = config
        
        if(!interval_start || !interval_end) return 1

        if(timeStringToDecimal(interval_start) >= timeStringToDecimal(interval_end)){
            toast.error('A hora de início do intervalo não pode ser maior que a hora de término', {containerId: 'alert'})
            return 0
        }

        return 1        
    }

    const handleChange = (property, value) => {
        const new_config = opening_hours

        new_config[property] = value
        new_config.total_hours = calculateTotalHours(new_config)

        if((property === 'start' || property === 'end') && !checkOpeningHours(new_config)) return
        if((property === 'interval_start' || property === 'interval_end') && !checkOpeningHoursInterval(new_config)) return

        updateConfig(new_config, 'opening_hours')
    }

    return(
        <Column borderLeft paddingLeft={24}>
            <FormTitle help={openingHoursConfigInfo}>Horário de Atendimento</FormTitle>
            <Spacer top={2}/>
            <Row align={'center'} justify={'space-between'} width={'20rem'}>
                <Text>Abertura</Text>
                <input type="time" defaultValue={start} onChange={(e) => handleChange('start', e.target.value)}/>
            </Row>
            <Spacer top={1}/>
            <Row align={'center'} justify={'space-between'} width={'20rem'}>
                <Text>Fechamento</Text>
                <input type="time" defaultValue={end} onChange={(e) => handleChange('end', e.target.value)}/>
            </Row>
            <Spacer top={2}/>
            <ToggleLabel id={'interval'} text={'Intervalo'} state={interval} onToggle={(state) => handleChange('interval', state)}/>
            {
                interval &&
                <>
                    <Spacer top={1}/>
                    <Row align={'center'} justify={'space-between'} width={'20rem'}>
                        <Text>Início do Intervalo</Text>
                        <input type="time" defaultValue={interval_start} onChange={(e) => handleChange('interval_start', e.target.value)}/>
                    </Row>
                    <Spacer top={1}/>
                    <Row align={'center'} justify={'space-between'} width={'20rem'}>
                        <Text>Término do Intervalo</Text>
                        <input type="time" defaultValue={interval_end} onChange={(e) => handleChange('interval_end', e.target.value)}/>
                    </Row>
                </>
            }
            {
                totalTime &&
                <>
                    <Spacer top={3}/>
                    <Text>
                        <Text wrap={'pre'}>Tempo total de atendimento: </Text>
                        <Text weight={600}>{`${decimalTimeToString(totalTime)}h`}</Text>
                    </Text>
                </>
            }
        </Column>
    )
}