import styled from "styled-components";
import RatingStars from "../../others/RatingStars";
import { Column, Text, Icon, Row } from "../../layout/Layout";
import  {BsArrowRight } from 'react-icons/bs'
import img from '../../../img/Blank-profile.png'
import Modal from "../../modals/Modal";
import { useState } from "react";
import GeneralModalContainer from "../../modals/GeneralModalContainer";
import AttendantDataModal from '../../modals/attendantDataModal'

const Wrapper = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 70px;
    border-left: 5px solid ${props => props.color};
    border-bottom: 1px solid #B0BEC5;
    padding-left: 10px;
    cursor: pointer;

    :hover{
        background-color: #FAFAFA;
    }
`

const Avatar = styled.div`
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background-color: #B0BEC5;
    border-radius: 25px;
    background-image: url(${props => props.img ? props.img : img});
    background-position: center;
    background-size: contain;
`

function GuichesListRow({data, desk}){

    const [showAttendantDataModal, setShowAttendantDataModal] = useState(false)

    const getColor = () => {
        switch (data.status) {
            case 'active': return '#00C853'
            case 'inactive': return '#F34236'
            case 'paused': return '#FB9800'
            default: break;
        }
    }

    const getOperatingStatusString = (status) => {
        switch (status) {
            case 'idle': return '  (Ocioso)'
            case 'waiting': return '  (Aguardando senha)'
            case 'attending': return '  (Em atendimento)'
            default: break;
        }
    }

    const getOperatingStatusColor = (status) => {
        switch (status) {
            case 'idle': return '#F34236'
            case 'waiting': return '#FB9800'
            case 'attending': return '#00C853'
            default: break;
        }
    }

    return(
        <>
            <Wrapper color={getColor()} onClick={() => setShowAttendantDataModal(true)}>
                <Column width={'fit-content'}>
                    <Avatar img={data.avatar_url}/>
                </Column>
                <Column width={100}>
                    <Text size={12} weight={500}>{data.name}</Text>
                    {
                        data.status === 'inactive' ? 
                        <Text size={11} weight={300}>Offline</Text>
                        :
                        <Row align={'center'}>
                            <Text size={11} weight={300}>{desk?.name}</Text>
                            {
                                data.status === 'active' &&
                                <Text wrap={'pre'} size={11} weight={600} color={getOperatingStatusColor(desk?.operating_status)}>{getOperatingStatusString(desk?.operating_status)}</Text>
                            }
                        </Row>
                    }
                    <RatingStars ratings={data.rating}/>
                </Column>
            </Wrapper>
            <Modal isOpen={showAttendantDataModal}>
                <GeneralModalContainer onClose={() => setShowAttendantDataModal(false)}>
                    <AttendantDataModal attendant={data}/>
                </GeneralModalContainer>
            </Modal>
        </>
    )
}

export default GuichesListRow