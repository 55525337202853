import { Column, Row, Icon, Spacer, Text } from "../../layout/Layout"
import { AiOutlineIdcard } from 'react-icons/ai'
import RatingStarsFixed from "../../others/RatingStarsFixed"

export default function AttendantInfoModal({data}){

    return (
        <Column width={'18rem'}>
            <Row align={'center'}>
                <Icon size={32} color={'#00BFA5'}>
                    <AiOutlineIdcard/>
                </Icon>
                <Text weight={600} size={14} color={'#455A64'}>Detalhes do Atendente</Text>
            </Row>
            <Spacer top={2}/>
            <Text weight={600}>{data.name}</Text>
            <Text>{data.email}</Text>
            {
                data.status === 'active' ?
               <Text weight={600} color={'#00C853'}>Atendendo</Text>
               :
               data.status === 'paused' ?
               <Text weight={600} color={'#FB9800'}>Atendimento em pausa</Text>
               :
               data.status === 'inactive' &&
               <Text weight={600} color={'#F34236'}>Desconectado</Text>
            }
            <Spacer top={1}/>
            <Text weight={600}>Avaliações</Text>
            <Spacer top={1}/>
            {
                data.rating.map((e, i) => {
                    return <RatingStarsFixed stars={i} count={e}/>
                })
            }
            
        </Column>
    )
}

