import { Icon, Row, Text } from "../../../../../layout/Layout";
import { TbClock, TbClockOff } from 'react-icons/tb'

export default function ShowTimeStamp({state}){


    return(
        <>
            {
                state ? 
                <Row align={'center'}>
                    <Icon size={20} color={'#00BFA5'}>
                        <TbClock/>
                    </Icon>
                    <Text>
                        Exibe Data e Hora
                    </Text>
                </Row>
                :
                <Row align={'center'}>
                    <Icon size={20} color={'#78909C'}>
                        <TbClockOff/>
                    </Icon>
                    <Text>
                        Não Exibe Data e Hora
                    </Text>
                </Row>
            }
        </>
    )
}