import RatingStars from '../../others/RatingStars'
import { BiPause, BiPlay } from 'react-icons/bi'
import { IoClose } from 'react-icons/io5'
import { FiInfo } from 'react-icons/fi'
import { useState } from "react"
import { toast } from "react-toastify"
import { Text, Title, Column, Row, Icon } from "../../layout/Layout"
import AvatarLoad from '../../others/AvatarLoad'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import ConfirmationTooltip from "../../others/ConfirmationTooltip"
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';
import { create } from '../../../API/queries'
import Modal from '../../modals/Modal'
import GeneralModalContainer from '../../modals/GeneralModalContainer'
import AttendantInfoModal from './AttendantInfoModal'


function UsersAtendRow({data, onChange, onDelete}){
    
    const [showUserInfo, setShowUserInfo] = useState(false)

    const [deleteConfirm, setDeleteConfirm] = useState(false)
    
    const handlePermission = (state) => {
        onChange(state, data._id, 'active')

        if(state) return toast.success('Permissão alterada. Agora o atendente poderá acessar o sistema', {containerId: 'alert'})
        toast.warning('Permissão alterada. Agora o atendente não poderá acessar o sistema', {containerId: 'alert'})
    }

    const handleUpload = (e) => {

        e.preventDefault()
        const formData = new FormData();
        formData.append('file', e.target.files[0])

        create('upload/image', formData).then(res => {
            onChange(res.data.url, data._id, 'avatar_url')
        }).catch(err => {
            toast.error(`Não foi possível carregar a Imagem. Error: ${err}`, {containerId: 'alert'})
        })
    }

    const handleDelete = () => {
        setDeleteConfirm(false)

        if(data.status !== 'inactive') return toast.error('Você não pode excluir este atendente pois no momento ele está logado no sistema.', {containerId: 'alert'})
        
        onDelete(data._id)
    }
    
    return (
        <>
            <Row height={70} align={'center'} borderBtmWidth={1} borderBtmColor={'#CFD8DC'} borderBtmStyle={'solid'}>
                <Column width={50}>
                    <AvatarLoad 
                        id={data._id} 
                        size={55} 
                        status={data.status}
                        active={data.active}
                        url={data.avatar_url} 
                        onChange={(e) => handleUpload(e)}
                    />
                </Column>
                <Column width={120}>
                    <Text>{data.name}</Text>
                </Column>
                <Column>
                    <RatingStars ratings={data.rating}/>
                </Column>
                <Column>
                    <Text>
                        {new Date(data.since).toLocaleString([], {day: '2-digit', month:'2-digit', year:'numeric'})}
                    </Text>
                </Column>
                <Column width={120}>
                    <Text>{data.email}</Text>
                </Column>
                <Column>
                    <Title color={data.active ? '#00C853' : '#F34236' }>
                        {data.active ? 'Permitido' : 'Bloqueado'}
                    </Title>
                </Column>
                <Column>
                    <Row align={'center'} justify={'space-around'}>
                        <Tippy content='Detalhes'>
                            <Icon id={'pencil'+data._id} size={15} color={'#0091EA'} pointer={true} onClick={() => setShowUserInfo(true)}>
                                <FiInfo id={data._id}/>
                            </Icon>
                        </Tippy>
                        {
                            data.active ? 
                            <Tippy content='Bloquear Atendente'>
                                <Icon size={23} color={'#fb9800'} pointer={true} onClick={() => handlePermission(false)}>
                                    <BiPause/>
                                </Icon>
                            </Tippy>
                            : 
                            <Tippy content='Permitir Atendente'>
                                <Icon size={23} color={'#00C853'} pointer={true} onClick={() => handlePermission(true)}>
                                    <BiPlay/>
                                </Icon>
                            </Tippy>
                        }
                        <Tooltip theme="light" trigger="click" open={deleteConfirm} interactive={true} onRequestClose={() => setDeleteConfirm(false)} html={(<ConfirmationTooltip text={'Deseja Excluir?'} onYes={() => handleDelete()} onNo={() => setDeleteConfirm(false)}/>)}>
                            <Tippy content='Excluir Atendente'>
                                <Icon size={18} color={'#F34236'} pointer={true} onClick={() => setDeleteConfirm(true)}>
                                    <IoClose/>
                                </Icon>
                            </Tippy>
                        </Tooltip>
                    </Row>
                </Column>
            </Row> 
            <Modal isOpen={showUserInfo}>
                <GeneralModalContainer onClose={() => setShowUserInfo(false)}>
                    <AttendantInfoModal data={data}/>
                </GeneralModalContainer>
            </Modal>
        </>    
    )
}

export default UsersAtendRow

