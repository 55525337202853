import styled from "styled-components"
import { BsX } from 'react-icons/bs'
import { Circle, Icon, Position } from "../../../layout/Layout"
import { useState } from "react"
import { useEffect } from "react"
import { useRef } from "react"

const Wrapper = styled.div`
    position: relative;
    margin-right: 1rem;
`

const Thumb = styled.img`
    width: 6rem;
    object-fit: contain;
`

export default function ImageThumb({url, onDelete}){

    const [showX, setShowX] = useState(false)

    const thumbRef = useRef()

    const handleMouseEnter = () => setShowX(true)
    const handleMouseLeave = () => setShowX(false)

    useEffect(() => {
        thumbRef.current.addEventListener('mouseenter', handleMouseEnter)
        thumbRef.current.addEventListener('mouseleave', handleMouseLeave)

        // return () => {
        //     thumbRef.current.removeEventListener('mouseenter', handleMouseEnter)
        //     thumbRef.current.removeEventListener('mouseleave', handleMouseLeave)
        // }
    })

    return(
        <Wrapper ref={thumbRef}>
            <Thumb src={url}/>
            {
                showX &&
                <Position right={0} top={0}>
                    <Circle color={'white'} opacity={0.6} size={18} onClick={() => onDelete()}>
                        <Icon pointer size={20} color={'#263238'} hoverColor={'#FF5722'}>
                            <BsX/>
                        </Icon>
                    </Circle>
                </Position>
            }
        </Wrapper>
    )
}