import { TextInput, Label } from '../layout/Form'
import { Row } from '../layout/Layout'

export default function LabelTextInput({id, label, width, placeholder, value, max, state, onBlur, onChange}){
    
    return(
        <Row height={35} align={'center'}>
            <Label>{label}</Label>
            <TextInput
                id={id}
                type="text"
                placeholder={placeholder && placeholder}
                width={width && width}
                maxLength={max || undefined}
                disabled={!state}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={(e) => onBlur(e.target.value)}
            />
        </Row>
    )
}