import styled from "styled-components"


const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 1.5rem;
    font-size: 1rem;
`

const LeftButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 100%;
    border: 1px solid #00BFA5;
    border-radius: 0.5rem 0 0 0.5rem;
    background-color: ${props => props.selected ? '#00BFA5':''};
    color: ${props => props.selected ? 'white':''};
    cursor: pointer;
`

const RightButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 100%;
    border: 1px solid #00BFA5;
    border-radius: 0 0.5rem 0.5rem 0;
    background-color: ${props => props.selected ? '#00BFA5':''};
    color: ${props => props.selected ? 'white':''};
    cursor: pointer;
`

function ToggleVoiceCallType({selected, onSelect}){
    
    return(
        <Wrapper>
            <LeftButton selected={selected === 'full_call'} onClick={() => onSelect('full_call')}>
                Completa
            </LeftButton>
            <RightButton selected={selected === 'brief_call'} onClick={() => onSelect('brief_call')}>
                Breve
            </RightButton>
        </Wrapper>
    )
}

export default ToggleVoiceCallType