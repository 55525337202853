import { Box } from "@mui/material";
import AttendantReportBox from "./AttendantReportBox";
import { styled } from "@mui/system";
import { useAttendanceReportData } from "../AttendantPerformanceProvider";

const ScrollableBox = styled(Box)`
    overflow: auto;
    margin-bottom: 2rem;

    &::-webkit-scrollbar {
        width: 14px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #CFD8DC;
        border: 3px solid #ECEFF1;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #90A4AE;
    }
`;

export default function AttendantsSection() {

    const { reportData } = useAttendanceReportData()

    return (
        <ScrollableBox>
            {
                reportData?.map((e, i) => {
                    return <AttendantReportBox key={i} data={e} />;
                })
            }
        </ScrollableBox>
    );
}
