import { Row, Text } from '../layout/Layout'
import HelpIcon from './HelpIcon';


export default function ShortIdTerminal({shortId}){
    
    const help = 'Este é o ID utilizado para acessar o Terminal de Emissão de Senhas.'
    
    return(
        <Row align={'center'}>
            <Text weight={600} color={'#90A4AE'}>{`ID: ${shortId}`}</Text>
            <HelpIcon content={help}/>
        </Row>
    )
}