import { Box, Row, Icon, Column, Text, Circle, Spacer } from '../../layout/Layout'
import AtendentesIcon from '../../../img/icons/AtendentesIcon'
import TotalsDoughnut from '../atendimento/TotalsDoughnut'

function DesksStatsBox({desks}){
    
    return(
        <Box height={"100%"} width={"100%"} padding={'0px'} direction={'column'}>
            <Row align={'center'} height={40} left={10}>
                <Icon fill={'#00BFA5'} height={18} width={18}>
                    <AtendentesIcon/>
                </Icon>
                <Text>Dados dos Guichês</Text>
            </Row>
            <Row>
                <Column width={60} paddingLeft={15}>
                    <Text size={24} weight={800} color={'#00BFA5'}>{desks.length}</Text>
                    <Text size={11} weight={300}>Guichês Cadastrados</Text>
                    <Spacer top={1}/>
                    <Column width={80} >
                        <Row align={'center'}>
                            <Circle size={8} color={'#00C853'}/>
                            <Text>Ativos</Text>
                            <Text weight={600} left={'auto'}>{desks.filter(desk => desk.status === 'active').length}</Text>
                        </Row>
                        <Row align={'center'}>
                            <Circle size={8} color={'#FB9800'}/>
                            <Text>Pausados</Text>
                            <Text weight={600} left={'auto'}>{desks.filter(desk => desk.status === 'paused').length}</Text>
                        </Row>
                        <Row align={'center'}>
                            <Circle size={8} color={'#F34236'}/>
                            <Text>Inativos</Text>
                            <Text weight={600} left={'auto'}>{desks.filter(desk => desk.status === 'inactive').length}</Text>
                        </Row>
                    </Column>
                </Column>
                <Column width={40} height={100} paddingLeft={30} paddingRight={30} justify={'flex-start'}>
                    <TotalsDoughnut
                        types={['Atvos', 'Pausados', 'Inativos']}
                        totals={[desks.filter(desk => desk.status === 'active').length, desks.filter(desk => desk.status === 'paused').length, desks.filter(desk => desk.status === 'inactive').length]}
                        colors={['#00C853', '#FB9800', '#F34236']}
                    />
                </Column>
            </Row>
        </Box>
    )
}

export default DesksStatsBox