import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page } from "../components/layout/Layout"
import AddButton from "../components/buttons/AddButton"
import PwTypesTable from "../components/resources/senhas/pwTypes/PwTypesTable"
import { useContext, useEffect, useState } from "react"
import { toast } from 'react-toastify';
import InfoTooltip from "../components/others/InfoTooltip"
import { pwTypesHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import { create, update, destroy } from '../API/queries'
import PulseLoader from "react-spinners/PulseLoader"
import { DataContext } from "../contexts/DataProvider"
import HeaderFixedMenu from "../components/headerFixedMenu"

function PwTypes(){

    const { dataLoaded, pwTypes, setPwTypes, rules } = useContext(DataContext)

    const [dataUpdated, setDataUpdated] = useState(false)
    const [loading, setLoading] = useState(true)

    const [showAddNew, setShowAddNew] = useState(false)

    const toastId = 'toastId'

    useEffect(() => {
        if(dataLoaded) setLoading(false)
    },[dataLoaded])

    const updateData = (id, newData) => {
        const index = pwTypes.findIndex(el => el._id === id)
        
        toast.promise(
            update('pwtypes', id, newData).then((res) => {
                if(res.status === 200){
                    setDataUpdated(true)
                    const temp = [...pwTypes]
                    temp[index] = res.data    
                    setPwTypes(temp)
                }
            }),
            {
                pending: 'Atualizando dados do Tipo de Senha...',
                success: 'Dados atualizados com sucesso!',
                error: 'Erro: Não foi atualizar os dados'
            }, {containerId: 'alert', toastId: toastId}
        )
    }

    const handleDelete = (id) => {
        
        const temp = [...pwTypes]
        let index = temp.findIndex(temp => temp._id === id)

        if(index >= 0 && temp.length>1){
            
            toast.promise(
                destroy('pwtypes', id).then((res) => {
                    temp.splice(index, 1)
                    setPwTypes(temp)
                }),
                {
                    pending: 'Excluindo Tipo de Senha...',
                    success: 'Tipo de Senha excluído com sucesso!',
                    error: 'Erro: Não foi possível excluir o Tipo de Senha'
                }, {containerId: 'alert'}
            )
        }
        else toast.error('É necessário manter pelo menos um Tipo de Senha', {containerId: 'alert'})
    }  

    const addNewType = (data) =>{

        setShowAddNew(false)

        toast.promise(
            create('pwtypes', data).then((res) => {
                setPwTypes(types => [...types, res.data])  
            }),
            {
                pending: 'Criando Tipo de Senha...',
                success: 'Novo Tipo de Senha criado!',
                error: 'Erro: Não foi possível criar um novo Tipo de Senha'
            }, {containerId: 'alert'}
        )
    }
    
    return(
        <>
            <Header>
                <HeaderLeft>
                    <AddButton text={'Novo Tipo-de Senha'} onClick={() => setShowAddNew(true)}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={pwTypesHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
            {
                loading ? 
                <Page center>
                    <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                </Page>
                :
                <Page>
                    <PwTypesTable
                        pwTypes={pwTypes}
                        rules={rules}
                        showAddNew={showAddNew}
                        dataUpdated={dataUpdated}
                        onUpdateReport={() => setDataUpdated(false)}
                        updateData={(id, newData) => updateData(id, newData)}
                        onDelete={(id) => handleDelete(id)}
                        onCancelAddNew={() => setShowAddNew(false)}
                        onCreateNew={(data) => addNewType(data)}
                    />
                </Page>
            }
        </>
    )
}

export default PwTypes