import styled from "styled-components"
import { IoClose } from 'react-icons/io5'
import { HiCheck } from 'react-icons/hi'

const Menu = styled.span`
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: absolute;
    opacity: ${props => props.hide ? 0 : 1};
    right: ${props => props.hide ? 0 : 10}px;
    transition: all 0.3s ease;
    pointer-events: ${props => props.hide ? 'none' : 'auto'};;
`

const Text = styled.h1`
    font-size: 1rem;
    font-weight: 500;
    margin-right: 1rem;
    white-space: nowrap;
`

const ButtonNo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid #F34236;
    border-radius: 0.8rem;
    cursor: pointer;
    
    svg{
        font-size: 1.5rem;
        color: #F34236;
        width: 1.5rem;
        height: 1.5rem;
    }

    &:hover{
        background-color: #F34236;

        svg{
            color: white;
        }   
    }
`

const ButtonYes = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.6rem;
    width: 2.8rem;
    height: 1.6rem;
    border: 1px solid #00C853;
    border-radius: 0.8rem;
    cursor: pointer;
    
    svg{
        font-size: 1.5rem;
        color: #00C853;
        width: 1.5rem;
        height: 1.5rem;
    }

    &:hover{
        background-color: #00C853;

        svg{
            color: white;
        }    
    }
`

function DeleteConfirmation({hide, onConfirm, onCancel}){

    return(
        <Menu hide={hide}>
            <Text>Deseja excluir?</Text>
            <ButtonNo onClick={() => onCancel()}>
                <IoClose/>
            </ButtonNo>
            <ButtonYes onClick={() => onConfirm()}>
                <HiCheck/>
            </ButtonYes>
        </Menu>
    )
}

export default DeleteConfirmation
