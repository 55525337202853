import { Button, Typography, Box } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

export default function DownloadComplete({ onClose }) {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <CheckCircleOutline color="success" sx={{ fontSize: 40 }} />
            <Typography>Download concluído!</Typography>
            <Button variant="text" onClick={onClose}>
                Fechar
            </Button>
        </Box>
    );
}
