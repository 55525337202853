import { Panel, ShowFrame, Show2, Side2 } from "./styles"
import SidePanel3 from "./SidePanel3"
import SidePanel4 from "./SidePanel4"
import ImageExhibition from "./exhibitions/ImageExhibition"
import VideoExhibition from "./exhibitions/VideoExhibition"
import YouTubeExhibition from "./exhibitions/YouTubeExhibition"
import NoExhibition from "./exhibitions/NoExhibition"

export default function PanelPreview2({config}){



    return(
        <Panel>
            <ShowFrame bg={config.exhibition !== 'none' && 'black'}>
                <Show2>
                    {
                        config.exhibition === 'images' ? 
                        <ImageExhibition urls={config.gallery_urls}/>
                        :
                        config.exhibition === 'videos' ? 
                        <VideoExhibition urls={config.videos_urls}/>
                        :
                        config.exhibition === 'youtube' ? 
                        <YouTubeExhibition url={config.youtube_url}/>
                        :
                        config.exhibition === 'none' &&
                        <NoExhibition theme={config.theme}/> 
                    }
                </Show2>
            </ShowFrame>
            <Side2>
                {
                    config.exhibition === 'none' ? 
                    <SidePanel4 config={config}/>
                    :
                    <SidePanel3 config={config}/>
                }
            </Side2>
        </Panel>
    )
}