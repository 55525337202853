import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page } from "../components/layout/Layout"
import { useState, useContext, useEffect } from "react"
import { DataContext } from "../contexts/DataProvider" 
import InfoTooltip from "../components/others/InfoTooltip"
import { priorRulesHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import PulseLoader from "react-spinners/PulseLoader"
import PriorRulesBox from "../components/resources/senhas/priorRules/PriorRulesBox"
import HeaderFixedMenu from "../components/headerFixedMenu"

function PwPriorRules(){

    const { dataLoaded, rules, pwTypes } = useContext(DataContext)
   
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(dataLoaded) setLoading(false)
    },[dataLoaded])    
     
    return(
        <>
            <Header>
                <HeaderLeft>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={priorRulesHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
                {
                    loading ? 
                    <Page center>
                        <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                    </Page>
                    :
                    <Page>
                        <PriorRulesBox rules={rules} pwTypes={pwTypes}/>
                    </Page>
                }
        </>
    )
}

export default PwPriorRules