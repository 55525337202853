import { Column, Row, Text } from "../layout/Layout"


export default function TimeIntervalSelector({onChangeStart, onChangeEnd}){



    return(
        <Column width={'fit-content'}>
            <Row align={'center'}>
                <input type={'time'} onChange={(e) => onChangeStart(e.target.value)}/>
                <Text wrap={'pre'}>{'  até  '}</Text>
                <input type={'time'} onChange={(e) => onChangeEnd(e.target.value)}/>
            </Row>
        </Column>
    )
}