import { Box, Text, Icon, Column, Row, Spacer } from "../layout/Layout";
import { FiLogOut } from 'react-icons/fi'
import Yes from "../buttons/Yes";
import No from "../buttons/No";

export default function LogoutConfirmationModal({onNo, onYes}){



    return(
        <Box>
            <Column align={'center'}>
                <Icon color={'#00BFA5'} size={24}>
                    <FiLogOut/>
                </Icon>
                <Spacer top={1}/>
                <Text size={14} weight={400}>Deseja sair do sistema?</Text>
                <Spacer top={1}/>
                <Row align={'center'} justify={'center'}>
                    <No status={true} onClick={() => onNo()}/>
                    <Yes status={true} onClick={() => onYes()}/>
                </Row>
            </Column>
        </Box>
    )
}