import styled from "styled-components";
// import SystemAlertsPanel from "../../components/home/SystemAlertsPanel";
import TipOfTheDay from "./TipOfTheDay";
import PulseLoader from "react-spinners/PulseLoader";
import { DataContext } from "../../contexts/DataProvider";
import { useContext, useState, useEffect } from "react";
import { Page, Text, Column, PageSection, Row, FixedPage } from "../../components/layout/Layout";
import logo from '../../img/LOGO_BG.png'

const LogoBg = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${props => props.img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;

    opacity: 0.3;
`

export default function Home(){

    const { systemAlerts } = useContext(DataContext)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(systemAlerts !== null) setLoading(false)
    },[systemAlerts])

    return(
        <>
            {
                loading ? 
                <Page center>
                    <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                </Page>
                :
                <Page center>
                    <LogoBg img={logo}/>
                </Page>
            }
        </>

    )
}