import { Line, Row, Icon, Title, Column, Text } from "../../../layout/Layout"
import { BsCalendar2Date, BsCalendar2Day, BsClock, BsX } from 'react-icons/bs'
import { weekdayString, seasonString, timeString } from "../../../../services/schedulesStrings"
import { Tooltip } from 'react-tippy'

export default function ScheduledResourseRow({name, data, onDelete}){

    const { schedule } = data
    const { type, season, weekday, time } = schedule
    
    const nameString = () => {
        if(type === 'weekday') return weekdayString(weekday)
        if(type === 'season') return seasonString(season.start, season.end)
        if(type === 'time') return timeString(time.start, time.end)
    }

    return(
        <>
            <Row height={'2rem'} align={'center'}>
                <Column width={90}>
                    <Row align={'center'}>
                        <Icon size={14} color={'#00BFA5'}>
                            {
                                type === 'season' ? <BsCalendar2Date/> : type === 'time' ? <BsClock/> : type === 'weekday' && <BsCalendar2Day/>
                            }
                        </Icon>
                        <Title>{name}</Title>
                        <Text wrap={'pre'}>{` (${nameString()})`}</Text>
                    </Row>
                </Column>
                <Column width={10} align={'flex-end'}>
                    <Tooltip title='Excluir Interface' arrow={true} followCursor={false} theme={'light'}>
                        <Icon pointer size={20} color={'#CFD8DC'} hoverColor={'#F44336'} onClick={() => onDelete(data)}>
                            <BsX/>
                        </Icon>
                    </Tooltip>
                </Column>
            </Row>
            <Line/>
        </>
    )
}