import styled from "styled-components"

const Row = styled.span`
    display: flex;
    align-items: center;
    height: 25px;

    label{
        margin-left: 5px;
        font-size: 12px;
        font-weight: 400;
    }

    input{
        accent-color: #00BFA5;
    }
`


function RadioButton({id, label, name, state, onSelect}){

    return(
        <Row>
            <input type="radio" id={id} name={name} checked={state} onChange={onSelect}/>
            <label htmlFor={id}>{label}</label>
        </Row>
    )
}

export default RadioButton