import styled from "styled-components";

export const Layout = styled.div`
    display: grid;
    grid-template-columns: 80px auto;
    grid-template-rows: 6rem calc(100vh - 6rem);
    overflow-y: hidden;
    position: relative;
`

export const SidebarContainer = styled.div`
    grid-column: 1/2;
    grid-row: 1/3;
    position: relative;
    z-index: 2;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    grid-column: 2/3;
    grid-row: 1/3;
    background-color: #ECEFF1;
    max-width: calc(100vw - 80px);
    z-index: 1;
`