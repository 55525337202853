import styled from 'styled-components'
import { HiPlusSm } from 'react-icons/hi'
import { useEffect, useState } from 'react'
import CheckBoxList from '../../form/CheckBoxList'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
`

const Senha = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 500;
    border-radius: 10px;
    padding: 0 8px;
    height: 18px;
    margin: 0 5px 5px 0;
    width: fit-content;
    background-color: #B0BEC5;
    color: white;
`

const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    cursor: pointer;
    position: relative;

    svg{
        font-size: 20px;
        color: #90A4AE;

        &:hover{
            font-size: 22px;
            color: #00BFA5;
        }
    
    }
`

const Menu = styled.div`
    display: ${props => props.show ? 'flex' : 'none'}; 
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #37474F;
    padding: 15px;
    padding: 5px;
    position: absolute;
    top: 0px;
    left: 25px;
`

function PwTypesDisplay({id, options, selected, onSelect}){

    const [showMenu, setShowMenu] = useState(false)

    const handleSelect = (state, option) => {
        setShowMenu(false)
        
        const types = [...selected]

        if(state) {
            types.push(option)
            onSelect(types)
            return
        }

        if(types.length === 1) return toast.error('É necessário manter pelo menos um Tipo de Senha no Guichê', {containerId: 'alert'})

        const index = types.findIndex(type => type === option)

        types.splice(index, 1)

        onSelect(types)
    }

    const handleClickOutside = (e) => {
        if(e.target.id === 'show_menu' + id || e.target.parentElement.id === 'show_menu' + id) return     
        setShowMenu(false)
    }

    useEffect(() => {
        if(showMenu) document.body.addEventListener('click', handleClickOutside)

        return () => document.body.removeEventListener('click', handleClickOutside)
    },[showMenu])

    return(
        <Wrapper>
            {
                [...Array(selected.length)].map((e, i) => <Senha key={i}>{selected[i]}</Senha>)
            }
            <Button>
                <Tooltip arrow={true} delay={200} theme={'light'} html={'Adicionar ou Remover Senhas'}>
                    <HiPlusSm id={'show_menu' + id} onClick={() => setShowMenu(state => !state)}/>
                </Tooltip>
                <Menu show={showMenu}>
                    <CheckBoxList id={id} options={options} checks={selected} onSelect={(state, option) => handleSelect(state, option)}/>
                </Menu>
            </Button>
        </Wrapper>
    )
}

export default PwTypesDisplay