import { useContext } from 'react'
import { createContext, useEffect, useState, useCallback } from 'react'
import { getAll } from '../API/queries'
import { useIntervalAsync } from '../services/useIntervalAsync.ts'
import { ConfigContext } from './ConfigContext'
import { DataContext } from './DataProvider'

export const CallsContext = createContext({})

export const CallsProvider = ({children}) => {

    const { calls: oldCalls, places, fetch } = useContext(DataContext)
    const { config } = useContext(ConfigContext)
    
    const [calls, setCalls] = useState([])
    const [waiting, setWaiting] = useState([])
    const [finished, setFinished] = useState([])
    const [unattended, setUnattended] = useState([])
    const [canceled, setCanceled] = useState([]) 

    const [filteredOldCalls, setFilteredOldCalls] = useState([])

    const [longQueuePlaces, setLongQueuePlaces] = useState([])
    const [longWaitingPasswords, setLongWaitingPasswords] = useState([])

    useEffect(() => {
      fetch(['calls'])
    },[])

    const fetchCalls = useCallback(async () => {

      await getAll('calls/todays').then((res) => {
        setCalls(res.data)
        setFinished(res.data.filter(call => call.status === 'finished'))
        setUnattended(res.data.filter(call => call.status === 'unattended'))
        setWaiting(res.data.filter(call => call.status === 'waiting'))
        setCanceled(res.data.filter(call => call.status === 'canceled'))
      }).catch(err => {
        console.log(err)
      })
    },[])
    
    useIntervalAsync(fetchCalls, 5000)

    const checkLongQueue = () => {
      
      if(!config?.notification.longQueue) return
      if(!places) return
      
      const _places = []
      const longQueues = []

      waiting.forEach(call => {
        if(!_places.includes(call.placeId)) _places.push(call.placeId)
      })

      _places.forEach(id => {
        const placeQueue = waiting.filter(call => call.placeId === id)

        if(placeQueue.length > config?.notification.longQueue) longQueues.push(places.find(el => el._id === id).name)
      })

      setLongQueuePlaces(longQueues)
    }

    const checkLongWaiting = () => {
      if(!config?.notification.passwordWaiting) return

      const longWaiting = []

      waiting.forEach(call => {
        const waitingTime = Math.floor((Date.now() - call.created_at)/60000)
        if(waitingTime > config.notification.passwordWaiting) longWaiting.push(call)
      })

      setLongWaitingPasswords(longWaiting)
    }

    const filterCallsByPeriod = (start, end) => {
      const startTime = new Date(parseInt(start.substring(0,4)), parseInt(start.substring(5,7)) - 1, parseInt(start.substring(8,10)), 0, 0, 0, 0).valueOf()
      const endTime = new Date(parseInt(end.substring(0,4)), parseInt(end.substring(5,7)) - 1, parseInt(end.substring(8,10)), 23, 59, 59, 999).valueOf()

      const filteredCalls = []

      oldCalls.forEach(call => {
        if(call.created_at > startTime && call.created_at < endTime) filteredCalls.push(call)
      })

      setFilteredOldCalls(filteredCalls)
    }

    useEffect(() => {
      checkLongQueue()
      checkLongWaiting()
    },[waiting])
    
    return (
        <CallsContext.Provider value={{calls, waiting, setWaiting, finished, unattended, canceled, oldCalls, longQueuePlaces, longWaitingPasswords, filterCallsByPeriod, filteredOldCalls}}>
            {children}
        </CallsContext.Provider>
    )
}