import { VerticalBox, Column, Row, Title, Text, Table } from "../../layout/Layout"
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';
import { filaTableSenhaInfo, filaTableTipoInfo, filaTablePriorInfo, filaTableTimeInfo, filaTablePosInfo, filaTableWaitInfo, filaTableCancelInfo } from '../../../content/Tooltips'
import QueueRow from "./QueueRow";
import QueueTableTotals from "./QueueTableTotals";


export default function QueueTable({waiting, canceled, order, filter, showCanceled, onCancel}){

    const sort = (a, b) => {
        if (order === 'por ordem de Emissão') {
            if(a.created_at > b.created_at) return 1
            else return -1
        }
    
        if (order === 'por posição na Fila') {
            if(a.index > b.index) return 1
            else return -1
        }
    }
    
    return(
        <VerticalBox>
            <Row bottom={5} borderBtmWidth={1} borderBtmColor={'#37474F'} borderBtmStyle={'solid'} align={'flex-end'}>
                <Column width={5}></Column>
                <Column>
                    <Tooltip theme="light" arrow={true} html={filaTableSenhaInfo}>
                        <Title>Senha</Title>
                    </Tooltip>
                </Column>
                <Column width={50}>
                    <Tooltip theme="light" arrow={true} html={filaTableTipoInfo}>
                        <Title>Tipo</Title>
                    </Tooltip>
                </Column>
                <Column align={'center'}>
                    <Tooltip theme="light" arrow={true} html={filaTablePriorInfo}>
                        <Title>Prioritária</Title>
                    </Tooltip>
                </Column>
                <Column width={90} align={'center'}>
                    <Tooltip theme="light" arrow={true} html={filaTableTimeInfo}>
                        <Title>Emitida às</Title>
                    </Tooltip>
                </Column>
                <Column align={'center'} width={100}>
                    <Tooltip theme="light" arrow={true} html={filaTablePosInfo}>
                        <Title>Posição</Title>
                    </Tooltip>
                </Column>
                <Column align={'center'} width={120}>
                    <Tooltip theme="light" arrow={true} html={filaTableWaitInfo}>
                        <Title>Tempo em espera</Title>
                    </Tooltip>
                </Column>
                <Column align={'center'} width={50}>
                    <Tooltip theme="light" arrow={true} html={filaTableCancelInfo}>
                        <Title>Cancelar</Title>
                    </Tooltip>
                </Column>
            </Row>
            <Table>
                {
                    waiting.length === 0 ? 
                    <Row justify={'center'} align={'flex-end'} height={50}>
                        <Text size={16} color={'#78909C'} weigth={600}>No momento não há nenhuma senha na fila</Text>
                    </Row>
                    :
                    <>
                        {
                            waiting.sort((a,b) => sort(a,b)).map((e,i) => {
                                if(filter === e.type) return <QueueRow key={i} data={e} onCancel={onCancel}/>
                                else if(filter === 'Todos') return <QueueRow key={i} data={e} onCancel={onCancel}/>
                            })
                        }
                        {
                            showCanceled &&
                            canceled.sort((a,b) => sort(a,b)).map((e,i) => {
                                if(filter === e.type) return <QueueRow key={i} data={e}/>
                                else if(filter === 'Todos') return <QueueRow key={i} data={e}/>
                            })  
                        }
                    </>
                }
            </Table>
            {   
                waiting.length > 0 &&
                <Row align={'center'} justify={'flex-end'} height={50} marginTop={10}>
                    <QueueTableTotals data={waiting}/>
                </Row>
            }
        </VerticalBox>
    )
}

