import { FiEdit } from 'react-icons/fi'
import { BsCheckLg } from 'react-icons/bs'
import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components';
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';
import { toast } from 'react-toastify';

const Name = styled.span`
    display: flex;
    align-items: center;
    height: 2.5rem;

    h1{
        font-size: 1.2rem;
        font-weight: 400;
        white-space: nowrap;
    }

    input{
        border: 1px solid #37474F;
        border-radius: 5px;
        height: 2rem;
        width: 12.5rem;
        padding-left: 0.5rem;
        font-size: 1rem;
        color: #37474F;
        outline: none;
        font-size: 1.2rem;

        &:focus{
            border: 1px solid #00BFA5;
        }
    }

    svg{
        color: #00BFA5;
        margin-left: 1rem;
        font-size: 1.1rem;
        cursor: pointer;
    }
`

function NameEdit({id, name, setName}){

    const [editNameEnable, setEditNameEnable] = useState(false)
    const [tempName, _setTempName] = useState('')

    const tempNameRef = useRef(tempName)
    const inputRef = useRef()
    
    const setTempName = (data) => {
        tempNameRef.current = data
        _setTempName(data)
    }

    useEffect(() => {
        if(editNameEnable) inputRef.current.select()
    },[editNameEnable])

    const handleConfirm = () => {

        if (tempNameRef.current.length >= 4) {
            setEditNameEnable(false)
            setName(id, tempNameRef.current)
            
            document.body.removeEventListener('click', handleEvents, true)
        } 
        else toast.error('Dados inválidos! O Nome deve ter 4 ou mais caracteres', {containerId: 'alert'})
        
    }

    const handleCancel = () => {

        document.body.removeEventListener('click', handleEvents, true)

        setTempName(name)
        setEditNameEnable(false)
    }

    const handleEvents = (e) => {

        console.log(e)

        if(e.target.id.includes(id) || e.target.parentElement.id.includes(id)) return

        handleCancel()
    }

    const handleEditEnable = () => {
        document.body.addEventListener('click', handleEvents, true)

        setTempName(name)
        setEditNameEnable(true)
    }

    const handleKeyDown = (e) => {
        if(e.key === 'Enter') handleConfirm()
        if(e.key === 'Escape') handleCancel()
    }
    
    return(
        <Name>
            {
                !editNameEnable?
                <>
                    <h1>{name}</h1>                
                    <Tooltip arrow={true} theme={'light'} title='Editar Nome'>
                        <FiEdit onClick = {() => handleEditEnable()}/>
                    </Tooltip>
                </>
                :
                <>
                    <input 
                        ref={inputRef}
                        id={id} 
                        type="text" 
                        value={tempName} 
                        maxLength="20" 
                        onChange={(e) => setTempName(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                    <Tooltip arrow={true} theme={'light'} title='Confirmar'>
                        <BsCheckLg id={id} onClick = {() => handleConfirm()}/>
                    </Tooltip>
                </>
            }
        </Name>
    )
}

export default NameEdit