import styled from "styled-components";
import { GrHomeRounded } from 'react-icons/gr'
import { BsClock } from 'react-icons/bs'
import { BiFilterAlt } from 'react-icons/bi'

const Sel = styled.span`
    display: flex;
    align-items: center;
    height: 35px;

    label{
        font-size: 12px;
        font-weight: 400;
    }

    select{
        border: 1px solid #37474F;
        border-radius: 5px;
        height: 25px;
        width: 150px;
        margin: 0 10px 0 auto;
        outline: none;
        color: #37474F; 

        &:focus{
            border: 1px solid #00BFA5;
        }
    }

    svg{
        width: 16px;
        height: 16px;

        path{
            stroke: #37474F;
        }
        margin-right: 10px;
    }
`


function SelectIcon({icon, options, value, disabled, onChange}){
    return(
        <Sel>
            {icon === 'clock' ? <BsClock/> : icon === 'home' ? <GrHomeRounded/> : icon === 'filter' ? <BiFilterAlt/> : ''}
            <select name="select" value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled}>
                {
                    options.map((options, index) => 
                        <option key={index}>{options}</option>
                    )
                }  
            </select>
        </Sel>
    )
}

export default SelectIcon