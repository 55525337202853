import { VerticalBox, Column, Row, Title, Table } from "../../../layout/Layout"
import PwTypesRow from "./PwTypesRow"
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';
import { nameInfo, prefixInfo, priorInfo, startInfo, restartInfo, digitsInfo, previewInfo, statusInfo, actionsInfo } from '../../../../content/tooltips/pwTypesTooltips'
import PwTypesRowNew from "./PwTypesRowNew";
import TableTitle from "../../../form/TableTitle";


function PwTypesTable({pwTypes, showAddNew, dataUpdated, onUpdateReport, rules, updateData, onDelete, onCreateNew, onCancelAddNew}){
    
    return(
        <VerticalBox>
            <Row bottom={5} borderBtmWidth={1} borderBtmColor={'#37474F'} borderBtmStyle={'solid'} align={'flex-end'}>
                <Column>
                    <TableTitle help={nameInfo}>Nome</TableTitle>
                </Column>
                <Column align={'center'}>
                    <TableTitle center help={prefixInfo}>Prefixo</TableTitle>
                </Column>
                <Column align={'center'}>
                    <TableTitle center help={priorInfo}>Prioridade</TableTitle>
                </Column>
                <Column align={'center'}>
                    <TableTitle center help={startInfo}>Iniciar em</TableTitle>
                </Column>
                <Column align={'center'}>
                    <TableTitle center help={restartInfo}>Reiniciar em</TableTitle>
                </Column>
                <Column align={'center'}>
                    <TableTitle center help={digitsInfo}>Qtde de dígitos</TableTitle>
                </Column>
                <Column align={'center'}>
                    <TableTitle center help={previewInfo}>Preview</TableTitle>
                </Column>
                <Column>
                    <TableTitle help={statusInfo}>Status</TableTitle>
                </Column>
                <Column>
                    <TableTitle help={actionsInfo}>Ações</TableTitle>
                </Column>
            </Row>
            <Table>
                {
                    pwTypes.map((tipo, index) =>
                        {
                            return <PwTypesRow 
                                key={index}
                                id={index}
                                pwTypes={pwTypes} 
                                dataUpdated={dataUpdated}
                                onUpdateReport={onUpdateReport}
                                data={tipo}
                                rules={rules}
                                updateData={updateData}
                                onDelete={onDelete}/>
                        }
                    )
                }
                {
                    showAddNew &&
                    <PwTypesRowNew pwTypes={pwTypes} rules={rules} onCreate={onCreateNew} onCancel={onCancelAddNew}/>
                }
            </Table>
        </VerticalBox>
    )
}

export default PwTypesTable
