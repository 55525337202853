import { Item, Icon, ItemLabel, Arrow } from "./style"
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import { Link } from "react-router-dom"

export default function SideMenuItem({icon, extIcon, subitem, link, iconSize, label, arrow, show, selected, onClick}){

    return(
        <Link to={link}>
            <Item selected={selected} show={show} subitem={subitem} onClick={onClick}>
                <Icon selected={selected} show={show} extIcon={extIcon} iconSize={iconSize}>
                    {icon}
                </Icon>
                <ItemLabel selected={selected} show={show}>
                    {label}    
                </ItemLabel>
                {
                    arrow &&
                    <Arrow show={show} selected={selected}>
                        <MdOutlineArrowForwardIos/>
                    </Arrow>
                }
            </Item>
        </Link>
    )
}