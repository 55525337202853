import { Row } from '../layout/Layout'
import { SelectField, Label, Option } from '../layout/Form'
import HelpIcon from "../others/HelpIcon";


function Select({label, width, placeholder, left, options, values, value, disabled, onChange, help}){

    return(
        <Row height={35} align={'center'}>
            <Label htmlFor="">{label}</Label>
            <SelectField name="select" width={width && width} left={left && left} value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled}>
                {
                    placeholder &&
                    <Option value="" disabled selected>{placeholder}</Option>
                }
                {
                    options &&
                    options.map((options, index) => 
                        <Option key={index} value={values && values[index]}>{options}</Option>
                    )
                } 
            </SelectField>
            {help && <HelpIcon content={help}/>}
        </Row>
    )
}

export default Select