import { useState } from "react"
import styled from "styled-components"
import { Text } from "../../../components/layout/Layout"

const SelectorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 23px;
    border: 1px solid #90A4AE;
    border-radius: 8px;
`

const SelectorCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-left: ${props => props.pos === 'center' && '1px solid #90A4AE'};
    border-right: ${props => props.pos === 'center' && '1px solid #90A4AE'};
    border-radius: ${props => props.pos === 'left' ? '8px 0 0 8px' : props.pos === 'right' && '0 8px 8px 0'};
    background-color: ${props => props.selected && '#00BFA5'};
    cursor: pointer;
`


export default function BackgroundTypeSelector({selected, onSelect}){
    
    return(
        <SelectorWrapper>
            <SelectorCell pos={'left'} selected={selected === 'color'} onClick={() => onSelect('color')}>
                <Text size={11} color={selected === 'color' && 'white'} weight={500}>Cor Sólida</Text>
            </SelectorCell>
            <SelectorCell pos={'center'} selected={selected === 'gradient'} onClick={() => onSelect('gradient')}>
                <Text size={11} color={selected === 'gradient' && 'white'} weight={500}>Gradiente</Text>
            </SelectorCell>
            <SelectorCell pos={'right'} selected={selected === 'image'} onClick={() => onSelect('image')}>
                <Text size={11} color={selected === 'image' && 'white'} weight={500}>Imagem</Text>
            </SelectorCell>
        </SelectorWrapper>
    )
}