import styled from "styled-components";

const Modal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 99999999;
`

export default function({isOpen, children}){
    

    if(isOpen){
        return(
            <Modal>
                {children}
            </Modal>
        )
    }
    
}
