import { Text, Column, Icon, Row } from '../../../components/layout/Layout'
import GrowthArrowUp from '../../../img/icons/GrowthArrowUp'
import GrowthArrowDown from '../../../img/icons/GrowthArrowDown'


function GuicheStats({title, number, growth}){
    return(
        <Column align={'center'} justify={'center'}>
            <Text size={11} weight={300} align={'center'}>{title}</Text>
            <Row align={'center'} justify={'center'} height={30}>
                <Icon size={18}>
                    {growth !== undefined && (growth ? <GrowthArrowUp/> : <GrowthArrowDown/>)}
                </Icon>
                <Text size={16} weight={700} align={'center'} color={'#00BFA5'}>
                    {number}
                </Text>
            </Row>
        </Column>
    )
}

export default GuicheStats