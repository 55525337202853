import styled from "styled-components"

const But = styled.button`
    background-color: ${props => props.status ? '#00BFA5' : 'white'};
    color: ${props => props.status ? 'white' : '#37474F'};
    height: 30px;
    width: 160px;
    border: ${props => props.status ? 'none' : '1px solid #37474F'};
    border-radius: 5px;
    margin-right: 20px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;

    &:hover{
        border: 1px solid #00BFA5;
    }
`

function Button({text, status, onClick}){
    return(
        <But status={status} onClick={onClick}>{text}</But>
    )
}

export default Button