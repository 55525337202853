import styled from "styled-components";
import RatingStars from "../../others/RatingStars";
import img from '../../../img/Blank-profile.png'
import { useContext } from "react";
import { DataContext } from "../../../contexts/DataProvider";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    border-left: 1px solid #B0BEC5;
`

const Avatar = styled.div`
    width: 70px;
    height: 70px; 
    border-radius: 50%;
    margin: 10px;
    background-color: #fff;
    background-image: url(${props => props.url ? props.url : img});
    background-position: center;
    background-size: contain;
`

const Name = styled.h1`
    font-size: 12px;
    font-weight: 400;
    text-align: center;
`

function UserInfo({user}){
    
    const { attendants } = useContext(DataContext)
    
    return(
        <Wrapper>
            <Avatar url={attendants.find(el => el._id === user).avatar_url}/>
            <Name>{attendants.find(el => el._id === user).name}</Name>
            <RatingStars ratings={attendants.find(el => el._id === user).rating}/>
        </Wrapper>
    )
}

export default UserInfo