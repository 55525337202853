import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page } from "../components/layout/Layout"
import AddButton from "../components/buttons/AddButton"
import { useEffect, useState, useRef, useContext } from 'react'
import { toast } from 'react-toastify';
import { update } from '../API/queries'
import InfoTooltip from "../components/others/InfoTooltip"
import { dashboardServicesHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import { DataContext } from "../contexts/DataProvider"
import PulseLoader from "react-spinners/PulseLoader"
import HeaderFixedMenu from "../components/headerFixedMenu"
import { CallsContext } from "../contexts/CallsProvider";
import Modal from "../components/modals/Modal"
import GeneralModalContainer from "../components/modals/GeneralModalContainer"
import ServicesAddNewModal from "../components/dashboard/services/ServicesAddNewModal";
import ServiceBox from "../components/dashboard/services/ServiceBox";
import { PlaceContext } from "../contexts/PlaceContext";

export default function DashboardServices(){
    
    const page = useRef(null)

    const { placeId } = useContext(PlaceContext)
    const { dataLoaded, services } = useContext(DataContext)

    const [loading, setLoading] = useState(true)
    
    const [showAddNew, setShowAddNew] = useState(false)

    useEffect(() => {
        if(dataLoaded) setLoading(false)
    },[dataLoaded])
 
    const scrollBottom = () => {
        
        const el = page.current 
        
        el.scrollTo({
            top: 99999999,
            behavior: 'smooth',
        }) 
    }

    const handleAddButton = () => {
        setShowAddNew(true)
        setTimeout(() => {scrollBottom()}, 100)
    }
    
    return(
        <>
            <Header>
                <HeaderLeft>
                    <AddButton text={'Novo-Serviço'} icon={''} onClick={() => handleAddButton()}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={dashboardServicesHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
            {
                loading ? 
                <Page center>
                    <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                </Page>
                :
                <Page ref={page}>
                    {
                        services.filter(el => el.placeId === placeId).map((service, index) => <ServiceBox key={index} service={service}/>)
                    }
                </Page>
            }
            <Modal isOpen={showAddNew}>
                <GeneralModalContainer onClose={() => setShowAddNew(false)}>
                    <ServicesAddNewModal onClose={() => setShowAddNew(false)}/>
                </GeneralModalContainer>
            </Modal>
        </>
    )
}