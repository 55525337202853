import styled from 'styled-components';
import { Row, Text, Icon, Box } from '../../layout/Layout'
import { BsBarChart } from 'react-icons/bs'
import { useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2'
import { getDatasets } from './methods/TotalStats'

const Chart = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width};
    height: calc(100% - 30px);
    position: relative;
`

export default function TotalCallsByDeskChart({desks, calls}){

    const canvasRef = useRef()
    const [canvasWidth, setCanvasWidth] = useState(0)

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

    useEffect(() => {
        setCanvasWidth(getComputedStyle(canvasRef.current).width)
    },[])


    const options = {
        canvas: {
            styled:{
                width: '128px'
            }
        },
        layout: {
            padding: 10
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {display: false},
          title: {display: false},
          decimation: {
            enable: false
          }
        },
      };
      
    const labels = getDatasets(desks, calls).labels
    
    const data = {
        labels,
        datasets: getDatasets(desks, calls).datasets
    };  
    
    return(
        <Box ref={canvasRef} height={"100%"} width={"100%"} padding={'0px'} direction={'column'}>
            <Row align={'center'} height={40} left={10}>
                <Icon size={18} color={'#00BFA5'}>
                    <BsBarChart/>
                </Icon>
                <Text>Total de Atendimentos por Guichê</Text>
            </Row>
            <Chart width={canvasWidth}>
                {
                    calls.length > 0 ?
                    <Bar options={options} data={data}/>
                    :
                    <Text size={14} weight={500} color={'#78909C'}>Não há dados para este local</Text>
                }
            </Chart>
        </Box>
    )
}