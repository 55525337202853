import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page, Text } from '../components/layout/Layout'
import  {BsClock } from 'react-icons/bs'
import { MdOutlineWorkspaces } from 'react-icons/md'
import styled from "styled-components";
import NotifBox from "../components/notif/NotifBox" 
import GuichesList from "../components/dashboard/atendimento/GuichesList"
import WaitingTimeChart from "../components/dashboard/atendimento/WaitingTimeChart";
import AttendanceTimeChart from "../components/dashboard/atendimento/AttendanceTimeChart";
import { useEffect, useState, useContext } from "react";
import FluxoAtendimento from "../components/dashboard/atendimento/FluxoAtendimento";
import InfoTooltip from "../components/others/InfoTooltip"
import { dashboardAttendanceHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import TotalWaitingChart from "../components/dashboard/atendimento/TotalWaitingChart";
import TotalDoneChart from "../components/dashboard/atendimento/TotalDoneChart";
import PulseLoader from "react-spinners/PulseLoader"
import { DataContext } from "../contexts/DataProvider";
import HeaderFixedMenu from "../components/headerFixedMenu"
import { CallsContext } from "../contexts/CallsProvider";
import { DateInput } from "../components/layout/Form";
import { getTypeColors } from "../components/dashboard/atendimento/typeColors";
import { PlaceContext } from "../contexts/PlaceContext";
import SelectWithIcon from "../components/form/SelectWithIcon"

const PageCenter = styled.div`
    display: flex;
    align-items: ${props => props.center && 'center'};
    justify-content: ${props => props.center && 'center'};
    padding: 15px;
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
`

const Dash = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 28% 28% calc(44% - 40px);
    grid-gap: 20px;
    height: 100%;
    width: 100%;
`

const Chart = styled.div`
    grid-column: ${props => props.col};
    grid-row: ${props => props.row};
`

function DashboardGeneral(){

    const { placeId } = useContext(PlaceContext)
    const { filteredOldCalls, calls, filterCallsByPeriod, oldCalls } = useContext(CallsContext)
    const { dataLoaded } = useContext(DataContext)

    const [placeCalls, setPlaceCalls] = useState([])
    
    const [selectedService, setSelectedService] = useState('Todos')

    const [loading, setLoading] = useState(true)

    const [timeFilter, setTimeFilter] = useState('Tempo Real')
    const [periodStart, setPeriodStart] = useState(null)
    const [periodEnd, setPeriodEnd] = useState(null)
    const [periodLegend, setPeriodLegend] = useState('')
    const timeOpts = ['Tempo Real', 'Ontem', 'Última Semana', 'Selecionar Período']

    const [typeColors, setTypeColors] = useState({})

    useEffect(() => {
        if(calls.length > 0) setPlaceCalls(calls.filter(call => call.placeId === placeId))
    },[calls, placeId])

    useEffect(() => {
        setSelectedService('Todos')
    },[placeId])
    
    const getServices = () => {
        const temp = [...placeCalls]
        let _services = ['Todos']

        temp.forEach(e => {
            if(!_services.includes(e.service)) _services.push(e.service)
        })

        return _services
    }
    
    useEffect(() => {
        if(dataLoaded && loading) {
            setLoading(false)
            setTypeColors(getTypeColors(oldCalls))
        }
    },[dataLoaded])

    const yesterdayString = () => {
        const year = new Date().getFullYear()
        const month = new Date().getMonth() + 1
        const day = new Date().getDate() - 1

        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    }

    const lastWeekStrings = () => {
        const today = new Date()
        const start = new Date()
        const end = new Date()

        start.setDate(today.getDate() - 6)
        end.setDate(today.getDate())

        const startYear = start.getFullYear()
        const startMonth = start.getMonth() + 1
        const startDay = start.getDate() - 1

        const endYear = end.getFullYear()
        const endMonth = end.getMonth() + 1
        const endDay = end.getDate() - 1

        const startString = `${startYear}-${startMonth.toString().padStart(2, '0')}-${startDay.toString().padStart(2, '0')}`
        const endString = `${endYear}-${endMonth.toString().padStart(2, '0')}-${endDay.toString().padStart(2, '0')}`
        
        return [startString, endString]
    }
    
    const handleTimeFilterSelect = (filter) => {
        setTimeFilter(filter)

        if(filter === 'Tempo Real'){
            setPeriodStart(null)
            setPeriodEnd(null)
            setPeriodLegend('')
            return
        }

        if(filter === 'Ontem'){
            const yesterday = yesterdayString()
            setPeriodStart(yesterday)
            setPeriodEnd(yesterday)
            setPeriodLegend('')
            return
        }

        if(filter === 'Última Semana'){
            const strings = lastWeekStrings()
            setPeriodStart(strings[0])
            setPeriodEnd(strings[1])
            return
        }

        if(filter === 'Selecionar Período'){
            setPeriodStart(null)
            setPeriodEnd(null)
            return
        }
    }

    const periodString = () => {
        if(timeFilter === 'Tempo Real') return ''
        if(timeFilter === 'Ontem') return `Dados de ${periodStart.substring(8,10)}/${periodStart.substring(5,7)}/${periodStart.substring(0,4)}`
        return `Dados de ${periodStart.substring(8,10)}/${periodStart.substring(5,7)}/${periodStart.substring(0,4)} à ${periodEnd.substring(8,10)}/${periodEnd.substring(5,7)}/${periodEnd.substring(0,4)}`
    }

    useEffect(() => {
        if(periodStart && periodEnd){
            setPeriodLegend(periodString())
            filterCallsByPeriod(periodStart, periodEnd)
        }
    },[periodStart, periodEnd])
    
    return(
        <>
            <Header>
                <HeaderLeft>
                    <SelectWithIcon
                        icon={<MdOutlineWorkspaces/>}
                        options={getServices()}
                        width={'13rem'}
                        value={selectedService}
                        onChange={(value) => setSelectedService(value)}
                    />
                    <SelectWithIcon
                        icon={<BsClock/>}
                        options={timeOpts}
                        width={'13rem'}
                        value={timeFilter}
                        onChange={(value) => handleTimeFilterSelect(value)}
                    />
                    {
                        timeFilter === "Selecionar Período" &&
                        <>
                            <Text>Período de</Text>
                            <DateInput type={'date'} onChange={(e) => setPeriodStart(e.target.value)}/>
                            <Text>até</Text>
                            <DateInput type={'date'} onChange={(e) => setPeriodEnd(e.target.value)}/>
                        </>
                    }
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={dashboardAttendanceHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
                {
                    loading ? 
                    <Page center>
                        <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                    </Page>
                    :
                    <PageCenter>
                        <Dash>
                            <Chart col={'1/2'} row={'1/2'}>
                                <TotalDoneChart
                                    calls={timeFilter === 'Tempo Real' ?
                                        selectedService === 'Todos' ? placeCalls : placeCalls.filter(el => el.service === selectedService)
                                        :
                                        filteredOldCalls
                                    }
                                    typeColors={typeColors}
                                    legend={periodLegend}
                                />
                            </Chart>
                            <Chart col={'2/3'} row={'1/2'}>
                                <TotalWaitingChart
                                    calls={selectedService === 'Todos' ? placeCalls : placeCalls.filter(el => el.service === selectedService)}
                                    typeColors={typeColors}
                                    showLegend={timeFilter !== 'Tempo Real'}
                                />
                            </Chart>
                            <Chart col={'1/2'} row={'2/3'}>
                                <AttendanceTimeChart
                                    calls={timeFilter === 'Tempo Real' ?
                                        selectedService === 'Todos' ? placeCalls.filter(el => el.status === 'finished')
                                        :
                                        placeCalls.filter(el => el.service === selectedService && el.status === 'finished')
                                        :
                                        filteredOldCalls
                                    }
                                    legend={periodLegend}
                                />
                            </Chart>
                            <Chart col={'2/3'} row={'2/3'}>
                                <WaitingTimeChart
                                    calls={timeFilter === 'Tempo Real' ?
                                        selectedService === 'Todos' ? placeCalls.filter(el => el.status === 'finished')
                                        :
                                        placeCalls.filter(el => el.service === selectedService && el.status === 'finished')
                                        :
                                        filteredOldCalls
                                    }
                                    legend={periodLegend}
                                />
                            </Chart>
                            <Chart col={'3/4'} row={'1/3'}>
                                <GuichesList/>
                            </Chart>
                            <Chart col={'1/4'} row={'3/4'}>
                                <FluxoAtendimento 
                                    data={timeFilter === 'Tempo Real' ?
                                        selectedService === 'Todos' ? placeCalls.filter(el => el.status === 'finished')
                                        :
                                        placeCalls.filter(el => el.service === selectedService && el.status === 'finished')
                                        :
                                        filteredOldCalls
                                    }
                                    typeColors={typeColors}
                                    legend={periodLegend}
                                />
                            </Chart>
                        </Dash>
                        <NotifBox/>
                    </PageCenter>
                }
        </>
    )
}

export default DashboardGeneral