import styled from "styled-components"
import { Icon, Text, Column } from "../../components/layout/Layout"
import { HiOutlineLightBulb } from 'react-icons/hi'
import { getAll } from "../../API/queries"
import { useEffect, useState } from "react"
import { useRef } from "react"

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0.5rem 3rem;
    padding: 1.5rem;
    border: 1px solid #00BFA5;
    border-radius: 1rem;
`

const Tip = styled.div`
    h1{
        font-size: 1rem;
        font-weight: 400;
        color: #37474F;
    }

    strong{
        font-size: 1rem;
        font-weight: 700;
        color: #37474F;
    }

    a{
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        color: #1565C0;
    }
`

export default function TipOfTheDay(){

    const [tip, setTip] = useState(null)

    const tipRef = useRef()
    
    const getRandomTip = () => {
        getAll('tips/random').then(res => {
            setTip(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const openLink = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const getAElement = () => {
        const element = tipRef.current.getElementsByTagName('a')[0]
        if(element) element.addEventListener('click', () => openLink(tip.url))
    }

    useEffect(() => {
        getRandomTip()
    },[])

    useEffect(() => {
        if(tip !== null) getAElement()
    },[tip])

    if(tip !== null){
        return(
            <Wrapper>
                <Icon size={24} color={'#00BFA5'}>
                    <HiOutlineLightBulb/>
                </Icon>
                <Column>
                    <Text size={14} weight={600}>Dica de hoje</Text>
                    <Tip ref={tipRef} dangerouslySetInnerHTML={{__html: tip.text}}/>
                </Column>
            </Wrapper>
        )
    }
}