import { useEffect, useState } from "react";
import { createContext, useCallback } from "react";
import { getDataFrom } from "../API/dataGetter";
import { useIntervalAsync } from '../services/useIntervalAsync.ts'

export const DataContext = createContext()

export const DataProvider = ({children}) => {

    const [admins, setAdmins] = useState(null)
    const [attendants, setAttendants] = useState(null)
    const [calls, setCalls] = useState(null)
    const [desks, setDesks] = useState(null)
    const [displays, setDisplays] = useState(null)
    const [interfaces, setInterfaces] = useState(null)
    const [panels, setPanels] = useState(null)
    const [places, setPlaces] = useState(null)
    const [services, setServices] = useState(null)
    const [printLayouts, setPrintLayouts] = useState(null)
    const [pwTypes, setPwTypes] = useState(null)
    const [supportCalls, setSupportCalls] = useState(null)
    const [systemAlerts, setSystemAlerts] = useState(null)
    const [rules, setRules] = useState(null)
    const [terminals, setTerminals] = useState(null)

    const [dataLoaded, setDataLoaded] = useState(false)

    const checkDataIsLoaded = () => {
        if(!admins) return
        if(!attendants) return
        if(!calls) return
        if(!desks) return
        if(!places) return
        if(!services) return
        if(!printLayouts) return
        if(!systemAlerts) return
        if(!rules) return
        if(!terminals) return

        setDataLoaded(true)
    }
    
    useEffect(() => {
        checkDataIsLoaded()
    },[admins, attendants, calls, desks, panels, places, services, printLayouts, pwTypes, systemAlerts, rules, terminals])

    const fetchAllData = useCallback(async () => {
        if(window.document.hidden) return

        // setDataLoaded(false)

        try {
            const res = await getDataFrom('data')

            setAdmins(res.admins)
            setAttendants(res.attendants)
            setCalls(res.calls)
            setDesks(res.desks)
            setInterfaces(res.interfaces)
            setPanels(res.panels)
            setPlaces(res.places)
            setServices(res.services)
            setPrintLayouts(res.printLayouts)
            setPwTypes(res.pwTypes)
            setSystemAlerts(res.systemAlerts)
            setRules(res.rules)
            setTerminals(res.terminals)

            // setTimeout(() => {
            //     setDataLoaded(true)
            // }, 1000);

        } catch (error) {
            console.log(error)
        }
    },[])


    const fetch = async (array) => {

        return

        if(array.includes('admin')) setAdmins(await getDataFrom('admin'))
        if(array.includes('attendant')) setAttendants(await getDataFrom('attendant'))
        if(array.includes('calls')) setCalls(await getDataFrom('calls'))
        if(array.includes('desk')) setDesks(await getDataFrom('desk'))
        if(array.includes('display')) setDisplays(await getDataFrom('display'))
        if(array.includes('interface')) setInterfaces(await getDataFrom('interface'))
        if(array.includes('panel')) setPanels(await getDataFrom('panel'))
        if(array.includes('place')) setPlaces(await getDataFrom('place'))
        if(array.includes('printlayout')) setPrintLayouts(await getDataFrom('printlayout'))
        if(array.includes('pwtypes')) setPwTypes(await getDataFrom('pwtypes'))
        if(array.includes('support')) setSupportCalls(await getDataFrom('support'))
        if(array.includes('sysalerts')) setSystemAlerts(await getDataFrom('sysalerts'))
        if(array.includes('rules')) setRules(await getDataFrom('rules'))
        if(array.includes('terminal')) setTerminals(await getDataFrom('terminal'))

        setTimeout(() => {
            setDataLoaded(true)
        }, 4000);
    }

    // useEffect(() => {
    //     if(dataLoaded){
    //         setTimeout(() => {
    //             setDataLoaded(false)
    //         }, 100);
    //     }
    // },[dataLoaded])

    useIntervalAsync(fetchAllData, 5000)

    return(
        <DataContext.Provider
            value={{
                dataLoaded, admins, attendants, calls, desks, displays, interfaces, panels, places, services, printLayouts, pwTypes, supportCalls, systemAlerts, rules, terminals,
                setAdmins, setAttendants, setCalls, setDesks, setDisplays, setInterfaces, setPanels, setServices, setPlaces, setPrintLayouts, setPwTypes, setSupportCalls, setSystemAlerts, setRules, setTerminals,
                fetch
            }}
        >
            {children}
        </DataContext.Provider>
    )
}