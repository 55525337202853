import styled from "styled-components"

const Number = styled.span`
    display: flex;
    align-items: center;
    height: 35px;

    h1{
        font-size: 12px;
        font-weight: 400;
    }

    input{
        border: 1px solid #37474F;
        border-radius: 5px;
        height: 25px;
        width: 50px;
        margin: 0 5px;
        padding-left: 3px;
        outline: none;
        color: #37474F; 

        &:focus{
            border: 1px solid #00BFA5;
        }
    }
`

function NumberBetween({before, after, value, defaultValue, min, max, onChange}){
    return(
        <Number>
            <h1>{before}</h1>
            <input type="number" defaultValue={defaultValue} value={value} min={min} max={max} onChange={(e) => onChange(e.target.value)}/>
            <h1>{after}</h1>
        </Number>
    )
}

export default NumberBetween