import styled from 'styled-components'
import FormTitle from '../../form/FormTitle'
import { Wrapper, Row, Spacer, VerticalLine, Column, Border, Text, Icon, Title } from '../../layout/Layout'
import InterfacePreview from './InterfacePreview'
import ToggleLabel from '../../form/ToggleLabel'
import LabelNumber from '../../form/LabelNumber'
import LabelInput from '../../form/LabelInput'
import LabelPickColor from '../../form/LabelPickColor'
import LabelLoad from '../../form/LabelLoad'
import { update } from '../../../API/queries'
import BackgroundTypeSelector from './BackgroundTypeSelector'
import LoadPatterns from './LoadPatterns'
import { loadInterfaceInfo, screenBgInfo, contentInfo, buttonsInfo } from '../../../content/tooltips/interfacesTooltips'
import { BsEye } from 'react-icons/bs'
import { useState } from 'react'

const Section = styled.div`
    display: ${props => props.hide ? 'none' : 'flex'};
    flex-direction: column;
    width: 100%;
`

function InterfaceBox({data, setValue, onChangeBg, onLoadPattern}){

    const [tempData, setTempData] = useState(data)

    const handleBgUpload = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('file', e.target.files[0])
        update('interface/bg', data._id, formData).then((res) => {
            onChangeBg(data._id, res.data.url)
            setTempData(prev => ({...prev, bgUrl: res.data.url}))     
        })
    }

    const handleGradientColors = (index, color) => {
        let gradientColors = data.gradientColors
        gradientColors[index] = color
        setValue(data._id, gradientColors, 'gradientColors')        
    }

    const handleTempGradientColors = (index, color) => {
        let gradientColors = tempData.gradientColors
        gradientColors[index] = color
        setTempData(prev => ({...prev, gradientColors: gradientColors}))        
    }

    const handleToggleChange = (state, property) => {
        setTempData(prev => ({...prev, [property]: state}))
        setValue(data._id, state, property)
    }
    
    return(
        <Wrapper>
            <Row>
                <Column>
                    <FormTitle help={loadInterfaceInfo}>Carregar Interface</FormTitle>
                    <LoadPatterns
                        onChange={(pattern) => {
                            onLoadPattern(data._id, pattern)
                            setTempData(pattern)
                        }}
                    />
                    <Spacer top={1.8}/>
                    <FormTitle help={screenBgInfo}>Fundo de Tela</FormTitle>
                    <BackgroundTypeSelector
                        selected={tempData.bgType}
                        onSelect={(value) => {
                            setTempData(prev => ({...prev, bgType: value}))
                            setValue(data._id, value, 'bgType')
                        }}
                    />
                    <Spacer top={1.8}/>
                    <Section hide={tempData.bgType !== 'image'}>
                        <LabelLoad
                            id={'bg-image-'+data._id}
                            label={'Imagem'}
                            state
                            onLoad={(e) => handleBgUpload(e)}
                        />
                    </Section>
                    <Section hide={tempData.bgType !== 'gradient'}>
                        <LabelPickColor 
                            id={'gradient-color1-'+data._id} 
                            text={'Primeira Cor'} 
                            value={tempData.gradientColors[0]} 
                            input={(value) => handleTempGradientColors(0, value)}
                            onBlur={(value) => handleGradientColors(0, value)}
                        />
                        <LabelPickColor 
                            id={'gradient-color2-'+data._id} 
                            text={'Segunda Cor'} 
                            value={tempData.gradientColors[1]} 
                            input={(value) => handleTempGradientColors(1, value)}
                            onBlur={(value) => handleGradientColors(1, value)}
                        />
                        <LabelNumber 
                            id={'gradient-angle-'+data._id} 
                            text={'Ângulo do Gradiente'} 
                            min={0} 
                            max={360} 
                            step={10} 
                            value={tempData.gradientAngle} 
                            input={(value) => setTempData(prev => ({...prev, gradientAngle: value}))}
                            onBlur={(value) => setValue(data._id, value, 'gradientAngle')}
                        />
                    </Section>
                    <Section hide={tempData.bgType !== 'color'}>
                        <LabelPickColor 
                            id={'bg-color-'+data._id} 
                            text={'Cor do Fundo'} 
                            value={tempData.bgColor} 
                            input={(value) => setTempData(prev => ({...prev, bgColor: value}))}
                            onBlur={(value) => setValue(data._id, value, 'bgColor')}
                        />
                    </Section>
                    <Spacer top={1.8}/>
                    <FormTitle help={contentInfo}>Conteúdo</FormTitle>
                    <ToggleLabel 
                        id={'show-top-text-'+data._id} 
                        text={'Texto Acima'} 
                        bold={true} 
                        state={tempData.showTopText} 
                        onToggle={(state) => handleToggleChange(state, 'showTopText')}
                    />
                    <Section hide={!tempData.showTopText}>
                        <LabelInput 
                            id={'top-text'+data._id} 
                            text={'Texto'} 
                            state={tempData.showTopText} 
                            value={tempData.topText} 
                            input={(value) => setTempData(prev => ({...prev, topText: value}))}
                            onBlur={(value) => setValue(data._id, value, 'topText')}
                        />
                        <LabelPickColor 
                            id={'top-text-color-'+data._id} 
                            text={'Cor do Texto'} 
                            value={tempData.topTextColor} 
                            input={(value) => setTempData(prev => ({...prev, topTextColor: value}))}
                            onBlur={(value) => setValue(data._id, value, 'topTextColor')}
                        />
                        <LabelNumber 
                            id={'top-text-size-'+data._id} 
                            text={'Tamanho da Fonte'} 
                            min={1} 
                            max={15} 
                            value={tempData.topTextSize} 
                            input={(value) => setTempData(prev => ({...prev, topTextSize: value}))}
                            onBlur={(value) => setValue(data._id, value, 'topTextSize')}
                        />
                        <LabelNumber 
                            id={'top-text-weight-'+data._id} 
                            text={'Espessura da Fonte'} 
                            min={1} 
                            max={9} 
                            value={tempData.topTextWeight} 
                            input={(value) => setTempData(prev => ({...prev, topTextWeight: value}))}
                            onBlur={(value) => setValue(data._id, value, 'topTextWeight')}
                        />             
                    </Section>
                    <Spacer top={1.8}/>
                    <ToggleLabel 
                        id={'show-bottom-text-'+data._id} 
                        text={'Texto Abaixo'} 
                        bold={true} 
                        state={tempData.showBottomText} 
                        onToggle={(state) => handleToggleChange(state, 'showBottomText')}
                    />
                    <Section hide={!tempData.showBottomText}>
                        <LabelInput 
                            id={'bottom-text'+data._id} 
                            text={'Texto'} 
                            state={tempData.showBottomText} 
                            value={tempData.bottomText} 
                            input={(value) => setTempData(prev => ({...prev, bottomText: value}))}
                            onBlur={(value) => setValue(data._id, value, 'bottomText')}
                        />
                        <LabelPickColor 
                            id={'bottom-text-color-'+data._id} 
                            text={'Cor do Texto'} 
                            value={tempData.bottomTextColor} 
                            input={(value) => setTempData(prev => ({...prev, bottomTextColor: value}))}
                            onBlur={(value) => setValue(data._id, value, 'bottomTextColor')}
                        />
                        <LabelNumber 
                            id={'bottom-text-size-'+data._id} 
                            text={'Tamanho da Fonte'} 
                            min={1} 
                            max={15} 
                            value={tempData.bottomTextSize} 
                            input={(value) => setTempData(prev => ({...prev, bottomTextSize: value}))}
                            onBlur={(value) => setValue(data._id, value, 'bottomTextSize')}
                        />
                        <LabelNumber 
                            id={'bottom-text-weight-'+data._id} 
                            text={'Espessura da Fonte'} 
                            min={1} 
                            max={9} 
                            value={tempData.bottomTextWeight} 
                            input={(value) => setTempData(prev => ({...prev, bottomTextWeight: value}))}
                            onBlur={(value) => setValue(data._id, value, 'bottomTextWeight')}
                        />
                    </Section>
                    <Spacer top={1.8}/>
                </Column>
                <VerticalLine/>
                <Column>
                    <FormTitle help={buttonsInfo}>Botões</FormTitle>
                    <LabelNumber 
                        id={'width-'+data._id} 
                        text={'Largura'} 
                        min={40} 
                        max={80} 
                        value={tempData.width} 
                        input={(value) => setTempData(prev => ({...prev, width: value}))}
                        onBlur={(value) => setValue(data._id, value, 'width')}
                    />
                    <LabelNumber 
                        id={'height-'+data._id} 
                        text={'Altura'} 
                        min={5} 
                        max={10} 
                        value={tempData.height} 
                        input={(value) => setTempData(prev => ({...prev, height: value}))}
                        onBlur={(value) => setValue(data._id, value, 'height')}
                    />
                    <LabelNumber 
                        id={'stroke-radius-'+data._id} 
                        text={'Raio da Borda'} 
                        min={0} 
                        max={50} 
                        value={tempData.radius} 
                        input={(value) => setTempData(prev => ({...prev, radius: value}))}
                        onBlur={(value) => setValue(data._id, value, 'radius')}
                    />
                    <Spacer top={1.8}/>
                    <ToggleLabel 
                        id={'stroke-'+data._id} 
                        text={'Contorno'} 
                        bold={true} 
                        state={tempData.stroke} 
                        onToggle={(state) => handleToggleChange(state, 'stroke')}
                    />
                    <Section hide={!tempData.stroke}>
                        <LabelPickColor 
                            id={'stroke-color-'+data._id} 
                            text={'Cor do Contorno'} 
                            value={tempData.strokeColor} 
                            input={(value) => setTempData(prev => ({...prev, strokeColor: value}))}
                            onBlur={(value) => setValue(data._id, value, 'strokeColor')}
                        />
                        <LabelNumber 
                            id={'stroke-width-'+data._id} 
                            text={'Espessura do Contorno'} 
                            min={1} 
                            max={5} 
                            value={tempData.strokeWidth} 
                            input={(value) => setTempData(prev => ({...prev, strokeWidth: value}))}
                            onBlur={(value) => setValue(data._id, value, 'strokeWidth')}
                        />
                    </Section>
                    <Spacer top={1.8}/>
                    <ToggleLabel
                        id={'bg-'+data._id}
                        text={'Fundo'}
                        bold={true}
                        state={tempData.bg}
                        onToggle={(state) => handleToggleChange(state, 'bg')}
                    />
                    <Section hide={!tempData.bg}>
                        <LabelPickColor 
                            id={'button-color-'+data._id} 
                            text={'Cor do Fundo'} 
                            value={tempData.buttonColor} 
                            input={(value) => setTempData(prev => ({...prev, buttonColor: value}))}
                            onBlur={(value) => setValue(data._id, value, 'buttonColor')}
                        />
                        <LabelNumber 
                            id={'bg-opacity-'+data._id} 
                            text={'Opacidade'} 
                            min={1} 
                            max={10} 
                            value={tempData.buttonOpacity} 
                            input={(value) => setTempData(prev => ({...prev, buttonOpacity: value}))}
                            onBlur={(value) => setValue(data._id, value, 'buttonOpacity')}
                        />
                    </Section>
                    <Spacer top={1.8}/>
                    <FormTitle>Texto dos Botões</FormTitle>
                    <LabelPickColor 
                        id={'text-color-'+data._id} 
                        text={'Cor do Texto'} 
                        value={tempData.textColor} 
                        input={(value) => setTempData(prev => ({...prev, textColor: value}))}
                        onBlur={(value) => setValue(data._id, value, 'textColor')}
                    />
                    <LabelNumber 
                        id={'text-size-'+data._id} 
                        text={'Tamanho da Fonte'} 
                        min={1} 
                        max={12} 
                        value={tempData.textSize} 
                        input={(value) => setTempData(prev => ({...prev, textSize: value}))}
                        onBlur={(value) => setValue(data._id, value, 'textSize')}
                    />
                    <LabelNumber 
                        id={'text-weight-'+data._id} 
                        text={'Espessura da Fonte'} 
                        min={1} 
                        max={9} 
                        value={tempData.textWeight} 
                        input={(value) => setTempData(prev => ({...prev, textWeight: value}))}
                        onBlur={(value) => setValue(data._id, value, 'textWeight')}
                    />
                </Column>
                <VerticalLine/>
                <Column>
                    <Row align={'center'} justify={'center'}>
                        <Icon size={14}>
                            <BsEye/>
                        </Icon>
                        <Title>Preview</Title>
                    </Row>
                    <Spacer top={1}/>
                    <InterfacePreview config={tempData}/>
                    <Spacer top={1}/>
                    <Border padding={15} border={'#CFD8DC'}>
                        <Text size={11} weight={600} decoration={'underline'}>                         
                            Observação:
                        </Text>
                        <Spacer top={0.5}/>
                        <Text size={11} wrap={'wrap'}>
                            O texto exibido nos botões são apenas exemplos ilustrativos. No equipamento real irão aparecer os nomes de cada opção de senha configurados no sistema.
                        </Text>
                    </Border>
                </Column>
            </Row>
        </Wrapper>
    )
}

export default InterfaceBox