import PanelPreview1 from "./PanelPreview1"
import PanelPreview2 from "./PanelPreview2"

export default function PanelPreview({config}){

    return(
        <>
            {
                config.show_marquee ?
                <PanelPreview1 config={config}/>
                :
                <PanelPreview2 config={config}/>
            }
        </>
    )
}