import api from './api'

const headers = () => {
    return {
        headers: {
            authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('token'))
        }
    }
}

export const create = async (route, data) => {
    return await api.post(route, data, headers())
}

export const getAll = async (route) => {
    return await api.get(route, headers())
}

export const getByID = async (route, id) => {
    return await api.get(`${route}/${id}`, headers())
}

export const update = async (route, id, data) => {
    return await api.put(`${route}/${id}`, data, headers())
}

export const destroy = async (route, id) => {
    return await api.delete(`${route}/${id}`, headers())
}