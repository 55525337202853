import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page, VertLine, Row } from "../components/layout/Layout"
import PanelBox from "../components/resources/devices/displayBox/index"
import AddButton from "../components/buttons/AddButton"
import { useState, useRef, useEffect, useContext } from "react"
import { toast } from 'react-toastify';
import InfoTooltip from "../components/others/InfoTooltip"
import { newPanel, duplicatePanel } from "../resources/newPanel"
import { panelsHeaderInfo } from "../content/tooltips/headerTooltipsInfo"
import { update, create, destroy } from '../API/queries' 
import PulseLoader from "react-spinners/PulseLoader"
import { DataContext } from "../contexts/DataProvider" 
import ShrinkBox from "../components/shrinkBox"
import AddrStamp from "../components/others/AddrStamp"
import DeviceStatus from "../components/others/DeviceStatus"
import { validateNewName } from "../methods/validateNewName"
import HeaderFixedMenu from "../components/headerFixedMenu"
import ShortIdDisplay from "../components/others/ShortIdDisplay"
import { PlaceContext } from "../contexts/PlaceContext"


export default function Panels(){

    const page = useRef(null)

    const { placeId } = useContext(PlaceContext)
    const { dataLoaded, panels, setPanels, places, services } = useContext(DataContext)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(dataLoaded) setLoading(false)
    },[dataLoaded])

    const scrollBottom = () => {
        
        const el = page.current 
        
        el.scrollTo({
            top: 99999999,
            behavior: 'smooth',
        }) 
    }

    function addNewPanel(){

        toast.promise(
            create('panel', newPanel(panels, placeId, services)).then((res) => {
                setPanels(panels => [...panels, res.data])  
                setTimeout(() => {scrollBottom()}, 100) 
            }),
            {
                pending: 'Criando Painel...',
                success: 'Novo Painel criado! Personalize suas configurações',
                error: 'Erro: Não foi possível criar um novo Painel'
            }, {containerId: 'alert'}
        )
    }
    
    const handleChange = (id, value, property) => {

        console.log(id, value, property)

        const temp = [...panels]
        let index = temp.findIndex(temp => temp._id === id)

        update('panel', id, {[property]: value}).then((res) => {

            if(res.status === 200){
                setPanels( prev => {
                    prev[index] = {...prev[index], [property]: value}
                    return [...prev]
                })
            }
            else toast.error(res, {containerId: 'alert'})
        }).catch((err) => {
            toast.error(err, {containerId: 'alert'})
        })
    }
    
    const handleDuplicate = (id) => {

        const temp = [...panels]
        const _panel = temp.find(temp => temp._id === id)

        toast.promise(
            create('panel', duplicatePanel(panels, _panel)).then((res) => {
                setPanels(panels => [...panels, res.data])  
                setTimeout(() => {scrollBottom()}, 100) 
            }),
            {
                pending: 'Duplicando Painel...',
                success: 'Novo Painel criado! Personalize suas configurações',
                error: 'Erro: Não foi possível criar um novo Painel'
            }, {containerId: 'alert'}
        )
    }

    const deletePanel = (id) => {

        const temp = [...panels]
        let index = temp.findIndex(temp => temp._id === id)

        if(index >= 0 && temp.length>1){
            
            toast.promise(
                destroy('panel', id).then((res) => {
                    temp.splice(index, 1)
                    setPanels(temp)
                }),
                {
                    pending: 'Excluindo Painel...',
                    success: 'Painel excluído com sucesso!',
                    error: 'Erro: Não foi possível excluir o Painel'
                }, {containerId: 'alert'}
            )
        }
        else toast.error('É necessário manter pelo menos um Painel', {containerId: 'alert'})
    }

    const handleChangeName = (id, newName) => {
        if(!validateNewName(panels, newName)) return toast.error('Já existe um Painel com este nome', {containerId: 'alert'})

        handleChange(id, newName, 'name')
    }

    return(
        <>
            <Header>
                <HeaderLeft>
                    <AddButton text={'Novo-Painel'} icon={''} onClick={addNewPanel}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={panelsHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
            {
                loading ? 
                <Page center>
                    <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                </Page>
                :
                <Page ref={page}>
                    {
                        panels.filter(el => el.placeId === placeId).map((e,i) => {
                            return <ShrinkBox
                                key={i}
                                id={e._id}
                                name={e.name}
                                onChangeName={(id, name) => handleChangeName(id, name)}
                                onDuplicate={(id) => handleDuplicate(id)}
                                onDelete={(id) => deletePanel(id)}
                                headerContent={
                                    <Row height={'100%'} align={'center'}>
                                        <VertLine/>
                                        <AddrStamp before={places.find(el => el._id === e.placeId).name} after={services.filter(el => e.services.includes(el._id)).map(el => el.name)}/>
                                        <VertLine/>
                                        <DeviceStatus status={e.status} count={e.connectedDevices}/>
                                        <VertLine/>
                                        <ShortIdDisplay shortId={e.shortId}/>
                                    </Row>
                                }
                                >
                            <PanelBox data={e} setValue={(id, value, property) => handleChange(id, value, property)}/>
                        </ShrinkBox>
                        })
                    }
                </Page>
            }
        </>
    )
}