import { Icon, Row, TextLink } from "../../../../layout/Layout";
import { FaPlay, FaPause } from "react-icons/fa";
import { brief_calls, full_calls } from "../../../../../content/voices";
import { alerts } from "../../../../../content/alerts";
import { useState } from "react";

export default function PlayAlert({data}){

    const [playing, setPlaying] = useState(false)

    const simulateAlert = () => {

        if(data?.voice_enable && !data?.alert_enable) return playVoice()
        playAlertAndVoice()
    }

    const playAlertAndVoice = () => {

        const index = alerts.findIndex(alerts => alerts.name === data.alert)
        
        if(index !== -1) {   
            const alert = alerts[index].audio

            const onPlay = () => setPlaying(true);
            const onEnded = () => { 
                playVoice(false);
                alert.removeEventListener('play', onPlay);
                alert.removeEventListener('ended', onEnded);
            };

            alert.addEventListener('play', onPlay);
            alert.addEventListener('ended', onEnded);
            
            alert.play();
        }
    }

    const playVoice = () => {

        const voices = data?.voice_type === 'full_call' ? full_calls : data?.voice_type === 'brief_call' && brief_calls

        const index = voices.findIndex(voice => voice.name === data.voice_id);

        if (index !== -1) {
            const audio = voices[index].audio;

            const onPlay = () => setPlaying(true);
            const onEnded = () => { 
                setPlaying(false);
                audio.removeEventListener('play', onPlay);
                audio.removeEventListener('ended', onEnded);
            };

            audio.addEventListener('play', onPlay);
            audio.addEventListener('ended', onEnded);
            
            audio.play();
        }
    }


    return (
        <Row align={'center'} marginTop={12}>
            <Icon pointer color="#1565C0" onClick={() => simulateAlert()}>
                {
                    playing ?
                    <FaPause/>
                    :
                    <FaPlay/>
                }
            </Icon>
            <TextLink onClick={() => simulateAlert()}>
                Ouvir o alerta sonoro
            </TextLink>
        </Row>
    )
}