import { Text, Column, Icon, VertLine, Row } from '../../../layout/Layout'
import UserInfo from './../UserInfo'
import GuicheStats from './../GuicheStats'
import StatusStampMin from '../../../others/StatusStampMin'
import PwTypesDisplay from './../PwTypesDisplay'
import TimeFormat from '../../../../services/TimeFormat'
import { useEffect, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { AiOutlineDisconnect } from 'react-icons/ai'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import ConfirmationTooltip from '../../../others/ConfirmationTooltip'
import { Card, Desk, User, Info} from './styles'
import ServicesDisplay from './ServicesDisplay'
import { useContext } from 'react'
import { DataContext } from '../../../../contexts/DataProvider'

export default function DeskCard({data, calls, setValue, onDelete, onDisconnect}){

    const { services, pwTypes } = useContext(DataContext) 

    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [disconnectConfirm, setDisconnectConfirm] = useState(false)

    const [stats, setStats] = useState({
        avarageTime: null,
        avarageGrowth: null,
        done: null,
        lastHour: null,
        lastHourGrowth: null, 
        lastPw: null
    })

    const updateStats = (property, value) => {
        setStats( prev => {
            prev[property] = value
            return prev
        })
    }

    function sortByTime(a,b) {
        if(a.alerted_at > b.alerted_at) return 1
        else return -1
    }

    const getLastPw = () => {
        const temp = calls.sort(sortByTime).pop()
        updateStats('lastPw', temp.password)
    }

    const getAverageTime = () => {
        let times = []
        
        const temp = calls.sort(sortByTime).forEach((el) => {
            times.push(el.finished_at - el.attended_at)
        })

        let timesBefore = times.slice(0, -1)
        
        const average = times.reduce((a, b) => a + b, 0) / times.length;
        const averageBefore = timesBefore.reduce((a, b) => a + b, 0) / timesBefore.length;

        updateStats('avarageTime', average/1000)
        updateStats('avarageGrowth', average < averageBefore)
    }

    const getLastHour = () => {
        const now = Date.now()
        const lastHour = calls.filter(call => call.finished_at > (now - 3600000))
        const hourBefore = calls.filter(call => call.finished_at < (now - 3600000) && call.finished_at > (now - 7200000))
        updateStats('lastHour', lastHour.length)
        updateStats('lastHourGrowth', lastHour.length > hourBefore.length)
    }

    useEffect(() => { 
        if(calls.length > 0){
            updateStats('done', calls.length)
            getAverageTime()
            getLastHour()
            getLastPw()
        }
    },[calls])

    const getPwTypesOptions = () => pwTypes.map(el => el.name)

    const getServicesOptions = () => services.filter(el => el.placeId === data.placeId).map(el => el.name)

    useEffect(() => {
        checkPwTypes()
    },[])

    const checkPwTypes = () => {
        const available = getPwTypesOptions()
        const selected = [...data.pw_types]
        let changed = 0

        selected.forEach((type, index) => {
            if(!available.includes(type)) {
                selected.splice(index, 1) 
                changed = 1
            }
        })

        if(changed) setValue(data._id, selected, 'pw_types')

    }

    return(
        <Card>
            <Desk>
                <Row height={'25%'} align={'center'}>
                    <Text size={14}>{data.name}</Text>
                    <VertLine space={1}/>
                    <Tooltip arrow={true} delay={200} theme={'light'} html={'Status do Guichê'}>
                        <StatusStampMin stat={data.status}/>
                    </Tooltip>
                    <VertLine space={1}/>
                    {
                        data.status === 'inactive' ?
                        <Tooltip theme="light" trigger="click" open={deleteConfirm} interactive={true} onRequestClose={() => setDeleteConfirm(false)} html={(<ConfirmationTooltip text={'Deseja Excluir?'} onYes={() => {onDelete(data._id); setDeleteConfirm(false)}} onNo={() => setDeleteConfirm(false)}/>)}>
                            <Tippy content='Excluir Guichê'>
                                <Icon pointer size={16} color={'#888'} hoverColor={'#F34236'} onClick={() => setDeleteConfirm(true)}>
                                    <FiTrash2/>
                                </Icon>
                            </Tippy>
                        </Tooltip>
                        :
                        data.status === 'active' &&
                        <Tooltip theme="light" trigger="click" open={disconnectConfirm} interactive={true} onRequestClose={() => setDisconnectConfirm(false)} html={(<ConfirmationTooltip text={'Desconectar guichê?'} onYes={() => {onDisconnect(data._id); setDisconnectConfirm(false)}} onNo={() => setDisconnectConfirm(false)}/>)}>
                            <Tippy content='Desconectar Guichê'>
                                <Icon pointer size={18} color={'#888'} hoverColor={'#F34236'} onClick={() => setDisconnectConfirm(true)}>
                                    <AiOutlineDisconnect/>
                                </Icon>
                            </Tippy>
                        </Tooltip>
                    }
                </Row>
                <ServicesDisplay id={data._id} options={getServicesOptions()} selected={data.services} onSelect={(types) => setValue(data._id, types, 'services')}/>
                <PwTypesDisplay id={data._id} options={getPwTypesOptions()} selected={data.pw_types} onSelect={(types) => setValue(data._id, types, 'pw_types')}/>
            </Desk>
            <User>
                {
                    data.status !== 'inactive' &&
                    <Tooltip arrow={true} delay={200} theme={'light'} html={'Usuário Logado'}>
                        <UserInfo user={data.logged_user}/>
                    </Tooltip>
                }
            </User>
            <Info>
                {
                    calls.length > 0 ?
                    <>
                        <GuicheStats title={'Tempo Médio'} number={TimeFormat(stats.avarageTime && stats.avarageTime)} growth={stats.avarageGrowth && stats.avarageGrowth}/>
                        <GuicheStats title={'Atendimentos'} number={stats.done && stats.done}/>
                        <GuicheStats title={'Última Hora'} number={stats.lastHour && stats.lastHour} growth={stats.lastHourGrowth && stats.lastHourGrowth}/>
                        <GuicheStats title={'Ultima Senha'} number={stats.lastPw && stats.lastPw}/>
                    </> :
                    <Column align={'center'} justify={'center'}>
                        <Text color={'#78909C'} size={16} weight={300}>Sem Dados</Text>
                    </Column>
                }
            </Info>
        </Card>
    )
}