import { useEffect, useState } from "react"
import styled from "styled-components"
import ToggleSwitch from "./ToggleSwitch"

const Toggle = styled.span`
    display: flex;
    align-items: center;
    height: 35px;

    h1{
        font-size: 12px;
        font-weight: 400;
    }

    input{
        border: 1px solid #37474F;
        border-radius: 5px;
        height: 25px;
        width: 50%;
        margin-left: auto;
        padding-left: 3px;
        outline: none;
        color: #37474F; 

        &:focus{
            border: 1px solid #00BFA5;
        }
    }
`

function ToggleInput({id, text, input, value, state, onToggle, max, onBlur}){
    
    return(
        <Toggle>
            <ToggleSwitch id={id} isToggled={state} setIsToggled={(state) => onToggle(state)}/>
            <h1>{text}</h1>
            <input type="text" 
                disabled={!state} 
                defaultValue = {value}
                maxLength={max}
                onChange={(e) => {input(e.target.value)}}
                onBlur={(e) => onBlur(e.target.value)}
            />
        </Toggle>
    )
}

export default ToggleInput