import styled from "styled-components";
import { Column, Row, Spacer, Text, Title } from "../../../layout/Layout";

const Thumb = styled.video`
    height: 12rem;
`

export default function VideoPreview({url}){
    
    return(
        <Column align={'center'}>
            <Title>Vídeo que será exibido</Title>
            <Spacer top={1.5}/>
            {
                !url ?
                <Text>Nenhum Vídeo Carregado</Text>
                :
                <Thumb src={`${url}#t=5`}/>
            }
        </Column>
    )
}