import styled from "styled-components"
import ToggleSwitch from "./ToggleSwitch"
import HelpIcon from '../others/HelpIcon'

const Toggle = styled.span`
    display: flex;
    align-items: center;
    height: 35px;

    label{
        font-size: 12px;
        font-weight: ${props => props.bold ? '600':'400'};
    }

    input{
        border: 1px solid #37474F;
        border-radius: 5px;
        height: 25px;
        width: 50%;
        margin-left: auto;
        padding-left: 3px;
        outline: none;
        color: #37474F; 

        &:focus{
            border: 1px solid #00BFA5;
        }
    }
`

function ToggleLabel({id, text, bold, state, help, onToggle}){
    
    return(
        <Toggle bold={bold}>
            <ToggleSwitch id={id} isToggled={state} setIsToggled={(state) => onToggle(state)}/>
            <label htmlFor={id}>{text}</label>
            {
                help && <HelpIcon content={help}/>
            }
        </Toggle>
    )
}

export default ToggleLabel