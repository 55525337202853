import { useState } from 'react'
import { Box, Column, Row, Text, Spacer, Icon } from '../../../layout/Layout'
import { priorRulesInfo } from '../../../../content/tooltips/passwordsTooltips'
import PriorRulesTable from './PriorRulesTable'
import NewRuleBox from './NewRuleBox'
import { toast } from 'react-toastify'
import { create, destroy } from '../../../../API/queries'
import { Button } from '../../../buttons/Button'
import { FiArrowLeft } from 'react-icons/fi'
import { useContext } from 'react'
import { DataContext } from '../../../../contexts/DataProvider'
import { validateNewName } from '../../../../methods/validateNewName'
import FormTitle from '../../../form/FormTitle'

function PriorRulesBox({rules, pwTypes}){

    const { setRules } = useContext(DataContext)

    const [selectedRule, setSelectedRule] = useState('')
    const [selectedName, setSelectedName] = useState('')
    const [selectedValue, setSelectedValue] = useState(0)

    const [added, setAdded] = useState(false)

    const handleChange = (value, property) => {
        setAdded(false)
        
        switch (property) {
            case 'rule':
                setSelectedRule(value)
            break;

            case 'name':
                setSelectedName(value)
            break;

            case 'value':
                setSelectedValue(value)
            break;
        
            default: break;
        }
    }

    const addRule = () => {

        if(rules.length === 5) return toast.error('Máximo permitido de 5 regras', {containerId: 'alert'})
        if(!selectedName || !selectedRule) return toast.error('Preencha as informações da regra corretamente', {containerId: 'alert'})
        if(!validateNewName(rules, selectedName)) return toast.error(`Já existe uma regra chamada ${selectedName}. Escolha outro nome`, {containerId: 'alert'})

        toast.promise(
            create('rules', {
                name: selectedName,
                rule: selectedRule,
                value: selectedValue
            }).then(res => {
                setRules(prev => [...prev, res.data])
            }),
            {
                pending: 'Criando Regra...',
                success: 'Regra criada com sucesso!',
                error: 'Erro: Não foi possível criar a Regra'
            }, {containerId: 'alert'}
        )

        setSelectedName('')
        setSelectedRule('')
        setSelectedValue(0)

        setAdded(true)
    }

    const checkPwTypes = (id) => {
        return pwTypes.every(type => type.prior_rule._id !== id)
    }

    const deleteRule = (id) => {

        if(!checkPwTypes(id)) return toast.error('Não é possível excluir essa regra pois já existe um Tipo de Senha cadastrado com ela', {containerId: 'alert'})

        const temp = [...rules]
        let index = temp.findIndex(temp => temp._id === id)
        
        if(index >= 0 && temp.length>1){
            
            toast.promise(
                destroy('rules', id).then((res) => {
                    temp.splice(index, 1)
                    setRules(temp)
                }),
                {
                    pending: 'Excluindo Regra...',
                    success: 'Regra excluída com sucesso!',
                    error: 'Erro: Não foi possível excluir a Regra'
                }, {containerId: 'alert'}
            )
        }
        else toast.error('É necessário manter pelo menos uma Regra', {containerId: 'alert'})
    }
    
    return(
        <Box>
            <Column>
                <FormTitle help={priorRulesInfo}>Regras de Atendimento Prioritário</FormTitle>
                <Spacer top={2}/>
                <Row>
                    <Column width={50}>
                        <PriorRulesTable rules={rules} onDelete={(id) => deleteRule(id)}/>
                    </Column>
                    <Column width={15} align={'center'} justify={'center'}>
                        <Button width={110} height={30} radius={50} onClick={() => addRule()}>
                            <Icon size={20}>
                                <FiArrowLeft/>
                            </Icon>
                            Adicionar
                        </Button>
                    </Column>
                    <Column width={35}>
                        <NewRuleBox added={added} selectedRule={selectedRule} setValue={(value, property) => handleChange(value, property)}/>
                    </Column>
                </Row>
            </Column>
        </Box>
    )
}

export default PriorRulesBox