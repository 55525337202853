import styled from 'styled-components'
import { HiCheck } from 'react-icons/hi'

const But = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 2rem;
    border: 1px solid #00C853;
    border-radius: 1rem;
    margin: 0 1rem;
    cursor: ${props => props.status && 'pointer'};

    &:hover{
        background-color: #00C853;

        svg{
            color: white;
        }
    }

    svg{
        font-size: 1.5rem;
        color: #00C853;
    }
`

function Yes({status, onClick}){
    
    return(
        <But status={status} onClick={onClick}>
            <HiCheck/>
        </But>
    )
}

export default Yes