import styled from "styled-components"
import { Column, Row, Text, Spacer } from '../../../layout/Layout'
import PriorRulesTableRow from "./PriorRulesTableRow"

const Table = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #90A4AE;
    border-radius: 10px;
    height: 100%;
`

function PriorRulesTable({rulesDefault, rules, onDelete}){
    return(
        <Table>
            <Row left={15} bottom={5} borderBtmStyle={'solid'} borderBtmWidth={1} borderBtmColor={'#90A4AE'} align={'flex-end'} height={40}>
                <Column width={25}>
                    <Text weight={600}>Nome</Text>
                </Column>
                <Column width={40}>
                    <Text weight={600}>Regra</Text>
                </Column>
                <Column width={25}>
                    <Text weight={600}>Valor</Text>
                </Column>
                <Column width={10}>
                    <Text weight={600}>Excluir</Text>
                </Column>
            </Row>
            {
                rules.map((e, i) => {
                    return <PriorRulesTableRow rulesDefault={rulesDefault} key={i} rule={e} onDelete={onDelete}/>
                })
            }
        </Table>
    )
}

export default PriorRulesTable