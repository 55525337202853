import { Accordion, AccordionDetails, AccordionSummary, Divider, Box, Typography, IconButton, Tooltip, Snackbar, Alert } from "@mui/material";
import { Text } from "../../../components/layout/Layout";
import RatingStars from "../../../components/others/RatingStars";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import AttendantChartModal from "./AttendantChartModal";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useAttendanceReportData } from "../AttendantPerformanceProvider";

export default function AttendantReportBox({ data }) {

    const { startDate, endDate } = useAttendanceReportData()

    const [showEvolutionChartModal, setShowEvolutionChartModal] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleOpenChartModal = (e) => {
        e.stopPropagation();

        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffInDays = (end - start) / (1000 * 60 * 60 * 24);

        if (diffInDays < 5) {
            setSnackbarOpen(true);
            return;
        }

        setShowEvolutionChartModal(true);
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    }

    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Text size={14}>{data?.name}</Text>
                            <RatingStars ratings={data?.rating} />
                            <Tooltip title={<Typography fontSize="1rem">Ver Evolução</Typography>}>
                                <IconButton
                                    color="primary"
                                    onClick={handleOpenChartModal}
                                >
                                    <QueryStatsIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, marginRight: 2 }}>
                            <Box textAlign="right">
                                <Typography variant="caption">Atendimentos</Typography>
                                <Typography variant="h6">{data?.totalCalls}</Typography>
                            </Box>
                            <Box textAlign="right">
                                <Typography variant="caption">Tempo Médio</Typography>
                                <Typography variant="h6">{data?.avgCallDurationFormatted}</Typography>
                            </Box>
                            <Box textAlign="right">
                                <Typography variant="caption">Eficiência</Typography>
                                <Typography variant="h6">{data?.workEfficiencyFormatted}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    <Box>
                        <Typography variant="h6">Detalhes por Serviços</Typography>
                        {
                            Object.keys(data.services).map(service => (
                                <>
                                    <Box key={service} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
                                        <Typography variant="subtitle1">{service}</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, marginRight: 2 }}>
                                            <Box textAlign="right">
                                                <Typography variant="caption">Atendimentos</Typography>
                                                <Typography variant="h6">{data.services[service].count}</Typography>
                                            </Box>
                                            <Box textAlign="right">
                                                <Typography variant="caption">Percentual</Typography>
                                                <Typography variant="h6">{data.services[service].percentage.toFixed(1)}%</Typography>
                                            </Box>
                                            <Box textAlign="right">
                                                <Typography variant="caption">Tempo Médio</Typography>
                                                <Typography variant="h6">{data.services[service].avgDurationFormatted}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ marginY: 2 }} />
                                </>
                            ))
                        }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
                        <Typography variant="h6">Tempos Detalhados</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, marginRight: 2 }}>
                            <Box textAlign="right">
                                <Typography variant="caption">Tempo Total em Atendimento</Typography>
                                <Typography variant="h6">{data?.totalAttendingTimeFormatted}</Typography>
                            </Box>
                            <Box textAlign="right">
                                <Typography variant="caption">Tempo Total Logado</Typography>
                                <Typography variant="h6">{data?.totalWorkTimeFormatted}</Typography>
                            </Box>
                            <Box textAlign="right">
                                <Typography variant="caption">Tempo Total em Pausa</Typography>
                                <Typography variant="h6">{data?.totalPausedTimeFormatted}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <AttendantChartModal
                open={showEvolutionChartModal}
                onClose={() => setShowEvolutionChartModal(false)}
                attendantId={data._id}
                attendantName={data.name}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    Para ver a evolução de um atendente selecione um período maior que 5 dias
                </Alert>
            </Snackbar>
        </>
    )
}
