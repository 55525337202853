import { createContext, useState, useContext, useEffect } from 'react';
import { create } from "../../API/queries";

const AttendantPerformanceContext = createContext();

export const AttendantPerformanceProvider = ({ children }) => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [reportData, setReportData] = useState(null);
    const [loadingReport, setLoadingReport] = useState(false);

    const [intelligentReport, setIntelligentReport] = useState(null);
    const [loadingIntelligentReport, setLoadingIntelligentReport] = useState(false);

    const getReportData = async () => {
        setLoadingReport(true);

        try {
            const res = await create('attendance-report', { startDate, endDate });
            setReportData(res.data);
            console.log(res.data);
        } catch (error) {
            setReportData([]);
            console.log(error);
        } finally {
            setLoadingReport(false);
        }
    };


    const fetchIntelligentReport = async () => {
        setLoadingIntelligentReport(true);

        try {
            const response = await create('/attendance-ia-analisys', {
                reportData,
                startDate,
                endDate
            });
            setIntelligentReport(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingIntelligentReport(false);
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            getReportData();
            setIntelligentReport(null)
        }
    }, [startDate, endDate]);

    return (
        <AttendantPerformanceContext.Provider value={{ startDate, endDate, loadingReport, reportData, setStartDate, setEndDate, fetchIntelligentReport, intelligentReport, loadingIntelligentReport }}>
            {children}
        </AttendantPerformanceContext.Provider>
    );
};

export const useAttendanceReportData = () => {
    return useContext(AttendantPerformanceContext);
};
