import { VerticalBox, Column, Row, Table } from "../../layout/Layout"
import UsersAtendRow from "./UsersAtendRow"
import { nameInfo, avatarInfo, ratingInfo, sinceInfo, emailInfo, actionsInfo, activeInfo } from '../../../content/tooltips/userAtendTooltips'
import TableTitle from "../../form/TableTitle";

function UsersAtendTable({data, onChange, onDelete}){

    
    return (
        <VerticalBox maxHeight={'calc(100% - 8.5rem)'}>
            <Row bottom={5} borderBtmWidth={1} borderBtmColor={'#37474F'} borderBtmStyle={'solid'} align={'flex-end'}>
                <Column width={50}>
                    <TableTitle help={avatarInfo}>Imagem</TableTitle>
                </Column>
                <Column width={120}>
                    <TableTitle help={nameInfo}>Nome</TableTitle>
                </Column>
                <Column>
                    <TableTitle help={ratingInfo}>Classificação</TableTitle>
                </Column>
                <Column>
                    <TableTitle help={sinceInfo}>Desde de</TableTitle>
                </Column>
                <Column width={120}>
                    <TableTitle help={emailInfo}>E-mail</TableTitle>
                </Column>
                <Column>
                    <TableTitle help={activeInfo}>Permissão</TableTitle>
                </Column>
                <Column>
                    <TableTitle help={actionsInfo}>Ações</TableTitle>
                </Column>
            </Row>
            <Table>
                {
                    data.map((attendant, index) =>
                        {
                            return <UsersAtendRow 
                                key={index}
                                data={attendant}
                                onChange={onChange}
                                onDelete={onDelete}/>
                        }
                    )
                }
            </Table>
        </VerticalBox>
    )
}

export default UsersAtendTable

