import { Text, Column, Icon, Row } from "../../../../layout/Layout";
import { BsCalendar2Plus } from 'react-icons/bs'
import 'react-tippy/dist/tippy.css'
import { Spacer } from "../preview/styles";
import { SelectField, Option } from "../../../../layout/Form";
import { useState } from "react";
import WeekdaySelector from "../../../../others/WeekdaySelector";
import TimeIntervalSelector from "../../../../others/TimeIntervalSelector";
import SeasonSelector from "../../../../others/SeasonSelector";
import { Button } from "../../../../buttons/Button";
import { toast } from "react-toastify";
import HelpIcon from "../../../../others/HelpIcon";
import { newConfigInfo } from '../../../../../content/tooltips/displayTooltips'

export default function NewConfigRowSchedule({onCreate, onCancel}){

    const [selectedType, setSelectedType] = useState(null)

    const [selectedWeekdays, setSelectedWeekdays] = useState([])
    const [selectedTimeStart, setSelectedTypTimeStart] = useState(null)
    const [selectedTimeEnd, setSelectedTimeEnd] = useState(null)
    const [selectedSeasonStart, setSelectedSeasonStart] = useState(null)
    const [selectedSeasonEnd, setSelectedSeasonEnd] = useState(null)

    const typesOpts = ['por período', 'por horário', 'por dia da semana']
    const typesValues = ['season', 'time', 'weekday']

    const newSchedule = () => {
        return{
            type: selectedType,
            season: {
                start: selectedType === 'season' ? selectedSeasonStart : null, 
                end: selectedType === 'season' ? selectedSeasonEnd : null, 
            },
            weekday: selectedType === 'weekday' ? selectedWeekdays : null,
            time: {
                start: selectedType === 'time' ? selectedTimeStart : null, 
                end: selectedType === 'time' ? selectedTimeEnd : null, 
            }
        }
    }

    const validateSeasonInterval = () => {
        const startYear = parseInt(selectedSeasonStart.slice(0,4))
        const endYear = parseInt(selectedSeasonEnd.slice(0,4))
        const startMonth = parseInt(selectedSeasonStart.slice(5,7))
        const endMonth = parseInt(selectedSeasonEnd.slice(5,7))
        const startDay = parseInt(selectedSeasonStart.slice(8))
        const endDay = parseInt(selectedSeasonEnd.slice(8))

        if(startYear > endYear) return 0
        if(startMonth > endMonth) return 0
        if(startDay >= endDay) return 0
        return 1
    }

    const validateTimeInterval = () => {
        const startHour = parseInt(selectedTimeStart.slice(0,2))
        const endHour = parseInt(selectedTimeEnd.slice(0,2))
        const startMinutes = parseInt(selectedTimeStart.slice(3,5))
        const endMinutes = parseInt(selectedTimeEnd.slice(3,5))

        if(startHour > endHour) return 0
        if(startHour === endHour && startMinutes > endMinutes) return 0
        
        return 1
    }

    const checkData = () => {
        if(selectedType === null){
            toast.error(`Selecione um tipo de agendamento`, {containerId: 'alert'})
            return 0
        }

        if(selectedType === 'season' && (selectedSeasonStart === null || selectedSeasonEnd === null)){
            console.log('season')
            toast.error('Preencha corretamente as datas de início e fim do agendamento', {containerId: 'alert'})
            return 0
        } 

        if(selectedType === 'season' && !validateSeasonInterval()){
            toast.error('A data de início não pode ser maior ou igual à data de fim', {containerId: 'alert'})
            return 0
        } 

        if(selectedType === 'time' && (selectedTimeStart === null || selectedTimeEnd === null)){
            toast.error('Preencha corretamente o horário de início e fim do agendamento', {containerId: 'alert'})
            return 0
        } 

        if(selectedType === 'time' && !validateTimeInterval()){
            toast.error('A hora de início não pode ser maior ou igual à hora de fim', {containerId: 'alert'})
            return 0
        } 

        if(selectedType === 'weekday' && selectedWeekdays.length === 0){
            toast.error('Selecione pelo menos um dia da semana', {containerId: 'alert'})
            return 0
        } 

        return 1
    }

    const handleCreate = () => {
        if(!checkData()) return
        onCreate(newSchedule())
    }

    return(
        <Row borderBtmStyle={'solid'} borderBtmWidth={1} borderBtmColor={'#CFD8DC'} bottom={10}>
            <Column>
                <Row align={'center'}>
                    <Icon size={16} color={'#00BFA5'}>
                        <BsCalendar2Plus/>
                    </Icon>
                    <Text weight={600}>Nova Interface</Text>
                    <HelpIcon content={newConfigInfo}/>
                </Row>
                <Spacer top={1}/>
                <Text>Quando essa Interface será utilizada?</Text>
                <Spacer top={1}/>
                <SelectField width={30} left={'none'} defaultValue={''} onChange={(e) => setSelectedType(e.target.value)}>
                    <Option value="" disabled>Selecione o Tipo</Option>
                    {
                        typesOpts.map((e,i) => {
                            return <Option key={i} value={typesValues[i]}>{e}</Option>
                        })
                    }
                </SelectField>
                <Spacer top={2.5}/>
                {
                    selectedType === 'weekday' ?
                    <>
                        <Text weight={600}>Esta Interface será utilizada nos seguintes dias da semana</Text>
                        <Spacer top={0.5}/>
                        <Column width={55}>
                            <WeekdaySelector selected={selectedWeekdays} onSelect={(weekdays) => setSelectedWeekdays(weekdays)}/>
                        </Column>
                    </>
                    :
                    selectedType === 'time' ?
                    <>
                        <Text weight={600}>Esta Interface será utilizada entre os horários de</Text>
                        <Spacer top={0.5}/>
                        <TimeIntervalSelector onChangeStart={(value) => setSelectedTypTimeStart(value)} onChangeEnd={(value) => setSelectedTimeEnd(value)}/>
                    </>
                    :
                    selectedType === 'season' &&
                    <>
                        <Text weight={600}>Esta Interface será utilizada entre o período de</Text>
                        <Spacer top={0.5}/>
                        <SeasonSelector onChangeStart={(value) => setSelectedSeasonStart(value)} onChangeEnd={(value) => setSelectedSeasonEnd(value)}/>
                    </>
                }
                <Spacer top={1}/>
            </Column>
            <Column align={'flex-end'} justify={'center'} marginRight={20}>
                <Button width={90} height={30} radius={15} onClick={() => handleCreate()}>Confirmar</Button>
                <Spacer top={1}/>
                <Button width={90} height={30} radius={15} onClick={() => onCancel()}>Cancelar</Button>
            </Column>
        </Row>
    )
}