export const nameInfo = <p style={{textAlign: 'left'}}>
Nome que identifica o <strong>Administrador</strong>.
</p>

export const roleInfo = <p style={{textAlign: 'left'}}>
Cargo ou função que o <strong>Administrador</strong> desempenha na Empresa. 
</p>

export const sinceInfo = <p style={{textAlign: 'left'}}>
Data que o <strong>Administrador</strong> foi cadastrado no sistema.
</p>

export const emailInfo = <p style={{textAlign: 'left'}}>
E-mail que será utilizado para <strong>receber mensagens do sistema</strong>, como por exemplo recuperação de senha, respostas do suporte técnico, entre outras.<br/><br/> Este também é o e-mail que será utilizado para <strong>fazer o login neste sistema</strong>.
</p>

export const statusInfo = <p style={{textAlign: 'left'}}>
Indica se o <strong>Administrador</strong> está <strong>Ativo</strong> ou <strong>Inativo</strong> para o sistema. <strong>Administradores inativos</strong> terão o acesso negado ao tentarem se logar no sistema.
</p>

export const actionInfo = <p style={{textAlign: 'left'}}>
<strong>Editar: </strong> Ao clicar em editar você poderá alterar as informações de cada <strong>Administrador</strong>. <br/><br/>
<strong>Permitir/Bloquear: </strong> Ao clicar neste botão você alterará o <strong>Status do Administrador.</strong> <strong>Administradores Bloqueados</strong> não poderão fazer login no sistema<br/><br/>
<strong>Excluir: </strong> Ao clicar em excluir o <strong>Administrador</strong> será excluído e todos os seus dados serão perdidos.
</p>

export const permissionDashboard = <p style={{textAlign: 'left'}}>
Permite que o usuário tenha acesso aos dados e relatórios pertinentes ao atendimento da empresa, bem como vizualizar gráficos, senhas emitidas, senhas em espera, etc.
</p>

export const permissionDevices = <p style={{textAlign: 'left'}}>
Permite que o usuário personalize e configure os dispositivos da empresa (totem de emissão de senhas e painel de chamados). 
</p>

export const permissionPasswords = <p style={{textAlign: 'left'}}>
Permite que o usuário configure e personalize a emissão de senhas, tipos de senhas, regras de prioridade e layouts de impressão.
</p>

export const permissionConfig = <p style={{textAlign: 'left'}}>
Permite que o usuário altere as configurações do atendimento e de notificações dos sistema.
</p>

export const permissionUsers = <p style={{textAlign: 'left'}}>
Permite que o usuário gerencie a equipe da empresa, atendentes e administradores, podendo adicionar novos, alterar dados e permissões.
</p>

export const permissionFinances = <p style={{textAlign: 'left'}}>
Permite que o usuário tenha acesso ao dados de mensalidades do sistema e notas fiscais.
</p>