import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BiBell, BiArrowToBottom } from 'react-icons/bi'
import { FiVolume2, FiVolumeX} from 'react-icons/fi'
import NotifRow from './NotifRow'
import { NotifContext } from '../../contexts/NotifContext'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-width: ${props => props.minimized ? '0' : '300px'};
    height: 100%;
    margin-left: 20px;
    position: relative;
    transition: ${props => props.minimized ? 'min-width 500ms ease 100ms':'min-width 500ms ease'};
`

const NotifContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: white;
    border-radius: ${props => props.minimized ? '30px' : '5px'};
    width: ${props => props.minimized ? '60px' : '100%'};
    height: ${props => props.minimized ? '60px' : '100%'};
    position: absolute;
    right: 0;
    bottom: 0;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #37474F;
    margin-left: auto;
    cursor: ${props => props.minimized && 'pointer'};
    transition: ${props => props.minimized ? 'all 500ms ease':'all 500ms ease 100ms'};
`

const IconBig = styled.div`
    display: ${props => !props.minimized && 'none'};
    position: relative;
    cursor: pointer;

    svg{
        font-size: 30px;
        color: #00BFA5;
        position: absolute;
        top: 15px;
        left: 15px;
    }
`

const Header = styled.span`
    display: ${props => props.minimized ? 'none':'flex'};
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #B0BEC5;

    h1{
        font-size: 12px;
        font-weight: 400;
    }

    span{
        margin-left: auto;
    }

    svg{
        margin: 5px;
    }

    #bell{
        font-size: 18px;
        color: #00BFA5;
    }

    #volume{
        font-size: 18px;
        color: #00BFA5;
        cursor: pointer;
    }

    #volumeX{
        font-size: 19px;
        color: #37474F;
        cursor: pointer;
    }

    #arrow{
        font-size: 19px;
        cursor: pointer;
    }

`

const Body = styled.span`
    display: ${props => props.minimized ? 'none':'flex'};
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    width: 100%;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #CFD8DC;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`

const New = styled.div`
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #F34236;
    border: 3px solid white;
    position: absolute;
    top: 15px;
    right: 15px;
`

function NotifBox(){

    const { notifications, setNotifications, mute, setMute } = useContext(NotifContext)
    
    const [minimized, setMinimized] = useState(false)
    const [isNew, setIsNew] = useState(false)

    const deleteNotif = (id) => {
        const temp = [...notifications]
        let index = temp.findIndex(temp => temp.id === id)

        temp.splice(index, 1)
        setNotifications(temp)
        
    }

    const onMinimize = (state) => {
        setMinimized(state)
        if(!state) setIsNew(false)
    }

    useEffect(() => {
        if(minimized) setIsNew(true)
    },[notifications])
    
    return(
        <Container minimized={minimized}>
            <NotifContainer minimized={minimized}>
                <IconBig minimized={minimized} onClick={() => onMinimize(false)}>
                    <BiBell/>
                    {isNew && <New/>}
                </IconBig>
                <Header minimized={minimized}>
                    <BiBell id={'bell'}/>
                    <h1>Notificações e Atividades</h1>
                    <span>
                        {!mute && <FiVolume2 id={'volume'} onClick={() => setMute(true)}/>}
                        {mute && <FiVolumeX id={'volumeX'} onClick={() => setMute(false)}/>}
                        <BiArrowToBottom id={'arrow'} onClick={() => onMinimize(true)}/>
                    </span>
                </Header>
                <Body minimized={minimized}>
                    {
                        notifications.map((notif, index) => {
                            return <NotifRow key={index} data={notif} erase={(id) => deleteNotif(id)}/>
                        })
                    }
                </Body>
            </NotifContainer>
        </Container>
    )
}

export default NotifBox