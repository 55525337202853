const TerminaisIcon = () => {
  return (
    <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.73884 11.9927L3.826 16.253H2.53954V12.5122L0.402414 1.20946C0.364064 1.01074 0.465168 0.853851 0.58719 0.693479L0.998578 0.153096C1.07876 0.0485059 1.21124 0.0275879 1.34373 0.0275879H8.60926C8.79404 0.0275879 8.96487 0.0763967 9.11478 0.167042L9.1357 0.177501C9.2891 0.275118 9.41112 0.418058 9.49131 0.592375L11.489 9.44769L11.0044 10.0055L8.95093 0.843392C8.91955 0.773665 8.87074 0.717884 8.81147 0.68302L8.80101 0.676048C8.74523 0.644671 8.67899 0.627239 8.60926 0.627239H2.02705C1.92246 0.627239 1.82833 0.669075 1.76209 0.738802C1.69585 0.805042 1.65052 0.90266 1.65052 1.00376L3.73884 11.9927V11.9927ZM3.37626 1.7603H7.84576C7.89456 1.7603 7.95732 1.7847 7.96778 1.83351L9.63076 9.2664C9.64122 9.31172 9.65517 9.35355 9.63425 9.3919C9.61333 9.43025 9.57498 9.45117 9.52966 9.45117H4.95558C4.90677 9.45117 4.84401 9.42677 4.83355 9.37796L3.27516 1.94508C3.2647 1.89975 3.25076 1.85792 3.27167 1.81957C3.29259 1.78122 3.33094 1.7603 3.37626 1.7603V1.7603ZM5.47156 14.0427H7.53198C7.57033 14.0427 7.59823 14.0741 7.59823 14.1089V14.4297C7.59823 14.468 7.56685 14.4959 7.53198 14.4959H5.47156C5.43321 14.4959 5.40532 14.4645 5.40532 14.4297V14.1089C5.40532 14.0706 5.43669 14.0427 5.47156 14.0427V14.0427ZM8.65459 14.1682L11.8585 17.3721C11.9387 17.4523 12.0468 17.4977 12.1584 17.4977C12.3919 17.4977 12.5802 17.3094 12.5802 17.0758V10.6679C12.5802 10.4343 12.3919 10.2461 12.1584 10.2461C12.0468 10.2461 11.9387 10.2914 11.8585 10.3716L8.65459 13.5755C8.5744 13.6557 8.52908 13.7638 8.52908 13.8753C8.52908 13.9869 8.5744 14.095 8.65459 14.1752V14.1682Z" fill="white"/>
    </svg>


  )
}

export default TerminaisIcon;