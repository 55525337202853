import styled from "styled-components";

const Checkbox = styled.div`
    display: flex;
    align-items: center;
    height: 35px;
`

const Input = styled.input`
    accent-color: #00BFA5;
    margin-right: 5px;
`

const Label = styled.label`
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
`

function CheckBox({id, label, defaultChecked, checked, onCheck}){
    
    return(
        <Checkbox>
            <Input id={label+id} name={label} defaultChecked={defaultChecked} checked={checked && checked} type="checkbox" onChange={(e) => onCheck(e.target.checked)}/>
            <Label htmlFor={label+id}>{label}</Label>
        </Checkbox>
    )
}

export default CheckBox