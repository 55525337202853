import { Row, Column, Spacer, Border, VerticalLine } from "../../../layout/Layout"
import ToggleSwitchBig from "../../../form/ToggleSwitchBig"
import ToggleLoad from "../../../form/ToggleLoad"
import NumberBetween from "../../../form/NumberBetween"
import FormTitle from "../../../form/FormTitle"
import Select from "../../../form/Select"
import UploadImages from "./UploadImages"
import UploadVideo from "./UploadVideo"
import ImagesPreview from "./ImagesPreview"
import VideoPreview from "./VideoPreview"
import { create } from "../../../../API/queries"
import { toast } from 'react-toastify'
import { autoAproachInfo, autoAproachAudioInfo, autoAproachExhibInfo, autoAproachStartInfo } from "../../../../content/tooltips/terminalsTooltips"

export default function AutoAproachSection({id, data, onChange}){

    const { enable, audio_enable, audio_url, start_time, exhibition, images_urls, video_url } = data

    const exhibitionOpts = ['Não exibir nada', 'Galeria de Imagens', 'Vídeo']
    const exhibitionValues = ['none', 'images', 'video']

    const handleChange = (value, property) => {
        const _data = {...data}

        _data[property] = value

        onChange(_data)
    }

    const handleAudioUpload = (e) => {
        e.preventDefault()

        const formData = new FormData();
        formData.append('file', e.target.files[0])

        toast.promise(
            create('upload/audio', formData).then(res => {
                handleChange(res.data.url, 'audio_url')
            }),
            {
                pending: 'Carregando Áudio...',
                success: 'Áudio carregado com sucesso!',
                error: 'Erro: Não foi possível carregar o Áudio'
            }, {containerId: 'alert'}
        )
    }

    const playAudio = () => {
        const voiceover = new Audio(audio_url)
        voiceover.play()
    }

    return(
        <Row>
            <Column>
                <ToggleSwitchBig id={'autoAbord'+id} text={'Abordagem Automática'} help={autoAproachInfo} state={enable} setState={(value) => handleChange(value, 'enable')}/>
                <Spacer top={0.7}/>
                {
                    enable &&
                    <Border border={'#90A4AE'} direction={'row'}>
                        <Column width={30}>
                            <FormTitle help={autoAproachAudioInfo}>Áudio</FormTitle>
                            <ToggleLoad id={'auto-audio'+id} icon={'audio'} state={audio_enable} text={'Áudio de Saudação'} isLoaded={audio_url !== ''} onToggle={(state) => handleChange(state, 'audio_enable')} onLoad={(e) => handleAudioUpload(e)} onPlay={() => playAudio()}/>
                            <Spacer top={2.5}/>
                            <FormTitle help={autoAproachStartInfo}>Início</FormTitle>
                            <NumberBetween before={'Iniciar em'} after={'segundos'} value={start_time} max={60} min={5} onChange={(value) => handleChange(value, 'start_time')}/>
                        </Column>
                        <VerticalLine/>
                        <Column width={30}>
                            <FormTitle help={autoAproachExhibInfo}>Exibição</FormTitle>
                            <Select id={'exhibition'+id} width={50} label={'O que exibir?'} value={exhibition} options={exhibitionOpts} values={exhibitionValues} onChange={(value) => handleChange(value, 'exhibition')}/>
                            <Spacer top={1.5}/>
                            {
                                exhibition === 'images' ?
                                <UploadImages urls={images_urls} onChange={value => handleChange(value, 'images_urls')}/>
                                :
                                exhibition === 'video' &&
                                <UploadVideo url={video_url} onChange={url => handleChange(url, 'video_url')}/>
                            }
                        </Column>
                        <VerticalLine/>
                        <Column width={40} height={100}>
                            {
                                exhibition === 'images' ?
                                <ImagesPreview urls={images_urls} onChange={(urls) => handleChange(urls, 'images_urls')}/>
                                :
                                exhibition === 'video' &&
                                <VideoPreview url={video_url}/>
                            }
                        </Column>
                    </Border>
                }
            </Column>
        </Row>
    )
}