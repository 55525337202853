import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page } from "../components/layout/Layout"
import AddButton from '../components/buttons/AddButton'
import ShrinkBox from "../components/shrinkBox"
import { useState, useContext, useRef, useEffect } from "react"
import { DataContext } from "../contexts/DataProvider" 
import PwPrintLayoutBox from "../components/resources/senhas/PwPrintLayoutBox"
import InfoTooltip from "../components/others/InfoTooltip"
import { printLayoutsHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import { toast } from 'react-toastify';
import { create, update, destroy } from '../API/queries'
import PulseLoader from "react-spinners/PulseLoader"
import { newPrintLayout, copyPrintLayout } from "../resources/newPrintLayout"
import { validateNewName } from "../methods/validateNewName"
import PwPrintLayoutBoxHeader from "../components/resources/senhas/PwPrintLayoutBoxHeader"
import HeaderFixedMenu from "../components/headerFixedMenu"

function PwPrintLayouts(){

    const page = useRef(null)

    const { dataLoaded, printLayouts, setPrintLayouts, terminals } = useContext(DataContext)
   
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(dataLoaded) setLoading(false)
    },[dataLoaded])

    const scrollBottom = () => {
        
        const el = page.current 
        
        el.scrollTo({
            top: 99999999,
            behavior: 'smooth',
        }) 
    }
    
    const handleChange = (id, value, property) => {
        const temp = [...printLayouts]
        let index = temp.findIndex(temp => temp._id === id)

        update('printlayout', id, {[property]: value}).then((res) => {

            if(res.status === 200){
                setPrintLayouts( prev => {
                    prev[index] = {...prev[index], [property]: value}
                    return [...prev]
                })
            }
            else toast.error(res, {containerId: 'alert'})
        }).catch((err) => {
            toast.error(err, {containerId: 'alert'})
        })
    }

    const addNewLayout = () => {

        toast.promise(
            create('printlayout', newPrintLayout(printLayouts)).then((res) => {
                setPrintLayouts(layouts => [...layouts, res.data])  
                setTimeout(() => {scrollBottom()}, 100) 
            }),
            {
                pending: 'Criando Layout de Impressão...',
                success: 'Novo Layout criado! Personalize suas configurações',
                error: 'Erro: Não foi possível criar um novo Layout'
            }, {containerId: 'alert'}
        )
    }

    const checkIsTerminal = (id) => {
        let check = 0
        
        terminals.forEach(terminal => {
            terminal.print_layouts.forEach(el => {
                if(el.print_layout === id) check = 1
            })
        })

        return check
    } 

    const destroyLayout = (id) => {

        if(checkIsTerminal(id)) {
            toast.error('Não é possível excluir este Layout pois no momento ele está sendo utilizado em Terminais.', {containerId: 'alert'})
            return
        }

        const temp = [...printLayouts]
        let index = temp.findIndex(temp => temp._id === id)

        if(index >= 0 && temp.length>1){
            
            toast.promise(
                destroy('printlayout', id).then((res) => {
                    temp.splice(index, 1)
                    setPrintLayouts(temp)
                }),
                {
                    pending: 'Excluindo Layout de Impressão...',
                    success: 'Layout de Impressão excluído com sucesso!',
                    error: 'Erro: Não foi possível excluir o Layout de Impressão'
                }, {containerId: 'alert'}
            )
        }
        else toast.error('É necessário manter pelo menos um Layout de Impressão', {containerId: 'alert'})
    }  

    const handleChangeName = (id, newName) => {
        if(!validateNewName(printLayouts, newName)) return toast.error('Já existe um Layout com este nome', {containerId: 'alert'})

        handleChange(id, newName, 'name')
    }

    const handleDuplicate = (id) => {

        const temp = [...printLayouts]
        const copy = temp.find(temp => temp._id === id)

        toast.promise(
            create('printlayout', copyPrintLayout(printLayouts, copy)).then((res) => {
                setPrintLayouts(layouts => [...layouts, res.data])  
                setTimeout(() => {scrollBottom()}, 100) 
            }),
            {
                pending: 'Criando Layout de Impressão...',
                success: 'Novo Layout criado! Personalize suas configurações',
                error: 'Erro: Não foi possível criar um novo Layout'
            }, {containerId: 'alert'}
        )
    }
     
    return(
        <>
            <Header>
                <HeaderLeft>
                    <AddButton text={'Novo Layout-de Impressão'} onClick={addNewLayout}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={printLayoutsHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
                {
                    loading ? 
                    <Page center>
                        <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                    </Page>
                    :
                    <Page ref={page}>
                        {
                            printLayouts.map((layout, index) => {
                                return <ShrinkBox 
                                    key={index}
                                    id={layout._id}  
                                    name={layout.name}
                                    onChangeName={(id, name) => handleChangeName(id, name)}
                                    onDuplicate={(id) => handleDuplicate(id)}
                                    onDelete={(id) => destroyLayout(id)}
                                    destroy={(id) => destroyLayout(id)}
                                    headerContent={<PwPrintLayoutBoxHeader id={layout._id} terminals={terminals}/>}
                                >
                                    <PwPrintLayoutBox 
                                        data={layout} 
                                        onChange={(id, state, property) => handleChange(id, state, property)}
                                    />
                                </ShrinkBox>
                            })
                        }
                    </Page>
                }
        </>
    )
}

export default PwPrintLayouts