import { Column, Row, Text } from "../../../layout/Layout"
import styled from "styled-components"
import { BiPause, BiPlay } from 'react-icons/bi'
import { IoClose } from 'react-icons/io5'
import { HiPencil, HiCheck } from 'react-icons/hi'
import { useState, useRef, useEffect } from "react"
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { toast } from "react-toastify"
import ConfirmationTooltip from "../../../others/ConfirmationTooltip"
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';
import { checkForRepeatedObjValues, checkIntervalForPrefix } from "../../../../services/validations"

const Status = styled.h1`
    font-size: 12px;
    font-weight: 600;
    color: ${props => props.status ? '#00C853' : '#fb9800'};
`

const RowHeight = styled.span` 
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #CFD8DC;
`

const Preview = styled.h1`
    font-size: 12px;
    font-weight: 800;
    text-align: center;
`

const CenterText = styled.h1`
    font-size: 12px;
    font-weight: 400;
    text-align: center;
`

const Input = styled.input`
    width: ${props => props.width}%;
    height: 25px;
    outline: none;
    border-radius: 5px;
    border: 1px solid ${props => props.error ? '#F34236' : '#00C853'};
    padding-left: 0.5rem;
`

const Select = styled.select`
    height: 25px;
    width: 80%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #00C853;
`

const Icons = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
`

const Icon = styled.div`
    cursor: pointer;

    svg{
        font-size: ${props => props.size}px;
        color: ${props => props.color};
    }
`

function PwTypesRow({data, pwTypes, rules, dataUpdated, onUpdateReport, updateData, onDelete}){

    const [editEnable, setEditEnable] = useState(false)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [rulesOpts, setRulesOpts] = useState([])
    
    const [tempData, _setTempData] = useState({
        name: '',
        prefix: '',
        prior: '',
        prior_rule: {},
        start: 0,
        restart: 0,
        digits:''
    })

    const tempDataRef = useRef(tempData) 
    
    const setTempData = (data, property) => {
        tempDataRef.current[property] = data
        _setTempData(tempData => ({...tempData, [property]: data}))
    }

    const toastId = 'toastId'

    const zeroPad = (num, places) => String(num).padStart(places, '0')
    const tempPreview = tempData.prefix + zeroPad(tempData.start, tempData.digits)
    const preview = data.prefix + zeroPad(data.start, data.digits)

    useEffect(() => {
        getRulesOpts()
    },[])
        
    const getRulesOpts = () => {
        let opts = ['Nenhuma']
        rules.forEach(rule => opts.push(rule.name))
        setRulesOpts(opts)
    }

    const handleRuleSelect = (selected) => {
        const selectedRule = rules.find(rule => rule.name === selected)
    
        if(selected === 'Nenhuma'){
            setTempData(false, 'prior')
            setTempData({}, 'prior_rule')
        }
        else {
            setTempData(true, 'prior')
            setTempData(selectedRule, 'prior_rule')
        }
    }

    const handleStatus = (newStatus) => {
        if(data.name !== 'Novo Tipo'){
            updateData(data._id, {status: newStatus}, 'update_status')
        }
        else toast.error('As informações do Tipo de senha são inválidas', {containerId: 'alert', toastId: toastId})
    }

    const handleConfirm = () => {

        if(checkForRepeatedObjValues(pwTypes, 'name', tempDataRef.current.name, data._id)){
            toast.error('Dados inválidos! Já existe um Tipo com este nome', {containerId: 'alert', toastId: toastId})
            return
        }

        if(tempDataRef.current.name.length < 4) {
            toast.error('Dados inválidos! O Nome deve ter 4 ou mais caracteres', {containerId: 'alert', toastId: toastId})
            return
        }

        if(tempDataRef.current.start >= tempDataRef.current.restart){
            toast.error('Dados inválidos! O valor de Início deve ser menor que o de Reinício', {containerId: 'alert', toastId: toastId})
            return
        }

        if(tempDataRef.current.restart - tempDataRef.current.start <= 80){
            toast.error('Dados inválidos! Os valores de Início e Reinício devem possuir um intervalo de no mínimo 80', {containerId: 'alert', toastId: toastId})
            return
        }

        if(checkForRepeatedObjValues(pwTypes, 'prefix', tempDataRef.current.prefix, data._id)){
            if(checkIntervalForPrefix(pwTypes, tempDataRef.current, data._id)){
                toast.error('Dados inválidos! Utilize intervalos de Início e Reinício diferentes para utilizar o mesmo prefixo', {containerId: 'alert', toastId: toastId})
                return
            }
        }

        updateData(data._id, tempDataRef.current)

        document.body.removeEventListener('click', handleClickOutside, true)
        document.body.removeEventListener('keydown', handleKeydown, true)
    }

    useEffect(() => {
        if(dataUpdated) setEditEnable(false)
        onUpdateReport()
    },[dataUpdated])

    const handleCancel = () => {

        document.body.removeEventListener('click', handleClickOutside, true)
        document.body.removeEventListener('keydown', handleKeydown, true)

        const temp = {...data}
        _setTempData(temp)
        setEditEnable(false)
    }

    const handleKeydown = (e) => {
        if(e.key === 'Enter') handleConfirm()
        if(e.key === 'Escape') handleCancel()

    }

    const handleClickOutside = (e) => {
        
        if(e.target.id.includes(data._id) || e.target.parentElement.id.includes(data._id)) return

        handleCancel()
    }

    const handleEditEnable = () => {
        document.body.addEventListener('click', handleClickOutside, true)
        document.body.addEventListener('keydown', handleKeydown, true)

        setTempData(data.name, 'name')
        setTempData(data.prefix, 'prefix')
        setTempData(data.prior, 'prior')
        setTempData(data.prior_rule, 'prior_rule')
        setTempData(data.start, 'start')
        setTempData(data.restart, 'restart')
        setTempData(data.digits, 'digits')
        setEditEnable(true)
    }

    return(
        <Row>
            {
                !editEnable ? 
                <RowHeight> 
                    <Column><Text>{data.name}</Text></Column>
                    <Column><CenterText>{data.prefix}</CenterText></Column>
                    <Column><CenterText>{data.prior ? data.prior_rule.name : 'Nenhuma'}</CenterText></Column>
                    <Column><CenterText>{data.start}</CenterText></Column>
                    <Column><CenterText>{data.restart}</CenterText></Column>
                    <Column><CenterText>{data.digits}</CenterText></Column>
                    <Column><Preview>{preview}</Preview></Column>
                    <Column><Status status={data.status}>{data.status ? 'Ativo' : 'Pausada'}</Status></Column>
                    <Column>
                        <Icons>
                            <Tippy content='Editar'>
                                <Icon id={'pencil'} size={15} color={'#0091EA'} onClick={() => handleEditEnable()}>
                                    <HiPencil/>
                                </Icon>
                            </Tippy>
                            {
                                data.status ? 
                                <Tippy content='Pausar Tipo'>
                                    <Icon size={23} color={'#fb9800'} onClick={() => handleStatus(false)}>
                                        <BiPause/> 
                                    </Icon>
                                </Tippy>
                                : 
                                <Tippy content='Ativar Tipo'>
                                    <Icon size={23} color={'#00C853'} onClick={() => handleStatus(true)}>
                                        <BiPlay/>
                                    </Icon>
                                </Tippy>
                            }
                            <Tooltip theme="light" trigger="click" open={deleteConfirm} interactive={true} onRequestClose={() => setDeleteConfirm(false)} html={(<ConfirmationTooltip text={'Deseja Excluir?'} onYes={() => {onDelete(data._id); setDeleteConfirm(false)}} onNo={() => setDeleteConfirm(false)}/>)}>
                                <Tippy content='Excluir'>   
                                    <Icon size={18} color={'#F34236'} onClick={() => setDeleteConfirm(true)}>
                                        <IoClose/>
                                    </Icon>
                                </Tippy>
                            </Tooltip>
                        </Icons>
                    </Column>
                </RowHeight>
            :
            <RowHeight>
                <Column>
                     <Input id={'name-'+data._id} width={100} maxLength="20" error={tempData.name.length < 4} value={tempData.name} onChange={(e) => setTempData(e.target.value, 'name')}></Input>  {/* name */}
                </Column>
                <Column>
                    <CenterText>
                        <Input id={'prefix-'+data._id} width={50} maxLength="3" value={tempData.prefix} onChange={(e) => setTempData(e.target.value.toUpperCase(), 'prefix')}></Input> {/* Prefixo */}
                    </CenterText>
                </Column>
                <Column>
                    <CenterText>
                        <Select name="prioridade" id={'prioridade:'+data._id} value={tempData.prior ? tempData.prior_rule.name : 'Nenhuma'} onChange={(e) => handleRuleSelect(e.target.value)}>
                            {
                                rulesOpts.map((e,i) => {
                                    return <option key={i}>{e}</option>
                                })
                            }
                        </Select>
                    </CenterText>
                </Column>
                <Column>
                    <CenterText>
                        <Input id={'start-'+data._id} width={40} type='number' min={1} max={999} value={tempData.start} onChange={(e) => setTempData(parseInt(e.target.value), 'start')}></Input> {/* start */}
                    </CenterText>
                </Column>
                <Column>
                    <CenterText>
                        <Input id={'restart-'+data._id} width={40} type='number' min={1} max={999} value={tempData.restart} onChange={(e) => setTempData(parseInt(e.target.value), 'restart')}></Input> {/* Restart */}
                    </CenterText>
                </Column>
                <Column>
                    <CenterText>
                        <Input id={'digits-'+data._id} width={40} type='number' min={1} max={3} value={tempData.digits} onChange={(e) => setTempData(parseInt(e.target.value), 'digits')}></Input> {/* Dígitos */}
                    </CenterText>
                </Column>
                <Column>
                    <Preview>{tempPreview}</Preview>
                </Column>
                <Column>
                    <Status status={data.status}>{data.status ? 'Ativo' : 'Pausada'}</Status>
                </Column>
                <Column>
                    <Icons>
                        <Tippy content='Confirmar'>
                            <Icon id={data._id} size={20} color={'#00C853'} onClick={() => handleConfirm()}>
                                    <HiCheck id={data._id}/>
                            </Icon>
                        </Tippy>
                        {
                            data.status ? 
                            <Tippy content='Pausar Tipo'>
                                <Icon size={23} color={'#fb9800'} onClick={() => handleStatus(false)}>
                                    <BiPause/>
                                </Icon>
                            </Tippy>
                            : 
                            <Tippy content='Ativar Tipo'>
                                <Icon size={23} color={'#00C853'} onClick={() => handleStatus(true)}>
                                    <BiPlay/>
                                </Icon>
                            </Tippy>
                        }
                        <Tippy content='Excluir Tipo'>
                            <Icon size={18} color={'#F34236'} onClick={() => onDelete(data._id)}>
                                <IoClose/>
                            </Icon>
                        </Tippy>
                    </Icons>
                </Column>
            </RowHeight>
            }
        </Row>
    )
}

export default PwTypesRow
