import { Row, Title, Spacer, Column } from '../../../layout/Layout'
import HelpIcon from "../../../others/HelpIcon";
import ServiceSelect from './ServiceSelect';
import { servicesInfo } from '../../../../content/tooltips/displayTooltips'
import { toast } from 'react-toastify'
import { useContext } from 'react';
import { PlaceContext } from '../../../../contexts/PlaceContext';
import { DataContext } from '../../../../contexts/DataProvider';

export default function ServicesConfig({services, onChange}){

    const { placeId } = useContext(PlaceContext)
    const { services: _services } = useContext(DataContext)

    const handleServiceSelect = (serviceId) => {

        const temp = [...services]

        const index = temp.findIndex(el => el === serviceId)

        if(index < 0){
            temp.push(serviceId)
            
            return onChange(temp)
        }

        if(temp.length === 1) return toast.error('É necessário manter pelo menos um Serviço.', {containerId: 'alert'})
        
        temp.splice(index, 1)
        onChange(temp)
    }
    
    return(
        <Row align={'center'}>
            <Column width={70}>
                <Row align={'center'}>
                    <Title>Serviços</Title>
                    <HelpIcon content={servicesInfo}/>
                </Row>
                <Spacer top={1}/>
                <Row align={'center'}>
                    {
                        _services.filter(el => el.placeId === placeId).map((e,i) => {
                            return <ServiceSelect key={i} service={e.name} state={services.includes(e._id)} onClick={() => handleServiceSelect(e._id)}/>
                        })
                    }
                </Row>
            </Column>
        </Row>
    )
}