import styled from "styled-components";
import { Row, Text } from "../../../../../layout/Layout";
import { getColor, getName } from "../../../../../../content/Colors";

const Color = styled.div`
    border-radius: 0.65rem;
    margin-right: 0.5rem;
    height: 1.3rem;
    width: 1.3rem;
    background-color: ${props => props.color};
`

export default function ThemeStamp({theme}){

    return(
        <Row align={'center'}>
            <Color color={getColor(theme, 500)}/>
            <Text>{getName(theme)}</Text>
        </Row>
    )
}