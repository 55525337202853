import { useEffect, useState } from "react";
import { Button, Dialog, Stack, Typography, Checkbox, FormControlLabel, Box } from "@mui/material";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa6";
import axios from 'axios';
import Downloading from './Downloading';
import DownloadComplete from "./DownloadComplete";
import { useAttendanceReportData } from "../AttendantPerformanceProvider";

export default function DownloadReportModal({ open, onClose }) {

    const { reportData, startDate, endDate, intelligentReport } = useAttendanceReportData();

    const [loading, setLoading] = useState(false);
    const [downloadComplete, setDownloadComplete] = useState(false);
    const [includeIntelligentReport, setIncludeIntelligentReport] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(false);
            setDownloadComplete(false);
        }
    }, [open]);

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const downloadPDF = async (startDate, endDate, reportData) => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_KEY}/attendance-report-pdf`, {
                startDate,
                endDate,
                reportData,
                includeIAAnalysis: includeIntelligentReport,
                iaAnalysis: intelligentReport
            }, {
                responseType: 'blob'
            });

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'attendance_report.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
                setDownloadComplete(true);
            } else {
                console.error('Failed to download PDF:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        } finally {
            setLoading(false);
        }
    };

    const downloadExcelReport = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_KEY}/attendance-report-excel`, { 
                reportData, 
                includeIntelligentReport: intelligentReport && includeIntelligentReport 
            }, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'attendance_report.xlsx');
            document.body.appendChild(link);
            link.click();
            setDownloadComplete(true);
        } catch (error) {
            console.error('Error downloading the Excel report:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{ '& .MuiDialog-paper': { padding: 3 } }}
        >
            <Stack spacing={2} alignItems="center">                
                {
                    loading ? 
                    <Downloading />
                    :
                    downloadComplete ?
                    <DownloadComplete onClose={onClose} />
                    :
                    <>
                        <Box textAlign="center">
                            <Typography variant="h5" fontWeight="bold">
                                Relatório de Desempenho de Atendentes
                            </Typography>
                            <Typography variant="subtitle1">
                                Período de {formatDate(startDate)} à {formatDate(endDate)}
                            </Typography>
                        </Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includeIntelligentReport}
                                    onChange={() => setIncludeIntelligentReport(!includeIntelligentReport)}
                                    disabled={!intelligentReport}
                                />
                            }
                            label="Incluir relatório inteligente"
                        />
                        <Typography>
                            Qual tipo de arquivo você deseja baixar?
                        </Typography>
                        <Button
                            variant="outlined"
                            startIcon={<FaRegFileExcel />}
                            onClick={downloadExcelReport}
                        >
                            Baixar em Excel
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<FaRegFilePdf />}
                            onClick={() => downloadPDF(startDate, endDate, reportData)}
                        >
                            Baixar em PDF
                        </Button>
                        <Button
                            variant="text"
                            onClick={onClose}
                        >
                            Cancelar
                        </Button>
                    </>
                }
            </Stack>
        </Dialog>
    );
}
