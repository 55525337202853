import { Icon, Row } from '../layout/Layout'
import { SelectField, Label, Option } from '../layout/Form'
import HelpIcon from "../others/HelpIcon";


export default function SelectWithIcon({icon, width, placeholder, left, options, values, value, disabled, onChange, help}){

    return(
        <Row height={35} align={'center'} width={'fit-content'} right={36}>
            <Icon size={20}>
                {icon}
            </Icon>
            <SelectField name="select" width={width || undefined} left={left || undefined} value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled}>
                {
                    placeholder &&
                    <Option value="" disabled selected>{placeholder}</Option>
                }
                {
                    options &&
                    options.map((options, index) => 
                        <Option key={index} value={values && values[index]}>{options}</Option>
                    )
                } 
            </SelectField>
            {help && <HelpIcon content={help}/>}
        </Row>
    )
}