import styled from "styled-components"
import { Text } from "../layout/Layout"

const SelectorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 28px;
    width: 100%;
    margin: 5px 0;
    border: 1px solid #90A4AE;
    border-radius: 8px;
`

const SelectorCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-left: ${props => props.pos > 0 && '1px solid #90A4AE'};
    border-radius: ${props => props.pos === 0 ? '8px 0 0 8px' : props.pos === 6 && '0 8px 8px 0'};
    background-color: ${props => props.selected && '#00BFA5'};
    cursor: pointer;
`

export default function WeekdaySelector({selected, onSelect}){


    const weekdays = ['DOM', 'SEG', 'TER', 'QUA' ,'QUI', 'SEX', 'SAB']

    const handleSelect = (value) => {
        const values = [...selected]

        const index = values.findIndex(el => el === value)

        if(index < 0) values.push(value)
        else values.splice(index,1)

        onSelect(values.sort())
    }

    return(
        <SelectorWrapper>
                {
                    weekdays.map((e,i) => {
                        return(
                            <SelectorCell pos={i} selected={selected.includes(i)} onClick={() => handleSelect(i)}>
                                <Text key={i} size={11} color={selected.includes(i) && 'white'} weight={500}>{e}</Text>
                            </SelectorCell>
                        )
                    })
                }
        </SelectorWrapper>
    )
}