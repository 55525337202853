import { VerticalBox, Column, Row, Table, Text } from "../../layout/Layout"
import TableTitle from "../../form/TableTitle";
import HelpIcon from "../../others/HelpIcon";
import { notVerifiedInfo } from "../../../content/tooltips/userAtendTooltips";
import NotVerifiedAtendRow from './NotVerifiedAtendRow'

export default function NotVerifiedAtendTable({data, onDelete}){
    
    return (
        <>
            <Row top={20} bottom={10} align={'center'}>
                <Text left={'1rem'}>Aguardando Ativação</Text>
                <HelpIcon
                    content={notVerifiedInfo}
                />
            </Row>
            <VerticalBox maxHeight={'calc(100% - 8.5rem)'}>
                <Row bottom={5} borderBtmWidth={1} borderBtmColor={'#37474F'} borderBtmStyle={'solid'} align={'flex-end'}>
                    <Column width={120}>
                        <TableTitle>Nome</TableTitle>
                    </Column>
                    <Column>
                        <TableTitle>Desde de</TableTitle>
                    </Column>
                    <Column width={120}>
                        <TableTitle>E-mail</TableTitle>
                    </Column>
                    <Column>
                        <TableTitle>Status</TableTitle>
                    </Column>
                    <Column align={'flex-end'}>
                        <Text weight={600}>Excluir</Text>
                    </Column>
                </Row>
                <Table>
                    {
                        data.map((attendant, index) => {
                            return <NotVerifiedAtendRow
                                key={index}
                                data={attendant}
                                onDelete={onDelete}
                            />
                        })
                    }
                </Table>
            </VerticalBox>
        </>
    )
}

