import { useState, useEffect } from "react";
import { getAll } from "../../API/queries";
import { Row, Text, Icon, Line } from "../layout/Layout";
import { BiDollarCircle } from 'react-icons/bi'
import { IoIosArrowDown } from 'react-icons/io'
import PendingInvoiceRow from "./PendingInvoiceRow";
import StatusMenu from "./StatusMenu";
import ReceivedInvoiceRow from "./ReceivedInvoiceRow";

export default function Invoices(){

    
    const [showInvoices, setShowInvoices] = useState(false)
    const [invoices, setInvoices] = useState([])
    const [selectedStatus, setSelectedStatus] = useState('pending')
    // const [loading, setLoading] = useState(true)

    const getInvoices = async () => {
        getAll('invoices').then((res) => {
            setInvoices(res.data.map(el => el.invoice))
        }).catch((err) => {
            console.log(err)
        }).finally(() => {}
            //setLoading(false)
        )
    }

    useEffect(() => {
        getInvoices()
    },[])

    return(
        <>
            <Row align={'center'}>
                <Icon size={28} color={'#78909C'}>
                    <BiDollarCircle/>
                </Icon>
                <Text size={16} weight={500}>Faturas e Pagamentos</Text>
                <Icon pointer size={18} hoverColor={'#00BFA5'} rotate={!showInvoices ? '-90deg' : undefined} onClick={() => setShowInvoices(prev => !prev)}>
                    <IoIosArrowDown/>
                </Icon>
            </Row>
            {
                showInvoices &&
                <>
                    <StatusMenu
                        selected={selectedStatus}
                        onSelect={(status) => setSelectedStatus(status)}
                    />
                    {
                        selectedStatus === 'pending' ?
                        <>
                            {
                                invoices.filter(el => el.status !== 'RECEIVED').map((invoice, index) => {
                                    return <PendingInvoiceRow key={index} data={invoice}/>
                                })
                            }
                        </>
                        :
                        <>
                            {
                                invoices.filter(el => el.status === 'RECEIVED').map((invoice, index) => {
                                    return <ReceivedInvoiceRow key={index} data={invoice}/>
                                })
                            }
                        </>
                    }
                </>
            }
            <Line height={2}/>
        </>
    )
}