import styled from "styled-components"
import { Column, Row, Text } from '../layout/Layout'
import { BiCheck } from 'react-icons/bi'
import { useEffect, useState } from "react"

const Wrapper = styled.div`
    position: relative;
    z-index: 999;
`

const Color = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 5px;
    background-color: ${props => props.color};
    cursor: pointer;

    svg{
        opacity: ${props => props.selected ? '100%' : 0};
        color: white;
        font-size: 22px;
    }
`

const Selected = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0 10px;
    height: 22px;
    background-color: ${props => props.color};
    cursor: pointer;
    position: relative;
`

const Menu = styled.div`
    display: ${props => props.show ? 'grid' : 'none'};
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 30px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`


function SelectUIColor({selected, onSelect}) {

    const colors = [
        {name: 'Vermelho', UI: 'red', color: '#F44336'},
        {name: 'Cor de Rosa', UI: 'pink', color: '#E91E63'},
        {name: 'Lilás', UI: 'purple', color: '#9C27B0'},
        {name: 'Roxo', UI: 'deep_purple', color: '#673AB7'},
        {name: 'Indigo', UI: 'indigo', color: '#3F51B5'},
        {name: 'Azul', UI: 'blue', color: '#2196F3'},
        {name: 'Azul Claro', UI: 'light_blue', color: '#03A9F4'},
        {name: 'Ciano', UI: 'cyan', color: '#00BCD4'},
        {name: 'Verde-azulado', UI: 'teal', color: '#00BFA5'},
        {name: 'Verde', UI: 'green', color: '#4CAF50'},
        {name: 'Verde Claro', UI: 'light_green', color: '#8BC34A'},
        {name: 'Amarelo Lima', UI: 'lime', color: '#CDDC39'},
        {name: 'Amarelo', UI: 'yellow', color: '#FFEB3B'},
        {name: 'Âmbar', UI: 'amber', color: '#FFC107'},
        {name: 'Laranja', UI: 'orange', color: '#FF9800'},
        {name: 'Laranja Escuro', UI: 'deep_orange', color: '#FF5722'},
        {name: 'Cinza', UI: 'gray', color: '#9E9E9E'},
        {name: 'Cinza-azulado', UI: 'blue_gray', color: '#607D8B'}       
    ]
    

    const [showMenu, setShowMenu] = useState(false)
    const [selectedColor, setSelectedColor] = useState('Azul')

    useEffect(() => {
        
        const handleClickOutside = (e) => {

            if(e.target.id !== 'color-select') setShowMenu(false)
        }
        
        if(showMenu) document.body.addEventListener('click', (e) => handleClickOutside(e))
    
    },[showMenu])

    const handleColorSelect = (color) => {
        setSelectedColor(color.name)
        onSelect(color.UI)
        setShowMenu(false)
    }

    useEffect(() => {
        const index = colors.findIndex(colors => colors.UI === selected)
        setSelectedColor(colors[index].name)
    },[])

    const findColor = () => {
        const index = colors.findIndex(colors => colors.name === selectedColor)

        return colors[index]
    }

    return(
        <Wrapper>
            <Row align={'center'}>
                <Text>Cor do Tema</Text>
                <Column align={'flex-end'}>
                    <Selected color={findColor().color} onClick={() => setShowMenu(showMenu => !showMenu)}>
                        <Text id="color-select" color={'white'}>
                            {
                                findColor().name
                            }
                        </Text>
                    </Selected>
                </Column>
            </Row>
            <Menu id="color-select" show={showMenu}>
                {
                    colors.map((e,i) => {
                        return <Color
                            id="color-select"
                            key={i}
                            color={e.color}
                            selected={e.name === selectedColor}
                            onClick={() => handleColorSelect(e)}
                        >
                            <BiCheck id="color-select"/>
                        </Color>
                    })
                }
            </Menu>
        </Wrapper>
    )
}

export default SelectUIColor