import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-width: 100%;
    margin-bottom: 1.6rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #37474F;
    padding: 0 1.6rem 0 1.6rem;
    cursor: ${props => !props.state && 'pointer'};
    transition: all 0.3s;

    :hover{
        background-color: ${props => !props.state && '#ECEFF1'};
    }
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    height: 4.2rem;
    position: relative;
`

export const RightIcons = styled.div`
    display: flex;
    position: absolute;
    right: 0;
`

export const Trash = styled.div`
    position: relative;

    svg{
        margin-right: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: #78909C;

        &:hover{
            color: #F34236;
        }

        &:active{
            transform: translateY(-2px);
        }
    }
`

export const DeleteConfirm = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 8rem;
`  

export const Duplicate = styled.div`
    margin-left: 1rem;

    svg{
        margin-right: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: #78909C;

        &:hover{
            color: #00BFA5;
        }

        &:active{
            transform: translateY(-2px);
        }
    }
    `

export const Arrow = styled.div`
    margin-left: 1rem;

    svg{
        font-size: 18px;
        color: #455A64;
        cursor: pointer;
        transition: all .5s;
        transform: ${props => !props.expanded && 'rotate(-90deg)'};
    }
`

export const Body = styled.div`
    display: ${props => props.expanded ? 'flex' : 'none'};
    flex-direction: column;
    height: fit-content;
    width: 100%;
    padding-bottom: 2rem;
`
