import { createContext, useEffect, useState } from 'react'
import { getByID, update } from '../API/queries'

export const AuthContext = createContext({})

export const AuthProvider = ({children}) => {
    
    const [authenticated, setAuthenticated] = useState(false)
    const [token, setToken] = useState(null)
    const [userAdmin, setUserAdmin] = useState(null)
    const [business, setBusiness] = useState(null)

    const getUserAdmin = (id) => {
      getByID('admin', id).then(res => {
        setUserAdmin(res.data)
        sessionStorage.setItem('userAdmin', JSON.stringify(res.data))
      })
    }

    const getBusiness = (id) => {
      getByID('auth', id).then(res => {
        setBusiness(res.data)
        sessionStorage.setItem('business', JSON.stringify(res.data))
      })
    }

    const reFetch = () => {
      getBusiness(business._id)
    }

    const restoreData = () => {
      const recoveredToken = sessionStorage.getItem('token')
      const recoveredUserAdmin = JSON.parse(sessionStorage.getItem('userAdmin'))
      const recoveredBusiness = JSON.parse(sessionStorage.getItem('business'))

      if(recoveredToken && recoveredUserAdmin && recoveredBusiness){
        setToken(JSON.parse(recoveredToken))
        setUserAdmin(recoveredUserAdmin)
        setBusiness(recoveredBusiness)
        return
      }

      window.location.replace('https://login.phila.com.br')
    }

    const recoverParams = () => { 
      const params = new URLSearchParams(window.location.search)
      const _token = params.get('token')
      const userId = params.get('userId')
      const adminId = params.get('adminId')
      
      if(_token && userId && adminId){ 
        setToken(_token)
        sessionStorage.setItem('token', JSON.stringify(_token))
        getBusiness(userId)
        getUserAdmin(adminId)
        return
      }

      restoreData()

    }

    useEffect(() => {
      recoverParams()
    },[])

    useEffect(() => {
      if(token && userAdmin && business){
        setAuthenticated(true)
      }
    },[token, userAdmin, business])

    const updateBusiness = (value, property) => {
      update('auth', business._id, {[property]: value}).then((res) => {
        if(res.status === 200){
            setBusiness(res.data.business)
            sessionStorage.setItem('business', JSON.stringify(res.data.business))
          }
        }).catch(err => {
        console.log(err)
      })
    }

    return (
        <AuthContext.Provider value={{authenticated, token, business, updateBusiness, userAdmin, setUserAdmin, reFetch}}>
            {authenticated && children}
        </AuthContext.Provider>
    )
}