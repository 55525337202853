const getNextNum = (numbers) => {

    for(let i = 1; i < Math.max(...numbers); i++) if(!numbers.includes(i)) return i
    return numbers.length + 1
}

const getName = (interfaces) => {
    const numbers = []
    const nameFormat = /^Interface +\d$|^Interface +\d\d$/

    interfaces.forEach(el => {
        if(nameFormat.test(el.name)) numbers.push(parseInt(el.name.replace(/\D/g,'')))
    })

    const nextNum = getNextNum(numbers.sort())
    
    return 'Interface ' + nextNum
}

export const newInterface = (interfaces) => {

    return{
        name: getName(interfaces),
        bgType: 'color',
        bgUrl: '',
        bgColor: '#00BCD4',
        gradientColors: ['#00b4db', '#0083b0'],
        gradientAngle: 0,
        showTopText: true,
        topText: 'Bem Vindo!',
        topTextColor: '#ffffff',
        topTextSize: 10,
        topTextWeight: 4,
        showBottomText: true,
        bottomText: 'Obrigado pela preferência!',
        bottomTextColor: '#ffffff',
        bottomTextSize: 8,
        bottomTextWeight: 4,
        width: 60,
        height: 8,
        stroke: false,
        strokeWidth: 2,
        radius: 10,
        strokeColor: '#ffffff',
        bg: true,
        buttonColor: '#ffffff',
        buttonOpacity: 10,
        textSize: 10,
        textWeight: 4,
        textColor: '#00BCD4'
    }
}

export const duplicateInterface = (interfaces, _interface) => {

    const interfaceCopy = {..._interface}
    
    interfaceCopy.name = getName(interfaces)

    delete interfaceCopy._id
    delete interfaceCopy.userId

    return interfaceCopy
}