import { Column, Row, Spacer } from "../../../layout/Layout"
import styled from "styled-components"
import { IoClose } from 'react-icons/io5'
import { HiCheck } from 'react-icons/hi'
import { useState, useRef, useEffect } from "react"
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { toast } from "react-toastify"
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';
import { validateNewName } from "../../../../methods/validateNewName"
import { checkForRepeatedObjValues, checkIntervalForPrefix, checkIntervalForPrefixNew } from "../../../../services/validations"

const Status = styled.h1`
    font-size: 12px;
    font-weight: 600;
    color: ${props => props.status ? '#00C853' : '#fb9800'};
`

const RowHeight = styled.span` 
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #CFD8DC;
`

const Preview = styled.h1`
    font-size: 12px;
    font-weight: 800;
    text-align: center;
`

const CenterText = styled.h1`
    font-size: 12px;
    font-weight: 400;
    text-align: center;
`

const Input = styled.input`
    width: ${props => props.width}%;
    height: 25px;
    outline: none;
    border-radius: 5px;
    border: 1px solid ${props => props.error ? '#F34236' : '#00C853'};
    padding-left: 0.5rem;
`

const Select = styled.select`
    height: 25px;
    width: 80%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #00C853;
`

const Icon = styled.div`
    cursor: pointer;

    svg{
        font-size: ${props => props.size}px;
        color: ${props => props.color};
    }
`

function PwTypesRowNew({pwTypes, rules, onCreate, onCancel}){

    const [tempData, _setTempData] = useState({
        name: '',
        prefix: '',
        prior: false,
        prior_rule: {
            name: '',
            rule: '',
            value: 0
        },
        start: 1,
        restart: 999,
        digits: 3
    })

    const tempDataRef = useRef(tempData) 

    const nameInputRef = useRef() 
    
    const setTempData = (data, property) => {
        tempDataRef.current[property] = data
        _setTempData(tempData => ({...tempData, [property]: data}))
    }

    const zeroPad = (num, places) => String(num).padStart(places, '0')
    const tempPreview = tempData.prefix + zeroPad(tempData.start, tempData.digits)
    const preview = tempData.prefix + zeroPad(tempData.start, tempData.digits)
        
    useEffect(() => {
        nameInputRef.current.focus()
    },[])
    
    const getRulesOpts = () => {
        let opts = ['Nenhuma']
        rules.forEach(rule => opts.push(rule.name))

        return opts
    }

    const handleRuleSelect = (selected) => {
        const selectedRule = rules.find(rule => rule.name === selected)
    
        if(selected === 'Nenhuma'){
            setTempData(false, 'prior')
            setTempData({
                name: '',
                rule: '',
                value: 0
            }, 'prior_rule')
            return
        }

        delete selectedRule.userId
        delete selectedRule.__v

        setTempData(true, 'prior')
        setTempData(selectedRule, 'prior_rule')
    }

    const checkData = () => {
        if(!validateNewName(pwTypes, tempData.name)){
            toast.error(`Dados inválidos! Já existe um Tipo de Senha chamado ${tempData.name}`, {containerId: 'alert'})
            return 0
        }    

        if(tempData.name.length < 4) {
            toast.error('Dados inválidos! O Nome deve ter 4 ou mais caracteres', {containerId: 'alert'})
            return 0
        }

        if(tempData.start >= tempData.restart){
            toast.error('Dados inválidos! O valor de Início deve ser menor que o de Reinício', {containerId: 'alert'})
            return 0
        }

        if(tempData.restart - tempData.start <= 80){
            toast.error('Dados inválidos! Os valores de Início e Reinício devem possuir um intervalo de no mínimo 80', {containerId: 'alert'})
            return 0
        }

        if(checkIntervalForPrefix(pwTypes, tempData)){
            toast.error('Dados inválidos! Utilize intervalos de Início e Reinício diferentes para utilizar o mesmo prefixo', {containerId: 'alert'})
            return 0
        }

        return 1
    }

    const handleConfirm = () => {
        if(!checkData()) return
        onCreate(tempData)
    }

    return(
        <Row color={'#ECEFF1'}>
            <RowHeight>
                <Column>
                     <Input id={'name'} ref={nameInputRef} width={100} maxLength="20" placeholder="Nome do Tipo" error={!validateNewName(pwTypes, tempData.name) || tempData.name.length < 4} value={tempData.name} onChange={(e) => setTempData(e.target.value, 'name')}></Input>  {/* name */}
                </Column>
                <Column>
                    <CenterText>
                        <Input id={'prefix'} width={50} maxLength="3" value={tempData.prefix} onChange={(e) => setTempData(e.target.value.toUpperCase(), 'prefix')}></Input> {/* Prefixo */}
                    </CenterText>
                </Column>
                <Column>
                    <CenterText>
                        <Select name="prioridade" id={'prioridade'} value={tempData.prior ? tempData.prior_rule.name : 'Nenhuma'} onChange={(e) => handleRuleSelect(e.target.value)}>
                            {
                                getRulesOpts().map((e,i) => {
                                    return <option key={i}>{e}</option>
                                })
                            }
                        </Select>
                    </CenterText>
                </Column>
                <Column>
                    <CenterText>
                        <Input id={'start-'} width={40} type='number' min={1} max={999} value={tempData.start} onChange={(e) => setTempData(parseInt(e.target.value), 'start')}></Input> {/* start */}
                    </CenterText>
                </Column>
                <Column>
                    <CenterText>
                        <Input id={'restart-'} width={40} type='number' min={1} max={999} value={tempData.restart} onChange={(e) => setTempData(parseInt(e.target.value), 'restart')}></Input> {/* Restart */}
                    </CenterText>
                </Column>
                <Column>
                    <CenterText>
                        <Input id={'digits-'} width={40} type='number' min={1} max={3} value={tempData.digits} onChange={(e) => setTempData(parseInt(e.target.value), 'digits')}></Input> {/* Dígitos */}
                    </CenterText>
                </Column>
                <Column>
                    <Preview>{tempPreview}</Preview>
                </Column>
                <Column>
                    {/* <Status status={data.status}>{data.status ? 'Ativo' : 'Pausada'}</Status> */}
                </Column>
                <Column>
                    <Row justify={'flex-end'} align={'center'}>
                        <Tippy content='Confirmar'>
                            <Icon size={22} color={'#00C853'} onClick={() => handleConfirm()}>
                                    <HiCheck/>
                            </Icon>
                        </Tippy>
                        <Spacer left={1}/>
                        <Tippy content='Cancelar'>
                            <Icon size={20} color={'#F34236'} onClick={() => onCancel()}>
                                <IoClose/>
                            </Icon>
                        </Tippy>
                        <Spacer left={5}/>
                    </Row>
                </Column>
            </RowHeight>
        </Row>
    )
}

export default PwTypesRowNew
