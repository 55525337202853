import styled from "styled-components"
import { create } from '../../API/queries'
import blank from '../../img/Blank-profile.png'
import { Column, Spacer, TextLink } from '../layout/Layout'
import { useContext } from "react"
import { AuthContext } from "../../contexts/AuthProvider"
import { toast } from "react-toastify"

const Avatar = styled.label`
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background-image: url(${props => props.url ? props.url : blank});
    background-color: #B0BEC5;
    background-position: center;
    background-size: contain;
    cursor: pointer;
`

const Input = styled.input`
    display: none;
`

export default function BusinessAvatar(){

    const { business, updateBusiness } = useContext(AuthContext)
    
    
    const handleUpload = (e) => {

        e.preventDefault()

        const formData = new FormData();

        formData.append('file', e.target.files[0])

        create('upload/image', formData).then(res => {
            updateBusiness(res.data.url, 'avatar_url')
        }).catch(err => {
            toast.error(`Não foi possível carregar a Imagem. Error: ${err}`, {containerId: 'alert'})
        })

        e.target.value = ''

    }

    return(
        <Column align={'center'} width={'fit-content'}>
            <Avatar htmlFor={'business_avatar'} url={business.avatar_url}/>
            <Input id={'business_avatar'} type="file" onChange={(e) => handleUpload(e)}/>
            <Spacer top={1}/>
            <TextLink htmlFor={'business_avatar'} color={'#2196F3'}>Alterar Imagem</TextLink>
        </Column>
    )
}