import styled from "styled-components";
import { useState } from "react";
import { Column, Row, Icon, Text, Spacer } from "../layout/Layout";
import { BiDollarCircle } from 'react-icons/bi'
import { getByID } from "../../API/queries";
import { useEffect } from "react";
import { BsCheck2Circle } from 'react-icons/bs'
import MoonLoader from "react-spinners/MoonLoader"

const QRCode = styled.img`
    width: 15rem;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: fit-content;
    border: 1px solid #37474F;
    border-radius: 999px;
    font-size: 12px;
    font-weight: 400;
    color: #37474F;
    cursor: pointer;
    white-space: nowrap;
    background-color: white;

    &:hover{
        border: 1px solid #00BFA5;
    }

    &:active{
        background-color: #00BFA5;
        color: white;

        svg{
            color: white;
        }
    }
`

export default function PaymentModal({invoice}){

    const [loading, setLoading] = useState(true)
    const [PIXdata, setPIXdata] = useState(null)
    const [payloadCopied, setPayloadCopied] = useState(false)

    async function copyPayload(payload) {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(payload);
        } else {
          return document.execCommand('copy', true, payload);
        }
    }

    const handleCopyClick = (payload) => {
        copyPayload(payload).then(() => {
            setPayloadCopied(true)
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {

        const getPIX = () => {
            getByID('invoices/pix', invoice.id).then((res) => {
                setPIXdata(res.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => setLoading(false))
        }

        getPIX()
    },[invoice])

    return(
        <Column>
            <Row align={'center'}>
                <Icon size={32} color={'#00BFA5'}>
                    <BiDollarCircle/>
                </Icon>
                <Text weight={600} size={14} color={'#455A64'}>Pagar com PIX</Text>
            </Row>
            <Spacer top={1}/>
            <Column align={'center'} width={'30rem'}>
                {
                    loading ?
                    <MoonLoader color={'black'} loading={true} size={16}/>
                    :
                    <>
                            <Text wrap={'wrap'} align={'center'}>Para pagar esta fatura com PIX basta escanear o código QR abaixo através do aplicativo do seu banco, ou usar o recurso de copiar e colar.</Text>
                            <Spacer top={1}/>
                            {
                                PIXdata &&
                                <>
                                    <QRCode src={`data:image/jpeg;base64,${PIXdata.encodedImage}`}/>
                                    <Spacer top={1}/>
                                    {
                                        payloadCopied ?
                                        <Column align={'center'}>
                                            <Icon size={24} color="#00C853">
                                                <BsCheck2Circle/>
                                            </Icon>
                                            <Text weight={600}>PIX copiado!</Text>
                                        </Column>
                                        :
                                        <Button onClick={() => handleCopyClick(PIXdata.payload)}>Copiar PIX</Button>
                                    }
                                </>
                            }
                    </>
                }
            </Column>
        </Column>
    )
}