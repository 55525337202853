import styled from "styled-components";
import { Icon, Row, Spacer, VertLine } from "../layout/Layout";
import { BsGear, BsBell, BsExclamationTriangle } from 'react-icons/bs'
import { Tooltip } from "react-tippy";
import { Link } from 'react-router-dom'
import { useContext } from "react";
import { NotifContext } from "../../contexts/NotifContext";
import { useState } from "react";
import NotifDisplay from "../notif/NotifDisplay";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import { CallsContext } from "../../contexts/CallsProvider";
import AlertsDisplays from "./AlertsDisplay";

const Bell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    cursor: pointer;
    width: fit-content;
    position: relative;
    
    svg{
        color: #37474F;
        font-size: 1.7rem;

        &:hover{
            color: #00BFA5;
        }
    }
`

const Engine = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    cursor: pointer;
    width: fit-content;
    
    svg{
        color: ${props => props.opened ? '#00BFA5' : '#37474F'};
        font-size: 1.7rem;

        &:hover{
            color: #00BFA5;
        }
    }
`

const Warning = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    cursor: pointer;
    width: fit-content;
    
    svg{
        color: ${props => props.highlight ? '#F44336' : '#37474F'};
        font-size: 1.65rem;
        transition: all 250ms;

        &:hover{
            color: #F44336;
        }
    }
`

const Circle = styled.div`
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    border: 3px solid white;
    background-color: #F34236;
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
`

export default function HeaderFixedMenu({configOpened}){

    const { longQueuePlaces, longWaitingPasswords } = useContext(CallsContext)
    const { notifications, isNew, setIsNew, paperRollNotified } = useContext(NotifContext)

    const [alert, setAlert] = useState(false)
    const [highlightAlert, setHighlightAlert] = useState(false)

    const alertHighlightInterval = useRef()

    const navigate = useNavigate()

    const link = () => navigate('../geral')

    useEffect(() => {
        if(longQueuePlaces.length > 0 || longWaitingPasswords.length > 0 || paperRollNotified.length > 0) setAlert(true)
        else setAlert(false)
    },[longQueuePlaces, longWaitingPasswords, paperRollNotified])

    useEffect(() => {
        if(alert){
            alertHighlightInterval.current = setInterval(() => {
                setHighlightAlert(prev => !prev)
            }, 600);
        }
        else clearInterval(alertHighlightInterval.current)

        return () => clearInterval(alertHighlightInterval.current)
    },[alert])
    
    return(
        <Row height={80} align={'center'} justify={'flex-end'}>
            <VertLine/>
            {
                alert &&
                <>
                    <Tooltip arrow={true} interactive trigger="click" theme={'light'} html={<AlertsDisplays longQueuePlaces={longQueuePlaces} longWaitingPasswords={longWaitingPasswords} paperRollNotified={paperRollNotified}/>}>
                        <Warning highlight={highlightAlert}>
                            <BsExclamationTriangle/>
                        </Warning>
                    </Tooltip>
                    <Spacer left={1}/>
                </>
            }
            <Tooltip arrow={true} theme={'light'} title='Notificações e Atividades'>
                <Tooltip arrow={true} interactive trigger="click" theme={'light'} html={<NotifDisplay data={notifications} onNavigate={() => link()}/>} onShown={() => setIsNew(false)}>
                    <Bell pointer size={20} color={'#37474F'} hoverColor={'#00BFA5'}>
                        <BsBell/>
                        {isNew && <Circle/>}
                    </Bell>
                </Tooltip>
            </Tooltip>
            <Spacer left={1}/>
            <Tooltip arrow={true} theme={'light'} title='Configurações'>
                <Link to='../config'>
                    <Engine opened={configOpened} pointer size={20} color={'#37474F'} hoverColor={'#00BFA5'}>
                        <BsGear/>
                    </Engine>
                </Link>
            </Tooltip>
        </Row>
    )
}