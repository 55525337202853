export const nameInfo = <p style={{textAlign: 'left'}}>
Nome que identifica o <strong>tipo de atendimento</strong> que será disponibilizado ao público.
É este nome que será exibido no Terminal para a emissão de senhas, bem como na senha impressa para identificação. (<strong>Máx:</strong> 30 caracteres)
</p>

export const prefixInfo = <p style={{textAlign: 'left'}}>
O <strong>prefixo</strong> compõe o formato das senhas emitidas e ajuda a indentificar o seu tipo. Você pode utilizar abreviações ou letras aleatórios com até 3 caracteres.
Pode ainda deixar este campo em branco para exibir apenas os números da senha.  
</p>

export const priorInfo = <p style={{textAlign: 'left'}}>
Este campo define qual é a <strong>Regra de Atendimento Prioritário</strong> do tipo de senha. As regras podem ser personalizadas em <strong>Regras de Prioridade</strong> no menu lateral.<br/><br/>
<strong>Obs.:</strong> Quando o <strong>Tipo de Senha</strong> for configurado com <strong>"Nenhuma"</strong> significa que ele não possui atendimento prioritário, e as senhas serão colocadas no final da fila.
</p>

export const startInfo = <p style={{textAlign: 'left'}}>
Número que inicia-se a contagem das senhas. <strong>Dica: </strong> Além do prefixo, você pode diferenciar os tipos de senhas pelo intervalo de números. Ex.: De <strong>1 a 199</strong> para senha 'Normal' e de <strong>200 a 399</strong> para senha 'Preferencial'
</p>

export const restartInfo = <p style={{textAlign: 'left'}}>
Número que se reinicia a contagem da senhas. Ao chegar nesse número a contagem voltará para o número inicial e se repitirá.
</p>

export const digitsInfo = <p style={{textAlign: 'left'}}>
Quantidade de dígitos que compõe a parte numérica da senha, exibindo <strong>zeros a esquerda</strong> quando necessário. 
</p>

export const previewInfo = <p style={{textAlign: 'left'}}>
Pré-vizualização do <strong>formato da senha</strong> que será impressa, de acordo com as configurações personalizadas aqui. 
</p>

export const statusInfo = <p style={{textAlign: 'left'}}>
O <strong>Status da Senha</strong> indica se ela deverá ser disponibilizada para atendimento ou não. Senhas 'pausadas' não serão exibidas no Terminal para emissão.<br/><br/>
<strong>Dica: </strong> Se seu estabelecimento possui algum tipo de atendimento em dias ou horários específicos, você poderá deixá-la cadastrada e apenas alterar seu status quando desejar.
</p>

export const actionsInfo = <p style={{textAlign: 'left'}}>
<strong>Editar: </strong> Ao clicar em editar você poderá alterar as configurações de cada senha. <br/><br/>
<strong>Pausar/Ativar: </strong> Ao clicar neste botão o status da senha se alterará. <br/><br/>
<strong>Excluir: </strong> Ao clicar em excluir o Tipo de Senha será excluído e todos os seus dados serão perdidos.
</p>