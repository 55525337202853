import { Column, Row, Text } from "../layout/Layout"

export default function AlertsDisplays({longQueuePlaces, longWaitingPasswords, paperRollNotified}){

    const getLongWaitingString = () => { 
        if(longWaitingPasswords.length === 0) return
        if(longWaitingPasswords.length === 1) return `A senha ${longWaitingPasswords[0].password} está aguardando a um tempo excessivo`
        return `Há ${longWaitingPasswords.length} senhas aguardando a um tempo excessivo`
    }

    const getLongQueueString = (name) => {
        return `A fila em ${name} está muito longa`
    }

    const getPaperRollString = (terminal) => {
        if(terminal.status === 'ending') return `A bobina do Terminal de Senhas: ${terminal.name} está acabando. Fique de olho!`
        else if(terminal.status === 'over') return `A bobina do Terminal de Senhas: ${terminal.name} acabou. Troque imediatamente!`
    }

    return(
        <Column width={'20rem'}>
            <Row align={'center'} justify={'center'} borderBtmWidth={1} borderBtmColor={'#CFD8DC'} borderBtmStyle={'solid'}>
                <Text weight={600} color={'#B71C1C'}>URGENTE:</Text>

            </Row>
            {
                paperRollNotified.map((e,i) => {
                    return <Row key={i} align={'center'} borderBtmWidth={1} borderBtmColor={'#CFD8DC'} borderBtmStyle={'solid'} top={10} bottom={10}>
                                <Text wrap={'wrap'} weight={600}>{getPaperRollString(e)}</Text>
                            </Row>
                })
            }
            {
                longQueuePlaces.map((e,i) => {
                    return <Row key={i} align={'center'} borderBtmWidth={1} borderBtmColor={'#CFD8DC'} borderBtmStyle={'solid'} top={10} bottom={10}>
                               <Text wrap={'wrap'} weight={600}>{getLongQueueString(e)}</Text>
                           </Row>
                })
            }
            {
                longWaitingPasswords.length > 0 &&
                <Row align={'center'} borderBtmWidth={1} borderBtmColor={'#CFD8DC'} borderBtmStyle={'solid'} top={10} bottom={10}>
                    <Text wrap={'wrap'} weight={600}>{getLongWaitingString()}</Text>
                </Row>
            }
        </Column>
    )
}