import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, TableSortLabel } from "@mui/material";
import RatingStars from "../../../components/others/RatingStars";
import { useState } from "react";
import { styled } from '@mui/material/styles';
import { useAttendanceReportData } from "../AttendantPerformanceProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: '1.1rem', 
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    flex: 1,
    marginBottom: theme.spacing(5), // Small margin at the bottom
}));

function Row({ data }) {
    return (
        <TableRow>
            <StyledTableCell>{data.name}</StyledTableCell>
            <StyledTableCell align="center">
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                    <RatingStars ratings={data.rating} />
                </Stack>
            </StyledTableCell>
            <StyledTableCell align="right">{data.totalFinished}</StyledTableCell>
            <StyledTableCell align="right">{data.avgCallDurationFormatted}</StyledTableCell>
            <StyledTableCell align="right">{data.totalAttendingTimeFormatted}</StyledTableCell>
            <StyledTableCell align="right">{data.totalWorkTimeFormatted}</StyledTableCell>
            <StyledTableCell align="right">{data.totalPausedTimeFormatted}</StyledTableCell>
            <StyledTableCell align="right">{data.workEfficiencyFormatted}</StyledTableCell>
            <StyledTableCell align="right">{data.loggedEfficiencyFormatted}</StyledTableCell>
        </TableRow>
    );
}

export default function ComparativeSection() {

    const { reportData } = useAttendanceReportData()

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = reportData?.slice().sort((a, b) => {
        if (orderBy === 'rating') {
            return order === 'asc' ? a.rating - b.rating : b.rating - a.rating;
        } else if (orderBy === 'avgCallDuration' || orderBy === 'totalWorkTime' || orderBy === 'workEfficiency') {
            return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
        } else if (typeof a[orderBy] === 'string') {
            return order === 'asc' ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
        } else {
            return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
        }
    });

    return (
        <StyledTableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'name'}
                                direction={orderBy === 'name' ? order : 'asc'}
                                onClick={() => handleRequestSort('name')}
                            >
                                Atendente
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={orderBy === 'rating'}
                                direction={orderBy === 'rating' ? order : 'asc'}
                                onClick={() => handleRequestSort('rating')}
                            >
                                Avaliação
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                            <TableSortLabel
                                active={orderBy === 'totalFinished'}
                                direction={orderBy === 'totalFinished' ? order : 'asc'}
                                onClick={() => handleRequestSort('totalFinished')}
                            >
                                Atendimentos Realizados
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                            <TableSortLabel
                                active={orderBy === 'avgCallDuration'}
                                direction={orderBy === 'avgCallDuration' ? order : 'asc'}
                                onClick={() => handleRequestSort('avgCallDuration')}
                            >
                                Tempo Médio de Atendimento
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                            <TableSortLabel
                                active={orderBy === 'totalAttendingTime'}
                                direction={orderBy === 'totalAttendingTime' ? order : 'asc'}
                                onClick={() => handleRequestSort('totalAttendingTime')}
                            >
                                Tempo Total em Atendimento
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                            <TableSortLabel
                                active={orderBy === 'totalLoggedTime'}
                                direction={orderBy === 'totalLoggedTime' ? order : 'asc'}
                                onClick={() => handleRequestSort('totalLoggedTime')}
                            >
                                Tempo Total Logado
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                            <TableSortLabel
                                active={orderBy === 'totalPausedTime'}
                                direction={orderBy === 'totalPausedTime' ? order : 'asc'}
                                onClick={() => handleRequestSort('totalPausedTime')}
                            >
                                Tempo Total em Pausa
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                            <TableSortLabel
                                active={orderBy === 'workEfficiency'}
                                direction={orderBy === 'workEfficiency' ? order : 'asc'}
                                onClick={() => handleRequestSort('workEfficiency')}
                            >
                                Rendimento do trabalho
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                            <TableSortLabel
                                active={orderBy === 'loggedEfficiency'}
                                direction={orderBy === 'loggedEfficiency' ? order : 'asc'}
                                onClick={() => handleRequestSort('loggedEfficiency')}
                            >
                                Eficiência efetiva
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.map((e, i) => (
                        <Row key={i} data={e} />
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
}
