import { Row, Text } from '../layout/Layout'
import HelpIcon from './HelpIcon';


export default function ShortIdDisplay({shortId}){
    
    const help = 'Este é o ID utilizado para abrir este Painel na sua televisão. Para utilizá-lo basta abrir o site ou aplicativo no seu aparelho e inserir esse número.'
    
    return(
        <Row align={'center'}>
            <Text weight={600} color={'#90A4AE'}>{`ID: ${shortId}`}</Text>
            <HelpIcon content={help}/>
        </Row>
    )
}