import styled from 'styled-components';
import { Row, Text, Icon, Box } from '../../layout/Layout'
import { BsBarChart } from 'react-icons/bs'
import { useEffect, useRef, useState } from 'react';
import { getDatasets } from './methods/TimeStats'
import HorizontalBarChart from './HorizontalBarChart/HorizontalBarChart';

const Chart = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width};
    height: calc(100% - 30px);
    position: relative;
    padding: 5px 20px 10px 20px;
`

export default function AverageTimeByDeskChart({desks, calls}){

    const canvasRef = useRef()
    const [canvasWidth, setCanvasWidth] = useState(0)

    useEffect(() => {

        setCanvasWidth(getComputedStyle(canvasRef.current).width)

        getDatasets(desks, calls)

    },[])
    
    return(
        <Box ref={canvasRef} height={"100%"} width={"100%"} padding={'0px'} direction={'column'}>
            <Row align={'center'} height={40} left={10}>
                <Icon size={18} color={'#00BFA5'} rotate={'90deg'}>
                    <BsBarChart/>
                </Icon>
                <Text>Tempo Médio por Guichê</Text>
            </Row>
            <Chart width={canvasWidth}>
                {
                    calls.length > 0 ?
                    <HorizontalBarChart data={getDatasets(desks, calls)}/>
                    :
                    <Text size={14} weight={500} color={'#78909C'}>Não há dados para este local</Text>
                }
            </Chart>
        </Box>
    )
}