import { Column, Icon, Row, Text } from "../../layout/Layout"
import { BsCheck, BsX } from 'react-icons/bs'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import ConfirmationTooltip from "../../others/ConfirmationTooltip"
import { useState } from "react"


export default function QueueRow({data, onCancel}){

    const [deleteConfirm, setDeleteConfirm] = useState(false)

    return(
        <Row height={35} borderBtmWidth={1} borderBtmColor={'#CFD8DC'} borderBtmStyle={'solid'} align={'center'} color={data.index === 0 ? '#E8F5E9' : data.status === 'canceled' && '#FFEBEE'}>
            <Column width={5}></Column>
            <Column>
                <Text weight={600} color={data.prior ? '#FFA726' : '#00BFA5'}>{data.password}</Text>
            </Column>
            <Column width={50}>
                <Text>{data.type}</Text>
            </Column>
            <Column align={'center'}>
                {
                    data.prior && <Icon size={20} color={'#00C853'}><BsCheck/></Icon>
                }
            </Column>
            <Column width={90} align={'center'}>
                <Text>{new Date(data.created_at).toLocaleString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'})}</Text>
            </Column>
            <Column align={'center'} width={100}>
                    {
                        data.index === 0 ?
                        <Text weight={600}>Próxima</Text>
                        : data.status === 'canceled' ? 
                        <Text weight={600} color={'#EF5350'} >Cancelada</Text> 
                        : 
                        <Text weight={600}>{data.index+'°'}</Text>
                    }
            </Column>
            <Column align={'center'} width={120}>
                {
                    data.status !== 'canceled' &&
                    <Text weight={600}>{Math.floor((Date.now() - data.created_at)/60000)+' minutos'}</Text>
                }
            </Column>
            <Column align={'center'} width={50}>
                {
                    data.status !== 'canceled' &&
                    <Tooltip theme="light" trigger="click" open={deleteConfirm} interactive={true} onRequestClose={() => setDeleteConfirm(false)} html={(<ConfirmationTooltip text={'Deseja Excluir?'} onYes={() => {onCancel(data._id); setDeleteConfirm(false)}} onNo={() => setDeleteConfirm(false)}/>)}>
                        <Icon size={20} color={'#F34236'} pointer onClick={() => setDeleteConfirm(true)}>
                            <BsX/>
                        </Icon>
                    </Tooltip>
                }
            </Column>
        </Row>
    )
}