export const newPrintLayout = (printLayouts) => {

    return{
        name: getName(printLayouts),
        show_logo: false,
        logo_url: '',
        show_title: true,
        title: "Nome",
        show_slogan: true,
        slogan: "Slogan",
        show_time: true,
        show_pw_type: true,
        invert: false,
        show_text: true,
        line1: "Aguarde sua senha",
        line2: "ser chamada no painel",
        show_QR: false,
        QR_title: "Acesse nosso site:",
        QR_code: "",
        show_thanks: true,
        thanks_text: "Obrigado pela preferência",
    }
}

export const copyPrintLayout = (printLayouts, printLayout) => {

    const copy = {...printLayout}

    delete copy._id
    delete copy.userId

    copy.name = getName(printLayouts)

    return copy
}

const getNextNum = (numbers) => {

    for(let i = 1; i < Math.max(...numbers); i++) if(!numbers.includes(i)) return i
    return numbers.length + 1
}

const getName = (terminals) => {
    const numbers = []
    const nameFormat = /^Layout +\d$|^Layout +\d\d$/

    terminals.forEach(el => {
        if(nameFormat.test(el.name)) numbers.push(parseInt(el.name.replace(/\D/g,'')))
    })

    const nextNum = getNextNum(numbers.sort())
    
    return 'Layout ' + nextNum
}