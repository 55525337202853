import _ from 'lodash'
import { HexToRgba } from '../../../../services/HexToRgba'

const getLabelsByIds = (ids, desks) => {
    let labels = []
    _.forEach(ids, id => {
        labels.push(_.find(desks, desk => desk._id === id).name)
    })

    return labels
}

export const getIds = (calls) => {
    let ids = []

    _.forEach(calls, call => {
        if(!ids.includes(call.desk_id)) ids.push(call.desk_id)
    })

    return ids
}

const getTypes = (calls) => {
    let types = [], priors = []

    _.forEach(calls, call => {
        if(!types.includes(call.type)) {
            types.push(call.type)
            priors.push(call.prior)
        }
    })

    return {types, priors}
}

const getCallsById = (id, calls) => {
    return _.filter(calls, call => call.desk_id === id)
}

const countByType = (type, calls) => {
    return _.filter(calls, call => call.type === type).length
}

const getColor = (index, prior) => {
    const oranges = ['#FF9800', '#FFA726', '#FB8C00', '#FFB74D', '#F57C00', '#FFCC80', '#EF6C00', '#FFE0B2']
    const greens = ['#00BFA5', '#26A69A', '#00897B', '#4DB6AC', '#00796B', '#80CBC4', '#00695C', '#B2DFDB']

    if(prior) return HexToRgba(oranges[index])
    else return HexToRgba(greens[index])
}

export const getDatasets = (desks, calls) => {

    const dataset = (label, data, color) => {
        return {
            label: label,
            data: data,
            backgroundColor: color
        }
    }
    
    const ids = getIds(calls)
    const labels = getLabelsByIds(ids, desks)

    let datasets = [], totals = [], priorIndex = 0, nonPriorIndex = 0
    
    const types = getTypes(calls).types
    const priors = getTypes(calls).priors

    _.forEach(ids, id => {
        totals.push(getCallsById(id, calls).length)    
    })

    datasets.push(dataset('Total', totals, HexToRgba('#01579B')))

    types.forEach((type, index) => {
         let counts = [], color = ''

        _.forEach(ids, id => {
            counts.push(countByType(type, getCallsById(id, calls)))
        })

        if(priors[index]){
            color = getColor(priorIndex, priors[index])
            priorIndex += 1
        } else {
            color = getColor(nonPriorIndex, priors[index])
            priorIndex += 1
        }

        datasets.push(dataset(type, counts, color))
    })
    
    return {datasets, labels}
}