import styled from "styled-components";

const Row = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 200px;
    min-height: 20px;
`

const Color = styled.div`
    width: 8px;
    height: 8px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: ${props => props.color};
`

const Type = styled.h1`
    font-size: 12px;
    font-weight: 400;
`

const Total = styled.h1`
    font-size: 12;
    font-weight: 600;
    margin-left: auto;
`

function TotalsTypes({type, total, color}){
    
    return(
        <Row>
            <Color color={color}/>
            <Type>{type}</Type>
            <Total>{total}</Total>
        </Row>
    )
}

export default TotalsTypes