const getWeekdayName = (weekday) => {
    switch (weekday) {
        case 0: return 'Dom';
        case 1: return 'Seg';
        case 2: return 'Ter';
        case 3: return 'Qua';
        case 4: return 'Qui';
        case 5: return 'Sex';
        case 6: return 'Sab';
        default: break;
    }
}

export const weekdayString = (weekdays) => {
    let str = ''

    weekdays.forEach((weekday, index) => {
        str += getWeekdayName(weekday)
        if(index < weekdays.length - 2) str += ', '
        else if(index === weekdays.length - 2) str += ' e '
    })

    return str
}

export const seasonString = (start, end) => {
    const startDay = start.substring(8,10)
    const startMonth = start.substring(5,7)
    const startYear = start.substring(0,4)
    const endDay = end.substring(8,10)
    const endMonth = end.substring(5,7)
    const endYear = end.substring(0,4)
    
    return `De ${startDay}/${startMonth}/${startYear} a ${endDay}/${endMonth}/${endYear}`
}

export const timeString = (start, end) => {
    const startHour = start.substring(0,2)
    const startMinutes = start.substring(3,5)
    const endHour = end.substring(0,2)
    const endMinutes = end.substring(3,5)
    
    return `De ${startHour}:${startMinutes} às ${endHour}:${endMinutes}`
    
}