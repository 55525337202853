import styled from "styled-components"

const Toggle = styled.span`
    display: flex;
    align-items: center;
    height: 35px;

    h1{
        font-size: 12px;
        font-weight: 400;
        margin-left: ${props => props.margin}px;
    }

    input{
        margin-left: auto;
        padding-left: 3px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        width: 20%;
        height: 25px;
        background-color: transparent;
        cursor: pointer;
    }
`

function LabelPickColor({id, text, input, value, margin, onBlur}){
    
    return(
        <Toggle margin={margin}>
            <h1>{text}</h1>
            <input id={id}
                    type="color"
                    value = {value}
                    onChange={(e) => {input(e.target.value)}}
                    onBlur={(e) => onBlur(e.target.value)}
            />
        </Toggle>
    )
}

export default LabelPickColor