import { useEffect, useState } from "react"
import { Column, Row, Text, VertLine } from "../../layout/Layout"

function CallsTableTotals({data}){

    const [types, setTypes] = useState([])
    const [totals, setTotals] = useState([])
    const [priors, setPriors] = useState([])
    
    const getTotals = () => {
        let types = []
        let totals = []
        let priors = []
        let count = 0

        data.forEach(el => {
            if(!types.includes(el.type)) {
                types.push(el.type)   
                priors.push(el.prior)
            } 
        })

        for(let i=0; i<types.length; i++){            
            count = 0

            data.forEach(el => {
                if(el.type === types[i]) count += 1
            })

            totals[i] = count
            count = 0
        }
    
        setTypes(types)
        setPriors(priors)
        setTotals(totals)
    }

    useEffect(() => {
        getTotals()
    },[data])
    
    return(
        <>
            {
                types.map((e,i) => {
                    return <>
                        {i !== 0 && <VertLine/>}
                        <Column width={5}>
                                <Text size={14} weight={600}  color={priors[i] ? '#ffa626' : '#00BFA5'}>{totals[i]}</Text>
                                <Text margin={1} size={12} weight={400}>{e}</Text>
                        </Column>               
                    </>
                })
            }
            <VertLine/>
            <Column width={5}>
                    <Text size={15} weight={600}  color={'#888'}>{data.length}</Text>
                    <Text margin={1} size={12} weight={600}>Total</Text>
            </Column>  
        </>
    )
}

export default CallsTableTotals