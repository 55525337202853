import { Wrapper, Row, VerticalLine, Section, Line } from "../../layout/Layout"
import ServicesSection from "./terminalBox/ServicesSection";
import InterfacesSection from "./terminalBox/InterfacesSection";
import PrintLayoutsSection from "./terminalBox/PrintLayoutSection";
import ConfirmationSection from "./terminalBox/ConfirmationSection"
import AutoAproachSection from "./terminalBox/AutoAproachSection"

function TerminalBox({data, interfaces, printLayouts, setValue}){

    return(   
        <Wrapper>
            <Row>
                <Section>
                    <ServicesSection data={data} onChange={(value) => setValue(data._id, value, 'services')}/>
                </Section>
                <VerticalLine/>
                <Section>
                    <PrintLayoutsSection data={data.print_layouts} layouts={printLayouts} onChange={(value) => setValue(data._id, value, 'print_layouts')}/>
                </Section>
                <VerticalLine/>
                <Section>
                    <InterfacesSection data={data.interfaces} interfaces={interfaces} onChange={(value) => setValue(data._id, value, 'interfaces')}/>
                </Section>
            </Row>
            <Line height={4}/>
            <AutoAproachSection id={data._id} data={data.auto_aproach} onChange={value => setValue(data._id, value, 'auto_aproach')}/>
            <Line height={4}/>
            <ConfirmationSection id={data._id} data={data.confirmation} onChange={value => setValue(data._id, value, 'confirmation')}/>
            <Line height={3}/>
        </Wrapper>
    )
}

export default TerminalBox