import { createContext, useEffect, useState } from 'react'
import { getAll, update } from '../API/queries'
import { toast } from 'react-toastify'

export const ConfigContext = createContext({})

export const ConfigProvider = ({children}) => {
    
    const [id, setId] = useState(null)
    const [config, setConfig] = useState(null)
    const [loadingConfig, setLoadingConfig] = useState(false)

    const fetchConfig = () => {

      setLoadingConfig(true)

      getAll('config').then((res) => {
        setConfig(res.data)
        setLoadingConfig(false)
        if(!id) setId(res.data._id)
      }).catch(err => {
        console.log(err)
        setLoadingConfig(false)
      })
    }
    
    useEffect(() => {
      fetchConfig()  
    },[]) 

    const updateConfig = (value, property) => {        
      update('config', id, {[property]: value}).then((res) => {
        setConfig(res.data)  
      }).catch((err) => {
          toast.error(`Não foi possível atualizar os dados (erro: ${err})`, {containerId: 'alert'})
      })
  }
    
    return (
        <ConfigContext.Provider value={{config, setConfig, loadingConfig, fetchConfig, updateConfig}}>
            {children}
        </ConfigContext.Provider>
    )
}