import { Row, Spacer, Text } from "../layout/Layout";



export default function StatusMenu({selected, onSelect}){


    return(
        <Row 
            height={'3rem'}
            align={'center'}
            borderBtmStyle={'solid'}
            borderBtmWidth={1}
            borderBtmColor={'#CFD8DC'}
            marginBtm={12}
        >
            <Text
                pointer
                weight={selected === 'pending' ? 600 : 400}
                color={selected === 'pending' ? '#00BFA5' : undefined}
                onClick={() => onSelect('pending')}
            >
                Faturas Pendentes
            </Text>
            <Spacer left={2}/>
            <Text
                pointer
                weight={selected === 'received' ? 600 : 400}
                color={selected === 'received' ? '#00BFA5' : undefined}
                onClick={() => onSelect('received')}
            >
                Faturas Pagas
            </Text>
        </Row>
    )
}