import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page, VertLine } from "../components/layout/Layout"
import { Link } from 'react-router-dom'
import Button from "../components/others/Button"
import ShrinkBox from "../components/shrinkBox"
import AddButton from "../components/buttons/AddButton"
import { useState, useRef, useEffect, useContext } from "react"
import InterfaceBox from "../components/resources/interfaces/InterfaceBox"
import InfoTooltip from "../components/others/InfoTooltip"
import { interfacesHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import { toast } from 'react-toastify';
import { create, update, destroy } from '../API/queries'
import { DataContext } from "../contexts/DataProvider"
import PulseLoader from "react-spinners/PulseLoader"
import { duplicateInterface, newInterface } from "../resources/newInterface"
import { validateNewName } from "../methods/validateNewName"
import InterfacesBoxHeader from "../components/resources/interfaces/InterfacesBoxHeader"
import HeaderFixedMenu from "../components/headerFixedMenu"

function Interfaces(){

    const { dataLoaded, interfaces, terminals, setInterfaces } = useContext(DataContext)

    const [loading, setLoading] = useState(true)

    const page = useRef(null)

    useEffect(() => {
        if(dataLoaded) setLoading(false)
    },[dataLoaded])

    const handleChange = (id, value, property) => {        
        
        const temp = [...interfaces]
        let index = temp.findIndex(temp => temp._id === id)

        update('interface', id, {[property]: value}).then((res) => {
            if(res.status === 200){
                setInterfaces( prev => {
                    prev[index] = {...prev[index], [property]: value}
                    return [...prev]
                })
            }
            else toast.error(res, {containerId: 'alert'})
        }).catch((err) => {
            toast.error(err, {containerId: 'alert'})
        })
    }

    const handleLoadPattern = (id, pattern) => {
        const temp = [...interfaces]
        let index = temp.findIndex(temp => temp._id === id)
        
        update('interface', id, pattern).then(res => {
            setInterfaces( prev => {
                prev[index] = res.data.updated
                return [...prev]
            })
        })
    }

    const scrollBottom = () => {
        
        const el = page.current 
        
        el.scrollTo({
            top: 99999999,
            behavior: 'smooth',
        }) 
    }

    const addNewInterface = () => {

        toast.promise(
            create('interface', newInterface(interfaces)).then((res) => {
                setInterfaces(interfaces => [...interfaces, res.data])  
                setTimeout(() => {scrollBottom()}, 100) 
            }),
            {
                pending: 'Criando Interface...',
                success: 'Nova Interface criada! Personalize suas configurações',
                error: 'Erro: Não foi possível criar uma nova Interface'
            }, {containerId: 'alert'}
        )
    }

    const checkIsTerminal = (id) => {
        let check = 0
        
        terminals.forEach(terminal => {
            terminal.interfaces.forEach(el => {
                if(el.use_id === id) check = 1
            })
        })

        return check
    } 

    const destroyInterface = (id) => {

        if(checkIsTerminal(id)) {
            toast.error('Não é possível excluir esta Interface pois no momento ela está sendo utilizada em Terminais.', {containerId: 'alert'})
            return
        }

        const temp = [...interfaces]
        let index = temp.findIndex(temp => temp._id === id)

        if(index >= 0 && temp.length>1){
            
            toast.promise(
                destroy('interface', id).then((res) => {
                    temp.splice(index, 1)
                    setInterfaces(temp)
                }),
                {
                    pending: 'Excluindo Interface...',
                    success: 'Interface excluída com sucesso!',
                    error: 'Erro: Não foi possível excluir a Interface'
                }, {containerId: 'alert'}
            )
        }
        else toast.error('É necessário manter pelo menos uma Interface', {containerId: 'alert'})
    }

    const handleBgChange = (id, url) => {
        const temp = [...interfaces]
        let index = temp.findIndex(temp => temp._id === id)

        setInterfaces( prev => {
            prev[index] = {...prev[index], bgUrl: url}
            return [...prev]
        })
    }

    const handleChangeName = (id, newName) => {
        if(!validateNewName(interfaces, newName)) return toast.error('Já existe uma Interaface com este nome', {containerId: 'alert'})

        handleChange(id, newName, 'name')
    }

    const handleDuplicate = (id) => {
        const temp = [...interfaces]
        const _interface = temp.find(temp => temp._id === id)

        toast.promise(
            create('interface', duplicateInterface(interfaces, _interface)).then((res) => {
                setInterfaces(interfaces => [...interfaces, res.data])  
                setTimeout(() => {scrollBottom()}, 100) 
            }),
            {
                pending: 'Duplicando Interface...',
                success: 'Nova Interface criada! Personalize suas configurações',
                error: 'Erro: Não foi possível criar uma nova Interface'
            }, {containerId: 'alert'}
        )
    }
    
    return(
        <>
            <Header>
                <HeaderLeft>
                    <Link to='../terminais'>
                        <Button text={'Terminais'} status={false}/>
                    </Link>
                    <Button text={'Interfaces'} status={true}/>
                    <VertLine/>
                    <AddButton text={'Nova-Interface'} onClick={addNewInterface}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={interfacesHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
            {
                loading ? 
                <Page center>
                    <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                </Page>
                :
                <Page ref={page}>
                    {
                        interfaces.map((Interface, index) => {
                            return <ShrinkBox 
                                key={index}
                                id={Interface._id}
                                name={Interface.name}
                                onChangeName={(id, name) => handleChangeName(id, name)}
                                onDuplicate={(id) => handleDuplicate(id)}
                                onDelete={(id) => destroyInterface(id)}
                                headerContent={<InterfacesBoxHeader id={Interface._id} terminals={terminals}/>}
                                >
                                    <InterfaceBox 
                                        data={Interface} 
                                        setValue={(id, state, property) => handleChange(id, state, property)}
                                        onChangeBg={(id, url) => handleBgChange(id, url)}
                                        onLoadPattern={(id, pattern) => handleLoadPattern(id, pattern)}
                                    />
                                </ShrinkBox>  
                            })
                    }
                </Page>
            }
        </>
    )
}

export default Interfaces