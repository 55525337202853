import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Box, Page, VertLine } from "../components/layout/Layout"
import AddButton from "../components/buttons/AddButton"
import UsersAtendTable from "../components/users/usersAtend/UsersAtendTable" 
import { useEffect, useState } from "react"
import { toast } from 'react-toastify';
import SelectIcon from "../components/form/SelectIcon"
import { CalculateStars } from "../services/Ratings";
import InfoTooltip from "../components/others/InfoTooltip"
import { userAtendHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import HeaderStats from '../components/others/HeaderStats'
import { update, create, destroy } from '../API/queries'
import { useContext } from "react"
import { DataContext } from "../contexts/DataProvider"
import PulseLoader from "react-spinners/PulseLoader"
import HeaderFixedMenu from "../components/headerFixedMenu"
import NotVerifiedAtendTable from "../components/users/usersAtend/NotVerifiedAtendTable"
import Modal from '../components/modals/Modal'
import GeneralModalContainer from "../components/modals/GeneralModalContainer"
import NewAttendantModal from "../components/users/usersAtend/NewAttendantModal"

function UsersAtend(){

    const { dataLoaded, attendants, setAttendants } = useContext(DataContext)

    const [loading, setLoading] = useState(true)
    const [sortBy, setSortBy] = useState('A-Z')

    const [showAddNew, setShowAddNew] = useState(false)

    useEffect(() => {
        if(dataLoaded) setLoading(false)
    },[dataLoaded])
    
    function sortAlphabetically(a,b) {
        return a.name.localeCompare(b.name)
    }
    
    function sortByDate(a,b) {
        if(a.since > b.since) return 1
        else return -1
    }
    
    function sortByRating(a,b) {
        if(CalculateStars(a.rating) < CalculateStars(b.rating)) return 1
        else return -1
    }

    function sortByStatus(arr) {
        let temp = []
        
        arr.forEach(e => {
            if(e.status === 'active') temp.push(e)
        })

        arr.forEach(e => {
            if(e.status === 'paused') temp.push(e)
        })

        arr.forEach(e => {
            if(e.status === 'inactive') temp.push(e)
        })

        return temp
    }
    
    const getSortedAttendants = (_attendants) => {

        let temp = [..._attendants]

        switch (sortBy) {
            case 'A-Z': return temp.sort(sortAlphabetically)  
            case 'Data de Início': return temp.sort(sortByDate)
            case 'Classificação': return temp.sort(sortByRating)
            case 'Status': return sortByStatus(temp)
            default: break;
        }
    }
    
    const handleChange = (id, value, property) => {
        const temp = [...attendants]
        let index = temp.findIndex(temp => temp._id === id)

        update('attendant', id, {[property]: value}).then((res) => {

            if(res.status === 200){
                setAttendants( prev => {
                    prev[index] = {...prev[index], [property]: value}
                    return [...prev]
                })
            }
            else toast.error(res, {containerId: 'alert'})
        }).catch((err) => {
            toast.error(err, {containerId: 'alert'})
        })
    }

    const deleteAttendant = (id) => {

        let temp = [...attendants]
        let index = temp.findIndex(attendant => attendant._id === id)

        if(index >= 0 && temp.length>1){
            
            toast.promise(
                destroy('attendant', id).then((res) => {
                    temp.splice(index, 1)
                    setAttendants(temp)
                }),
                {
                    pending: 'Excluindo Atendente...',
                    success: 'Atendente excluído com sucesso!',
                    error: 'Erro: Não foi possível excluir o Atendente'                
                },
                {containerId: 'alert'}
            )
        }
        else toast.error('É necessário manter pelo menos um Atendente', {containerId: 'alert'})        
    }

    const addNewAttendant = (data) =>{

        setShowAddNew(false)

        data.since = Date.now()

        toast.promise(
            create('attendant', data).then((res) => {
                setAttendants(attendants => [...attendants, res.data])  
            }),
            {
                pending: 'Criando Atendente...',
                success: 'Novo Atendente criado!',
                error: {
                    render({data}){
                        if(data.response.status === 403) return 'Erro: Este e-mail já está sendo utilizado por outro usuário.'
                        return 'Erro: Não foi possível criar um novo Atendente'
                    }
                }
            },
            {containerId: 'alert'}
        )
    }
    
    return(
        <>
            <Header>
                <HeaderLeft>
                    <AddButton text={'Novo-Atendente'} icon={''}  onClick={() => setShowAddNew(true)}/>
                    <VertLine space={5}/>
                    <SelectIcon icon={'filter'} options={['A-Z', 'Data de Início', 'Classificação', 'Status']} onChange={(value) => setSortBy(value)}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={userAtendHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
            {
                loading ? 
                <Page center>
                    <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                </Page>
                :
                <Page>
                    <Box align={'center'} height={'7rem'}>
                        <HeaderStats legend={['Total', 'de atendentes']} number={attendants.length}/>
                        <HeaderStats legend={['Atendentes', 'Online']} color={'#00C853'} number={attendants.filter(el => el.status === 'active').length}/>
                        <HeaderStats legend={['Atendentes', 'Em pausa']} color={'#FB9800'} number={attendants.filter(el => el.status === 'paused').length}/>
                        <HeaderStats legend={['Atendentes', 'Offline']} color={'#F34236'} number={attendants.filter(el => el.status === 'inactive').length}/>
                    </Box>
                    <UsersAtendTable
                        data={getSortedAttendants(attendants).filter(el => el.verified)}
                        onChange={(value, id, property) => handleChange(id, value, property)}
                        onDelete={(id) => deleteAttendant(id)}
                    />  
                    {
                        attendants.filter(el => !el.verified).length > 0 &&
                        <NotVerifiedAtendTable
                            data={attendants.filter(el => !el.verified)}
                            onDelete={(id) => deleteAttendant(id)}
                        />
                    }
                </Page>
            }
            <Modal isOpen={showAddNew}>
                <GeneralModalContainer onClose={() => setShowAddNew(false)}>
                    <NewAttendantModal
                        onAdd={(newAttendant) => addNewAttendant(newAttendant)}
                        onClose={() => setShowAddNew(false)}
                    />
                </GeneralModalContainer>
            </Modal>
        </>
    )
}

export default UsersAtend