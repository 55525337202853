import { Row, Icon } from "../../../layout/Layout"
import FormTitle from "../../../form/FormTitle"
import Select from "../../../form/Select"
import { BiMinusCircle } from 'react-icons/bi'
import AddButton from "./AddButton"
import { Tooltip } from 'react-tippy'
import { toast } from 'react-toastify'
import { useState } from "react"
import { useEffect } from "react"
import { servicesInfo } from "../../../../content/tooltips/terminalsTooltips"
import { useContext } from "react"
import { DataContext } from "../../../../contexts/DataProvider"
import { PlaceContext } from "../../../../contexts/PlaceContext"

export default function ServicesSection({data, onChange}){

    const { placeId } = useContext(PlaceContext)
    const {services: _services} = useContext(DataContext)

    const { services } = data

    const [options, setOptions] = useState([])

    useEffect(() => {
        setOptions(getOptions())
    },[placeId])
    
    const handleServiceSelect = (selected, index) => {
        
        const temp = [...services]
        temp[index] = options.find(el => el.label === selected).id
        
        onChange(temp)
    }
    
    const getNextService = () => {
        const remaining = options.filter(el => !services.includes(el.id))

        if(remaining.length > 0) return remaining[0].label
        return 0
    }
    
    const handleAddService = () => {
        
        if(services.length === 5) return toast.error('Não é permitido adicionar mais que cinco Serviços no mesmo Terminal', {containerId: 'alert'})
        
        const nextService = getNextService()
        
        if(!nextService) return toast.error('Não há outros Serviços cadastrados para adicionar a este Terminal', {containerId: 'alert'})
        
        handleServiceSelect(nextService, services.length)
    }

    const handleDeleteService = (index) => {
        const temp = [...services]

        temp.splice(index, 1)

        onChange(temp)
    }

    const getOptions = () => {
        const _options = _services.filter(el => el.placeId === placeId)

        return _options.map(el => {
            return {
                label: el.name,
                id: el._id
            }
        })
    }

    const getOptionByExcluding = (selected) => {

        const included = []

        services.forEach(el => {
            included.push(options.find(opt => opt.id === el)?.label)
        })

        let _options = options.map(el => el.label)

        _options = _options.filter(el => !included.includes(el))
        _options.unshift(selected)

        return _options
    }

    return(
        <>
            <FormTitle help={servicesInfo}>Serviços</FormTitle>  
            {
                services.map((service, index) => {
                    return <Row key={index} align={'center'}>
                        {
                            index !== 0 &&
                            <Tooltip arrow={true} delay={200} theme={'light'} html={'Excluir Serviço'}>
                                <Icon pointer color={'#555'} margin={'2px'} size={14} onClick={() => handleDeleteService(index)}>
                                    <BiMinusCircle/>
                                </Icon>
                            </Tooltip>
                        }
                        <Select width={'16rem'} label={`Serviço ${index+1}`} value={options.find(el => el.id === service)?.label} options={getOptionByExcluding(options.find(el => el.id === service)?.label)} onChange={(value) => handleServiceSelect(value, index)}/>
                    </Row>
                })
            }
            <AddButton text={'Adicionar Serviço'} onClick={() => handleAddService()}/>
        </>
    )
}