import styled from "styled-components";
import { Icon, Row } from "../../layout/Layout";
import { MdOutlineHomeWork } from "react-icons/md";
import { useContext } from "react";
import { PlaceContext } from "../../../contexts/PlaceContext";

const Select = styled.select`
    width: 100%;
    border: 1px solid #37474F;
    border-radius: 5px;
    height: 2.1rem;
    outline: none;
    color: white;
    background-color: #37474F;

    &:focus{
        border: 1px solid #00BFA5;
    }
`

const Option = styled.option`
    color: white;
`

export default function PlaceSelector({show}){

    const { placesOptions, placeId, setPlaceId } = useContext(PlaceContext)

    if(placesOptions.length === 1) return

    return (
        <Row align={'center'} padding={1} left={24} borderBtmStyle={'solid'} borderBtmWidth={1} borderBtmColor={'#455A64'}>
            <Icon size={24} color="white">
                <MdOutlineHomeWork/>
            </Icon>
            {
                show &&
                <Select value={placeId} onChange={(e) => setPlaceId(e.target.value)}>
                    {
                        placesOptions.map((option, index) => 
                        <Option key={index} value={option.id}>{option.name}</Option>
                        )
                    } 
                </Select>
            }
        </Row>
    )   
}