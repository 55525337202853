import styled from "styled-components";
import { Column, Row, Spacer, Text, Title } from "../../../layout/Layout";

const Thumb = styled.img`
    height: 12rem;
    object-fit: contain;
`

export default function ImagePreview({url}){
    
    return(
        <Column align={'center'}>
            <Title>Imagem que será exibida</Title>
            <Spacer top={1.5}/>
            {
                !url ?
                <Text>Nenhuma Imagem Carregada</Text>
                :
                <Thumb src={url}/>
            }
        </Column>
    )
}