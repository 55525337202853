import { Row, Spacer, Column, Icon, Text } from '../../layout/Layout'
import { useState } from "react";
import { Button } from '../../buttons/Button'
import { toast } from 'react-toastify';
import HelpIcon from "../../others/HelpIcon";
import { newServiceInfo } from "../../../content/tooltips/placesTootips";
import { TbLayoutGridAdd } from "react-icons/tb"
import LabelTextInput from "../../form/LabelTextInput";
import { MdOutlineAddToQueue } from 'react-icons/md'
import { useContext } from 'react';
import { DataContext } from '../../../contexts/DataProvider';
import { create } from '../../../API/queries';
import { PlaceContext } from '../../../contexts/PlaceContext';
import { newDeskInfo } from '../../../content/Tooltips';
import Select from '../../form/Select';
import { Number } from '../../layout/Form';
import { useEffect } from 'react';

export default function DeskAddNewModal({onCreate}){

    const { placeId } = useContext(PlaceContext)
    const { desks } = useContext(DataContext)
    
    const [name, setName] = useState('Guichê')
    const [number, setNumber] = useState(null)

    const nameOpts = ['Guichê', 'Caixa', 'Mesa', 'Estação', 'Sala', 'Consultório']

    const [invalidNumber, setInvalidNumber] = useState(false)

    const validateName = (name) => desks.filter(desk => desk.placeId === placeId).every(desk => desk.name !== name)

    useEffect(() => {
        if(number) setInvalidNumber(validateName(name + ' ' + number))
    },[name, number])
    

    const checkData = () => {
        if(!number){
            toast.error('Informe o número que deseja dar ao Guichê', {containerId: 'alert'})
            return 0
        }
        
        if(!invalidNumber){
            toast.error('Já existe um Guichê neste local com este número', {containerId: 'alert'})
            return 0
        }

        return 1
    }

    const handleCreate = () => {
        if(!checkData()) return
        
        onCreate({name, number})
    }
    
    return(
        <Column>
            <Row align={'center'} right={50}>
                <Icon size={24} color={'#00BFA5'} top={3}>
                    <MdOutlineAddToQueue/>
                </Icon>
                <Text size={16}>Novo Guichê</Text>
                <HelpIcon content={newDeskInfo}/>
            </Row>
            <Spacer top={1.5}/>
            <Select label={'Nome'} width={50} options={nameOpts} value={name} onChange={(value) => setName(value)}/>
            <Spacer top={0.5}/>
            <Row align={'center'} justify={'space-between'}>
                <Text>Número</Text>
                <Number id={'number'} type={'number'} min={1} max={100} width={35} onChange={(e) => setNumber(e.target.value)} valid={invalidNumber}/>
            </Row>
            <Spacer top={1.5}/>
            <Row justify={'center'} marginTop={24}>
                <Button width={90} height={30} radius={15} onClick={handleCreate}>Criar</Button>
            </Row>  
        </Column>
    )
}
