import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { HexToRgba } from '../../../services/HexToRgba'

function TotalsDoughnut({types, totals, colors = []}) {

    ChartJS.register(ArcElement, Tooltip, Legend);
  
    const options = {
      cutout: '70%',
      plugins: {
        legend: {
            display: false
        },
    },
      elements: {
        arc: {
          borderWidth: 0
        }
      }
    }
  
    const data = {
      labels: types,
      datasets: [
        {
          data: totals,
          backgroundColor: colors.map(color => HexToRgba(color)),
        },
      ],
    }
  
    return (
        <div className='doughnut_container'>
          <Doughnut data={data} options={options} />
        </div>
    )
  }
  
  export default TotalsDoughnut