import { VerticalBox, Column, Row, Title, Text, Table } from "../../layout/Layout"
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';
import { callsTableSenhaInfo, callsTableTypeInfo, callsTableCreatedInfo, callsTableAlertedInfo, callsTableDurationInfo, callsTableAttendantInfo, callsTableWaitedInfo, callsTableAnnotationInfo, callsTableServiceInfo } from '../../../content/Tooltips'
import CallRow from "./CallsRow";
import CallsTableTotals from "./CallsTableTotals";


function CallsTable({calls, unattended}){
    
    const sort = (a, b) => {
        if(a.alerted_at > b.alerted_at) return 1
        else return -1
    }
    
    return(
        <VerticalBox>
            <Row bottom={5} borderBtmWidth={1} borderBtmColor={'#37474F'} borderBtmStyle={'solid'} align={'flex-end'}>
                <Column width={5}></Column>
                <Column width={80}>
                    <Tooltip theme="light" arrow={true} html={callsTableSenhaInfo}>
                        <Title>Senha</Title>
                    </Tooltip>
                </Column>
                <Column>
                    <Tooltip theme="light" arrow={true} html={callsTableServiceInfo}>
                        <Title>Serviço</Title>
                    </Tooltip>
                </Column>
                <Column align={'center'}>
                    <Tooltip theme="light" arrow={true} html={callsTableTypeInfo}>
                        <Title>Tipo</Title>
                    </Tooltip>
                </Column>
                <Column align={'center'}>
                    <Tooltip theme="light" arrow={true} html={callsTableCreatedInfo}>
                        <Title>Emitida às</Title>
                    </Tooltip>
                </Column>
                <Column align={'center'} width={120}>
                    <Tooltip theme="light" arrow={true} html={callsTableAlertedInfo}>
                        <Title>Chamada às</Title>
                    </Tooltip>
                </Column>
                <Column align={'center'} width={120}>
                    <Tooltip theme="light" arrow={true} html={callsTableDurationInfo}>
                        <Title>Duração</Title>
                    </Tooltip>
                </Column>
                <Column width={120}>
                    <Tooltip theme="light" arrow={true} html={callsTableAttendantInfo}>
                        <Title>Atendida por</Title>
                    </Tooltip>
                </Column>
                <Column align={'center'} width={150}>
                    <Tooltip theme="light" arrow={true} html={callsTableWaitedInfo}>
                        <Title>Tempo de espera</Title>
                    </Tooltip>
                </Column>
                <Column align={'center'} width={50}>
                    <Tooltip theme="light" arrow={true} html={callsTableAnnotationInfo}>
                        <Title>Cometário</Title>
                    </Tooltip>
                </Column>
            </Row>
            <Table>
                {
                    calls.length === 0 ? 
                    <Row justify={'center'} align={'flex-end'} height={50}>
                        <Text size={16} color={'#78909C'} weigth={600}>No momento não há nenhuma senha atendida</Text>
                    </Row>
                    :
                    <>
                        {
                            calls.sort(sort).map((e,i) => {
                                return <CallRow key={i} data={e}/>
                            })
                        }
                        {
                            unattended.sort((a,b) => sort(a,b)).map((e,i) => {
                                return <CallRow key={i} data={e}/>
                            })  
                        }
                    </>
                }
            </Table>
            {   
                calls.length > 0 &&
                <Row align={'center'} justify={'flex-end'} height={50} marginTop={10}>
                    <CallsTableTotals data={calls}/>
                </Row>
            }
        </VerticalBox>
    )
}

export default CallsTable