import styled from "styled-components";

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    padding: ${props => props.padding}px;
    border: 1px solid #37474F;
    border-radius: ${props => props.radius}px;
    font-size: 12px;
    font-weight: 400;
    background-color: ${props => props.background ? props.background : 'white'};
    color: #37474F;
    cursor: pointer;

    &:hover{
        border: 1px solid #00BFA5;
    }

    &:active{
        background-color: #00BFA5;
        color: white;

        svg{
            color: white;
        }
    }
`