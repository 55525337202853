import { VerticalBox, Row, Column } from "../../layout/Layout"
import UsersAdminRow from "./UsersAdminRow"
import 'react-tippy/dist/tippy.css';
import { nameInfo, roleInfo, sinceInfo, emailInfo, statusInfo, actionInfo } from '../../../content/tooltips/userAdminTooltips'
import TableTitle from "../../form/TableTitle";

function UsersAdminTable({data, onDelete}){
    return (
        <VerticalBox>
            <Row bottom={5} borderBtmWidth={1} borderBtmColor={'#37474F'} borderBtmStyle={'solid'} align={'flex-end'}>
                <Column>
                    <TableTitle help={nameInfo}>Nome</TableTitle>
                </Column>
                <Column width={60}>
                    <TableTitle help={roleInfo}>Cargo</TableTitle>
                </Column>
                <Column width={60}>
                    <TableTitle help={sinceInfo}>Desde de</TableTitle>
                </Column>
                <Column>
                    <TableTitle help={emailInfo}>E-mail</TableTitle>
                </Column>
                <Column width={60}>
                    <TableTitle help={statusInfo}>Status</TableTitle>
                </Column>
                <Column width={80}>
                    <TableTitle help={actionInfo}>Ações</TableTitle>
                </Column>
            </Row>
            <Column>
                <UsersAdminRow data={data.find(e => e.master)}/>
                {
                    data.filter(e => !e.master).map((admin, index) => {
                        return <UsersAdminRow key={index} data={admin} onDelete={onDelete}/>
                    })
                }
            </Column>
        </VerticalBox>
    )
}

export default UsersAdminTable

