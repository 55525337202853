import { Column, Icon, Row, Text } from "../../layout/Layout"
import { BsChatSquareText } from 'react-icons/bs'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import { useState } from "react"
import TimeFormat from '../../../services/TimeFormat'
import { useContext } from "react"
import { DataContext } from "../../../contexts/DataProvider"


function CallRow({data}){

    const { attendants } = useContext(DataContext)

    const [showAnnotation, setShowAnnotation] = useState(false)

    const annotation = <p style={{textAlign: 'left'}}>
        {data.annotation}
    </p>

    return(
        <Row width={"100%"} height={35} borderBtmWidth={1} borderBtmColor={'#CFD8DC'} borderBtmStyle={'solid'} align={'center'} color={data.index === 0 ? '#E8F5E9' : (data.status === 'unattended') && '#FFEBEE'}>
            <Column width={5}></Column>
            <Column width={80}>
                <Text weight={600} color={data.prior ? '#FFA726' : '#00BFA5'}>{data.password}</Text>
            </Column>
            <Column overflow={'hidden'} textOverflow={'ellipsis'}>
                <Text>{data.service}</Text>
            </Column>
            <Column align={'center'}>
                <Text>{data.type}</Text>
            </Column>
            <Column align={'center'}>
                <Text>{new Date(data.created_at).toLocaleString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'})}</Text>
            </Column>
            <Column align={'center'} width={120}>
                <Text>{new Date(data.alerted_at).toLocaleString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'})}</Text>
            </Column>
            <Column align={'center'} width={120}>
                {
                    data.status === 'unattended' ? 
                    <Text weight={600} color={'#F34236'}>Não Atendida</Text>
                    :
                    <Text>{TimeFormat((data.finished_at - data.attended_at)/1000)}</Text>
                }
            </Column>
            <Column width={120}>
                <Text weight={600}>{attendants.find(el => el._id === data.attendant_id)?.name}</Text>
            </Column>
            <Column align={'center'} width={150}>
                <Text>{Math.floor((data.alerted_at - data.created_at)/60000)+' minutos'}</Text>
            </Column>
            <Column align={'center'} width={50}>
                {
                    data.annotation !== undefined ?
                    <Tooltip theme="light" trigger="click" open={showAnnotation} interactive={false} html={annotation} onRequestClose={() => setShowAnnotation(false)}>
                        <Icon size={16} color={'#00BFA5'} pointer onClick={() => setShowAnnotation(true)}>
                            <BsChatSquareText/>
                        </Icon>
                    </Tooltip>
                    :
                    <Icon size={16} color={'#888'}>
                        <BsChatSquareText/>
                    </Icon>
                }
            </Column>
        </Row>
    )
}

export default CallRow