import styled from "styled-components"
import { Row, Title } from "../../layout/Layout"

const Permission = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    border-radius: 0.5rem;
    padding: 0 0.5rem;
    margin: 0 0.4rem;
    background-color: #90A4AE;
    font-size: 0.85rem;
    font-weight: 500;
    color: white;
`

function UsersAdminRowPermissionDisplay({permissions}){
    
    return (
        <Row>
            <Title>Permissões: </Title>
            {
                permissions.map((e, i) => {
                    return <Permission key={i}>{e}</Permission>
                })
            }
        </Row>
    )
}

export default UsersAdminRowPermissionDisplay

