import { Row, Column, Icon, Text } from '../../layout/Layout'
import GrowthArrowUp from '../../../img/icons/GrowthArrowUp'
import GrowthArrowDown from '../../../img/icons/GrowthArrowDown'
import TimeFormat from '../../../services/TimeFormat'

function TimeChartRow({type, average, min, max, growth}){
    
    return(
        <Row align={'center'} justify={'flex-end'} height={25}>
            <Column width={37}>
                <Row align={'center'}>
                    <Icon height={8}>
                        {
                            growth ? 
                            <GrowthArrowUp/>
                            : 
                            <GrowthArrowDown/>
                        }
                    </Icon>
                    <Text size={11}>{type}</Text>
                </Row>
            </Column>
            <Column width={21} align={'center'}>
                <Text align={'center'} size={11}>
                    {TimeFormat(min/1000)}
                </Text>
            </Column>
            <Column width={21} align={'center'}>
                <Text align={'center'} size={12} weight={600} color={'#00BFA5'}>
                    {TimeFormat(average/1000)}
                </Text>
            </Column>
            <Column width={21} align={'center'}>
                <Text align={'center'} size={11}>
                    {TimeFormat(max/1000)}
                </Text>
            </Column>
        </Row>
    )
}

export default TimeChartRow