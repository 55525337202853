import styled from "styled-components"
import { IoMdAdd } from 'react-icons/io'

const AvatarCont = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    border: 2px solid ${props => props.color};
    background-image: url(${props => props.img});
    background-color: #B0BEC5;
    background-position: center;
    background-size: contain;
    cursor: pointer;
`

const File = styled.input`
    display: none;
`

const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    svg{
        color: #37474F;
        font-size: 20px;

        &:hover{
            color: #00BFA5;
        }
    }
`

export default function AvatarLoad({id, url, status, active, size, onChange}){
    
    const getColor = () => {
        if(!active) return 'white'

        switch (status) {
            case 'active': return '#00C853'
            case 'inactive': return '#F34236'
            case 'paused': return '#FB9800'
            default: break;
        }
    }
    
    return(
        <>
            <AvatarCont size={size} color={getColor()} htmlFor={'avatar'+id} img={url}>
                {
                    url === '' && <Icon><IoMdAdd/></Icon>
                }
            </AvatarCont>
            <File id={'avatar'+id} type={'file'} onChange={onChange}></File>
        </>
    )
}