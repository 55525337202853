import styled from "styled-components"
import { HexToRgba } from "../../../services/HexToRgba"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
`

const Screen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${props => props.bgUrl});
    background-image: linear-gradient(${props => props.gradientAngle + 'deg'}, ${props => props.gradientColors[0]}, ${props => props.gradientColors[1]});
    background-color: ${props => props.bgType === 'color' && props.color};
    background-size: contain;
    border: 1px solid #37474F;
    width: 300px;
    height: 533px;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width}%;
    height: ${props => props.height}%;
    border-style: ${props => props.stroke ? 'solid' : 'none'};
    border-color: ${props => props.strokeColor};
    border-width: ${props => props.strokeWidth}px;
    border-radius: ${props => props.radius}px;
    background-color: ${props => props.bg ? props => props.buttonColor : 'transparent'};
    font-size: ${props => props.textSize*2}px;
    font-weight: ${props => props.textWeight*100};
    color: ${props => props.textColor};
`

const Text = styled.h1`
    font-size: ${props => props.size*2}px;
    font-weight: ${props => props.weight*100};
    color: ${props => props.color};
    text-align: center;
`

const Spacer = styled.div`
    height: ${props => props.height}px;
`

function InterfacePreview({config}){
    
    return(
        <Wrapper>
            <Screen bgType={config.bgType} color={config.bgType === 'color' && config.bgColor} bgUrl={config.bgType === 'image' && config.bgUrl} gradientAngle={config.gradientAngle} gradientColors={config.bgType === 'gradient' ? config.gradientColors : []}> 
                {
                    config.showTopText && <Text size={config.topTextSize} color={config.topTextColor} weight={config.topTextWeight}>{config.topText}</Text> 
                }
                <Spacer height={100}/>
                <Button width={config.width}
                        height={config.height}
                        strokeWidth={config.strokeWidth}
                        radius={config.radius} stroke={config.stroke}
                        strokeColor={config.strokeColor}
                        bg={config.bg}
                        buttonColor={HexToRgba(config.buttonColor, config.buttonOpacity/10)}
                        textColor={config.textColor}
                        textSize={config.textSize}
                        textWeight={config.textWeight}
                        >Senha 1
                </Button>
                <Spacer height={50}/>
                <Button width={config.width}
                        height={config.height}
                        strokeWidth={config.strokeWidth}
                        radius={config.radius} stroke={config.stroke}
                        strokeColor={config.strokeColor}
                        bg={config.bg}
                        buttonColor={HexToRgba(config.buttonColor, config.buttonOpacity/10)}
                        textColor={config.textColor}
                        textSize={config.textSize}
                        textWeight={config.textWeight}
                        >Senha 2
                </Button>
                <Spacer height={100}/>
                {
                    config.showBottomText && <Text size={config.bottomTextSize} color={config.bottomTextColor} weight={config.bottomTextWeight}>{config.bottomText}</Text>
                }
            </Screen>
        </Wrapper>
    )
}

export default InterfacePreview