import { Icon, Row, Text } from "../../../../../layout/Layout";
import { AiOutlineYoutube } from 'react-icons/ai'
import { BiImages, BiBlock, BiMovie } from 'react-icons/bi'

export default function ExhibitionStamp({type, urls}){


    return(
        <>
            {
                type === 'none' ? 
                <Row align={'center'}>
                    <Icon size={20} color={'#78909C'}>
                        <BiBlock/>
                    </Icon>
                    <Text>
                        Sem exibição de mídia
                    </Text>
                </Row>
                :
                type === 'images' ? 
                <Row align={'center'}>
                    <Icon size={20} color={'#00BFA5'}>
                        <BiImages/>
                    </Icon>
                    <Text>
                        {`Exibe ${urls.length === 1 ? urls.length + ' Imagem' : urls.length + ' Imagens'}`}
                    </Text>
                </Row>
                :
                type === 'videos' ? 
                <Row align={'center'}>
                    <Icon size={20} color={'#00BFA5'}>
                        <BiMovie/>
                    </Icon>
                    <Text>
                        {`Exibe ${urls.length === 1 ? urls.length + ' Vídeo' : urls.length + ' Vídeos'}`}
                    </Text>
                </Row>
                :
                type === 'youtube' &&
                <Row align={'center'}>
                    <Icon size={22} color={'#00BFA5'}>
                        <AiOutlineYoutube/>
                    </Icon>
                    <Text>
                        Exibe um Vídeo do Youtube
                    </Text>
                </Row>
            }
        </>
    )
}