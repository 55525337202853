import { Icon, Row, Text, VertLine } from "../../layout/Layout"
import { Tooltip } from 'react-tippy'
import { AiOutlineLink } from 'react-icons/ai'

export default function PwPrintLayoutBoxHeader({id, terminals}){

    const linkedTerminalsString = (array) => {
        let str = ''

        array.forEach((el, index) => {
            str += el
            if(index < array.length - 2) str += ', '
            else if(index === array.length - 2) str += ' e '
        })

        return str
    }
    
    const getLinkedTerminals = () => {

        const names = []

        terminals.forEach(terminal => {
            terminal.print_layouts.forEach(el => {
                if(el.use_id === id && !names.includes(terminal.name)) names.push(terminal.name)
            })
        })

        if(names.length === 0) return 'Nenhum'

        return linkedTerminalsString(names)
    }
    
    return(
        <Row height={'100%'} align={'center'}>
            <VertLine/>
            <Tooltip arrow={true} theme={'light'} title='Terminais utilizando esse Layout'>
                <Row align={'center'}>
                    <Icon size={18} color={'#00BFA5'}>
                        <AiOutlineLink/>
                    </Icon>
                    <Text>{getLinkedTerminals()}</Text>
                </Row>
            </Tooltip>
        </Row>
    )
}