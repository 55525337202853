import CallsTable from "../components/dashboard/calls/CallsTable"
import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page } from "../components/layout/Layout"
import { BiUser, BiShow } from 'react-icons/bi'
import { MdOutlineWorkspaces } from 'react-icons/md'
import { useEffect, useState, useContext } from "react"
import { dashboardCallsHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import InfoTooltip from '../components/others/InfoTooltip'
import CheckBox from "../components/form/CheckBox"
import PulseLoader from "react-spinners/PulseLoader";
import { DataContext } from "../contexts/DataProvider"
import HeaderFixedMenu from "../components/headerFixedMenu"
import { CallsContext } from "../contexts/CallsProvider"
import { PlaceContext } from "../contexts/PlaceContext"
import SelectWithIcon from "../components/form/SelectWithIcon"

function DashboardCalls(){ 

    const { placeId } = useContext(PlaceContext)
    const { finished, unattended } = useContext(CallsContext)
    const { dataLoaded, attendants  } = useContext(DataContext)
    
    const [attendantsOptions, setAttendantsOptions] = useState([])
    const [attendantsIds, setAttendantsIds] = useState([])
    const [selectedAttendant, setSelectedAttendant] = useState('Todos')

    const [selectedService, setSelectedService] = useState('Todos')
    const [selectedType, setSelectedType] = useState('Todos')

    const [unattendedFilter, setUnattendedFilter] = useState(false)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(dataLoaded) {
            setLoading(false)
            getAttendantOptions()
        }
    },[dataLoaded])

    const getTypes = () => {
        const temp = [...finished]
        let types = ['Todos']

        temp.forEach(e => {
            if(!types.includes(e.type)) types.push(e.type)
        })

        return types
    }

    const getAttendantOptions = () => {
        const temp = [...attendants]
        let options = ['Todos']
        let ids = ['Todos']

        temp.forEach(e => {
            if(!options.includes(e.name)) {
                options.push(e.name)
                ids.push(e._id)
            }
        })

        setAttendantsOptions(options)
        setAttendantsIds(ids)
    }

    useEffect(() => {
        setSelectedType('Todos')
        setSelectedAttendant('Todos')
    },[selectedService])

    const getServices = () => {
        const temp = finished.filter(el => el.placeId === placeId)
        let _services = ['Todos']

        temp.forEach(e => {
            if(!_services.includes(e.service)) _services.push(e.service)
        })

        return _services
    }
    
    return(
        <>
            <Header>
                <HeaderLeft>
                    <SelectWithIcon
                        icon={<MdOutlineWorkspaces/>}
                        options={getServices()}
                        width={'13rem'}
                        value={selectedService}
                        onChange={(value) => setSelectedService(value)}
                    />
                    <SelectWithIcon
                        icon={<BiShow/>}
                        options={getTypes()}
                        width={'13rem'}
                        value={selectedType}
                        onChange={(filter) => setSelectedType(filter)}
                    />
                    <SelectWithIcon
                        icon={<BiUser/>}
                        options={attendantsOptions}
                        width={'13rem'}
                        value={selectedAttendant}
                        values={attendantsIds}
                        onChange={(option) => setSelectedAttendant(option)}
                    />
                    <CheckBox id={'show-unattended'} label={'Exibir senhas não atendidas'} checked={unattendedFilter} onCheck={(state) => setUnattendedFilter(state)}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={dashboardCallsHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
            <Page>
                {
                    loading ? 
                    <Page center>
                        <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                    </Page>
                    :
                    <CallsTable
                        calls={finished.filter(el => el.placeId === placeId).filter(el => {
                            if(selectedService !== 'Todos') return el.service === selectedService
                            return el
                        }).filter(el => {
                            if(selectedType !== 'Todos') return el.type === selectedType
                            return el
                        }).filter(el => {
                            if(selectedAttendant !== 'Todos') return el.attendant_id === selectedAttendant
                            return el
                        })
                        }
                        unattended={!unattendedFilter ? [] : unattended.filter(el => el.placeId === placeId).filter(el => {
                            if(selectedService !== 'Todos') return el.service === selectedService
                            return el
                        }).filter(el => {
                            if(selectedType !== 'Todos') return el.type === selectedType
                            return el
                        }).filter(el => {
                            if(selectedAttendant !== 'Todos') return el.attendant_id === selectedAttendant
                            return el
                        })
                        }
                    />
                }
            </Page>
        </>
    )
}

export default DashboardCalls