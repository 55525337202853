import styled from "styled-components"
import { TextInput, Label } from '../layout/Form'
import { Row } from '../layout/Layout'

const Toggle = styled.span`
    display: flex;
    align-items: center;
    height: 35px;

    h1{
        font-size: 12px;
        font-weight: 400;
        margin-left: ${props => props.margin}px;
    }

    input{
        border: 1px solid #37474F;
        border-radius: 5px;
        height: 25px;
        width: 50%;
        margin-left: auto;
        padding-left: 3px;
        outline: none;
        color: #37474F; 

        &:focus{
            border: 1px solid #00BFA5;
        }
    }
`

function LabelInput({id, text, width, max, input, placeholder, value, state, onBlur}){
    
    return(
        <Row height={35} align={'center'}>
            <Label>{text}</Label>
            <TextInput id={id} type="text" placeholder={placeholder && placeholder} width={width && width} maxLength={max} disabled={!state} value={value} onChange={(e) => {input(e.target.value)}} onBlur={(e) => onBlur(e.target.value)}/>
        </Row>
    )
}

export default LabelInput