import QueueTable from "../components/dashboard/fila/QueueTable"
import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page } from "../components/layout/Layout"
import { MdOutlineWorkspaces } from 'react-icons/md'
import { BiSortAlt2, BiShow } from 'react-icons/bi'
import { useEffect, useState, useContext } from "react"
import { DataContext } from "../contexts/DataProvider"
import { filaHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import InfoTooltip from '../components/others/InfoTooltip'
import CheckBox from "../components/form/CheckBox"
import { update } from '../API/queries'
import { toast } from 'react-toastify'
import PulseLoader from "react-spinners/PulseLoader";
import HeaderFixedMenu from "../components/headerFixedMenu"
import { CallsContext } from "../contexts/CallsProvider"
import { PlaceContext } from "../contexts/PlaceContext"
import SelectWithIcon from "../components/form/SelectWithIcon"

export default function DashboardQueue(){ 

    const { placeId } = useContext(PlaceContext)
    const { waiting, canceled, setWaiting } = useContext(CallsContext)
    const { dataLoaded } = useContext(DataContext)

    const [selectedService, setSelectedService] = useState('Todos')

    const [typeFilter, setTypeFilter] = useState('Todos')
    const [canceledFilter, setCanceledFilter] = useState(true)
    const [order, setOrder] = useState('por posição na Fila')

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(dataLoaded) setLoading(false)
    },[dataLoaded])

    useEffect(() => {
        setSelectedService('Todos')
        setTypeFilter('Todos')
    },[placeId])

    const getTypes = () => {
        const temp = waiting.filter(el => el.placeId === placeId)
        let types = ['Todos']
        
        console.log(temp)

        temp.forEach(e => {
            if(!types.includes(e.type)) types.push(e.type)
        })

        return types
    }

    const getServices = () => {
        const temp = waiting.filter(el => el.placeId === placeId)
        let _services = ['Todos']

        console.log(temp)

        temp.forEach(e => {
            if(!_services.includes(e.service)) _services.push(e.service)
        })

        return _services
    }

    const cancelCall = (id) => {

        let temp = [...waiting]

        const cancelOpts = {
            decrement: true,
            increment: false,
            returnDocument: 'before',
            alert: false,
            options: {
                index: null,
                status: "canceled",
                canceled_at: null
            }
        }

        cancelOpts.options.canceled_at = Date.now()

        toast.promise(
            update('calls', id, cancelOpts).then(() => {
                const index = temp.findIndex(el => el._id === id)
                temp.splice(index, 1)
                setWaiting(temp)
            }),
            {
                pending: 'Cancelando senha...',
                success: 'Senha cancelada com sucesso',
                error: 'Não foi possível cancelar a senha',
            },{containerId: 'alert'}
    )}
    
    
    return(
        <>
            <Header>
                <HeaderLeft>
                    <SelectWithIcon
                        icon={<MdOutlineWorkspaces/>}
                        options={getServices()}
                        width={'13rem'}
                        value={selectedService}
                        onChange={(value) => setSelectedService(value)}
                    />
                    <SelectWithIcon
                        icon={<BiShow/>}
                        options={getTypes()}
                        width={'13rem'}
                        value={typeFilter}
                        onChange={(filter) => setTypeFilter(filter)}
                    />
                    <SelectWithIcon
                        icon={<BiSortAlt2/>}
                        options={['por posição na Fila', 'por ordem de Emissão']}
                        width={'13rem'}
                        value={order}
                        onChange={(order) => setOrder(order)}
                    />
                    <CheckBox id={'show-canceled'} label={'Exibir senhas canceladas'} checked={canceledFilter} onCheck={(state) => setCanceledFilter(state)}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={filaHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
            <Page>
                {
                    loading ? 
                    <Page center>
                        <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                    </Page>
                    :
                    <QueueTable
                        waiting={selectedService === "Todos" ? waiting.filter(el => el.placeId === placeId) : waiting.filter(el => el.service === selectedService && el.placeId === placeId)}
                        canceled={selectedService === "Todos" ? canceled.filter(el => el.placeId === placeId) : canceled.filter(el => el.service === selectedService && el.placeId === placeId)}
                        order={order}
                        filter={typeFilter}
                        showCanceled={canceledFilter}
                        onCancel={(id) => cancelCall(id)}
                    />
                }
            </Page>
        </>
    )
}