import styled from 'styled-components';
import { useEffect } from 'react';
import { useState } from 'react';
import { Column, Text, Icon } from '../../../layout/Layout'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';
import { IoClose } from 'react-icons/io5'
import ConfirmationTooltip from "../../../others/ConfirmationTooltip"

const Row = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    border-top: ${props => props.index !== 0 && '1px solid #ECEFF1'};
    padding-left: 15px;

    &:hover{
        background-color: #ECEFF1;
    }
`

function PriorRulesTableRow({index, rule, onDelete}){

    const [properties, setProperties] = useState({})
    const [loading, setLoading] = useState(true)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    
    const defineProperties = () => {

        switch (rule.rule) {
            case 'immediate':
                setProperties({
                    text: 'Nesta Regra as senhas prioritárias serão chamadas imediatamente no próxima atendimento',
                    rule: 'Atendimento imediato',
                    unit: ''
                })
            break;
        
            case 'by_time':
                setProperties({
                    text: `Nesta Regra as senhas prioritárias serão chamadas em até ${rule?.value} minutos após a sua emissão`,
                    rule: 'Por tempo de espera',
                    unit: ' minutos'
                })
            break;
        
            case 'by_interval':
                setProperties({
                    text: `Nesta Regra as senhas prioritárias serão chamadas a cada ${rule?.value} senhas não-prioritárias`,
                    rule: 'Por intervalo de senhas',
                    unit: ' senhas'
                })
            break;
        
            case 'by_proportion':
                setProperties({
                    text: `Nesta Regra as senhas prioritárias serão chamadas quando houver uma proporção máxima de ${rule?.value}% das senhas não-prioritárias`,
                    rule: 'Por proporção máxima',
                    unit: '%'
                })
            break;
        
            default: break;
        }
    }

    useEffect(() => {
        defineProperties()
    },[])

    useEffect(() => {
        setLoading(false)
    },[properties])
    
    return(
        <>
            {
                !loading &&
                <Tooltip position={"right"} theme="dark" arrow={true} html={properties.text}>
                    <Row index={index}>
                        <Column width={25}>
                            <Text weight={600}>{rule.name}</Text>
                        </Column>
                        <Column width={40}>
                            <Text>{properties.rule}</Text>
                        </Column>
                        <Column width={25}>
                            <Text>{rule.value && rule?.value + properties?.unit}</Text>
                        </Column>
                        <Column width={10}>
                            <Tooltip theme="light" trigger="click" open={deleteConfirm} interactive={true} onRequestClose={() => setDeleteConfirm(false)} html={(<ConfirmationTooltip text={'Deseja Excluir?'} onYes={() => {onDelete(rule._id); setDeleteConfirm(false)}} onNo={() => setDeleteConfirm(false)}/>)}>
                                <Icon pointer size={18} color={'#F34236'} onClick={() => setDeleteConfirm(true)}>
                                    <IoClose/>
                                </Icon>
                            </Tooltip>
                        </Column>
                    </Row>
                </Tooltip>
            }
        </>
    )
}

export default PriorRulesTableRow