import styled from "styled-components"
import Yes from "../buttons/Yes";
import No from "../buttons/No";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const Text = styled.h1`
    font-size: 12px;
    font-weight: 400;
`

const Menu = styled.span`
    display: flex;
    justify-content: space-around;
`

const Btn = styled.button`
    width: 30px;
    height: 20px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 400;

    &:hover{
        font-weight: 600;
    }
`


function ConfirmationTooltip({text, onYes, onNo}){

    return(
        <Wrapper>
            <Text>{text}</Text>
            <Menu>
                <Btn onClick={onYes}>Sim</Btn>
                <Btn onClick={onNo}>Não</Btn>
            </Menu>
        </Wrapper>
    )
}

export default ConfirmationTooltip
