import styled from "styled-components";
import { BsX } from 'react-icons/bs'

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: ${props => props.align && props.align};
    justify-content: ${props => props.justify && props.justify};
    background-color: white;
    border-radius: 5px;
    height: 'fit-content';
    max-height: ${props => props.maxHeight};
    width: ${props => props.width};
    max-width: ${props => props.width};
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #37474F;
    padding: 3rem;
`

const QuitIcon = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;

    svg{
        color: #90A4AE;
        font-size: 2rem;

        &:hover{
            color: #888;
        }
    }
`

export default function GeneralModalContainer({children, onClose}){


    return (
        <Container>
            {children}
            <QuitIcon onClick={onClose}>
                <BsX/>
            </QuitIcon>
        </Container>
    )
}