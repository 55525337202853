import { IoClose } from 'react-icons/io5'
import { useState } from "react"
import { toast } from "react-toastify"
import { Text, Title, Column, Row, Icon } from "../../layout/Layout"
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import ConfirmationTooltip from "../../others/ConfirmationTooltip"
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';


export default function NotVerifiedAtendRow({data, onDelete}){
    
    const [deleteConfirm, setDeleteConfirm] = useState(false)

    const handleDelete = () => {
        setDeleteConfirm(false)

        if(data.status !== 'inactive') return toast.error('Você não pode excluir este atendente pois no momento ele está logado no sistema.', {containerId: 'alert'})
        
        onDelete(data._id)
    }
    
    return (
        <Row height={40} align={'center'} borderBtmWidth={1} borderBtmColor={'#CFD8DC'} borderBtmStyle={'solid'}>
            <Column width={120}>
                <Text>
                    {data.name}
                </Text>
            </Column>
            <Column>
                <Text>
                    {new Date(data.since).toLocaleString([], {day: '2-digit', month:'2-digit', year:'numeric'})}
                </Text>
            </Column>
            <Column width={120}>
                <Text>
                    {data.email}
                </Text>
            </Column>
            <Column>
                <Title>
                    Aguardando...
                </Title>
            </Column>
            <Column>
                <Row align={'center'} justify={'flex-end'}>
                    <Tooltip theme="light" trigger="click" open={deleteConfirm} interactive={true} onRequestClose={() => setDeleteConfirm(false)} html={(<ConfirmationTooltip text={'Deseja Excluir?'} onYes={() => handleDelete()} onNo={() => setDeleteConfirm(false)}/>)}>
                        <Tippy content='Excluir Atendente'>
                            <Icon size={18} color={'#F34236'} pointer={true} onClick={() => setDeleteConfirm(true)}>
                                <IoClose/>
                            </Icon>
                        </Tippy>
                    </Tooltip>
                </Row>
            </Column>
        </Row> 
    )
}

