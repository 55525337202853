import styled from "styled-components";
import { Row, Column, Icon, Text } from '../../layout/Layout'
import { AiOutlineLineChart } from 'react-icons/ai'
import LastHourDisplay from "./LastHourDisplay";
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { useEffect } from "react";
import { getFlowStats } from "../../../services/attendanceFlow";
import LinesLegend from "./LinesLegend";

const Box = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    height: 100%;
    padding: 0 15px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #37474F;
`

const Body = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

function FluxoAtendimento({data, typeColors, legend}){
    
    const calcHourAverage = (data) => {
        const now = Date.now()
        const hour = 3600000
        const counts = []
        let jumpOut = 0

        for(let i=0; i<24; i++){
            let count = 0
            const min = hour * i      
            const max = hour * (i+1)  
            
            data.forEach(call => {
                const elapsed = now - call.finished_at
                if(elapsed > min && elapsed < max){
                    count += 1
                    jumpOut += 1
                }
            })
        
            counts.push(count)

            if(jumpOut === data.length) break
        }

        const average = counts.reduce((a, b) => a + b, 0) / counts.length
        const beforeAverage = counts.slice(0, -1).reduce((a, b) => a + b, 0) / (counts.length - 1)

        return {
            average: average.toFixed(1),
            growth: average > beforeAverage
        }
        
    }

    useEffect(() => {
        calcHourAverage(data)
    },[])

    const calcLastHour = (data) => {
        const now = Date.now()
        let lastHourCount = 0
        let hourBeforeCount = 0

        data.forEach(call => {
            const elapsed = now - call.finished_at
            
            if(elapsed < 3600000) lastHourCount += 1
            else if(elapsed > 3600000 && elapsed < 7200000) hourBeforeCount += 1
        })

        return {
            count: lastHourCount,
            growth: lastHourCount > hourBeforeCount
        }
    }

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
      
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 10
        },
        plugins: {
          legend: {display: false},
          title: {display: false},
          decimation: {
            enable: false
          }
        },
        elements: {
            line: {
                borderWidth: 1
            }
        },
      };
      
    return(
        <Box>
            <Row align={'center'} justify={'space-between'} height={65}>
                <Row align={'center'} width={'fit-content'}>
                    <Icon size={22} color={'#00BFA5'}>
                        <AiOutlineLineChart/>
                    </Icon>
                    <Column>
                        <Text>Fluxo de Atendimento</Text>
                        {
                            legend &&
                            <Text size={10.5} weight={200}>{legend}</Text>
                        }
                    </Column>
                </Row>
                <LastHourDisplay lastHour={calcLastHour(data).count} lastHourGrowth={calcLastHour(data).growth} average={calcHourAverage(data).average} averageGrowth={calcHourAverage(data).growth}/>
                <LinesLegend typeColors={typeColors} calls={data}/>
            </Row>
            <Body>
                {
                    data.length > 0 ?
                    <Line options={options} data={getFlowStats(data, typeColors)}/>
                    :
                    <Column justify={'center'} align={'center'} height={100}>
                        <Text color={'#90A4AE'} size={13} weight={500}>Ainda não há nenhum</Text>
                        <Text color={'#90A4AE'} size={13} weight={500}>atendimento neste local</Text>
                    </Column>
                }
            </Body>             
        </Box>
    )
}

export default FluxoAtendimento