import { Icon, Row, Text } from "../../../../../layout/Layout";
import { HiOutlineVolumeUp, HiOutlineVolumeOff } from 'react-icons/hi'

export default function AlertStamp({state}){


    return(
        <>
            {
                state ? 
                <Row align={'center'}>
                    <Icon size={18} color={'#00BFA5'}>
                        <HiOutlineVolumeUp/>
                    </Icon>
                    <Text>
                        Emite Alerta Sonoro
                    </Text>
                </Row>
                :
                <Row align={'center'}>
                    <Icon size={18} color={'#78909C'}>
                        <HiOutlineVolumeOff/>
                    </Icon>
                    <Text>
                        Sem Alerta Sonoro
                    </Text>
                </Row>
            }
        </>
    )
}