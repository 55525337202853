import { toast } from "react-toastify"
import { Column, Row, Icon, Spacer, Text } from "../../layout/Layout"
import { TextInput } from "../../layout/Form"
import { AiOutlineUserAdd } from 'react-icons/ai'
import { validateEmailString } from '../../../methods/validateEmailString'
import { validateNewName } from '../../../methods/validateNewName'
import { useContext } from 'react'
import { DataContext } from '../../../contexts/DataProvider'
import { useState } from "react"
import { Button } from "../../buttons/Button"

export default function NewAttendantModal({onAdd, onClose}){

    const { attendants } = useContext(DataContext)

    const [newAttendant, setNewAttendant] = useState({})

    const handleChange = (property, value) => setNewAttendant(prev => ({...prev, [property]: value}))

    const checkData = () => {
        if(!newAttendant.name || !newAttendant.email){
            toast.error(`Preencha corretamente as informações do novo atendente.`, {containerId: 'alert'})
            return 0
        }
        if(!validateNewName(attendants, newAttendant.name)) {
            toast.error(`O nome ${newAttendant.name} já está sendo utilizado.`, {containerId: 'alert'})
            return 0
        }
        if(!validateEmailString(newAttendant.email)) {
            toast.error(`O e-mail informado é inválido. Insira um endereço válido.`, {containerId: 'alert'})
            return 0
        }

        return 1
    }

    const handleConfirm =  () => {
        
        if(!checkData()) return 

        onAdd(newAttendant)
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') handleConfirm()
        if(e.key === 'Escape') onClose()
    }

    return (
        <Column width={'18rem'}>
            <Row align={'center'}>
                <Icon size={32} color={'#00BFA5'}>
                    <AiOutlineUserAdd/>
                </Icon>
                <Text weight={600} size={14} color={'#455A64'}>Novo Atendente</Text>
            </Row>
            <Spacer top={2}/>
            <Text>Nome do Atendente</Text>
            <TextInput
                type={'text'}
                placeholder={'Digite o Nome'}
                width={'100%'}
                onChange={(e) => handleChange("name", e.target.value)}
                onKeyDown={(e) => handleKeyPress(e)}
            />
            <Spacer top={1}/>
            <Text>E-mail do Atendente</Text>
            <TextInput
                type={'text'}
                placeholder={'Digite o E-mail'}
                width={'100%'}
                onChange={(e) => handleChange("email", e.target.value)}
                onKeyDown={(e) => handleKeyPress(e)}
            />
            <Spacer top={2}/>
            <Row justify={'center'}>
                <Button width={80} height={25} radius={15} onClick={() => handleConfirm()}>Adicionar</Button>
            </Row>
        </Column>
    )
}

