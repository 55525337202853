export const getTypeColors = (calls) => {
    const colors = {}
    
    const priors = []
    const nonPriors = []

    const oranges = ['#FFB74D', '#FF9800', '#F57C00', '#E65100', '#FFCC80', '#FFA726', '#FB8C00', '#EF6C00']
    const greens = ['#00BFA5', '#26A69A', '#00897B', '#4DB6AC', '#00796B', '#80CBC4', '#00695C', '#B2DFDB']
    let greenIndex = 0, orangeIndex = 0

    calls.forEach(call => {
        if(call.prior && !priors.includes(call.type)) priors.push(call.type)
        if(!call.prior && !nonPriors.includes(call.type)) nonPriors.push(call.type)
    })

    priors.forEach(type => {
        colors[type] = oranges[orangeIndex]
        orangeIndex += 1
    })

    nonPriors.forEach(type => {
        colors[type] =  greens[greenIndex]
        greenIndex += 1
    })

    return colors
}