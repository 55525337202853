import styled from "styled-components";

export const Page = styled.div`
    display: ${props => props.center && 'flex'};
    align-items: ${props => props.center && 'center'};
    justify-content: ${props => props.center && 'center'};
    padding: 15px;
    height: 100%;
    max-width: 100%;
    overflow-y: auto;
`

export const NoScrollPage = styled.div`
    display: ${props => props.center ? 'flex' : 'block'};
    align-items: ${props => props.center ? 'center' : 'initial'};
    justify-content: ${props => props.center ? 'center' : 'initial'};
    padding: 15px;
    height: 100vh;
    max-width: 100%;
    overflow: hidden;
`

export const FixedPage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    height: 100vh;
    max-height: 100vh;
`

export const VertLine = styled.div`
    height: ${props => props.height ? props.height : 70}%;
    min-width: ${props => props.space ? props.space : 2.5}rem;
    margin-left: ${props => props.space ? props.space : 2.5}rem;
    border-left: 1px solid ${props => props.color ? props.color : '#CFD8DC'};
`

export const VerticalLine = styled.div`
    height: 100%;
    min-width: 30px;
    border-right: 1px solid #B0BEC5;
    margin-right: 30px;
`

export const Box = styled.div`
    display: flex;
    position: relative;
    flex-direction: ${props => props.direction};
    align-items: ${props => props.align && props.align};
    justify-content: ${props => props.justify && props.justify};
    background-color: white;
    border-radius: 5px;
    height: ${props => props.height ? props.height : 'fit-content'};
    max-height: ${props => props.maxHeight};
    width: ${props => props.width};
    max-width: ${props => props.width};
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #37474F;
    padding: ${props => props.padding ? props.padding : '40px 30px'};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '1.5rem'};
`
export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.width};
    background-color: white;
    border-radius: 5px;
    height: 230px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #37474F;
    padding: 15px;
    margin: ${props => props.margin ? props.margin : '0 15px 15px 0'};
`

export const VerticalBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    height: fit-content;
    max-height: ${props => props.maxHeight ? props.maxHeight : '100%'};
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #37474F;
    padding: 40px 30px;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.align && props.align};
    justify-content: ${props => props.justify && props.justify};
    margin: ${props => props.margin}px;
    margin-left: ${props => props.marginLeft && props.marginLeft}px;
    margin-right: ${props => props.marginRight && props.marginRight}px;
    padding-left: ${props => props.paddingLeft && props.paddingLeft}px;
    padding-right: ${props => props.paddingRight && props.paddingRight}px;
    padding: ${props => props.padding}px;
    width: ${props => (typeof props.width === 'string' ? props.width : typeof props.width === 'number' ? props.width+'\%' : 100+'\%')};
    height: ${props => props.height && props.height}%;
    border-bottom: ${props => props.borderBtm && '1px solid #B0BEC5'};
    border-left: ${props => props.borderLeft && '1px solid #B0BEC5'};
    padding-bottom: ${props => props.paddingBtm && props.paddingBtm+'px'};
    padding-top: ${props => props.paddingTop && props.paddingTop+'px'};
    text-overflow: ${props => props.textOverflow || undefined};
    overflow: ${props => props.overflow || undefined};
`

export const Row = styled.span`
    display: flex;
    align-items: ${props => props.align ? props.align : 'none'};
    justify-content: ${props => props.justify ? props.justify : 'none'};
    width: ${props => props.width ? props.width : "100%"};
    height: ${props => props.height && props.height}px;
    height: ${props => (typeof props.height === 'string' ? props.height : typeof props.height === 'number' && props.height + 'px')};
    min-height: ${props => props.height && props.height}px;
    padding: ${props => props.padding}rem;
    padding-top: ${props => props.top && props.top+'px'};
    padding-bottom: ${props => props.bottom && props.bottom+'px'};
    padding-left: ${props => props.left && props.left+'px'};
    padding-right: ${props => props.right && props.right+'px'};
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBtm}px;
    border-bottom-style: ${props => props.borderBtmStyle && props.borderBtmStyle};
    border-bottom-width: ${props => props.borderBtmWidth && props.borderBtmWidth+'px'};
    border-bottom-color: ${props => props.borderBtmColor && props.borderBtmColor};
    background-color: ${props => props.color && props.color};
    position: ${props => props.position};
    transition: background-color 0.3s;
    cursor: ${props => props.pointer && 'pointer'};

    &:hover{
        background-color: ${props => props.hoverColor};
    }
`

export const Spacer = styled.div`
    margin-left: ${props => props.left}rem;
    margin-right: ${props => props.right}rem;
    margin-top: ${props => props.top}rem;
    margin-bottom: ${props => props.bottom}rem;
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px 0 15px;
    height: 30px;
    border: 1px solid #37474F;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 400;
    background-color: white;
    margin: 10px;
    color: #37474F;
    cursor: pointer;

    svg{
        font-size: 16px;
        margin-right: 5px;
    }

    &:hover{
        border: 1px solid #00BFA5;
    }

    &:active{
        background-color: #00BFA5;
        color: white;

        svg{
            color: white;
        }
    }
`

export const Text = styled.h1`
    display: flex;
    align-items: ${props => props.align ? props.align : 'none'};
    justify-content: ${props => props.justify ? props.justify : 'none'};
    font-size: ${props => props.size ? props.size : 12}px;
    font-weight: ${props => props.weight ? props.weight : 400};
    color: ${props => props.color ? props.color : '#37474F'};
    text-align: ${props => props.align};
    text-decoration: ${props => props.decoration || undefined};
    cursor: ${props => props.pointer && 'pointer'};
    margin: ${props => props.margin}px;
    margin-bottom: ${props => props.bottom}px;
    margin-left: ${props => props.left};
    margin-right: ${props => props.right};
    white-space: ${props => props.wrap ? props.wrap : 'nowrap'};
    height: ${props => props.height};
    text-overflow: ${props => props.textOverflow || undefined};
    overflow: ${props => props.overflow || undefined};
`

export const TextLink = styled.label`
    font-size: 1rem;
    font-weight: 400;
    color: #1565C0;
    text-decoration: underline;
    cursor: pointer;
    white-space: ${props => props.wrap};

    &:hover{
        font-weight: 500;
    }
`

export const Link = styled.a`
    font-size: 1rem;
    font-weight: 400;
    color: #1565C0;
    text-decoration: underline;
    cursor: pointer;
    white-space: ${props => props.wrap};

    &:hover{
        font-weight: 500;
    }
`

export const P = styled.p`
    font-size: ${props => props.size ? props.size : 12}px;
    font-weight: ${props => props.weight ? props.weight : 400};
    color: ${props => props.color ? props.color : '#37474F'};
    text-align: ${props => props.align && props.align};
    margin: ${props => props.margin && props.margin}px;
`

export const Title = styled.h1`
    display: flex;
    align-items: center;
    font-size: ${props => props.size ? props.size : 12}px;
    font-weight: 600;
    color: ${props => props.color ? props.color : '#37474F'};
    text-align: center;
`

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const Table = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #CFD8DC;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`

export const Border = styled.div`
    display: flex;
    flex-direction: ${props => props.direction ? props.direction : 'column'};
    margin: 5px 0;
    margin-top: ${props => props.top};
    padding: ${props => props.padding ? props.padding : '20'}px;
    padding-left: ${props => props.left}px;
    border-radius: 10px;
    border: 1px solid ${props => props.border ? props.border : '#37474F'};
    width: 100%;
    background-color: ${props => props.background};
`

export const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${props => props.margin ? props.margin : '5px'};
    padding-top: ${props => props.top && props.top}px;
    padding-bottom: ${props => props.bottom && props.bottom}px;
    padding-left: ${props => props.left && props.left}px;
    padding-right: ${props => props.right && props.right}px;
    cursor: ${props => props.pointer && 'pointer'}; 
    transform: rotate(${props => props.rotate});
    height: ${props => props.height && props.height}px;
    width: ${props => props.width ? props.width + 'px' : 'fit-content'};
    
    svg{
        color: ${props => props.color ? props.color : '#37474F'};
        font-size: ${props => props.size && props.size}px;
        height: ${props => props.height && props.height}px;
        width: ${props => props.width && props.height}px;

        path{
            fill: ${props => props.fill};
        }

        &:hover{
            color: ${props => props.hoverColor};
        }
    }
`

export const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    border-radius: 50%;
    background-color: ${props => props.color};
    opacity: ${props => props.opacity};
    margin: 5px;
`

export const Position = styled.div`
    position: absolute;
    top: ${props => props.top}px;
    bottom: ${props => props.bottom}px;
    right: ${props => props.right}px;
    left: ${props => props.left}px;
`

export const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid ${props => props.color ? props.color : '#CFD8DC'};
    margin-bottom: ${props => props.height/2}rem;
    margin-top: ${props => props.height/2}rem;
`

export const Scroll = styled.div`
    display: flex;
    flex-direction: column;
    max-height: ${props => props.maxHeight};
    overflow-y: auto;
    padding: 0.5rem;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #CFD8DC;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`

export const PageSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justify};
    padding: ${props => props.padding}rem;
    margin-top: ${props => props.top}rem;
    margin-bottom: ${props => props.bottom}rem;
    overflow-y: auto;
    height: ${props => props.height};
    max-height: ${props => props.maxHeight};

    &::-webkit-scrollbar{
        width: 10px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #B0BEC5;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`