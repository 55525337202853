import styled from "styled-components"

const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 5px;
    margin: 5px; 
    border-radius: 0.5rem;
    background-color: ${props => props.status ? '#00C853' : !props.status && '#F34236'};
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
`

export default function DeviceStatus({status, count}){

    return(
        <Status status={status}>
            {
                status ?
                `Conectado${count > 1 ? ' x'+count : ''}`
                :
                'Desconectado'
            }
        </Status>
    )
}