import { Row, Column, Button, Text, TextLink } from '../../../layout/Layout'
import { File } from '../../../layout/Form'
import { useRef, useState } from 'react'
import MoonLoader from "react-spinners/MoonLoader"
import { toast } from "react-toastify";
import { create } from '../../../../API/queries'
import { BsUpload } from 'react-icons/bs'

export default function UploadImage({url, onChange}){

    const [loading, setLoading] = useState(false)

    const inputRef = useRef()

    const handleUpload = (e) => {

        setLoading(true)

        e.preventDefault()

        const formData = new FormData();

        formData.append('file', e.target.files[0])

        create('upload/image', formData).then(res => {
            setLoading(false)
            onChange(res.data.url)
        }).catch(err => {
            setLoading(false)
            toast.error(`Não foi possível carregar a Imagem. Error: ${err}`, {containerId: 'alert'})
        })

        e.target.value = ''

    }

    return(
        <Column>
            <Row justify={'center'}>
                {
                    !loading ? 
                    <Button onClick={() => inputRef.current.click()}>
                        <BsUpload/>
                        {
                            !url ? 'Carregar Imagem' : 'Substituir Imagem'
                        }
                    </Button>
                    :
                    <Button>
                        <MoonLoader color={'black'} loading={true} size={16}/>
                        <Text wrap={'pre'} weight={600}> Carregando...</Text>
                    </Button>
                }
                <File ref={inputRef} id={'image_upload'} type={'file'} accept=".jpg,.png,.gif" onChange={(e) => handleUpload(e)}/>
            </Row>
            <Row justify={'center'}>
                {
                    !url ?
                    <Text wrap={'normal'} weight={600}>Nenhuma imagem carregada</Text>
                    :
                    <Column align={'center'}>
                        <Text wrap={'normal'} weight={600}>{'Imagem carregada'}</Text>
                        <TextLink onClick={() => onChange('')}>Excluir Imagem</TextLink>
                    </Column>
                }
            </Row>
        </Column>
    )
}