import { Box, Typography } from "@mui/material";
import { LuConstruction } from "react-icons/lu";

export default function ServicesAnalyticsPage() {
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                color: '#B0BEC5',
            }}
        >
            <LuConstruction size={48} />
            <Typography variant="h4" mt={2}>
                Em Breve
            </Typography>
        </Box>
    );
}
