import styled from "styled-components";

export const SelectField = styled.select`
    border: 1px solid #37474F;
    border-radius: 5px;
    height: 2.1rem;
    width: ${props => (typeof props.width === 'string' ? props.width : typeof props.width === 'number' ? props.width+'%' : '50%')};
    outline: none;
    font-style: ${props => props.italic && 'italic'};
    color: ${props => props.color ? props.color : '#37474F'};
    margin-left: ${props => props.left ? props.left : 'auto'};

    &:focus{
        border: 1px solid #00BFA5;
    }
`

export const Option = styled.option`
    font-style: ${props => props.textStyle && props.textStyle};
    color: ${props => props.color ? props.color : '#37474F'};
`

export const TextInput = styled.input`
    border: 1px solid #37474F;
    border-radius: 5px;
    height: 2.1rem;
    width: ${props => (typeof props.width === 'string' ? props.width : typeof props.width === 'number' ? props.width+'%' : '50%')};
    margin-left: auto;
    padding-left: 3px;
    outline: none;
    color: #37474F; 
    background-color: ${props => props.background};

    &:focus{
        border: 1px solid #00BFA5;
    }
`

export const PasswordStrengthInput = styled.input`
    border: 1px solid #37474F;
    border-radius: 5px;
    height: 25px;
    width: ${props => (typeof props.width === 'string' ? props.width : typeof props.width === 'number' ? props.width+'%' : '50%')};
    margin-left: auto;
    padding-left: 3px;
    outline: none;
    color: #37474F; 
    background-color: ${props => props.background};

    &:focus{
        border: 1px solid ${props => props.strength === 'strong' ? '#00C853': props.strength === 'medium' ? '#FB9800' : props.strength === 'weak' && '#F34236'};
    }
`

export const Textarea = styled.textarea`
    resize: none;
    width: 100%;
    height: ${props => props.height}px;
    min-height: ${props => props.height}px;
    border-radius: 5px;
    outline: none;
    padding: 10px;

    &:focus{
        border: 1px solid #00BFA5;
    }
`
export const File = styled.input`
    display: none;
`

export const Input = styled.input`
    width: ${props => props.width}%;
    height: 25px;
    outline: none;
    border-radius: 5px;
    border: 1px solid ${props => props.error ? '#F34236' : '#00C853'};
    margin-left: ${props => props.left ? props.left : '5px'};
    margin-right: ${props => props.right};
    padding-left: 3px;

    &:focus{
        border: 1px solid ${props => props.valid ? '#00C853' : '#F34236'};
    }
`

export const DateInput = styled.input`
    width: ${props => props.width}rem;
    height: 25px;
    outline: none;
    border: 1px solid #37474F;
    border-radius: 5px;
    margin: 0 0.5rem;
    padding-left: 3px;

    &:focus{
        border: 1px solid #00C853;
    }
`

export const Number = styled.input`
    width: ${props => props.width}%;
    height: 25px;
    outline: none;
    border-radius: 5px;
    border: 1px solid ${props => props.valid ? '#37474F' : '#F34236'};
    margin-left: ${props => props.left ? props.left : '5px'};
    margin-right: ${props => props.right};
    padding-left: 3px;

    &:focus{
        border: 1px solid ${props => props.valid ? '#00BFA5' : '#F34236'};
    }
`

export const Label = styled.label`
    align-items: ${props => props.align ? props.align : 'none'};
    justify-content: ${props => props.justify ? props.justify : 'none'};
    font-size: ${props => props.size ? props.size : 12}px;
    font-weight: ${props => props.weight ? props.weight : 400};
    color: ${props => props.color ? props.color : '#37474F'};
    text-align: ${props => props.align};
    text-decoration: ${props => props.decoration};
    cursor: ${props => props.pointer && 'pointer'};
    margin: ${props => props.margin}px;
    margin-bottom: ${props => props.bottom}px;
    margin-left: ${props => props.left};
    white-space: ${props => props.wrap ? props.wrap : 'nowrap'};
    height: ${props => props.height};
`

export const Checkbox = styled.input`
    margin: 0 5px 0 20px;
`

export const Check = styled.input`
    margin: 0 5px 0 0px;
    accent-color: #00BFA5;
`