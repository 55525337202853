import ReactMarkdown from 'react-markdown';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledMarkdown = styled(ReactMarkdown)(({ theme }) => ({
    '& h1, & h2, & h3': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    '& p': {
        marginBottom: theme.spacing(2),
        lineHeight: 1.6,
    },
    '& ul': {
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
    },
    '& li': {
        marginBottom: theme.spacing(1),
    },
    '& code': {
        backgroundColor: theme.palette.action?.hover,
        padding: '0.2em 0.4em',
        borderRadius: '4px',
    },
}));

export default function MarkdownRenderer({ content }) {
    return (
        <Box sx={{ overflowY: 'auto', maxHeight: '100%' }}>
            <StyledMarkdown>
                {content}
            </StyledMarkdown>
        </Box>
    );
}

