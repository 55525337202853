import styled from "styled-components"

const Wrapper = styled.span`
    display: flex;
    align-items: center;
    height: 35px;
    width: 100%;

    label{
        font-size: 12px;
        font-weight: 400;
    }
`

const Load = styled.span`
    display: flex;
    align-items: center;
    margin-left: auto;

    input{
        display: none;
    }

    label{
        font-size: 12px;
        font-weight: 400;
        text-decoration: underline;
        color: ${props => props.state ? '#1565C0' : '#B0BEC5'};
        cursor: ${props => props.state && 'pointer'};
    }
`

export default function LabelLoad({id, label, state, onLoad}){
    
    return(
        <Wrapper>
            <label>{label}</label>
            <Load state={state}>
                <input id={'file'+id} type="file" disabled={!state} onChange={(e) => onLoad(e)}/>
                <label id='file_label' htmlFor={'file'+id}>Carregar</label>   
            </Load>
        </Wrapper>
    )
}