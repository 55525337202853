import styled from 'styled-components'

export const Card = styled.div`
    display: grid; 
    grid-template-columns: 70% 30%;
    grid-template-rows: 70% 30%;
    background-color: white;
    border-radius: 5px;
    height: 230px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #37474F;
    padding: 15px;
`

export const Desk = styled.div`
    grid-column: 1/2;
    grid-row: 1/2;
    width: 100%;
    height: 100%;
`

export const User = styled.div`
    grid-column: 2/3;
    grid-row: 1/2;
    width: 100%;
    height: 100%;
`

export const Info = styled.div`
    display: flex;
    grid-column: 1/3;
    grid-row: 2/3;
    width: 100%;
    height: 100%;
    border-top: 1px solid #B0BEC5;
`