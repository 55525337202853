import styled from "styled-components"
import placeHolder from '../../img/Blank-profile.png'

const AvatarCont = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.size ? props.size : 5}rem;
    height: ${props => props.size ? props.size : 5}rem;
    border-radius: 50%;
    border: 2px solid ${props => props.color ? props.color : 'white'};
    background-image: url(${props => props.img ? props.img : placeHolder});
    background-color: #B0BEC5;
    background-position: center;
    background-size: contain;
`

export default function AvatarWithStatus({url, status, size}){
    
    const getColor = () => {
        switch (status) {
            case 'active': return '#00C853'
            case 'inactive': return '#F34236'
            case 'paused': return '#FB9800'
            default: break;
        }
    }
    
    return(
        <AvatarCont size={size} color={getColor()} img={url}/>
    )
}