import { Icon, Row, Text } from "../../../../../layout/Layout";
import { BsFileArrowUp } from 'react-icons/bs'

export default function ShowMarqueeStamp({state}){


    return(
        <>
            {
                state ? 
                <Row align={'center'}>
                    <Icon size={20} color={'#00BFA5'} rotate={'-90deg'}>
                        <BsFileArrowUp/>
                    </Icon>
                    <Text>
                        Exibe Texto Rotativo
                    </Text>
                </Row>
                :
                <Row align={'center'}>
                    <Icon size={20} color={'#78909C'} rotate={'-90deg'}>
                        <BsFileArrowUp/>
                    </Icon>
                    <Text>
                        Sem Texto Rotativo
                    </Text>
                </Row>
            }
        </>
    )
}