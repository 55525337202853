import styled from "styled-components";
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { Text } from "../layout/Layout";

const Starts = styled.span`
    display: flex;
    align-items: center;
    color: #ffc82a;
    padding-top: 2px;
`

export default function RatingStarsFixed({stars, count}){


    const starsFill = () => {
        return [...Array(stars)].map((e, i) => <AiFillStar key={i}/>)
    }

    const starsOutline = () => {
        return [...Array(5 - stars)].map((e, i) => <AiOutlineStar key={i}/>)
    }

    return(
        <Starts>
            {starsFill()}
            {starsOutline()}
            <Text>{`(${count})`}</Text>
        </Starts>
    )
}
