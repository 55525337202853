export const avatarInfo = <p style={{textAlign: 'left'}}>
Foto ou imagem que identifica o <strong>Atendente</strong>. Clique para carregar um arquivo ou se preferir pode deixar vázio.
</p>

export const nameInfo = <p style={{textAlign: 'left'}}>
Nome do <strong>Atendente</strong>. 
</p>

export const ratingInfo = <p style={{textAlign: 'left'}}>
A <strong>Classificação do Atendente</strong> é coletada pelos <strong>Teclados de Avaliação de Atendimento</strong> em cada Guichê, se disponíveis. Os votos são computados pelo sistema e o resultado é exibido aqui.<br/><br/>
Os Atendentes podem ser classificados com notas de 1 a 5, representados por uma estrela cada, e a quantidade de votos recebidas é exibida entre parenteses ao lado das estrelas.   
</p>

export const sinceInfo = <p style={{textAlign: 'left'}}>
Data que o <strong>Atendente</strong> foi cadastrado no sistema.
</p>

export const emailInfo = <p style={{textAlign: 'left'}}>
Este é o <strong>e-mail</strong> que o <strong>Atendente</strong> irá utilizar para realizar o login no <strong>Painel de Atendimento</strong>, e também será utilizado para enviar mensagens.
</p>

export const activeInfo = <p style={{textAlign: 'left'}}>
Indica o status atual da <strong>Permissão do Atendente</strong>. Atendentes bloqueados não poderão acessar o Painel de Atendimento.
</p>

export const actionsInfo = <p style={{textAlign: 'left'}}>
<strong>Detalhes: </strong> Ao clicar em detalhes você terá acesso às <strong>informações mais importantes de cada Atendente</strong>. <br/><br/>
<strong>Permitir/Bloquear: </strong> Ao clicar neste botão você alterará a <strong>Permissão do Atendente.</strong> Atendentes Bloqueados não poderão fazer login no <strong>Painel de Atendimento.</strong><br/><br/>
<strong>Excluir: </strong> Ao clicar em excluir o <strong>Atendente</strong> será excluído e todos os seus dados serão perdidos.
</p>

export const notVerifiedInfo = <p style={{textAlign: 'left'}}>
Este são os atendentes que forão cadastrados e <strong>ainda não estão ativos</strong>.<br/><br/>
Para um atendente se tornar ativo e começar a utilizar o Painel de Atendimento do Sistema Phila, ele deverá <strong>acessar o link que foi enviado para o e-mail cadastrado</strong>. 
</p>