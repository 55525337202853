import styled from 'styled-components'
import { Row, Column, Box, Icon, Text } from '../../layout/Layout'
import ClockIcon from '../../../img/icons/ClockIcon'
import TimeChartRow from './TimeChartRow'
import { useEffect, useState } from 'react'

const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: auto;
  max-height: 100%;
  height: fit-content;
  width: 100%;

  &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #CFD8DC;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`

export default function WaitingTimeChart({calls, legend}){
    
    const [stats, setStats] = useState([])

    const getTimes = () => {

        let types = [], stats = []
        
        calls.forEach(el => {
            if(!types.includes(el.type)) types.push(el.type)
        })

        for(let i = 0; i<types.length; i++){

            let times = []
            let min = 999999999, max = 0
            
            calls.filter(el => el.status === 'finished').forEach(el => {
                if(el.type === types[i]) {
                    const time = el.alerted_at - el.created_at
                    times.push(time)
                    if(time < min) min = time
                    if(time > max) max = time
                }
            })

            const _average = times.reduce((a, b) => a + b, 0) / times.length;
            const averageBefore = times.slice(0, -1).reduce((a, b) => a + b, 0) / (times.length - 1);
            
            stats.push({type: types[i], average: _average, min, max, growth: _average < averageBefore})        
        }

        setStats(stats)
    }

    useEffect(() => {
        getTimes()
    },[calls])

    return(
        <Box height={'100%'} padding={'15px'} marginBottom={'0px'}>
            <Column height={100}>
                <Row align={'center'}>
                    <Icon height={20}>
                        <ClockIcon/>
                    </Icon>
                    <Column>
                        <Text>Tempo de Espera</Text>
                        {
                            legend &&
                            <Text size={10.5} weight={200}>{legend}</Text>
                        }
                    </Column>
                </Row>
                {
                    stats.length === 0 ? 
                    <Column justify={'center'} align={'center'} height={100}>
                        <Text color={'#90A4AE'} size={13} weight={500}>Ainda não há nenhum</Text>
                        <Text color={'#90A4AE'} size={13} weight={500}>atendimento neste local</Text>
                    </Column>
                    :
                    <Column justify={'flex-end'} height={100}>
                        <Row justify={'flex-end'} height={20}>
                            <Column width={21} align={'center'}>
                                <Text>Min</Text>
                            </Column>
                            <Column width={21} align={'center'}>
                                <Text>Média</Text>
                            </Column>
                            <Column width={21} align={'center'}>
                                <Text>Max</Text>
                            </Column>
                        </Row>
                        <Table>
                            {
                                stats.map((e, i) => <TimeChartRow key={i} type={e.type} average={e.average} min={e.min} max={e.max} growth={e.growth}/>)
                            }
                        </Table>
                    </Column>
                }
            </Column>
        </Box>
    )
}