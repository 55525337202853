import { Column, Page, Row, Spacer, Text, Icon, Line } from "../components/layout/Layout";
import { IoIosArrowDown } from 'react-icons/io'
import { MdOutlineMapsHomeWork, MdOutlineAdminPanelSettings } from 'react-icons/md'
import BusinessAccountBox from "../components/myAccount/BusinessAccountBox";
import AdminAccountBox from "../components/myAccount/AdminAccountBox";
import { useState } from "react";
import Invoices from "../components/finan/Invoices";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthProvider";

export default function MyAccount(){

    const [showBusinessAccount, setShowBusinessAccount] = useState(false)
    const [showAdminAccount, setShowAdminAccount] = useState(false)

    const { userAdmin } = useContext(AuthContext)

    return(
        <Page>
            <Row justify={'center'}>
                <Column height={100} width={"80%"} paddingTop={10}>
                    <Row align={'center'}>
                        <Icon size={28} color={'#78909C'}>
                            <MdOutlineMapsHomeWork/>
                        </Icon>
                        <Text size={16} weight={500}>Conta da Empresa</Text>
                        <Icon pointer size={18} hoverColor={'#00BFA5'} rotate={!showBusinessAccount ? '-90deg' : undefined} onClick={() => setShowBusinessAccount(prev => !prev)}>
                            <IoIosArrowDown/>
                        </Icon>
                    </Row>
                    <Spacer top={1}/>
                    {
                        showBusinessAccount && <BusinessAccountBox/>
                    }
                    <Line height={2}/>
                    <Row align={'center'}>
                        <Icon size={28} color={'#78909C'}>
                            <MdOutlineAdminPanelSettings/>
                        </Icon>
                        <Text size={16} weight={500}>Conta do Administrador</Text>
                        <Icon pointer size={18} hoverColor={'#00BFA5'} rotate={!showAdminAccount ? '-90deg' : undefined} onClick={() => setShowAdminAccount(prev => !prev)}>
                            <IoIosArrowDown/>
                        </Icon>
                    </Row>
                    <Spacer top={1}/>
                    {
                        showAdminAccount && <AdminAccountBox/>
                    }
                    <Line height={2}/>
                    {
                        userAdmin.permissions.includes('Financeiro') &&
                        <Invoices/>
                    }
                </Column>
            </Row>
        </Page>
    )
}