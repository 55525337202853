import styled from "styled-components"
import blank from '../../img/Blank-profile.png'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: ${props => props.show ? '20rem': '100%'};
    background-color: #263238;
    transition: all 0.3s ease-in-out;
    position: relative;
    box-shadow: ${props => props.show && '2px 0 10px 0 rgba(0,0,0,0.7)'};
`

export const MenuScroll = styled.div`
    overflow-y: ${props => !props.show ? 'hidden' : undefined};
    overflow-x: hidden;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;              
    }

    &::-webkit-scrollbar-thumb{
        background: #37474F;
        border: 3px solid #263238;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #455A64;
    }
`

export const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.show ? 'flex-start' : 'center'};
    height: 4rem;
    width: 100%;
    background-color: ${props => props.selected && '#37474F'};
    transition: all 0.4 ease-in-out;
    position: relative;
    cursor: pointer;
    padding-left: ${props => props.subitem && props.show && '1rem'};
    margin-top: ${props => !props.subitem && '2rem'};

    &:hover{
        background-color: #37474F;
    }
`

export const ItemLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
    font-size: 1rem;
    font-weight: ${props => props.selected ? '400' : '200'};
    width: ${props => props.show ? '100%' : 0};
    overflow: hidden;
    opacity: ${props => props.show ? 1 : 0};
    transition: width 0.4s linear;
    white-space: nowrap;
`

export const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${props => props.show ? '1rem' : '2rem'};
    transition: all 0.4s linear;
    
    svg{
        color: ${props => props.selected ? '#00BFA5' : 'white'};
        font-size: ${props => props.iconSize}rem;
        height: ${props => props.iconSize}rem;
        width: ${props => props.iconSize}rem;

        path{
            fill: ${props => props.selected && !props.extIcon && '#00BFA5'};
        }
    }
`

export const Arrow = styled.div`
    display: ${props => !props.show && 'none'};
    color: white;
    margin: 1rem;
    transform: rotate(${props => props.selected && '90deg'});
    transition: all 0.2s linear;;
`

export const MenuSection = styled.div`
    display: ${props => props.selected ? 'flex' : 'none'};
    flex-direction: column;
    height: fit-content;
    background-color: #263238;
    transition: all 0.3s ease-in-out;
    margin-bottom: 2rem;
`

export const LoggedAccountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #455A64;
    padding: 1rem ;
    position: relative;
`

export const Avatar = styled.label`
    min-width: 4rem;
    min-height: 4rem;
    border-radius: 50%;
    background-image: url(${props => props.url ? props.url : blank});
    background-color: #B0BEC5;
    background-position: center;
    background-size: contain;
    cursor: pointer;
`

export const AccountInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.show ? '100%' : '0'};
    opacity: ${props => props.show ? '1' : '0'};
    transition: all 0.4s linear;
    padding-left: 1rem;
    padding-right: 1rem;
    pointer-events: ${props => !props.show && 'none'};
    overflow: hidden;
`

export const AccountName = styled.div`
    font-size: 1rem;
    font-weight: 500;
    color: white;
    white-space: nowrap;
`

export const UserName = styled.div`
    font-size: 1rem;
    font-weight: 300;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const AccountLink = styled.div`
    font-size: 1rem;
    font-weight: 400;
    color: #2196F3;
    text-decoration: underline;
    white-space: nowrap;
    cursor: pointer;
    width: fit-content;

    &:hover{
        color: #1976D2;
        font-weight: 500;
    }
`

export const LogoutIcon = styled.div`
    display: ${props => !props.show && 'none'};
    transition: all 0.4s linear;
    position: absolute;
    right: 0.5rem;
    cursor: pointer;

    svg{
        color: white;
        font-size: 1.5rem;

        &:hover{
            color: #1976D2;
        }
    }
`