import { createContext, useContext, useEffect, useState } from 'react'
import { DataContext } from './DataProvider'

export const PlaceContext = createContext({})

export const PlaceProvider = ({children}) => {

    const { places } = useContext(DataContext)

    const [placeId, _setPlaceId] = useState(null)
    const [placesOptions, setPlacesOptions] = useState([])

    const setPlaceId = (id) => {  
      _setPlaceId(id)
      sessionStorage.setItem('placeId', JSON.stringify(id))
    }

    useEffect(() => {

      if(places && !placeId) {
        const recoveredId = sessionStorage.getItem('placeId')

        if(recoveredId && places.map(el => el._id).includes(recoveredId)) setPlaceId(JSON.parse(recoveredId))
        else  setPlaceId(places.find(el => el.main)._id)

        setPlacesOptions(places.map(el => {
          return {
            name: el.name,
            id: el._id
          }
        }))
      }

    },[places])
    
    return (
        <PlaceContext.Provider value={{placeId, setPlaceId, placesOptions}}>
            {children}
        </PlaceContext.Provider>
    )
}