import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import TabPanel from "../../components/others/TabPanel";
import AttendantsSection from "./attendantsSection";
import ComparativeSection from "./comparativeSection";
import IntelligentReportSection from "./intelligentReportSection";


export default function ReportContent(){

    const [ section, setSection ] = useState(0)

    return (
        <>
            <Tabs value={section} onChange={(_, newValue) => setSection(newValue)} sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <Tab label="Atendentes" />
                <Tab label="Comparativo" />
                <Tab label="Relatório inteligente" />
            </Tabs>
            <TabPanel value={section} index={0}>
                <AttendantsSection/>
            </TabPanel>
            <TabPanel value={section} index={1}>
                <ComparativeSection/>        
            </TabPanel>
            <TabPanel value={section} index={2}>
                <IntelligentReportSection/>
            </TabPanel>
        </>
    )
}