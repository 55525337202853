import styled from "styled-components"
import { Row } from "../layout/Layout"
import HelpIcon from "../others/HelpIcon"

const Title = styled.h1`
    font-size: 1rem;
    font-weight: 600;
    color: #37474F;
`

export default function TableTitle({children, center, help}){

    return(
        <Row align={'center'} justify={center && 'center'}>
            <Title>
                {children}
            </Title>
            {
                help &&
                <HelpIcon content={help}/>
            }
        </Row>
    )
}