import { Column, Line, Row, Scroll, TextLink } from "../layout/Layout";
import NotifDisplayRow from "./NotifDisplayRow";

export default function NotifDisplay({data, onNavigate}){

    

    return(
        <Column>
            <Scroll maxHeight={'25rem'}>
                {
                    data.map((e, i) => {
                        return <NotifDisplayRow key={i} data={e}/>
                    })
                }
            </Scroll>
            <Line/>
            <Row align={'center'} justify={'center'} height={'2rem'}>
                <TextLink onClick={() => onNavigate()}>
                    Ver Todas Notificações
                </TextLink>
            </Row>
        </Column>
    )
}