import { NoExhibitionContainer, Text, Spacer } from "../styles"
import { getColor } from "../../../../../../content/Colors"

export default function NoExhibition({theme}){

    return(
        <NoExhibitionContainer>
            <Text color={getColor(theme, 800)} size={1.5} weight={400}>Senha</Text>
            <Text color={getColor(theme, 800)} size={3.5} weight={700}>NR005</Text>
            <Spacer top={2}/>
            <Text color={getColor(theme, 800)} size={1.5} weight={400}>Guichê</Text>
            <Text color={getColor(theme, 800)} size={3.5} weight={700}>Guichê 1</Text>
        </NoExhibitionContainer>
    )
}