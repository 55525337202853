import { useEffect, useState } from "react"
import styled from "styled-components"
import { Column, Row, Text } from '../../../layout/Layout'

const Grid = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border-top: ${props => props.index === 0 && '1px solid #B0BEC5'};
    border-bottom: 1px solid #B0BEC5;
`

const Bar = styled.div`
    width: ${props => props.size}%; 
    height: 85%;
    background-color: ${props => props.color};
    margin: 0 5px;
    transition: 1s ease-in-out;
`

function HorizontalBar({index, data}){

    const [width, setWidth] = useState(1)

    useEffect(() => {
        setWidth((data.size*85)/100)
    })
    
    return(
        <Row height={'100%'} align={'center'}>
            <Grid index={index}>
                <Bar size={width} color={data.color}/>
                <Text size={11} weight={600}>{data.data}</Text>
            </Grid>
        </Row>
    )
}

export default HorizontalBar