import styled from "styled-components"
import ToggleSwitch from "./ToggleSwitch"
import { Text, Icon } from '../layout/Layout'
import { HiOutlineVolumeUp } from 'react-icons/hi'
import { BsEye } from 'react-icons/bs'
import { Tooltip } from 'react-tippy'
import HelpIcon from '../others/HelpIcon'

const Toggle = styled.span`
    display: flex;
    align-items: center;
    height: 35px;
    width: 100%;

    label{
        font-size: 12px;
        font-weight: 400;
    }
`

const Load = styled.span`
    display: flex;
    align-items: center;
    margin-left: auto;

    input{
        display: none;
    }

    label{
        font-size: 12px;
        font-weight: 400;
        text-decoration: underline;
        color: ${props => props.state ? '#1565C0' : '#B0BEC5'};
        cursor: ${props => props.state && 'pointer'};
    }
`

function ToggleLoad({id, icon, text, state, help, isLoaded, onToggle, onLoad, onPlay}){
    
    return(
        <Toggle>
            <ToggleSwitch id={'toggle'+id} isToggled={state} setIsToggled={onToggle}/>
            <label htmlFor={'toggle'+id}>{text}</label>
            {
                help && <HelpIcon content={help}/>
            }
            <Load state={state}>
                <input id={'file'+id} type="file" disabled={!state} onChange={(e) => onLoad(e)}/>
                <label id='file_label' htmlFor={'file'+id}>Carregar</label>
                <Tooltip arrow={true} delay={200} theme={'light'} html={icon === 'eye' ? 'Visualizar' : icon === 'audio' && 'Ouvir'}>
                    <Icon pointer={isLoaded && state} size={14} color={isLoaded && state ? '#1565C0' : '#888'} onClick={() => state && onPlay()}>
                        {
                            icon === 'eye' ? <BsEye/>
                            : icon === 'audio' && <HiOutlineVolumeUp/>
                        }
                    </Icon>
                </Tooltip>     
            </Load>
        </Toggle>
    )
}

export default ToggleLoad