import _ from 'lodash'
import timeFormat from '../../../../services/TimeFormat'

const getLabelById = (id, desks) => {
    const label = _.find(desks, desk => desk._id === id)
    if(label) return label.name
}

const getIds = (calls) => {
    let ids = []

    _.forEach(calls, call => {
        if(!ids.includes(call.desk_id)) ids.push(call.desk_id)
    })

    return ids
}

const getCallsById = (id, calls) => {
    return _.filter(calls, call => call.desk_id === id)
}

const getColor = (count, index) => {
    const colors = ['#00897B', '#00BFA5', '#26A69A', '#4DB6AC', '#80CBC4', '#FFE082', '#FFD54F', '#FFCA28', '#FFC107', '#FFB300']
    
    const mappedIndex = Math.floor((index*colors.length)/count)
    
    return colors[mappedIndex]
}

const getAverageById = (id, calls) => {
    const _calls = getCallsById(id, calls)
    let times = [] 

    _.forEach(_calls, call => {
        times.push(call.finished_at - call.attended_at)
    })

    return times.reduce((a, b) => a + b, 0) / times.length;
}

const getTotalAverage = (calls) => {
    let times = [] 

    _.forEach(calls, call => {
        times.push(call.finished_at - call.attended_at)
    })

    return times.reduce((a, b) => a + b, 0) / times.length;
}

const getSize = (highest, average) => {
    return (average/highest)*100
}

const sortByAverage = (a,b) => {
    if(a.average < b.average) return 1
    else return -1
}

export const getDatasets = (desks, calls) => {

    const dataset = (label, average, data, size) => {
        return {
            label: label,
            average: average,
            data: data,
            size: size,
        }
    }
    
    const ids = getIds(calls)
    const dataCount = ids.length

    let datasets = [], averages = [], colorIndex = 0

    const totalAverage = getTotalAverage(calls)
    averages.push(totalAverage)

    _.forEach(ids, id => {
        averages.push(getAverageById(id, calls))
    })

    const highest = Math.max(...averages)
    
    _.forEach(ids, id => {
        const average = getAverageById(id, calls)
        datasets.push(dataset(getLabelById(id, desks), average, timeFormat(average/1000), getSize(highest, average)))
    })

    datasets = datasets.sort(sortByAverage)

    _.forEach(datasets, dataset => {
        _.assign(dataset, {color: getColor(dataCount, colorIndex)})
        colorIndex += 1
    })

    datasets.unshift(dataset('Geral', totalAverage, timeFormat(totalAverage/1000), getSize(highest, totalAverage)))
    _.assign(datasets[0], {color: '#01579B'})
    
    return datasets
}