
// Checa se algum objeto de um array já possui um atributo com o 'value' passado,
// excluindo o objeto com o ID passado
export const checkForRepeatedObjValues = (array, property, value, id) => {
    let result = false
    
    array.forEach(el => {
        if(el[property] === value && el._id !== id) result = true
    })

    return result
}

// Checa se algum objeto do array que possui o mesmo prefixo do objeto passado possui conflito de intervalo
export const checkIntervalForPrefix = (array, objToCheck, id) => {
    let result = false
    
    const temp = array.filter(el => el.prefix === objToCheck.prefix && el._id !== id)
    let objRange = []
    
    for(let i=objToCheck.start; i<=objToCheck.restart; i++) objRange.push(i)

    temp.forEach(el => {
        let elRange = []
        for(let i=el.start; i<=el.restart; i++) elRange.push(i)

        objRange.forEach(value => {
            if(elRange.includes(value)) result = true
        })
    })

    return result
}

export const checkIntervalForPrefixNew = (array, objToCheck) => {
    let result = false
    
    const temp = array.filter(el => el.prefix === objToCheck.prefix)
    let objRange = []
    
    for(let i=objToCheck.start; i<=objToCheck.restart; i++) objRange.push(i)

    temp.forEach(el => {
        let elRange = []
        for(let i=el.start; i<=el.restart; i++) elRange.push(i)

        objRange.forEach(value => {
            if(elRange.includes(value)) result = true
        })
    })

    return result
}