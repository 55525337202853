import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page } from "../components/layout/Layout"
import AddButton from "../components/buttons/AddButton"
import { useState, useContext, useEffect } from "react"
import { toast } from 'react-toastify';
import UsersAdminTable from "../components/users/usersAdmin/UsersAdminTable"
import InfoTooltip from "../components/others/InfoTooltip"
import { userAdminHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import { DataContext } from "../contexts/DataProvider";
import { AuthContext } from "../contexts/AuthProvider";
import PulseLoader from "react-spinners/PulseLoader";
import { create, destroy } from "../API/queries";
import HeaderFixedMenu from "../components/headerFixedMenu"
import Modal from "../components/modals/Modal";
import GeneralModalContainer from "../components/modals/GeneralModalContainer";
import NewAdminModal from "../components/users/usersAdmin/NewAdminModal";

function UsersAdmin(){

    const { userAdmin } = useContext(AuthContext)
    const { admins, setAdmins, dataLoaded } = useContext(DataContext)

    const [showAddNew, setShowAddNew] = useState(false)
    
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(dataLoaded) setLoading(false)
    },[dataLoaded])

    const deleteAdmin = (id) => {

        if(id === userAdmin._id) {
            toast.error('Você não pode excluir seu próprio usuário', {containerId: 'alert'})
            return
        }

        const temp = [...admins]
        let index = temp.findIndex(temp => temp._id === id)
 
        if(index < 0) return

        toast.promise(
            destroy('admin', id).then((res) => {
                temp.splice(index, 1)
                setAdmins(temp)
            }),
            {
                pending: 'Excluindo Administrador...',
                success: 'Administrador excluído com sucesso!',
                error: 'Erro: Não foi possível excluir o Administrador'
            }, {containerId: 'alert'}
        )
        
    }

    const addNew = (data) =>{

        setShowAddNew(false)

        data.since = Date.now()

        toast.promise(
            create('admin', data).then((res) => {
                setAdmins(prev => [...prev, res.data])  
            }),
            {
                pending: 'Criando Administrador...',
                success: 'Novo Administrador criado!',
                error: {
                    render({data}){
                        if(data.response.status === 403) return 'Erro: Este e-mail já está sendo utilizado por outro usuário.'
                        return 'Erro: Não foi possível criar um novo Administrador'
                    }
                }
            }, {containerId: 'alert'}
        )       
    }

    return(
        <>
            <Header>
                <HeaderLeft>
                    <AddButton text={'Novo-Administrador'} icon={''} onClick={() => setShowAddNew(true)}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={userAdminHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
            {
                loading ? 
                <Page center>
                    <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                </Page>
                :
                <Page>
                    <UsersAdminTable
                        data={admins}
                        onDelete={(id) => deleteAdmin(id)}
                    />           
                </Page>
            } 
            <Modal isOpen={showAddNew}>
                <GeneralModalContainer onClose={() => setShowAddNew(false)}>
                    <NewAdminModal
                        onAdd={(newAdmin) => addNew(newAdmin)}
                    />
                </GeneralModalContainer>
            </Modal>
        </>
    )
}

export default UsersAdmin