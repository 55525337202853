import { Spacer, Line } from "../../../layout/Layout"
import Select from "../../../form/Select"
import FormTitle from "../../../form/FormTitle"
import AddButton from "./AddButton"
import Modal from "../../../modals/Modal"
import AddInterfaceModal from "../../../modals/AddInterfaceModal"
import { useState } from "react"
import ScheduledResourseRow from "./ScheduledResourseRow"
import { interfacesInfo } from "../../../../content/tooltips/terminalsTooltips"

export default function InterfacesSection({data, interfaces, onChange}){

    const [showAddInterfaceModal, setShowAddInterfaceModal] = useState(false)
    
    const getOptions = () => {
        const array = []

        interfaces.forEach(el => array.push(el.name))

        return array
    }
    
    const getNameById = (id) => {
        return interfaces.find(el => el._id === id).name
    }
    
    const getIdbyName = (name) => {
        return interfaces.find(el => el.name === name)._id
    }

    const handleChangeDefault = (name) => {

        const _data = [...data]
        const index = _data.findIndex(el => el.default)

        _data[index].use_id = getIdbyName(name)
        
        onChange(_data)
    }

    const handleAddResourse = (resource) => {
        const _data = [...data]

        _data.push(resource)

        onChange(_data)
    }

    const handleDelete = (resourse) => {
        const _data = [...data]
        const index = _data.findIndex(el => JSON.stringify(el) === JSON.stringify(resourse))

        _data.splice(index, 1)

        onChange(_data)
    }

    return(
        <>
            <FormTitle help={interfacesInfo}>Designs da Interface</FormTitle>
            <Select label={'Interface Padrão'} value={getNameById(data.find(el => el.default).use_id)} options={getOptions()} onChange={(value) => handleChangeDefault(value)}/>
            <Spacer top={1}/>
            <Line/>
            {
                data.filter(el => !el.default).map((e, i) => {
                    return <ScheduledResourseRow key={i} name={getNameById(e.use_id)} data={e} onDelete={data => handleDelete(data)}/>
                })
            }
            <AddButton text={'Adicionar Interface'} onClick={() => setShowAddInterfaceModal(true)}/>
            <Modal isOpen={showAddInterfaceModal}>
                <AddInterfaceModal interfaces={interfaces} onAdd={(data) => handleAddResourse(data)} onClose={() => setShowAddInterfaceModal(false)}/>
            </Modal>
        </>
    )
}