import styled from "styled-components"
import { Icon, Title } from "../../layout/Layout"
import qrcode from '../../../img/qrcode.png'
import logo_placeholder from '../../../img/logo_placeholder.png'
import { BsEye } from 'react-icons/bs'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`

const Logo = styled.img`
    width: 70%;
    object-fit: contain;
    mix-blend-mode: multiply;
    filter: grayscale(100%);
`

const Text = styled.h1`
    font-size: 12px;
    font-weight: 500;
`

const Paper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 10px;
    background-color: #F2EDCF;
    width: 200px;
    height: fit-content;
    color: #37474F;
    text-align: center;
`

const Line = styled.h1`
    font-family: 'DotGothic16', sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: black;
`

const Name = styled.h1`
    font-family: 'DotGothic16', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: black;
`

const Senha = styled.h1`
    font-size: 18px;
    font-weight: 800;
    font-family: 'DotGothic16', sans-serif;
    padding: 0 8px 2px 8px;
    color: ${props => props.invert ? '#F2EDCF':'#000'};
    background-color: ${props => props.invert ? '#222':'#F2EDCF'};
`

const Spacer = styled.div`
    margin-top: 12px;
`

const Row = styled.span`
    display: flex;
    align-items: center;
    margin: 10px;

    svg{
        margin-right: 5px;
    }
`


function PrintLayoutPreview({data}){
    
    const size = () => {

        const line = 4
        const space = 4
        const qr = 30
        const logo = 20
        const min = 29

        return min
             + (logo * data.show_logo)  
             + (line * data.show_title)
             + (line * data.show_slogan)
             + (line * (data.show_slogan || data.show_title)) 
             + ((line + space) * data.show_time) 
             + ((line + space) * data.show_pw_type) 
             + ((line + line + space) * data.show_text)
             + (qr * data.show_QR)  
             + ((line + space) * data.show_thanks) 
    }
    
    return(
        <Wrapper>
            <Row>
                <Icon size={14}>
                    <BsEye/>
                </Icon>
                <Title>Preview</Title>
            </Row>
            <Paper>
                {
                    data.show_logo &&
                    <>
                        <Logo width={'30px'} src={data.logo_url ? data.logo_url : logo_placeholder}/>
                        <Spacer/>
                    </>
                }
                {
                    data.show_title && data.title &&
                    <Name>{data.title}</Name>
                }
                {
                    data.show_slogan && data.slogan &&
                    <Line>{data.slogan}</Line>
                }
                {
                    ((data.show_title && data.title) || (data.show_slogan && data.slogan)) && <Spacer/>
                }
                {
                    data.show_time &&
                    <>
                        <Line>{'10/10/2022 20:15:55'}</Line>
                        <Spacer/>
                    </>
                }
                {
                    data.show_pw_type && 
                    <>
                        <Line>{'Tipo: Normal'}</Line>
                        <Spacer/>
                    </>
                }
                <Line>Senha:</Line>
                <Senha invert={data.invert}>NR001</Senha>
                {
                    data.show_text && (data.line1 || data.line2) &&
                    <>
                        <Spacer/>
                        <Line>{data.line1}</Line>
                        <Line>{data.line2}</Line>
                    </>
                }
                {
                    data.show_QR && 
                    <>
                        <Spacer/>
                        <Line>{data.QR_title}</Line>
                        <Spacer/>
                        <img width={'70px'} src={qrcode}/>
                    </>
                }
                {
                    data.show_thanks && data.thanks_text &&
                    <>
                        <Spacer/>
                        <Line>{data.thanks_text}</Line>
                    </>
                }
            </Paper>
            <Row>
                <Text>Comprimento: <strong>{size()}mm</strong></Text>
            </Row>
        </Wrapper>
    )
}

export default PrintLayoutPreview