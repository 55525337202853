import { useEffect, useState } from "react";
import styled from "styled-components";
import AtendentesIcon from '../../../img/icons/AtendentesIcon'
import GuichesListRow from "./GuichesListRow";
import { getAll } from '../../../API/queries'
import { Column, Text } from '../../layout/Layout'
import { useContext } from "react";
import { PlaceContext } from "../../../contexts/PlaceContext";
import { DataContext } from "../../../contexts/DataProvider";

const Box = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    max-height: 100%;
    height: 100%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #37474F;
    margin-bottom: 20px;
`

const Header = styled.span`
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #B0BEC5;

    svg{
        margin: 5px;
    }

    path{
        fill: #00BFA5;
    }

    h1{
        font-weight: 400;
    }
`

const Filter = styled.span`
    display: flex;
    align-items: center;
    margin-left: auto;
`

const Button = styled.div`
    width: ${props => props.selected ? '14px' : '8px'};
    height: 8px;
    background-color: ${props => props.color};
    border-radius: 4px;
    margin: 6px;
    cursor: pointer;
    transition: all 200ms;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    width: 100%;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #CFD8DC;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`

function GuichesList({}){

    const { placeId } = useContext(PlaceContext)
    const { desks, attendants } = useContext(DataContext)
    
    const [filter, setFilter] = useState('all')

    const [placeDesksIds, setPlaceDesksIds] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        setPlaceDesksIds(desks.filter(el => el.placeId === placeId).map(el => el._id))
        setFilter('all')
    },[placeId])
    
    const filterData = () => {
        
        const _attendants = [...attendants]
        
        const filtered = _attendants.filter(el => placeDesksIds.includes(el.desk_id))
        
        _attendants.filter(el => el.status === 'inactive').forEach(el => filtered.push(el))

        setFilteredData(filtered)
    }

    useEffect(() => {
        filterData()
    },[placeDesksIds, desks, attendants])
    
    const handleFilter = (filtered) => {
        if(filtered !== filter) setFilter(filtered)
        else setFilter('all')
    }

    return(
        <Box>
            <Header>
                <AtendentesIcon/>
                <h1>Atendentes em Operação</h1>
                <Filter>
                    <Button color={'#00C853'} selected={filter === 'active'} onClick={() => handleFilter('active')}/>
                    <Button color={'#FB9800'} selected={filter === 'paused'} onClick={() => handleFilter('paused')}/>
                    <Button color={'#F34236'} selected={filter === 'inactive'} onClick={() => handleFilter('inactive')}/>
                </Filter>
            </Header>
            {
                filteredData.length > 0 ?
                <Body>
                    {
                        filteredData.map((atendente, index) => {
                            if(filter === atendente.status) return <GuichesListRow key={index} data={atendente} desks={desks} desk={desks.find(el => el._id === atendente.desk_id)}/>
                            else if(filter === 'all') return <GuichesListRow key={index} data={atendente} desks={desks} desk={desks.find(el => el._id === atendente.desk_id)}/>
                        })
                    }
                </Body>
                :
                <Column height={100} align={'center'} justify={'center'}>
                    <Text color={'#78909C'} weight={500}>Não há atendentes</Text>
                    <Text color={'#78909C'} weight={500}>logados neste local</Text>
                </Column>
            }
        </Box>
    )
}

export default GuichesList