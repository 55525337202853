import { Header, HeaderRight } from "../components/layout/Header"
import { Page, Spacer, Text, Row, VerticalBox } from "../components/layout/Layout"
import { useEffect, useState } from "react"
import PulseLoader from "react-spinners/PulseLoader"
import { useContext } from "react"
import { ConfigContext } from "../contexts/ConfigContext"
import AttendanceConfigBox from "../components/config/AttendanceConfigBox"
import NotifConfigBox from "../components/config/NotifConfigBox"
import HeaderFixedMenu from "../components/headerFixedMenu"

function Config(){

    const { loadingConfig, fetchConfig } = useContext(ConfigContext)
    
    const [subSection, setSubSection] = useState('attendance')
 
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchConfig()
    },[])

    useEffect(() => {
        if(loadingConfig) {
            setTimeout(() => {
                setLoading(false)
            }, 1000);
        }
    },[loadingConfig])

    return(
        <>
            <Header>
                <HeaderRight>
                    <HeaderFixedMenu configOpened/>
                </HeaderRight>
            </Header>
            {
                loading ? 
                <Page center>
                    <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                </Page>
                :
                <Page>
                    <VerticalBox>
                        <Row borderBtmWidth={1} borderBtmColor={'#CFD8DC'} borderBtmStyle={'solid'} bottom={5}>
                            <Text pointer weight={subSection === 'attendance' && 600} color={subSection === 'attendance' && '#00BFA5'} onClick={() => setSubSection('attendance')}>
                                Atendimento
                            </Text>
                            <Spacer left={3}/>
                            <Text pointer weight={subSection === 'notification' && 600} color={subSection === 'notification' && '#00BFA5'} onClick={() => setSubSection('notification')}>
                                Notificações
                            </Text>
                        </Row>
                        <Spacer top={3}/>
                        {
                            subSection === 'attendance' ?
                            <AttendanceConfigBox/>
                            :
                            subSection === 'notification' &&
                            <NotifConfigBox/>
                        }
                    </VerticalBox>
                </Page>
            }
        </>
    )
}

export default Config