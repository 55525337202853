import { Image, ShowContainer, Text } from "../styles"
import { MdOutlineVideocamOff } from 'react-icons/md'
import { Icon } from "../../../../../layout/Layout"
import { getYoutubeId } from "../../../../../../services/getYoutubeId"
import { validateYouTubeUrl } from "../../../../../../services/validateYouTubeUrl"
import { useState, useEffect } from "react"

export default function YouTubeExhibition({url}){

    const [validUrl, setValidUrl] = useState(false)

    useEffect(() => {
        setValidUrl(validateYouTubeUrl(url))
    },[url])

    return(
        <ShowContainer>
            {
                !url ? 
                <>
                    <Icon size={40} color={'#90A4AE'}>
                        <MdOutlineVideocamOff/>
                    </Icon>
                    <Text size={1.3} color={'#90A4AE'} weight={600}>Insira o endereço</Text>
                    <Text size={1.3} color={'#90A4AE'} weight={600}>do Youtube</Text>
                </>
                :
                validUrl ?
                <Image url={`https://img.youtube.com/vi/${getYoutubeId(url)}/mqdefault.jpg`}/>
                :
                <>
                    <Icon size={40} color={'#90A4AE'}>
                        <MdOutlineVideocamOff/>
                    </Icon>
                    <Text size={1.3} color={'#90A4AE'} weight={600}>O endereço do Youtube</Text>
                    <Text size={1.3} color={'#90A4AE'} weight={600}>informado é inválido</Text>
                </>
            }
        </ShowContainer>
    )
}