import styled from "styled-components"
import GrowthArrowUp from '../../../img/icons/GrowthArrowUp'
import GrowthArrowDown from '../../../img/icons/GrowthArrowDown'
import { BsArrowRight } from 'react-icons/bs'
import { Text } from "../../layout/Layout"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: auto;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    height: 16px;
`

const Text1 = styled.h1`
    font-size: 0.9rem;
    font-weight: 400;
`

const Text2 = styled.h2`
    font-size: 0.9rem;
    font-weight: 600;
`

const Arrow = styled.div`
    display: flex;
    align-items: center;
    margin: 5px;
`

const Growth = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    margin: 5px;

    svg{
        width: 0.8rem;
    }
`

function LastHourDisplay({lastHour, lastHourGrowth, average, averageGrowth}){
    return (
        <Wrapper>
            <Row>
                <Text1>Atendimentos na última hora</Text1>
                <Arrow><BsArrowRight/></Arrow>
                <Text2>{lastHour + ' pessoas'}</Text2>
                <Growth>{lastHourGrowth ? <GrowthArrowUp/> : <GrowthArrowDown/>}</Growth>
            </Row>
            <Row>
                <Text1>Média de atendimentos/hora</Text1>
                <Arrow><BsArrowRight/></Arrow>
                <Text2>{average.toString().replace('.',',') + ' pessoas'}</Text2>
                <Growth>{averageGrowth ? <GrowthArrowUp/> : <GrowthArrowDown/>}</Growth>
            </Row>
        </Wrapper>
    )
}

export default LastHourDisplay