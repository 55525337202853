import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        text-decoration: none;

        --toastify-text-color-light: #37474F;
        --toastify-font-family: 'Inter', sans-serif;
        --toastify-toast-width: 300px;
        --toastify-toast-min-height: 50px;
    }
`

export default GlobalStyle