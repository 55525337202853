import { FaRegQuestionCircle } from 'react-icons/fa'
import { Icon } from '../layout/Layout'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';


function HelpIcon({content, position}){
    return(
        <Tooltip position={position && position} theme='light' trigger="click" arrow={true} html={content}>
            <Icon color={'#B0BEC5'} pointer={true}>
                <FaRegQuestionCircle/>
            </Icon>
        </Tooltip>
    )
}

export default HelpIcon