import { generateShortId } from "../services/generateShortId"

export const newPanel = (panels, placeId, services) => { 

    const _services = services.filter(el => el.placeId === placeId).map(el => el._id)

    return{
        name: getName(panels.filter(el => el.placeId === placeId)),
        placeId,
        services: _services,
        configs: [{
            default: true,
            alert_enable: true,
            alert: 'Alerta 1',
            theme: 'cyan',
            show_time: true,
            show_marquee: false,
            marquee_text: '',
            exhibition: 'none',    
            youtube_url: '',
            gallery_urls: [],
            videos_urls: [],
            video_sound: false,
        }],
        shortId: generateShortId()
    }
}

export const newPanelConfig = (schedule) => {

    return{
        default: false,
        alert_enable: true,
        alert: 'Alerta 1',
        theme: 'cyan',
        show_time: true,
        show_marquee: false,
        marquee_text: '',
        exhibition: 'none',    
        youtube_url: '',
        gallery_urls: [],
        videos_urls: [],
        video_sound: false,
        schedule
    }
}

export const duplicatePanel = (panels, panel) => {

    const { locale, configs } = panel

    return{
        name: getName(panels),
        locale,
        configs
    }
}

const getNextNum = (numbers) => {

    for(let i = 1; i < Math.max(...numbers); i++) if(!numbers.includes(i)) return i
    return numbers.length + 1
}

const getName = (panels) => {
    const numbers = []
    const nameFormat = /^Painel +\d$|^Painel +\d\d$/

    panels.forEach(el => {
        if(nameFormat.test(el.name)) numbers.push(parseInt(el.name.replace(/\D/g,'')))
    })

    const nextNum = getNextNum(numbers.sort())
    
    return 'Painel ' + nextNum
}