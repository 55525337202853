import vitoria from './voices/vitoria.mp3'
import camila from './voices/camila.mp3'
import thiago from './voices/thiago.mp3'
import thiago_full from './voices/thiago_full.mp3'
import vitoria_full from './voices/vitoria_full.mp3'
import camila_full from './voices/camila_full.mp3'
import thiago_brief from './voices/thiago_brief.mp3'
import vitoria_brief from './voices/vitoria_brief.mp3'
import camila_brief from './voices/camila_brief.mp3'


export const voices = [
    {name: 'Vitória', audio: new Audio(vitoria)},  
    {name: 'Camila', audio: new Audio(camila)},  
    {name: 'Thiago', audio: new Audio(thiago)},
]

export const full_calls = [
    {name: 'Vitória', audio: new Audio(vitoria_full)},  
    {name: 'Camila', audio: new Audio(camila_full)},  
    {name: 'Thiago', audio: new Audio(thiago_full)},
]

export const brief_calls = [
    {name: 'Vitória', audio: new Audio(vitoria_brief)},  
    {name: 'Camila', audio: new Audio(camila_brief)},  
    {name: 'Thiago', audio: new Audio(thiago_brief)},  
]
