import { Typography, CircularProgress, Box } from "@mui/material";

export default function Downloading() {

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography>Gerando relatório...</Typography>
        </Box>
    );
}
