import { Column, Row } from "../../../../layout/Layout"
import { getColor } from "../../../../../content/Colors"
import { SideBox, Text } from "./styles"

export default function SidePanel2({theme}){

    return(
        <Column>
            <SideBox height={8} color={getColor(theme, 900)}>
                <Text size={0.7} weight={400}>Últimas Chamadas</Text>
            </SideBox>
            <SideBox height={23} color={getColor(theme, 600)}>
                <Row>
                    <Column>
                        <Text size={0.7} weight={300}>Senha</Text>
                        <Text size={1} weight={500}>NR004</Text>
                    </Column>
                    <Column>
                        <Text size={0.7} weight={300}>Guichê</Text>
                        <Text size={1} weight={500}>Guichê 1</Text>
                    </Column>
                </Row>
            </SideBox>
            <SideBox height={23} color={getColor(theme, 500)}>
                <Row>
                    <Column>
                        <Text size={0.7} weight={300}>Senha</Text>
                        <Text size={1} weight={500}>NR003</Text>
                    </Column>
                    <Column>
                        <Text size={0.7} weight={300}>Guichê</Text>
                        <Text size={1} weight={500}>Guichê 3</Text>
                    </Column>
                </Row>
            </SideBox>
            <SideBox height={23} color={getColor(theme, 400)}>
                <Row>
                    <Column>
                        <Text size={0.7} weight={300}>Senha</Text>
                        <Text size={1} weight={500}>NR002</Text>
                    </Column>
                    <Column>
                        <Text size={0.7} weight={300}>Guichê</Text>
                        <Text size={1} weight={500}>Guichê 2</Text>
                    </Column>
                </Row>
            </SideBox>
            <SideBox height={23} color={getColor(theme, 300)}>
                <Row>
                    <Column>
                        <Text size={0.7} weight={300}>Senha</Text>
                        <Text size={1} weight={500}>NR001</Text>
                    </Column>
                    <Column>
                        <Text size={0.7} weight={300}>Guichê</Text>
                        <Text size={1} weight={500}>Guichê 1</Text>
                    </Column>
                </Row>
            </SideBox>
        </Column>
    )
}