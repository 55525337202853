import { Container, Header, DeleteConfirm, RightIcons, Arrow, Body, Trash } from './styles';
import { IoIosArrowDown } from 'react-icons/io'
import { FiTrash2 } from 'react-icons/fi'
import { useState } from "react";
import DeleteConfirmation from "../others/DeleteConfirmation";
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import { Icon, Text } from '../layout/Layout';

function ShrinkBox2({title, icon, headerContent, children, onDelete}){
    
    const [expanded, setExpanded] = useState(false)
    const [deleteConfirm, setDeleteConfirm] = useState(true)
    
    function handleBoxClick(e){
        if(!expanded && e.target.tagName !== 'svg' && e.target.tagName !== 'path' && e.target.tagName !== 'line') setExpanded(true)
    }

    const handleDelete = () => {
        onDelete()
        setDeleteConfirm(true)
    }

    return(
        <Container state={expanded} onClick={(e) => handleBoxClick(e)}>
            <Header>
                <Icon size={24} color='#00BFA5'>
                    {icon}
                </Icon>
                <Text size={12} weight={500}>
                    {title}
                </Text>
                {headerContent}
                <DeleteConfirm hide={deleteConfirm}>
                    <DeleteConfirmation hide={deleteConfirm} onCancel={() => setDeleteConfirm(true)} onConfirm={() => handleDelete()}/>
                </DeleteConfirm>
                <RightIcons>
                    <Tooltip title='Excluir' arrow={true} followCursor={false} theme={'light'}>
                        <Trash>
                            <FiTrash2 onClick={() => setDeleteConfirm(prev => !prev)}/>
                        </Trash>
                    </Tooltip>
                    <Tooltip title='Expandir' arrow={true} followCursor={false} theme={'light'}>
                        <Arrow expanded={expanded}>
                            <IoIosArrowDown onClick={() => setExpanded(prev => !prev)}/>
                        </Arrow>
                    </Tooltip>
                </RightIcons>
            </Header>
            <Body expanded={expanded}>
                {children}
            </Body>
        </Container>
    )
}

export default ShrinkBox2
