import styled from "styled-components"
import { Column, Row, Text, Icon, Spacer } from '../layout/Layout'
import { BiCheck } from 'react-icons/bi'
import { HiOutlineVolumeUp } from 'react-icons/hi'
import { useEffect, useState } from "react"
import { alerts } from "../../content/alerts"
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import ToggleLabel from "./ToggleLabel"

const Wrapper = styled.div`
    position: relative;
`

const Menu = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 15px 20px 15px 5px;
    position: absolute;
    right: 0;
    top: 30px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    z-index: 9999;
    overflow: scroll;
    max-height: 180px;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #CFD8DC;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`

const playAlert = (alert) => {
        
    const index = alerts.findIndex(alerts => alerts.name === alert)
    
    alerts[index].audio.play()
}

function SelectAlertMenu({selected, onSelect, onClose}){

    const handleClickOutside = (e) => { 

        if(e.target.id.includes('alert-select') || e.target.parentElement.id.includes('alert-select')) return
        onClose()
    }

    useEffect(() => {
        document.body.addEventListener('click', handleClickOutside)
        return () => document.body.removeEventListener('click', handleClickOutside) 
    },[])
    
    return(
        <Menu id='alert-select'>
            {
                alerts.map((e,i) => {
                    return (
                        <Row id='alert-select' key={i}>
                            <Column id='alert-select' width={'100px'}>
                                <Row id='alert-select' height={28} align={'center'}>
                                    <Icon id='alert-select' size={20} color={e.name === selected ? '#00BFA5' : 'transparent'}><BiCheck/></Icon>
                                    <Tooltip title='Selecionar' arrow={true} theme={'light'} delay={100} position={'right'}>
                                        <Text id='alert-select' weight={600} color={e.name === selected ? '#00BFA5' : 'gray'} pointer onClick={() => onSelect(e.name)}>
                                            {e.name}
                                        </Text>
                                    </Tooltip>
                                </Row>
                            </Column>
                            <Column id='alert-select' width={'100px'}>
                                <Row id='alert-select' height={28} align={'center'} justify={'flex-end'}>
                                    <Tooltip title='Ouvir' arrow={true} theme={'light'} delay={100} position={'right'}>
                                        <Icon id='alert-select' size={15} color={'#00BFA5'} pointer onClick={() => playAlert(e.name)}>
                                            <HiOutlineVolumeUp id='alert-select'/>
                                        </Icon>
                                    </Tooltip>
                                </Row>
                            </Column>
                        </Row>
                    )
                })
            }
        </Menu>
    )
}

 
function SelectAlert({id, selected, onSelect, enabled, onToggle}) {

    const [showMenu, setShowMenu] = useState(false)

    const handleAlertSelect = (alert) => {
        setShowMenu(false)
        onSelect(alert)
    }

    return(
        <Wrapper>
            <Row align={'center'}>
                <Column>
                    <ToggleLabel id={'alert_enable'+id} text={'Sinal Auditivo'} state={enabled} onToggle={(value) => onToggle(value)}/>
                </Column>
                {   
                    enabled &&
                    <Column align={'flex-end'}>
                        <Row align={'center'} justify={'flex-end'}>
                            <Tooltip title='Alterar' arrow={true} theme={'light'} delay={100}>
                                <Text id='alert-select' weight={600} color={'#37474F'} pointer onClick={() => setShowMenu(showMenu => !showMenu)}>
                                    {
                                        selected
                                    }
                                </Text>
                            </Tooltip>
                            <Spacer left={0.9}/>
                            <Tooltip title='Ouvir' arrow={true} theme={'light'} delay={100}>
                                <Icon size={15} color={'#00BFA5'} pointer onClick={() => playAlert(selected)}>
                                    <HiOutlineVolumeUp/>
                                </Icon>
                            </Tooltip>
                        </Row>
                    </Column>
                }
            </Row>
            {showMenu && <SelectAlertMenu selected={selected} onSelect={(alert) => handleAlertSelect(alert)} onClose={() => setShowMenu(false)}/>}            
        </Wrapper>
    )
}

export default SelectAlert