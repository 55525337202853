import styled from "styled-components"

const CheckBoxWrapper = styled.div`
  position: relative;
  width: 35px;
`

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 9px;
  left: 0;
  width: 24px;
  height: 12px;
  border-radius: 6px;
  background: #b3b3b3;
  cursor: pointer;
  
    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        position: relative;
        bottom: 2px;
        left: -2px;
        background: #999;
        transition: 0.2s;
    }
`

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  
    &:checked + ${CheckBoxLabel} {
        background: #81CAC4;
    
        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background: #00BFA5;
            margin-left: 12px;
            transition: 0.2s;
        }
    }
`

function ToggleSwitch({id, isToggled, setIsToggled}){
    
    return(
        <CheckBoxWrapper>
            <CheckBox id={id} type="checkbox" checked={isToggled} onChange={(e) => setIsToggled(e.target.checked)}/>
            <CheckBoxLabel htmlFor={id} />
        </CheckBoxWrapper>
    )
}

export default ToggleSwitch