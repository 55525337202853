import styled from "styled-components"
import HorizontalBar from "./HorizontalBar"
import { Row, Column, Text } from '../../../layout/Layout'

function HorizontalBarChart({data}){
    return(
        <Row height={'100%'}>
            <Column width={'fit-content'}>
                {
                    data.map((e, i) => {
                        return  <Row height={'100%'} align={'center'} justify={'flex-end'}>
                                    <Text>{e.label}</Text>
                                </Row>
                    })
                }
            </Column>
            <Column height={100}>
                {
                    data.map((e, i) => {
                        return <HorizontalBar index={i} data={e}/>
                    })
                }
            </Column>
        </Row>
    )
}

export default HorizontalBarChart