import { useEffect, useState, useContext } from 'react'
import SideMenuItem from './SideMenuItem';
import { Container, MenuScroll, MenuSection } from './style'
import DashboardIcon from '../../img/icons/DashboardIcon'
import AtendimentoIcon from '../../img/icons/AtendimentoIcon'
import AtendimentosIcon from '../../img/icons/AtendimentosIcon'
import AtendentesIcon from '../../img/icons/AtendentesIcon'
import FilaIcon from '../../img/icons/FilaIcon'
import DevicesIcon from '../../img/icons/DevicesIcon'
import TerminaisIcon from '../../img/icons/TerminaisIcon'
import DisplaysIcon from '../../img/icons/DisplaysIcon'
import SenhasIcon from '../../img/icons/SenhasIcon'
import UsersIcon from '../../img/icons/UsersIcon'
import { HiOutlineDocumentText } from 'react-icons/hi'
import { MdOutlineAdminPanelSettings, MdOutlineViewAgenda } from 'react-icons/md'
import { TbReplace } from 'react-icons/tb'
import LoggedAccount from './LoggedAccount';
import { AuthContext } from '../../contexts/AuthProvider';
import PlaceSelector from './placeSelector';
import { LiaUserClockSolid } from "react-icons/lia";
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { AiOutlinePieChart } from 'react-icons/ai';
import { FaListUl } from 'react-icons/fa';

export default function SideMenu() {

    const { userAdmin } = useContext(AuthContext)

    const [show, setShow] = useState(false)
    const [selectedItem, setSelectedItem] = useState('')
    const [selectedSubitem, setSelectedSubitem] = useState('')

    const handleShowMenu = (show) => {
        setTimeout(() => {
            setShow(show)
        }, 300);
    }

    const handleItemNavigation = (selected) => {
        setSelectedItem(selected)
        setShow(false)
    }

    const handleSubitemNavigation = (selected) => {
        setSelectedSubitem(selected)
        setShow(false)
    }

    const recoverMenu = () => {
        switch (window.location.pathname) {
            case '/': setSelectedItem('home'); setSelectedSubitem(''); break;
            case '/geral': setSelectedItem('dashboard'); setSelectedSubitem('general'); break;
            case '/atendimentos': setSelectedItem('dashboard'); setSelectedSubitem('calls'); break;
            case '/fila': setSelectedItem('dashboard'); setSelectedSubitem('queue'); break;
            case '/attendant-performance': setSelectedItem('dashboard'); setSelectedSubitem('attendant-performance'); break;
            case '/guiches': setSelectedItem('dashboard'); setSelectedSubitem('desks'); break;
            case '/locais': setSelectedItem('dashboard'); setSelectedSubitem('places'); break;
            case '/terminais': setSelectedItem('devices'); setSelectedSubitem('terminals'); break;
            case '/interfaces': setSelectedItem('devices'); setSelectedSubitem('terminals'); break;
            case '/displays': setSelectedItem('devices'); setSelectedSubitem('displays'); break;
            case '/senhas/tipos': setSelectedItem('passwords'); setSelectedSubitem('senhas/tipos'); break;
            case '/senhas/layout': setSelectedItem('passwords'); setSelectedSubitem('senhas/layout'); break;
            case '/config_atendimento': setSelectedItem('config'); setSelectedSubitem('config_atendimento'); break;
            case '/config_notif': setSelectedItem('config'); setSelectedSubitem('config_notif'); break;
            case '/usersAtend': setSelectedItem('users'); setSelectedSubitem('users_attendants'); break;
            case '/usersAdmin': setSelectedItem('users'); setSelectedSubitem('users_admins'); break;
            case '/support': setSelectedItem('support'); setSelectedSubitem(''); break;
            case '/finan': setSelectedItem('finan'); setSelectedSubitem(''); break;
            case '/services': setSelectedItem('services'); setSelectedSubitem('services'); break;
            case '/services-analytics': setSelectedItem('services'); setSelectedSubitem('services-analytics'); break;
            default: break;
        }
    }

    useEffect(() => {
        recoverMenu()
    }, [])

    return (
        <Container show={show} onMouseEnter={() => handleShowMenu(true)} onMouseLeave={() => handleShowMenu(false)}>
            <LoggedAccount show={show} onAnyAction={() => setShow(false)} />
            <PlaceSelector show={show} />
            <MenuScroll show={show}>
                {
                    userAdmin.permissions.includes('Dashboard') &&
                    <SideMenuItem selected={selectedItem === 'dashboard'} label={'Dashboard'} iconSize={1.6} icon={<DashboardIcon />} show={show} arrow onClick={() => setSelectedItem('dashboard')} />
                }
                <MenuSection selected={selectedItem === 'dashboard'}>
                    <SideMenuItem link={'geral'} selected={selectedSubitem === 'general'} label={'Geral'} subitem iconSize={1.3} icon={<AtendimentoIcon />} show={show} onClick={() => handleSubitemNavigation('general')} />
                    <SideMenuItem link={'atendimentos'} selected={selectedSubitem === 'calls'} label={'Atendimentos'} subitem iconSize={1.3} icon={<AtendimentosIcon />} show={show} onClick={() => handleSubitemNavigation('calls')} />
                    <SideMenuItem link={'fila'} selected={selectedSubitem === 'queue'} label={'Gerenciar Fila'} subitem iconSize={1.3} icon={<FilaIcon />} show={show} onClick={() => handleSubitemNavigation('queue')} />
                    <SideMenuItem link={'attendant-performance'} selected={selectedSubitem === 'attendant-performance'} label={'Desempenho dos Atendentes'} subitem iconSize={1.6} extIcon icon={<LiaUserClockSolid />} show={show} onClick={() => handleSubitemNavigation('attendant-performance')} />
                    <SideMenuItem link={'guiches'} selected={selectedSubitem === 'desks'} label={'Guichês'} subitem iconSize={1.3} icon={<AtendentesIcon />} show={show} onClick={() => handleSubitemNavigation('desks')} />
                </MenuSection>
                <SideMenuItem selected={selectedItem === 'services'} label={'Serviços'} iconSize={1.6} extIcon icon={<HiOutlineWrenchScrewdriver />} show={show} arrow onClick={() => setSelectedItem('services')} />
                <MenuSection selected={selectedItem === 'services'}>
                    <SideMenuItem link={'services'} selected={selectedSubitem === 'services'} label={'Gerenciar Serviços'} subitem iconSize={1.4} icon={<FaListUl />} show={show} onClick={() => handleSubitemNavigation('services')} />
                    <SideMenuItem link={'services-analytics'} selected={selectedSubitem === 'services-analytics'} label={'Desempenho de Serviços'} subitem iconSize={1.6} icon={<AiOutlinePieChart />} show={show} onClick={() => handleSubitemNavigation('services-analytics')} />
                </MenuSection>
                {
                    userAdmin.permissions.includes('Dispositivos') &&
                    <SideMenuItem selected={selectedItem === 'devices'} label={'Dispositivos'} iconSize={1.7} icon={<DevicesIcon />} show={show} arrow onClick={() => setSelectedItem('devices')} />
                }
                <MenuSection selected={selectedItem === 'devices'}>
                    <SideMenuItem link={'terminais'} selected={selectedSubitem === 'terminals'} label={'Terminais'} subitem iconSize={1.5} icon={<TerminaisIcon />} show={show} onClick={() => handleSubitemNavigation('terminals')} />
                    <SideMenuItem link={'panels'} selected={selectedSubitem === 'displays'} label={'Paineis'} subitem iconSize={1.5} icon={<DisplaysIcon />} show={show} onClick={() => handleSubitemNavigation('panels')} />
                </MenuSection>
                {
                    userAdmin.permissions.includes('Emissão de Senhas') &&
                    <SideMenuItem selected={selectedItem === 'passwords'} label={'Emissão de Senhas'} iconSize={1.7} icon={<SenhasIcon />} show={show} arrow onClick={() => setSelectedItem('passwords')} />
                }
                <MenuSection selected={selectedItem === 'passwords'}>
                    <SideMenuItem link={'senhas/regras'} selected={selectedSubitem === 'senhas/regras'} label={'Regras de Prioridade'} subitem iconSize={1.5} extIcon icon={<TbReplace />} show={show} onClick={() => handleSubitemNavigation('senhas/regras')} />
                    <SideMenuItem link={'senhas/tipos'} selected={selectedSubitem === 'senhas/tipos'} label={'Tipos de Senhas'} subitem iconSize={1.6} extIcon icon={<MdOutlineViewAgenda />} show={show} onClick={() => handleSubitemNavigation('senhas/tipos')} />
                    <SideMenuItem link={'senhas/layout'} selected={selectedSubitem === 'senhas/layout'} label={'Layouts de Impressão'} subitem iconSize={1.6} extIcon icon={<HiOutlineDocumentText />} show={show} onClick={() => handleSubitemNavigation('senhas/layout')} />
                </MenuSection>
                {
                    userAdmin.permissions.includes('Usuários') &&
                    <SideMenuItem selected={selectedItem === 'users'} label={'Gerenciar Equipe'} iconSize={1.6} icon={<UsersIcon />} show={show} arrow onClick={() => setSelectedItem('users')} />
                }
                <MenuSection selected={selectedItem === 'users'}>
                    <SideMenuItem link={'usersAtend'} selected={selectedSubitem === 'users_attendants'} label={'Atendentes'} subitem iconSize={1.25} icon={<AtendentesIcon />} show={show} onClick={() => handleSubitemNavigation('users_attendants')} />
                    <SideMenuItem link={'usersAdmin'} selected={selectedSubitem === 'users_admins'} label={'Administradores'} subitem iconSize={1.6} extIcon icon={<MdOutlineAdminPanelSettings />} show={show} onClick={() => handleSubitemNavigation('users_admins')} />
                </MenuSection>
            </MenuScroll>
        </Container>
    )
}
