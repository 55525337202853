import { HexToRgba } from "./HexToRgba";

const getData = (labels, datasets) => {

    return{
        labels,
        datasets
    }
};

const getTypes = (data) => {
    const types = []

    data.forEach(el => {
        if(!types.includes(el.type)) types.push(el.type)
    })

    return types
}

const newDataset = (label, data, typeColors) => {

    return{
        label,
        data,
        borderColor: HexToRgba(typeColors[label])
    }
}

const intervalIsBetween = (min, max, time) => {
    const _time = new Date(time)

    const __time = (_time.getHours() * 60) + _time.getMinutes()
    
    return (__time >= min) && (__time < max)
}

const makeDatasets = (data, intervals, typeColors) => {
    const datasets = []
    const types = getTypes(data)

    types.forEach(type => {

        const counts = Array(intervals.length).fill(0);

        data.forEach(el => {
            
            for(let i = 0; i < intervals.length; i++) {
                if(el.type === type && intervalIsBetween(intervals[i], intervals[i+1], el.attended_at)){
                    counts[i] += 1
                }
            }
        })

        datasets.push(newDataset(type, counts, typeColors))
    })

    return datasets
}

const getHourAndMinutesArray = (time) => {
    const _time = new Date(time)
    return [_time.getHours(), _time.getMinutes()]
}

const getMinTime = (array) => {
    const hours = []
    const minutes = []

    array.forEach(el => hours.push(el[0]))

    const minHour = Math.min(...hours)

    array.forEach(el => {
        if(el[0] === minHour) minutes.push(el[1])
    })

    const minMinutes = Math.min(...minutes)

    return [minHour, minMinutes]
}

const getMaxTime = (array) => {
    const hours = []
    const minutes = []

    array.forEach(el => hours.push(el[0]))

    const maxHour = Math.max(...hours)

    array.forEach(el => {
        if(el[0] === maxHour) minutes.push(el[1])
    })

    const maxMinutes = Math.max(...minutes)

    return [maxHour, maxMinutes]
}

const getTimes = (data) => {
    const hours = []

    data.forEach(el => {
        hours.push(getHourAndMinutesArray(el.attended_at))
    })

    return hours
}

const defineInterval = (min, max) => {
    return (max[0] + 1) - min[0]
}

// const getIntervalMinutesDivision = (interval) => {
//     switch (interval) {
//         case 1: return 10;
//         case 2: return 15;
//         case 3: return 20;
//         case 4: return 30;
//         default: return 60;
//     }
// }

const create60sIntervalsInMinutes = (min, max) => {
    const interval = []

    const count = defineInterval(min, max)

    for(let i = 0; i < count+1; i++) interval.push([(min[0] + i) * 60])

    return interval
}

// const createIntervalsInMinutes = (min, max) => {
//     const interval = []

//     const count = defineInterval(min, max)

//     for(let i = 0; i < count+1; i++) interval.push([(min[0] + i) * 60])

//     return interval
// }

const intervalsInMinutesToString = (intervals) => {
    const stringIntervals = []

    intervals.forEach(el => {
        const hour = el/60
        const min = el%60
        stringIntervals.push(`${String(hour).padStart(2, '0')}:${String(min).padStart(2, '0')}`)
    })

    return stringIntervals
}

const getTotal = (datasets, intervals) => {
    const total = Array(intervals.length).fill(0)

    datasets.forEach(el => {
        for(let i = 0; i < total.length; i++){
            total[i] += el.data[i]
        }
    })

    return total
}

export const getFlowStats = (data, typeColors) => {
    const times = getTimes(data)

    const intervals = create60sIntervalsInMinutes(getMinTime(times),getMaxTime(times))

    const datasets = makeDatasets(data, intervals, typeColors)

    const total = {
        label: 'Total',
        data: getTotal(datasets, intervals),
        borderColor: 'rgb(1, 87, 155)'
    }

    datasets.push(total)
    
    return getData(intervalsInMinutesToString(intervals), datasets)
}
