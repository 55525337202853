import styled from "styled-components"
import { Row, Spacer } from "../layout/Layout"
import HelpIcon from "../others/HelpIcon"

const Title = styled.h1`
    font-size: 1rem;
    font-weight: 600;
    color: #37474F;
`

export default function FormTitle({children, help}){

    return(
        <>
            <Row align={'center'}>
                <Title>
                    {children}
                </Title>
                {
                    help &&
                    <HelpIcon content={help}/>
                }
            </Row>
            <Spacer top={1}/>
        </>
    )
}