import { Column, Row, Text } from "../../layout/Layout"
import AvatarWithStatus from "../../others/AvatarWithStatus"


export default function AttendantDataModal({attendant}){


    return (
        <>
            <Row align={'center'}>
                <AvatarWithStatus url={attendant?.avatar_url} size={6}/>
                <Column width={'fit-content'}>
                    <Text>{attendant.name}</Text>
                </Column>
            </Row>
        </>
    )
}