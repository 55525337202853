import styled from "styled-components"

const Number = styled.span`
    display: flex;
    align-items: center;
    height: 35px;

    label{
        font-size: 12px;
        font-weight: 400;
        margin-left: 5px;
    }

    input[type=number]{
        border: 1px solid #37474F;
        border-radius: 5px;
        margin-left: 5px;
        height: 25px;
        width: 50px;
        padding-left: 3px;
        outline: none;
        color: #37474F; 

        &:focus{
            border: 1px solid #00BFA5;
        }
    }
`

const Input = styled.input`
    accent-color: #00BFA5;
`

function CheckNumberBetween({id, option, checked, value, min, max, onChange, onCheck}){
    
    return(
        <Number>
            <Input type={'checkbox'} id={option+id} name={option} checked={checked} onChange={(e) => onCheck(e.target.checked)}></Input>
            <label htmlFor={option+id}>{option.split('-')[0]}</label>
            <input type="number" value={value} min={min} max={max} disabled={!checked} onChange={(e) => onChange(e.target.value)}/>
            <label htmlFor={option+id}>{option.split('-')[1]}</label>
        </Number>
    )
}

export default CheckNumberBetween