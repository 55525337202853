export const newDeskInfo = <p style={{textAlign: 'left'}}>
Para criar um <strong>Novo Guichê</strong> você deve informar o <strong>nome</strong> que deseja utilizar e o <strong>número do Guichê</strong>. Depois basta clicar em <strong>Criar</strong>. O sistema validará automaticamente se o nome e o número são válidos para não haver repetições<br/><br/>
</p>

export const filaTableSenhaInfo = <p style={{textAlign: 'left'}}>
Esta é a <strong>Senha que identifica o atendimento</strong>. É ela que aparecerá no Painel quando for chamada.
</p>

export const filaTableTipoInfo = <p style={{textAlign: 'left'}}>
Tipo da <strong>Senha emitida</strong>.
</p>

export const filaTablePriorInfo = <p style={{textAlign: 'left'}}>
Mostra se a <strong>Senha é prioritária ou não</strong>. Senhas prioritárias também são exibidas na cor laranja.
</p>

export const filaTableTimeInfo = <p style={{textAlign: 'left'}}>
<strong>Hora em que a Senha foi emitida</strong> no Terminal de Emissão.
</p>

export const filaTablePosInfo = <p style={{textAlign: 'left'}}>
<strong>Posição na fila</strong> em que esta Senha se encontra.
</p>

export const filaTableWaitInfo = <p style={{textAlign: 'left'}}>
<strong>Tempo que esta Senha está aguardando</strong> na fila desde que foi emitida.
</p>

export const filaTableCancelInfo = <p style={{textAlign: 'left'}}>
Clique para cancelar esta senha. Senhas canceladas não serão chamadas no Painel e <strong>não poderão ser atendidas</strong>.
</p>

export const callsTableSenhaInfo = <p style={{textAlign: 'left'}}>
    Senha que <strong>identifica o atendimento</strong>.
</p>

export const callsTableServiceInfo = <p style={{textAlign: 'left'}}>
    <strong>Serviço</strong> para o qual a senha foi emitida.
</p>

export const callsTableTypeInfo = <p style={{textAlign: 'left'}}>
    <strong>Tipo da Senha</strong> atendida.
</p>

export const callsTablePriorInfo = <p style={{textAlign: 'left'}}>
    Indica se a Senha era <strong>prioritária ou não</strong>.
</p>

export const callsTableCreatedInfo = <p style={{textAlign: 'left'}}>
    Hora em que a <strong>Senha foi emitida</strong> no Terminal de Emissão de Senhas.
</p>

export const callsTableAlertedInfo = <p style={{textAlign: 'left'}}>
    Hora em que a <strong>Senha foi chamada</strong> pelo atendente.
</p>

export const callsTableDurationInfo = <p style={{textAlign: 'left'}}>
    Tempo de <strong>duração do atendimento</strong>, desde o seu início até a finalização. 
</p>

export const callsTableAttendantInfo = <p style={{textAlign: 'left'}}>
    <strong>Nome do atendente</strong> que realizou o atendimento.
</p>

export const callsTableWaitedInfo = <p style={{textAlign: 'left'}}>
    Tempo em que a <strong>Senha permanceu na fila</strong> de espera antes de ser chamada.
</p>

export const callsTableAnnotationInfo = <p style={{textAlign: 'left'}}>
    Caso o atendente tenha adcionado algum <strong>comentário a respeito do atendimento</strong>, ele pode ser mostrado clicando no ícone abaixo. 
</p>

export const finanHeaderInfo = <>
    <p style={{textAlign: 'left'}}>
    Aqui você terá acesso a todo <strong>controle financeiro</strong> de suas mensalidades, poderá vizualizar suas faturas pagas, bem como acessar novas faturas e <strong>realizar o pagamento</strong> das mesmas.
    Também é possível fazer o download das <strong>notas fiscais</strong> das faturas pagas clicando no ícone à direita de cada uma. <br/><br/>
    <strong>Obs.: </strong>Caso haja dúvidas ou tenha algum problema em relação às suas faturas, você pode abrir um chamado na área de Suporte que analizaremos a suas situação e responderemos o mais breve possível. 
    </p>
</>

