import styled from 'styled-components'
import {IoAdd} from 'react-icons/io5'
import {BsArrowClockwise} from 'react-icons/bs'

const AddBut = styled.span`

    display: flex;
    align-items: center;
    
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: 1px solid #37474F;
        border-radius: 50px;
        margin-right: 5px;
        color: #37474F;
        cursor: pointer;

        &:hover{
            border: 1px solid #00BFA5;
        }

        &:active{
            background-color: #00BFA5;
            color: white;
        }
    }

    svg{
        font-size: 22px;
    }

    h1{
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 400;
    }
`

function AddButton({text, icon, onClick}){
    
    const _text = text.split("-")
    
    return(
        <AddBut>
            <div onClick={onClick}>
                {icon === 'restore' ? <BsArrowClockwise/> : <IoAdd/>}
            </div>
            <h1>{_text[0]}<br/>{_text[1]}</h1>
        </AddBut>
    )
}

export default AddButton