export const newTerminal = (terminals, placeId, services, printLayouts, interfaces) => {
    
    return{
        name: getName(terminals.filter(el => el.placeId === placeId)),
        status: false,
        placeId,
        services: [
            services[0]._id
        ],
        interfaces: [{
            default: true,
            use_id: interfaces[0]._id
        }],
        print_layouts: [{
            default: true,
            use_id: printLayouts[0]._id
        }],
        paper_roll: "",
        auto_aproach: {
            enable: false,
            audio_enable: true,
            audio_url: '',
            start_time: 10,
            exhibition: 'none',
            images_urls: [],
            video_url: '',
        },
        confirmation: {
            enable: false,
            audio_enable: true,
            audio_url: '',
            duration: 10,
            exhibition: 'none',
            image_url: '',
            video_url: '',
        },
        started_at: null,
    }
}

export const copyTerminal = (terminals, terminal, placeId) => {

    const copy = {...terminal}

    delete copy._id
    delete copy.userId

    copy.name = getName(terminals.filter(el => el.placeId === placeId))
    copy.status = false
    copy.paper_roll = ""
    copy.started_at = null

    return copy
}

const getNextNum = (numbers) => {

    for(let i = 1; i < Math.max(...numbers); i++) if(!numbers.includes(i)) return i
    return numbers.length + 1
}

const getName = (terminals) => {
    const numbers = []
    const nameFormat = /^Terminal +\d$|^Terminal +\d\d$/

    terminals.forEach(el => {
        if(nameFormat.test(el.name)) numbers.push(parseInt(el.name.replace(/\D/g,'')))
    })

    const nextNum = getNextNum(numbers.sort())
    
    return 'Terminal ' + nextNum
}