import { createContext, useContext, useEffect, useRef, useState } from "react";
import { NotifToast, getIcon, getType } from '../components/notif/NotifToast'
import io from 'socket.io-client'
import { toast } from 'react-toastify'
import { ConfigContext } from "./ConfigContext";
import { AuthContext } from './AuthProvider'
import alert23 from '../content/alerts/alert-23.mp3'
import { CallsContext } from "./CallsProvider";
import { DataContext } from "./DataProvider";

export const NotifContext = createContext()

export const NotifProvider = ({children}) => {

    const { longQueuePlaces, longWaitingPasswords } = useContext(CallsContext)
    const { config } = useContext(ConfigContext)
    const { business } = useContext(AuthContext)
    const { places } = useContext(DataContext)

    const alert = new Audio(alert23)

    const [longWaitingNotified, setLongWaitingNotified] = useState([])
    const [longQueueNotified, setLongQueueNotified] = useState([])

    const [paperRollNotified, _setPaperRollNotified] = useState([])
    const paperRollNotifiedRef = useRef(paperRollNotified)
    const setPaperRollNotified = (data) => {
        paperRollNotifiedRef.current = data
        _setPaperRollNotified(data)
    }

    const [notifications, _setNotifications] = useState([])
    const notifRef = useRef([])
    const setNotifications = (data) => {
        notifRef.current = data
        _setNotifications(data)
        sessionStorage.setItem('notifs', JSON.stringify(data))
    }
    
    const [isNew, setIsNew] = useState(false)
    
    const [mute, _setMute] = useState(false)
    const muteRef = useRef(false)
    const setMute = (data) => {
        muteRef.current = data
        _setMute(data)
    }

    const configRef = useRef()

    
    useEffect(() => {
        
        configRef.current = config
        
        const recoveredNotifs = sessionStorage.getItem('notifs')
        
        if(recoveredNotifs) setNotifications(JSON.parse(recoveredNotifs))
        
        const socket = io.connect(process.env.REACT_APP_API_KEY)

        socket.on('notification', (data) => {
            if(data.notification.userId === business._id) notify(data.notification)
        })

        return () => socket.off('notification')
    },[])

    useEffect(() => {
        configRef.current = config
    },[config])

    const checkConfig = (event) => {
        return configRef.current.notification[event]
    }

    const playAlert = () => {
        if(muteRef.current) return
        alert.play()
    }

    const addNotification = (notif) => {
        const _notif = [...notifRef.current]

        _notif.unshift(notif)

        setNotifications(_notif)
    }

    const newNotification = (id, type, message, urgent) => {
        return{
            id,
            type,
            message,
            urgent,
            time: Date.now()
        }
    }

    const notify = (notification) => {

        const { id, type, event, message } = notification
        
        if(!checkConfig(event)) return
        
        toast(<NotifToast notification={notification}/>, {
            containerId: 'notify', 
            icon: getIcon(type), 
            toastId: id, 
            type: getType(type)
        })
        
        playAlert()
        setIsNew(true)

        addNotification(newNotification(id, type, message, false))

        if(event === 'paperRoll') checkPaperRoll(notification)
    }

    useEffect(() => {
        checkLongWaiting()
    },[longWaitingPasswords])

    const notifyLongWaiting = (call) => {

        const notification = {
            id: call._id,
            type: 'warning',
            event: 'passwordWaiting',
            message: `A senha ${call.password} está aguardando a mais de ${configRef.current.notification.passwordWaiting} minutos`
        }

        notify(notification)
    }

    const checkLongWaiting = () => {
        const longWaiting = [...longWaitingPasswords]

        if(longWaiting.length === 0) return setLongWaitingNotified([])

        const notified = [...longWaitingNotified]
        const search = [...longWaitingNotified]

        longWaiting.forEach(call => {
            if(!notified.includes(call._id)){
                notified.push(call._id)
                notifyLongWaiting(call)
            }
        })

        search.forEach((id, i) => {
            const find = longWaiting.find(call => call._id === id)

            if(!find) notified.splice(i, 1)
        })

        setLongWaitingNotified(notified)
    }

    useEffect(() => {
        checkLongQueue()
    },[longQueuePlaces])
    
    const notifyLongQueue = (name) => {

        const notification = {
            id: name,
            type: 'warning',
            event: 'longQueue',
            message: `A fila em ${name} possui mais de ${configRef.current.notification.longQueue} pessoas aguardando`
        }

        notify(notification)
    }

    const checkLongQueue = () => {
        
        if(!places) return
        
        const longQueue = [...longQueuePlaces]

        if(longQueue.length === 0) return setLongQueueNotified([])

        const notified = [...longQueueNotified]
        const search = [...longQueueNotified]

        longQueue.forEach(name => {
            if(!notified.includes(name)){
                notified.push(name)
                notifyLongQueue(name)
            }
        })

        search.forEach((name, i) => {
            const find = longQueue.find(el => el === name)

            if(!find) notified.splice(i, 1)
        })

        setLongQueueNotified(notified)
    }

    const checkPaperRoll = (notification) => {

        const { terminal } = notification

        const paperRolls = [...paperRollNotifiedRef.current]
        const search = [...paperRollNotifiedRef.current]

        const index = search.findIndex(el => el.id === terminal._id)
        if(index >= 0) paperRolls.splice(index, 1)

        if(terminal.paper_roll !== 'full'){
            paperRolls.push({
                id: terminal._id,
                name: terminal.name,
                status: terminal.paper_roll
            })
        }
        
        setPaperRollNotified(paperRolls)
    }


    return (
        <NotifContext.Provider value={{notifications, setNotifications, mute, setMute, isNew, setIsNew, paperRollNotified}}>
            {children}
        </NotifContext.Provider>
    )
}