import styled from "styled-components"
import { Text } from "../layout/Layout"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
    padding-left: 0.5rem;
    width: 8rem;
`

function HeaderStats({legend, number, color}){
    return(
        <Wrapper>
            <Text color={color ? color : '#888'} size={18} weight={800}>{number}</Text>
            <Text size={12} weight={400} color={'#78909C'}>{legend[0]}</Text>
            <Text size={12} weight={600} color={'#546E7A'}>{legend[1]}</Text>
        </Wrapper>
    )
}

export default HeaderStats