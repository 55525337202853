import styled from "styled-components";
import { BsInfoCircle } from 'react-icons/bs'
import 'tippy.js/dist/tippy.css'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css';

const Icon = styled.div`
    svg{
        font-size: 20px;
        color: #37474F;
        cursor: pointer;
    }
`

const Wrapper = styled.div`
    p{
        text-align: left;
    }

`

function InfoTooltip({html}){
    return(
        <Wrapper>
            <Tooltip theme='light' arrow={true} html={html}>
                <Icon>
                    <BsInfoCircle/>
                </Icon>
            </Tooltip>
        </Wrapper>
    )
}

export default InfoTooltip