import styled from "styled-components"
import { Icon } from "../layout/Layout"
import {BsArrowRight } from 'react-icons/bs'

const Stamp = styled.span`
    display: flex;
    align-items: center;
    color: #37474F;
    max-width: 40%;

    h1{
        font-size: 12px;
        font-weight: 600;
        white-space: pre;
    }

    h2{
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`

function AddrStamp({before, after}){

    const getAfterString = (array) => {
        let str = ''

        array.forEach((el, index) => {
            str = str + el
            if(index < array.length - 1) str = str + ', '
        });

        return str

    }

    return(
        <Stamp>
            <h1>{before}</h1>
            <Icon size={14} color={'#00BFA5'}>
                <BsArrowRight/>
            </Icon>
            {
                typeof after === 'string' ? 
                <h2>{after}</h2>
                :
                <h2>{getAfterString(after)}</h2>
            }
        </Stamp>
    )
}

export default AddrStamp