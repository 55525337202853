export const loadInterfaceInfo = <p style={{textAlign: 'left'}}>
Para auxiliá-lo, o sistema dispõe de alguns <strong>modelos de Interfaces pré-configurados</strong> que você poderá utilizar em seus equipamentos. <br/><br/> 
Você pode carregar estes modelos selencionando-os no campo abaixo e em seguida poderá <strong>personalizá-los de acordo com o seu gosto</strong>, ou ainda mantê-los da forma como estão. <br/><br/> 
Caso deseje poderá também criar Interfaces do zero, configurando-as da maneira que desejar, sem carregar nenhum modelo existente.
</p>

export const screenBgInfo = <p style={{textAlign: 'left'}}>
Para configurar o Fundo de Tela de sua Interface você deverá optar entre três opções:<br/><br/> 
<strong>Cor Sólida:</strong> define uma cor única.<br/><br/> 
<strong>Gradiente:</strong> define duas cores para compor uma gradiente no fundo, optando também pelo seu ângulo.<br/><br/> 
<strong>Imagem:</strong> define uma imagem para o fundo. Neste caso você deverá carregar um arquivo de seu computador, que tenha um <strong>formato vertical de 16x9 e em boa resolução</strong>. Caso contrário a imagem poderá ser destorcida e comprometer o seu design.<br/><br/> 
</p>

export const contentInfo = <p style={{textAlign: 'left'}}>
Além dos botões com as opções de senhas, você pode <strong>adicionar textos à sua Interface</strong>, tanto acima quanto abaixo destes botões, como por exemplo frases de saudações, agradecimento, instruções, etc. <br/><br/> 
Para adicionar estes textos basta habilitar a opção de cada um, inserí-los nos campo <strong>Texto</strong> e formatá-los conforme o seu gosto, escolhendo o tamanho, a cor e a espessura da fonte.
</p>

export const buttonsInfo = <p style={{textAlign: 'left'}}>
Os Botões são onde aparecerão a <strong>opções de senhas</strong> das quais o usuário poderá optar, ou seja, cada botão corresponderá a uma opção de senha.<br/><br/> 
Aqui você terá diversas opções de personalização para os botões da Interface:<br/><br/> 
<strong>Altura e Largura:</strong> definem o tamanho que o botões ocuparam na tela.<br/><br/> 
<strong>Raio da Borda:</strong> definem o arredondamento das quinas dos botões. Quanto maior este valor mais arredondadas serão (para quinas quadradas deixe o valor em zero).<br/><br/> 
<strong>Contorno:</strong> habilite esta opção para dar um contorno aos botões, escolhendo a sua cor e sua espessura.<br/><br/> 
<strong>Fundo:</strong> habilite esta opção para dar um fundo aos botões, escolhendo a sua cor e sua opacidade.<br/><br/> 
<strong>Texto dos Botões:</strong> faz a formatação do texto exibido nos botões. Você pode definir a sua cor, tamanho e espessura da fonte.<br/><br/> 
</p>