import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Page, VertLine } from "../components/layout/Layout"
import { Link } from 'react-router-dom'
import Button from "../components/others/Button"
import ShrinkBox from "../components/shrinkBox"
import AddButton from "../components/buttons/AddButton"
import TerminalBox from "../components/resources/devices/TerminalBox"
import { useState, useRef, useEffect, useContext } from "react"
import { toast } from 'react-toastify';
import InfoTooltip from "../components/others/InfoTooltip"
import { terminaisHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import { create, update, destroy } from '../API/queries'
import PulseLoader from "react-spinners/PulseLoader"
import { copyTerminal, newTerminal } from "../resources/newTerminal"
import { validateNewName } from "../methods/validateNewName"
import { DataContext } from "../contexts/DataProvider" 
import TerminalBoxHeader from "../components/resources/devices/terminalBox/TerminalBoxHeader"
import HeaderFixedMenu from "../components/headerFixedMenu"
import { PlaceContext } from "../contexts/PlaceContext"

function Terminals(){ 

    const page = useRef(null)

    const { dataLoaded, terminals, setTerminals, places, services, interfaces, printLayouts } = useContext(DataContext)
    const { placeId } = useContext(PlaceContext)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(dataLoaded) setLoading(false)
        console.log(placeId)
    },[dataLoaded])

    const scrollBottom = () => {
        
        const el = page.current 
        
        el.scrollTo({
            top: 99999999,
            behavior: 'smooth',
        }) 
    }
    
    function addNewTerminal(){

        toast.promise(
            create('terminal', newTerminal(terminals, placeId, services.filter(el => el.placeId === placeId), printLayouts, interfaces)).then((res) => {
                setTerminals(terminals => [...terminals, res.data])  
                setTimeout(() => {scrollBottom()}, 100) 
            }),
            {
                pending: 'Criando Terminal...',
                success: 'Novo Terminal criado! Personalize suas configurações',
                error: 'Erro: Não foi possível criar um novo Terminal'
            }, {containerId: 'alert'}
        )        
    }

    const handleChange = (id, value, property) => {        
        
        const temp = [...terminals]
        let index = temp.findIndex(temp => temp._id === id)

        update('terminal', id, {[property]: value}).then((res) => {
            if(res.status === 200){
                setTerminals( prev => {
                    prev[index] = {...prev[index], [property]: value}
                    return [...prev]
                })
            }
        }).catch((err) => {
            toast.error(err, {containerId: 'alert'})
        })
    }  

    const handleChangeName = (id, name) => {
        if(!validateNewName(terminals, name)) return toast.error('Já existe um Terminal com este nome', {containerId: 'alert'})
        
        handleChange(id, name, 'name')
    }

    const deleteTerminal = (id) => {
        const temp = [...terminals]
        let index = temp.findIndex(temp => temp._id === id)
 
        if(index >= 0 && temp.length>1){
            
            toast.promise(
                destroy('terminal', id).then((res) => {
                    console.log(res.data)
                    temp.splice(index, 1)
                    setTerminals(temp)
                }),
                {
                    pending: 'Excluindo Terminal...',
                    success: 'Terminal excluído com sucesso!',
                    error: 'Erro: Não foi possível excluir o Terminal'
                }, {containerId: 'alert'}
            )
        }
        else toast.error('É necessário manter pelo menos um Terminal', {containerId: 'alert'})
    }

    function duplicateTerminal(id){

        const temp = [...terminals]
        const _terminal = temp.find(temp => temp._id === id)

        toast.promise(
            create('terminal', copyTerminal(terminals, _terminal, placeId)).then((res) => {
                setTerminals(terminals => [...terminals, res.data])  
                setTimeout(() => {scrollBottom()}, 100) 
            }),
            {
                pending: 'Duplicando Terminal...',
                success: 'Novo Terminal criado! Personalize suas configurações',
                error: 'Erro: Não foi possível criar um novo Terminal'
            }, {containerId: 'alert'}
        )        
    }
    
    return(
        <>
            <Header>
                <HeaderLeft>
                    <Button text={'Terminals'} status={true}/>
                    <Link to='../interfaces'>
                        <Button text={'Interfaces'} status={false}/>
                    </Link>
                    <VertLine/>
                    <AddButton text={'Novo-Terminal'} icon={''} onClick={addNewTerminal}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={terminaisHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
            {
                loading ? 
                <Page center>
                    <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                </Page>
                :
                <Page ref={page}>
                    {
                        terminals.filter(el => el.placeId === placeId).map((terminal, index) => {
                            return <ShrinkBox 
                                key={index}
                                id={terminal._id}
                                name={terminal.name}
                                data={terminal}
                                onChangeName={(id, name) => handleChangeName(id, name)}
                                onDuplicate={(id) => duplicateTerminal(id)}
                                onDelete={(id) => deleteTerminal(id)}
                                headerContent={<TerminalBoxHeader terminal={terminal}/>}
                                destroy={(id) => deleteTerminal(id)}
                            >
                                <TerminalBox data={terminal} 
                                    interfaces={interfaces} 
                                    printLayouts={printLayouts} 
                                    places={places} 
                                    setValue={(id, value, property) => handleChange(id, value, property)}
                                />
                            </ShrinkBox>
                        })
                    }               
                </Page>
            }
        </>
    )
}

export default Terminals