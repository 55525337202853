import { useEffect, useRef, useState, useContext } from "react"
import { TextInput, PasswordStrengthInput } from "../layout/Form"
import { Text, Row, TextLink, Position, Icon } from "../layout/Layout"
import { create } from "../../API/queries"
import { toast } from 'react-toastify';
import { AuthContext } from "../../contexts/AuthProvider"
import { HiCheck } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'

export default function ChangeBusinessPassword(){

    const { userAdmin } = useContext(AuthContext)

    const [oldPassword, setOldPassword] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)

    const [passwordStrength, setPasswordStrength] = useState(null)

    const [step, setStep] = useState('idle')

    const oldPasswordInputRef = useRef()
    const newPasswordInputRef = useRef()
    const confirmPasswordInputRef = useRef()

    useEffect(() => {

        const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

        const checkPasswordStrength = () => {
            if(strongPassword.test(newPassword)) setPasswordStrength('strong')
            else if(mediumPassword.test(newPassword)) setPasswordStrength('medium')
            else setPasswordStrength('weak')
        }

        if(newPassword !== null) checkPasswordStrength()
    },[newPassword])

    const validateOldPassword = () => {
        create('admin/validate', {password: oldPassword, adminId: userAdmin._id}).then(res => {
            if(res.status === 200) setStep('new')
        }).catch(err => {
            if(err.response.status === 401) toast.error('Senha Inválida', {containerId: 'alert'})
        })
    }

    const validateNewPassword = () => {
        if(passwordStrength === 'weak') return toast.error('Senha muito fraca. Utilize pelo menos um número, um caractere especial e uma letra maiúscula', {containerId: 'alert'})
        if(passwordStrength === 'medium') return toast.error('A senha não é forte o suficiente. Deve conter pelo menos 8 dígitos', {containerId: 'alert'})
        setStep('confirm')
    }

    const validateConfirmPassword = () => {
        if(newPassword !== confirmPassword) return toast.error('As senhas devem ser iguais', {containerId: 'alert'})
        changePassword()
    }

    const changePassword = () => {

        create('admin/changepassword', {oldPassword, newPassword, adminId: userAdmin._id}).then(res => {
            if(res.status === 200){
                toast.success('Senha alterada com sucesso!', {containerId: 'alert'})
            }
        }).catch(err => {
            if(err.response.status === 401) toast.error('Senha Inválida', {containerId: 'alert'})
            else if(err.response.status === 400) toast.error('Não foi possível alterar a senha. Entre em contato com o suporte', {containerId: 'alert'})
        })

        setStep('idle')
    }

    const handleConfirmation = () => {
        if(step === 'old') return validateOldPassword()
        if(step === 'new') return validateNewPassword()
        if(step === 'confirm') return validateConfirmPassword()
    }

    useEffect(() => {
        if(step === 'old') oldPasswordInputRef.current.focus()
        else if(step === 'new') newPasswordInputRef.current.focus()
        else if(step === 'confirm') confirmPasswordInputRef.current.focus()
        else if(step === 'idle') {
            setOldPassword(null)
            setNewPassword(null)
            setConfirmPassword(null)
            setPasswordStrength(null)
        }
    },[step])

    return(
        <>
            {
                step === 'idle' ? 
                <TextLink onClick={() => setStep('old')}>Alterar senha</TextLink>
                :   
                <Row position={'relative'}>
                    {
                        step === 'old' ? 
                        <TextInput ref={oldPasswordInputRef} type={'password'} placeholder={'Digite a senha atual'} width={'100%'} background={'#ECEFF1'} onChange={e => setOldPassword(e.target.value)} onKeyUp={(e) => {e.key === 'Enter' && handleConfirmation()}}/>
                        :
                        step === 'new' ? 
                        <>
                            <PasswordStrengthInput ref={newPasswordInputRef} type={'password'} placeholder={'Digite a nova senha'} width={'100%'} background={'#ECEFF1'} strength={passwordStrength} onChange={e => setNewPassword(e.target.value)} onKeyUp={(e) => {e.key === 'Enter' && handleConfirmation()}}/>
                            <Position right={0} bottom={-16}>
                                {
                                    passwordStrength === 'weak' ?
                                    <Text color={'#F34236'} weight={600}>Senha Fraca</Text>
                                    :
                                    passwordStrength === 'medium' ?
                                    <Text color={'#FB9800'} weight={600}>Senha Média</Text>
                                    :
                                    passwordStrength === 'strong' &&
                                    <Text color={'#00C853'} weight={600}>Senha Forte</Text>
                                }
                            </Position>
                        </>
                        :
                        step === 'confirm' &&
                        <TextInput ref={confirmPasswordInputRef} type={'password'} placeholder={'Confirme a nova senha'} width={'100%'} background={'#ECEFF1'} onChange={e => setConfirmPassword(e.target.value)} onKeyUp={(e) => {e.key === 'Enter' && handleConfirmation()}}/>
                    }
                    <Position right={-45} top={3}>
                        <Row align={'center'}>
                            <Icon pointer margin={'0'} size={18} color={'#00C853'} onClick={() => handleConfirmation()}>
                                <HiCheck/>
                            </Icon>
                            <Icon pointer margin={'0'} size={18} color={'#F34236'} onClick={() => setStep('idle')}>
                                <IoClose/>
                            </Icon>
                        </Row>
                    </Position>
                </Row>
            }
        </>
    )
}