import styled from "styled-components"
import HelpIcon from "../others/HelpIcon"
import { Column, Row, Spacer, Title } from "../layout/Layout"


const Toggle = styled.div`
    display: flex;
    align-items: center;
    height: 18px;
    font-size: 12px;
    border: 1px solid #00BFA5;
    color: #00BFA5;
    border-radius: 5px;
    margin-left: auto;
    cursor: pointer;

    div{        
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-of-type{
            width: 50px;
            height: 100%;
            border-radius: 5px 0 0 5px;
            background-color: ${props => props.state ? '#00BFA5':''};
            color: ${props => props.state ? 'white':''};
        }

        &:last-of-type{
            width: 64px;
            height: 100%;
            border-radius: 0 5px 5px 0;
            background-color: ${props => !props.state ? '#00BFA5':''};
            color: ${props => !props.state ? 'white':''};
        }
    }


`

function ToggleSwitchBig({text, state, setState, help}){
    
    return(
        <Row>
            <Column width={30}>
                <Row align={'center'}>
                        <Title>{text}</Title>
                        <HelpIcon content={help}/> 
                        <Toggle state={state}>
                            <div onClick={() => {setState(true)}}>Ativar</div>
                            <div onClick={() => {setState(false)}}>Desativar</div>
                        </Toggle>
                </Row>
            </Column>
        </Row>
    )
}

export default ToggleSwitchBig