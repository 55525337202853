import { Row, VertLine } from "../../../layout/Layout"
import { Tooltip } from 'react-tippy'
import AddrStamp from "../../../others/AddrStamp"
import DeviceStatus from "../../../others/DeviceStatus"
import { statusInfo } from "../../../../content/tooltips/terminalsTooltips"
import ShortIdTerminal from "../../../others/ShortIdTerminal"
import { useContext } from "react"
import { DataContext } from "../../../../contexts/DataProvider"

export default function TerminalBoxHeader({terminal}){

    const { places, services } = useContext(DataContext)
    
    return(
        <Row height={'100%'} width={'100%'} align={'center'}>
            <VertLine/>
            <AddrStamp before={places.find(el => el._id === terminal.placeId).name} after={services.filter(el => terminal.services.includes(el._id)).map(el => el.name)}/>
            <VertLine/>
            <Tooltip arrow={true} theme={'light'} html={statusInfo}>
                <DeviceStatus status={terminal.status}/>
            </Tooltip>
            {/* <VertLine/>
            <Tooltip arrow={true} theme={'light'} html={paperRollInfo}>
                <PaperRollStatus status={terminal.paper_roll}/>
            </Tooltip> */}
            <VertLine/>
            <ShortIdTerminal shortId={terminal.shortId}/>
        </Row>
    )
}