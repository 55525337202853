import { useState } from "react";
import { Header, HeaderLeft, HeaderRight } from "../../components/layout/Header";
import { NoScrollPage, Text } from "../../components/layout/Layout";
import { PulseLoader } from "react-spinners";
import { Button, TextField, Box, Snackbar, Alert } from "@mui/material";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import DownloadReportModal from "./downloadReportModal";
import ReportContent from "./ReportContent";
import { useAttendanceReportData } from "./AttendantPerformanceProvider";

export default function DashboardAttendantPerformance() {

    const { startDate, setStartDate, endDate, setEndDate, loadingReport, reportData } = useAttendanceReportData()

    const [showDownloadReportModal, setShowDownloadReportModal] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const today = new Date().toISOString().split('T')[0];

    const handleReportDownloadClick = () => {
        if(!reportData) return setSnackbarOpen(true)
        setShowDownloadReportModal(true)
    }

    return (
        <>
            <Header>
                <HeaderLeft>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <TextField
                            label="Data de Início"
                            type="date"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            inputProps={{ max: today }}
                        />
                        <Text>até</Text>
                        <TextField
                            label="Data de Fim"
                            type="date"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            inputProps={{ max: today }}
                        />
                    </Box>
                </HeaderLeft>
                <HeaderRight>
                    <Button
                        variant="outlined"
                        startIcon={<HiOutlineDocumentDownload />}
                        onClick={handleReportDownloadClick}
                    >
                        Baixar relatório
                    </Button>
                </HeaderRight>
            </Header>
            <NoScrollPage center={loadingReport || !reportData}>
                {
                    loadingReport ?
                        <PulseLoader color={'#00BFA5'} loading={1} size={10} />
                        :
                        !reportData ?
                            <Text size={18} weight={600} color="#90A4AE">Selecione um período válido para obter os dados</Text>
                            :
                            <ReportContent/>
                }
            </NoScrollPage>
            <DownloadReportModal
                open={showDownloadReportModal}
                onClose={() => setShowDownloadReportModal(false)}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
                    Selecione um período válido para baixar o relatório
                </Alert>
            </Snackbar>
        </>
    );
}
