import styled from "styled-components"

const Toggle = styled.span`
    display: flex;
    align-items: center;
    height: 35px;

    h1{
        font-size: 12px;
        font-weight: 400;
        margin-left: ${props => props.margin}px;
    }

    input{
        border: 1px solid #37474F;
        border-radius: 5px;
        height: 25px;
        width: ${props => props.width ? props.width : 20}%;
        margin-left: ${props => props.left ? props.left : 'auto'};
        padding-left: 3px;
        outline: none;
        color: #37474F; 

        &:focus{
            border: 1px solid #00BFA5;
        }
    }
`

function LabelNumber({id, text, min, max, step, input, value, margin, left, width, onBlur}){
    
    return(
        <Toggle margin={margin} left={left}>
            <h1>{text}</h1>
            <input id={id}
                type="number"
                min={min}
                max={max}
                step={step}
                width={width}
                value = {value}
                onChange={(e) => input(e.target.value)}
                onBlur={(e) => onBlur(e.target.value)}
            />
        </Toggle>
    )
}

export default LabelNumber