import styled from 'styled-components'
import { Text, Box, Row, Column, Icon } from '../../layout/Layout'
import FilaClockIcon from '../../../img/icons/FilaClockIcon'
import TotalsTypes from './TotalsTypes'
import TotalsDoughnut from './TotalsDoughnut'
import { useEffect, useState } from 'react'


const Totals = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 100%;
    height: fit-content;
    width: 100%;
    margin: 10px 0;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #CFD8DC;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`

function TotalWaitingChart({calls, typeColors, showLegend}){
    
    const [stats, setStats] = useState([])
    const [total, setTotal] = useState()

    const [types, setTypes] = useState([])
    const [totals, setTotals] = useState([])
    const [colors, setColors] = useState([])
    
    const getWaiting = () => {
        
        let types = [], waiting = [], totals = [], colors = [], total = 0
        
        calls.forEach(el => {
            if(!types.includes(el.type)) types.push(el.type)
        })
    
        for(let i = 0; i<types.length; i++){
            
            let count = 0
            let color = ''
            const index = calls.findIndex(el => el.type === types[i])
            const prior = calls[index].prior

            colors.push(typeColors[types[i]])
            color = typeColors[types[i]]
            
            calls.forEach(el => {
                if(el.type === types[i] && el.status === 'waiting') {
                    count += 1
                    total += 1
                }
            })
            
            totals.push(count)
            waiting.push({type: types[i], count, prior, color})
        }
    
        setColors(colors)
        setTypes(types)
        setTotals(totals)
        setTotal(total)
        setStats(waiting)
    }
    
    useEffect(() => {
        getWaiting()
    },[calls])
    
    return(
        <Box height={'100%'} padding={'15px'} marginBottom={'0px'}>
            <Column height={100}>
                <Row align={'center'}>
                    <Icon height={16}>
                        <FilaClockIcon/>
                    </Icon>
                    <Column>
                        <Text>Aguardando Atendimento</Text>
                        {
                            showLegend &&
                            <Text size={10.5} weight={200}>Dados em Tempo Real</Text>
                        }
                    </Column>
                </Row>
                <Row height={'100%'}>
                    <Column width={60} height={100} padding={10}>
                        <Text size={24} weight={800} color={'#00BFA5'}>{total}</Text>
                        <Text size={11} weight={300}>Pessoas Aguardando</Text>
                        <Totals>
                            {
                                stats.map((e, i) => <TotalsTypes key={i} index={i} type={e.type} priority={e.prior} total={e.count} color={e.color}/>)
                            }
                        </Totals>
                    </Column>
                    <Column width={40} height={100} padding={10}>
                        <TotalsDoughnut types={types} totals={totals} colors={colors}/>
                    </Column>
                </Row>
            </Column>
        </Box>
    )
}

export default TotalWaitingChart