import styled from "styled-components"
import { Column, Row, Text } from "../layout/Layout"
import InvoiceStatus from "./InvoiceStatus"
import { useState } from "react"
import Modal from "../modals/Modal"
import GeneralModalContainer from "../modals/GeneralModalContainer"
import PaymentModal from "./PaymentModal"
import PIXModal from "./PIXModal"

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    height: fit-content;
    width: 100%;
    color: #37474F;
    padding: 1rem 2rem;
    background-color: #fff;
    margin-bottom: 1rem;
    border-left-width: 5px;
    border-left-style: solid;
    border-left-color: ${props => props.color};
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 1px solid #37474F;
    border-radius: 999px;
    font-size: 12px;
    font-weight: 400;
    color: #37474F;
    cursor: pointer;
    white-space: nowrap;
    margin-left: 1rem;
    background-color: white;

    &:hover{
        border: 1px solid #00BFA5;
    }

    &:active{
        background-color: #00BFA5;
        color: white;

        svg{
            color: white;
        }
    }
`

export default function PendingInvoiceRow({data}){

    const { invoiceNumber, dueDate, value, description, status } = data

    const [showPayModal, setShowPayModal] = useState(false)
    const [showPIXModal, setShowPIXModal] = useState(false)
    
    const formatDueDate = (date) => {
        const day = date.substring(8,10)
        const month = date.substring(5,7)
        const year = date.substring(0,4)

        return `${day}/${month}/${year}`
    }

    return (
        <>
            <Container color={data.status === 'OVERDUE' ? '#F34236' : data.status === 'PENDING' && '#FB9800'}>
                <Column width={'20%'}>
                    <InvoiceStatus status={status}/>
                </Column>
                <Column>
                    <Row bottom={10}>
                        <Text weight={300} size={13} color={"#546E7A"}>{description}</Text>
                    </Row>
                    <Row>
                    <Column>
                        <Text weight={400} size={11} color={"#546E7A"}>Fatura</Text>
                        <Text weight={600} size={14} color={"#546E7A"}>{invoiceNumber}</Text>
                    </Column>
                    <Column>
                        <Text weight={400} size={11} color={"#546E7A"}>Vencimento</Text>
                        <Text weight={600} size={14} color={"#546E7A"}>{formatDueDate(dueDate)}</Text>
                    </Column>
                    <Column>
                        <Text weight={400} size={11} color={"#546E7A"}>Valor Total</Text>
                        <Text weight={600} size={14} color={"#546E7A"}>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value)}</Text>
                    </Column>
                    </Row>
                </Column>
                <Button onClick={() => setShowPIXModal(true)}>Pagar com PIX</Button>                
                <Button onClick={() => setShowPayModal(true)}>Pagar com Boleto</Button>                
            </Container>
            <Modal isOpen={showPayModal}>
                <GeneralModalContainer onClose={() => setShowPayModal(false)}>
                    <PaymentModal invoice={data}/>
                </GeneralModalContainer>
            </Modal>
            <Modal isOpen={showPIXModal}>
                <GeneralModalContainer onClose={() => setShowPIXModal(false)}>
                    <PIXModal invoice={data}/>
                </GeneralModalContainer>
            </Modal>
        </>
    )
}

