import { Column, Row, VertLine, Title, Spacer } from "../../../../layout/Layout";
import { Textarea } from '../../../../layout/Form'
import Select from "../../../../form/Select"
import SelectUIColor from '../../../../form/SelectUIColor'
import SelectAlert from '../../../../form/SelectAlert'
import ToggleLabel from '../../../../form/ToggleLabel'
import HelpIcon from "../../../../others/HelpIcon";
import { conteudoInfo, exhibitionInfo, alertInfo, colorInfo } from '../../../../../content/tooltips/displayTooltips'
import UploadImages from '../UploadImages'
import UploadVideos from '../UploadVideos'
import YoutubeUrl from '../YoutubeUrl'
import PanelPreview from '../preview'
import PreviewWarning from "../preview/PreviewWarning";
import { useState } from "react";
import SelectVoice from "../../../../form/SelectVoice";
import PlayAlert from "./PlayAlert";

export default function ConfigBox({id, config, setConfig}){

    const exhibitionOpts = ['Não exibir nada', 'Galeria de Imagens', 'Galeria de Vídeos', 'Vídeo do Youtube']
    const exhibitionValues = ['none', 'images', 'videos', 'youtube']

    const [tempMarqueeText, setTempMarqueeText] = useState(config.marquee_text)

    const handleChange = (value, property) => {
        const _config = {...config}

        _config[property] = value

        setConfig(_config)
    }

    const handleVoiceEnable = (state) => {

        if(!state) return handleChange(state, 'voice_enable')    

        setConfig({
            ...config,
            voice_enable: true,
            voice_id: 'Vitória',
            voice_type: 'full_call', 
        })
    }

    return(
        <Row top={20} bottom={20}>
            <Column width={40}>
                <Spacer top={2}/>
                <Row align={'center'}>
                    <Title>Alerta Sonoro</Title>
                    <HelpIcon content={alertInfo}/>
                </Row>
                <Spacer top={1.5}/>
                <SelectAlert
                    id={id}
                    enabled={config.alert_enable}
                    onToggle={(value) => handleChange(value, 'alert_enable')}
                    selected={config.alert}
                    onSelect={(alert) => handleChange(alert, 'alert')}
                />
                <SelectVoice
                    id={id}
                    enabled={config?.voice_enable}
                    selectedVoice={config?.voice_id}
                    selectedType={config?.voice_type}
                    onToggle={(value) => handleVoiceEnable(value)}
                    onChangeVoice={(voice) => handleChange(voice, 'voice_id')}
                    onChangeType={(type) => handleChange(type, 'voice_type')}
                />
                {
                    config?.voice_enable &&
                    <PlayAlert data={config}/>
                }
                <Spacer top={2}/>
                <Row align={'center'}>
                    <Title>Cor do tema</Title>
                    <HelpIcon content={colorInfo}/>
                </Row>
                <Spacer top={1}/>
                <SelectUIColor selected={config.theme} onSelect={(color) => handleChange(color, 'theme')}/>                    
                <Spacer top={2}/>
                <Row align={'center'}>
                    <Title>Conteúdo</Title>
                    <HelpIcon content={conteudoInfo}/>
                </Row>
                <Spacer top={1.5}/>
                <ToggleLabel id={'showData'+id} text={'Exibir Data e Hora'} state={config.show_time} onToggle={(value) => handleChange(value, 'show_time')}/>
                <ToggleLabel id={'showText'+id} text={'Exibir Texto Rotativo'} state={config.show_marquee} onToggle={(value) => handleChange(value, 'show_marquee')}/>
                {
                    config.show_marquee && 
                    <Textarea
                        type={'text'}
                        height={70}
                        value={tempMarqueeText}
                        onChange={(e) => setTempMarqueeText(e.target.value)}
                        onBlur={() => handleChange(tempMarqueeText, 'marquee_text')}
                    />
                }
                <Spacer top={2}/>
                <Row align={'center'}>
                    <Title>Exibição</Title>
                    <HelpIcon content={exhibitionInfo}/>
                </Row>
                <Spacer top={1.5}/>
                <Select id={'exhibition'+id} width={40} label={'O que exibir?'} value={config.exhibition} options={exhibitionOpts} values={exhibitionValues} onChange={(value) => handleChange(value, 'exhibition')}/>
                {
                    (config.exhibition === 'videos' || config.exhibition === 'youtube') &&
                    <ToggleLabel id={'video_sound'+id} text={'Reproduzir áudio de vídeos'} state={config.video_sound} onToggle={(value) => handleChange(value, 'video_sound')}/>
                }
                <Spacer top={1}/>
                {
                    config.exhibition === 'images' ?
                    <UploadImages id={id} urls={config.gallery_urls} onChange={(urls) => handleChange(urls, 'gallery_urls')}/>
                    :
                    config.exhibition === 'videos' ?
                    <UploadVideos id={id} urls={config.videos_urls} onChange={(urls) => handleChange(urls, 'videos_urls')}/>
                    :
                    config.exhibition === 'youtube' &&
                    <YoutubeUrl url={config.youtube_url} onChange={(value) => handleChange(value, 'youtube_url')}/>
                }
                <Spacer top={2}/>
            </Column>
            <VertLine space={4} height={100}/>
            <Column width={60} height={100}>
                <Spacer top={2}/>
                <Title>Preview</Title>
                <Spacer top={2}/>
                <PanelPreview config={config}/>
                <Spacer top={2}/>
                <PreviewWarning/>
            </Column>
        </Row>    
    )
}