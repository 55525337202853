import { Row } from "../../../../layout/Layout"
import { getColor } from "../../../../../content/Colors"
import { FooterContainer, MarqueeContainer, Text } from "./styles"
import Marquee from "react-fast-marquee"
import Clock from "./Clock"

export default function Footer({config}){

    return(
        <FooterContainer>
            {
                config.show_time && <Clock width={20}/>
            }
            <MarqueeContainer color={getColor(config.theme, 900)} width={config.show_time ? 80 : 100}>
                <Marquee gradient={false} speed={30}>
                    <Text weight={300} size={1.2}>{config.marquee_text}</Text>
                </Marquee>
            </MarqueeContainer>
        </FooterContainer>
    )
}