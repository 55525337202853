import { Paper, Box, Button, CircularProgress, Typography } from "@mui/material";
import { AiOutlineRobot } from 'react-icons/ai';
import MarkdownRenderer from './MarkdownRenderer';
import { useAttendanceReportData } from '../AttendantPerformanceProvider';

export default function IntelligentReportSection() {
    
    const { fetchIntelligentReport, intelligentReport, loadingIntelligentReport } = useAttendanceReportData();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Paper sx={{ flexGrow: 1, height: '100%', mb: 5, p: 3 }}>
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: intelligentReport ? 'flex-start' : 'center',
                        alignItems: intelligentReport ? 'flex-start' : 'center',
                        flexDirection: 'column'
                    }}
                >
                    {intelligentReport ? (
                        <MarkdownRenderer content={intelligentReport} />
                    ) : (
                        loadingIntelligentReport ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <CircularProgress size={24} />
                                <Typography>Gerando relatório inteligente...</Typography>
                            </Box>
                        ) : (
                            <Button
                                variant="outlined"
                                onClick={fetchIntelligentReport}
                                startIcon={<AiOutlineRobot />}
                            >
                                Gerar Relatório
                            </Button>
                        )
                    )}
                </Box>
            </Paper>
        </Box>
    );
}
