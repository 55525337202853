import { Row, Spacer, Column, Icon, Text } from '../../layout/Layout'
import { useState } from "react";
import { Button } from '../../buttons/Button'
import { toast } from 'react-toastify';
import HelpIcon from "../../others/HelpIcon";
import { newServiceInfo } from "../../../content/tooltips/placesTootips";
import { TbLayoutGridAdd } from "react-icons/tb"
import LabelTextInput from "../../form/LabelTextInput";
import { useContext } from 'react';
import { DataContext } from '../../../contexts/DataProvider';
import { create } from '../../../API/queries';
import { PlaceContext } from '../../../contexts/PlaceContext';

export default function ServicesAddNewModal({onClose}){

    const { placeId } = useContext(PlaceContext)
    const { services, setServices, pwTypes } = useContext(DataContext)
    
    const [name, setName] = useState(null) 
    const [prefix, setPrefix] = useState(null)

    const checkData = () => {
        if(!name){
            toast.error('Informe o nome do Serviço para continuar.', {containerId: 'alert'})
            return 0
        }

        if(!prefix) {
            toast.error('Informe o prefixo do Serviço para continuar.', {containerId: 'alert'})
            return 0
        }

        return 1
    }

    const validateServiceName = () => services.filter(el => el.placeId === placeId).every(el => el.name !== name)
    const validateServicePrefix = () => services.filter(el => el.placeId === placeId).every(el => el.prefix !== prefix)

    const getPwTypes = () => {
        return pwTypes.map(el => el._id)
    }

    const handleCreate = () => {
        if(!checkData()) return

        if(!validateServiceName(name)) return toast.error('Já existe um Serviço com este nome', {containerId: 'alert'})
        if(!validateServicePrefix(name)) return toast.error('Já existe um Serviço com este prefixo', {containerId: 'alert'})

        onClose()

        toast.promise(
            create('service', {name, placeId, prefix, pw_types: getPwTypes()})
            .then((res) => {
                setServices(services => [...services, res.data])  
            }),
            {
                pending: 'Criando Serviço...',
                success: 'Novo Serviço criado! Personalize suas configurações',
                error: 'Erro: Não foi possível criar o novo Serviço'
            }, {containerId: 'alert'}
        )
    }
    
    return(
        <Column>
            <Row align={'center'} marginBtm={18}>
                <Icon size={32} color="#00BFA5">
                    <TbLayoutGridAdd/>
                </Icon>
                <Text size={16}>
                    Novo Serviço
                </Text>
                <HelpIcon content={newServiceInfo}/>
            </Row>
            <LabelTextInput
                label={"Nome do Serviço"}
                value={name}
                max={35}
                state={true}
                onChange={(value) => setName(value)}
            />
            <LabelTextInput
                label={"Prefixo do Serviço"}
                value={prefix}
                max={2}
                state={true}
                onChange={(value) => setPrefix(value.toUpperCase())}
            />
            <Spacer top={1.5}/>
            <Row justify={'center'} marginTop={24}>
                <Button width={90} height={30} radius={15} onClick={handleCreate}>Criar</Button>
            </Row>  
        </Column>
    )
}
