import Select from "../../form/Select"
import { patterns, patternOptions } from "../../../resources/interfacePatterns"
import { Column } from "../../layout/Layout"

export default function LoadPatterns({onChange}){

    return(
        <Column>
            <Select label={'Escolha um Modelo'} placeholder={'Selecione um Modelo'} options={patternOptions} onChange={(value) => onChange(patterns[value])}/>
        </Column>
    )
}