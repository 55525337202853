import styled from "styled-components"
import { IoWarningOutline } from 'react-icons/io5'
import { BiLogIn, BiLogOut, BiX } from 'react-icons/bi'
import { RiUserShared2Line } from 'react-icons/ri'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #B0BEC5;
`

const Header = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    height: 3rem;
    
    svg{
        font-size: 18px;
        margin-right: 5px;
        color: ${props => props.urgent ? '#E57373' : '#78909C'};
    }

    #orange{
        color: #FB9800;
    }

    #green{
        color: #00BFA5;
    }
    
    h1{
        font-weight: 500;
    }
`

const Right = styled.span`
    display: flex;
    align-items: center;
    margin-left: auto;

    h1{
        font-weight: 300;
    }
`

const Message = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding-bottom: 1rem;
    color:  ${props => props.urgent && '#E53935'};
`

function NotifDisplayRow({data}){
    return(
        <Container>
            <Header>
                {data.type === 'warning' && 
                    <>
                        <IoWarningOutline id={'orange'}/>
                        <h1>Aviso</h1>
                    </>
                }
                {data.type === 'login' && 
                    <>
                        <BiLogIn id={'green'}/>
                        <h1>Login</h1>
                    </>
                }
                {data.type === 'logout' && 
                    <>
                        <BiLogOut id={'green'}/>
                        <h1>Logout</h1>
                    </>
                }
                {data.type === 'attendance' && 
                    <>
                        <RiUserShared2Line id={'green'}/>
                        <h1>Atendimento</h1>
                    </>
                }
                <Right>
                    <h1>{new Date(data.time).toLocaleString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'})}</h1>
                </Right>
            </Header>
            <Message>
                {data.message}
            </Message>
        </Container>
    )
}

export default NotifDisplayRow