export const localeInfo = <p style={{textAlign: 'left'}}>
Caso você tenha mais de um Estabelecimento ou Serviço, você poderá configurar nesta secção <strong>em qual local este terminal estará bem como os serviços para quais ele emitirá senhas</strong>. Sendo assim esse Terminal só emitirá senhas para atendimento no local selecionado.<br/><br/>
<strong>Multi-serviços:</strong> Se desejar você pode utilizar o mesmo Terminal para emitir senhas para mais de um Serviço em um mesmo Estabelecimento. Para isto basta clicar em <strong>Adicionar</strong> e selecionar os Serviços que deseja incluir. <strong>Obs.: </strong> O máximo de Serviços permitidos é 5.<br/><br/>
Para saber mais sobre Locais, vá em <strong>Locais</strong> no Menu Lateral.
</p>

export const servicesInfo = <p style={{textAlign: 'left'}}>
Nesta secção você poderá escolher para <strong>quais serviços este terminal emitirá senhas</strong>, ou sejá, só será possível emitir senhas para os serviços que estiverem inclusos aqui. Além disso, os serviços apareceram na tela do terminal na ordem em que são listados aqui.<br/><br/>
<strong>Obs.: </strong> Para incluir um serviço basta clicar em <strong>Adicionar Serviço</strong>, e para excluir clique no <strong>sinal de menos</strong> ao lado daquele que deseja excluir. 
</p>

export const printLayoutsInfo = <p style={{textAlign: 'left'}}>
O <strong>Layout de Impressão</strong> é o conteúdo que será impresso nas senhas emitidas por este Terminal. <br/><br/>
Você deverá escolher um <strong>Layout Padrão</strong> que será utilizado para este Terminal, e caso deseje poderá adicionar outros Layouts para serem utilizador em datas, horários ou dias da semana específicos. <br/><br/>
<strong>Dica.: </strong> A adição de outros Layouts de Impressão é um recurso muito útil para utilizar em campanhas temporárias ou caso sua empresa possua atendimentos diferenciados em dias ou horários específicos, 
facilitando o trabalho de gestão destes recursos uma vez que eles podem ser configurados antecipadamente, e quando chegar no momento programado eles irão ser ativados de forma automática.<br/><br/>
<strong>Obs.: </strong> Os Layouts de Impressão são gerenciados em <strong>Senhas/Layouts de Impressão</strong> no menu lateral.
</p>

export const interfacesInfo = <p style={{textAlign: 'left'}}>
O <strong>Design da Interface</strong> é o conjunto de parâmetros (cores, textos e imagens) que serão utilizados para exibir na tela do Terminal. <br/><br/>
Você deverá escolher uma <strong>Interface Padrão</strong> que será utilizada para este Terminal, e caso deseje poderá adicionar outras Interfaces para serem utilizadas em datas, horários ou dias da semana específicos. <br/><br/>
<strong>Dica.: </strong> A adição de outras Interfaces é um recurso muito útil para utilizar em campanhas temporárias ou caso sua empresa possua atendimentos diferenciados em dias ou horários específicos, 
facilitando o trabalho de gestão destes recursos uma vez que eles podem ser configurados antecipadamente, e quando chegar no momento programado eles irão ser ativados de forma automática.<br/><br/>
<strong>Obs.: </strong> As Interfaces podem ser personalizadas clicando em <strong>Interfaces</strong> no cabeçalho.
</p>

export const autoAproachInfo = <p style={{textAlign: 'left'}}>
<strong>Abordagem Automática</strong> é um recurso exclusivo que exibe uma tela enquanto o Terminal estiver ocioso, e quando um usuário se aproxima do equipamento ela muda 
automaticamente para a tela de emissão de senhas com a opções disponíveis, podendo ainda ser reproduzido um áudio que o saudará no momento da sua aproximação<br/><br/>
O conteúdo a ser exibido pode ser definido pelo administrador, <strong>podendo ser uma propaganda ou divulgação de algum serviço ou promoção, vídeos ou imagens da empresa</strong>, ou ainda simplesmente a sua logo.<br/><br/>
</p>

export const autoAproachExhibInfo = <p style={{textAlign: 'left'}}>
Este é o <strong>conteúdo que será exibido</strong> durante o momento que o Terminal de Emissão de Senhas estiver ocioso. No momento em que o usuário se aproximar do equipamento este conteúdo cessará a sua exibição e a Tela de Senhas será exibida.<br/><br/>
Você pode optar por <strong>não exibir nenhum conteúdo</strong>. Neste caso a exibição sempre permanecerá na tela de senhas.<br/><br/>
Pode optar também por exibir uma <strong>Galeria de Imagens</strong>, que serão exibidas na tela, uma atrás da outra. É permitido carregar <strong>até 10 imagens</strong> na galeria.<br/><br/>
Caso queira <strong>exibir um vídeo</strong>, você pode selecionar a opção de <strong>Vídeo</strong> e carregar um vídeo de até 5 minutos.   
</p>

export const autoAproachAudioInfo = <p style={{textAlign: 'left'}}>
Este é o <strong>áudio que será reproduzido</strong> no momento que o usuário se aproximar do Terminal de Emissão de Senhas. Caso deseje utilizá-lo, ative o recurso e faça o carregamento de um arquivo de áudio (.mp3) clicando em <strong>carregar</strong>.  
</p>

export const autoAproachStartInfo = <p style={{textAlign: 'left'}}>
Este é o tempo em segundos que <strong>o conteúdo começará a ser exibido</strong> contados após a emissão da última senha.
</p>

export const confirmationInfo = <p style={{textAlign: 'left'}}>
<strong>Confirmar Emissão</strong> é um recurso que é ativado após a emissão de uma senha e oferece um feedback audiovisual para o usuário.
Ou seja, se este recurso estiver ativo, assim que o usuário realizar a escolha de sua senha e a impressão da mesma ocorrer, o Terminal exibirá em sua tela um conteúdo de confirmação, que pode ser uma imagem ou um pequeno vídeo com um agradecimento, um aviso, ou o que desejar.<br/><br/>
</p>

export const confirmExhibInfo = <p style={{textAlign: 'left'}}>
Este é o <strong>conteúdo que será exibido</strong> assim que uma senha for emitida no Terminal de Emissão de Senhas. Uma forma de feedback visual para o usuário, que pode ser um agradecimento ou divulgação de algo.<br/><br/>
Você pode optar por simplesmente <strong>não exibir nenhum conteúdo</strong>, ou exibir uma <strong>Imagem</strong> ou <strong>Vídeo</strong> que pode ter no <strong>máximo 10 segundos de duração</strong>.
</p>

export const confirmAudioInfo = <p style={{textAlign: 'left'}}>
Este é o <strong>áudio que será reproduzido</strong> após a emissão da senha, como por exemplo um áudio que diz: <strong>"Obrigado!"</strong> ou <strong>"Retire sua senha e aguarde o chamado no painel!"</strong>. Caso deseje utilizá-lo, ative o recurso e faça o carregamento de um arquivo de áudio (.mp3) clicando em <strong>carregar</strong>.  
</p>

export const confirmDurationInfo = <p style={{textAlign: 'left'}}>
Este é o tempo em segundos que <strong>o conteúdo escolhido permanecerá sendo exibido na tela</strong>, após esse tempo o Terminal volta para a tela principal.
</p>

export const statusInfo = <p style={{textAlign: 'left'}}>
Indica se há algum equipamento <strong>conectado e operando</strong> com esta configuração no momento.
</p>

export const paperRollInfo = <p style={{textAlign: 'left'}}>
Indica o estado que a <strong>bobina para impressão de senhas</strong> do equipamento se encontra.<br/><br/>
<strong style={{color: '#00C853'}}>Cheia: </strong> A bobina está cheia e o equipamento fazendo impressões normalmente. <strong>Fique tranquilo!</strong><br/><br/>
<strong style={{color: '#FB9800'}}>Acabando: </strong> O equipamento está fazendo impressões porém a bobina está quase no fim. <strong>Fique de olho!</strong><br/><br/>
<strong style={{color: '#F34236'}}>Acabou: </strong> A bobina acabou e o equipamento não está mais fazendo impressões. <strong>Troque a bobina imediatamente!</strong><br/><br/>
<strong style={{color: '#90A4AE'}}>Sem Informação: </strong> O Terminal provavelmente se encontra desconectado e não é possível adiquirir informações sobre a bobina.
</p>









