export const cardAttendantsInfo = <p style={{textAlign: 'left'}}>
Este card informa a <strong>quantidade de guichês</strong> que estão operando neste local no momento, bem como o <strong>total de guichês</strong> cadastrados nele.<br/><br/>
Para adicionar novos guichês neste local, vá em <strong>Atendentes</strong> no menu lateral e clique em <strong>Novo Guichê</strong>.
</p>

export const cardQueueInfo = <p style={{textAlign: 'left'}}>
Este card informa a <strong>quantidade de pessoas</strong> que foram atendidas neste local durante o expediente atual.<br/><br/>
</p>

export const cardWaitingInfo = <p style={{textAlign: 'left'}}>
Este card informa a <strong>quantidade de pessoas</strong> que estão na fila aguardando por atendimento neste local.<br/><br/>
</p>

export const cardTerminalsInfo = <p style={{textAlign: 'left'}}>
Este card informa quais os <strong>Terminais de Senhas</strong> que estão vinculados neste local, bem como se eles estão <strong>ativos</strong> ou <strong>inativos</strong> no presente momento.<br/><br/>
Caso haja mais de um Terminal no local, você poderá navagar por eles através das setas. 
</p>

export const cardPanelsInfo = <p style={{textAlign: 'left'}}>
Este card informa quais os <strong>Paineis</strong> que estão vinculados neste local, bem como se eles estão <strong>ativos</strong> ou <strong>inativos</strong> no presente momento.<br/><br/>
Caso haja mais de um Painel no local, você poderá navagar por eles através das setas. 
</p>

export const newPlaceInfo = <p style={{textAlign: 'left'}}>
Para cadastrar um Novo Local ou Serviço você deve optar por utilizar um <strong>Estabelecimento já existe</strong> e selecioná-lo ou ainda poderá <strong>criar um novo Estabelecimento</strong> dando um nome a ele.<br/><br/>
Em seguida basta dar um <strong>nome ao novo Serviço</strong> que deseja cadastrar, e informar também o prefixo deste serviço que aparecerá na senha. 
</p>

export const newServiceInfo = <p style={{textAlign: 'left'}}>
Para cadastrar um <strong>Novo Serviço</strong> você deve informar o seu <strong>nome e o prefixo</strong> que será utilizado para identificá-lo. O nome do serviço será o mesmo que aparecerá na tela de emissão de senhas, e o prefixo é composto de até duas letras maiúsculas,
e fará parte da senha em si.<br/><br/>
<strong>Exemplo: </strong> Exames - EX (EX001, EX002) 
</p>