import { ClockContainer, Text } from "./styles"

export default function Clock({width}){

    return(
        <ClockContainer width={width}>
            <Text size={1} weight={500}>12:00</Text>
            <Text size={0.8} weight={300}>01 de Janeiro de 2023</Text>
        </ClockContainer>
    )
}