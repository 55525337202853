import { Row, Column, Button, Text, TextLink } from '../../../layout/Layout'
import { File } from '../../../layout/Form'
import { useRef, useState } from 'react'
import MoonLoader from "react-spinners/MoonLoader"
import { toast } from "react-toastify";
import { create } from '../../../../API/queries'
import { BsUpload } from 'react-icons/bs'

export default function UploadImages({urls, onChange}){

    const [loading, setLoading] = useState(false)

    const inputRef = useRef()

    const pushUrls = (_urls) => {

        const __urls = [...urls]

        onChange(__urls.concat(_urls))
    }

    const handleUpload = (e) => {

        const totalLength = urls.length + e.target.files.length

        if(totalLength > 10){
            toast.error('O limite de imagens é de 10 arquivos', {containerId: 'alert'})
            return 0
        }

        setLoading(true)

        e.preventDefault()

        const formData = new FormData();

        for(let i=0; i<e.target.files.length; i++) formData.append('file', e.target.files[i])

        create('upload/images', formData).then(res => {
            setLoading(false)
            pushUrls(res.data.urls)
        }).catch(err => {
            setLoading(false)
            toast.error(`Não foi possível carregar as imgens. Error: ${err}`, {containerId: 'alert'})
        })

        e.target.value = ''

    }

    return(
        <Column>
            <Row justify={'center'}>
                {
                    !loading ? 
                    <Button onClick={() => inputRef.current.click()}>
                        <BsUpload/>
                        Carregar Imagens
                    </Button>
                    :
                    <Button>
                        <MoonLoader color={'black'} loading={true} size={16}/>
                        <Text wrap={'pre'} weight={600}> Carregando...</Text>
                    </Button>
                }
                <File ref={inputRef} id={'gallery_upload'} type={'file'} multiple accept=".jpg,.png,.gif" onChange={(e) => handleUpload(e)}/>
            </Row>
            <Row justify={'center'}>
                {
                    urls.length === 0 ?
                    <Text wrap={'normal'} weight={600}>Nenhuma imagem carregada</Text>
                    :
                    <Column align={'center'}>
                        <Text wrap={'normal'} weight={600}>{`${urls.length} imagens carregadas`}</Text>
                        <TextLink onClick={() => onChange([])}>Excluir Imagens</TextLink>
                    </Column>
                }
            </Row>
        </Column>
    )
}