import styled from "styled-components"

const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 7px;
    width: fit-content;
    margin-top: 5px; 
    border-radius: 0.5rem;
    background-color: ${props => props.color};
    font-size: 0.9rem;
    font-weight: 600;
    color: white;
`

export default function InvoiceStatus({status}){

    const getColor = (status) => {
        switch (status) {
            case 'RECEIVED': return '#00C853'
            case 'OVERDUE': return '#F34236'
            case 'PENDING': return '#FB9800'
        
            default: break;
        }
    } 

    const getLabel = (status) => {
        switch (status) {
            case 'RECEIVED': return 'Paga'
            case 'OVERDUE': return 'Vencida'
            case 'PENDING': return 'Em aberto'
        
            default: break;
        }
    } 

    return(
        <Status color={getColor(status)}>
            {
                getLabel(status)
            }
        </Status>
    )
}