import styled from "styled-components"
import { Header, HeaderLeft, HeaderRight } from "../components/layout/Header"
import { Spacer } from '../components/layout/Layout'
import AddButton from "../components/buttons/AddButton"
import AverageTimeByDeskChart from "../components/dashboard/guiches/AverageTimeByDeskChart"
import TotalCallsByDeskChart from "../components/dashboard/guiches/TotalCallsByDeskChart"
import InfoTooltip from "../components/others/InfoTooltip"
import { dashboardDesksHeaderInfo } from '../content/tooltips/headerTooltipsInfo'
import { useState, useRef, useEffect, useContext } from "react"
import { DataContext } from "../contexts/DataProvider"
import { toast } from 'react-toastify';
import { create, update, destroy } from '../API/queries'
import DesksStats from "../components/dashboard/guiches/DesksStatsBox"
import PulseLoader from "react-spinners/PulseLoader"
import HeaderFixedMenu from "../components/headerFixedMenu"
import { AuthContext } from "../contexts/AuthProvider"
import { CallsContext } from "../contexts/CallsProvider"
import DeskCard from "../components/dashboard/guiches/deskCard"
import Modal from "../components/modals/Modal"
import GeneralModalContainer from "../components/modals/GeneralModalContainer"
import DeskAddNewModal from "../components/dashboard/guiches/DeskAddNewModal"
import { PlaceContext } from "../contexts/PlaceContext"


const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.center && 'center'};
    justify-content: ${props => props.center && 'center'};
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const Cards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 20rem;
    grid-gap: 1.5rem;
    width: 100%;
    height: 75%;
    overflow: auto;
    padding-right: 1rem;

    &::-webkit-scrollbar{
        width: 10px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #B0BEC5;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`

const Charts = styled.div`
    display: flex;
    width: 100%;
    height: 25%;
    margin-top: 15px;
`

function DashboardDesks(){

    const cards = useRef(null)

    const { placeId } = useContext(PlaceContext)
    const { business } = useContext(AuthContext)
    const { finished: calls } = useContext(CallsContext)
    const { dataLoaded, desks, pwTypes, setDesks, services} = useContext(DataContext)
    
    const [showNew, setShowNew] = useState(false)
    
    const [loading, setLoading] = useState(true)

    const newDesk = (data) => {

        const {name, number} = data
        
        return{
            name: name + ' ' + number,
            number: number,
            placeId,
            status: 'inactive',
            pw_types: pwTypes.map(el => el.name),
            services: services.filter(el => el.placeId === placeId).map(el => el.name),
            user: {
                id: '',
                name: '',
                rating: []
            }
        }
    }

    const scrollBottom = () => {
        
        const el = cards.current 
        
        el.scrollTo({
            top: 999999999,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if(dataLoaded && calls !== null) setLoading(false)
    },[dataLoaded, calls])

    const handleAddButton = () => {
        if(desks.length === business.desk_limit) return toast.error(`O seu plano não permite o cadastro de mais de ${business.desk_limit} guichês. Aumente o limite do plano na página "Minha Conta"`, {containerId: 'alert'})
        
        setShowNew(true)
    }

    const addNewDesk = (data) => {

        setShowNew(false)
        
        toast.promise(
            create('desk', newDesk(data)).then((res) => {
                setDesks(desks => [...desks, res.data])  
                setTimeout(() => {scrollBottom()}, 100) 
            }),
            {
                pending: 'Criando Guichê...',
                success: 'Novo Guichê criado!',
                error: 'Erro: Não foi possível criar um novo Guichê'
            }, {containerId: 'alert'}
        )     
    }

    const handleChange = (id, value, property) => {        
        
        const temp = [...desks]
        let index = temp.findIndex(temp => temp._id === id)

        setDesks( prev => {
            prev[index] = {...prev[index], [property]: value}
            return [...prev]
        })

        update('desk', id, {[property]: value}).then((res) => {
            console.log(res)
        }).catch((err) => {
            toast.error(err, {containerId: 'alert'})
        })
    } 

    const destroyDesk = (id) => {
        const temp = [...desks]
        let index = temp.findIndex(temp => temp._id === id)
        
        if(index >= 0 && temp.length>1){
            
            toast.promise(
                destroy('desk', id).then((res) => {
                    console.log(res.data)
                    temp.splice(index, 1)
                    setDesks(temp)
                }),
                {
                    pending: 'Excluindo Guichê...',
                    success: 'Guichê excluído com sucesso!',
                    error: 'Erro: Não foi possível excluir o Guichê'
                }, {containerId: 'alert'}
            )
        }
        else toast.error('É necessário manter pelo menos um Guichê', {containerId: 'alert'})
    }

    const manuallyDisconnectDesk = (id) => {
        const temp = [...desks]
        let index = temp.findIndex(temp => temp._id === id)

        toast.promise(
            update('desk/disconnect', id, {}).then(res => {
                console.log(res.data)
                temp.splice(index, 1, res.data.desk)
                setDesks(temp)
            }),
            {
                pending: 'Desconectando Guichê...',
                success: 'Guichê desconectado com sucesso!',
                error: 'Erro: Não foi possível desconectar o Guichê'
            }, {containerId: 'alert'}
        )
    }
    
    return(
        <>
            <Header>
                <HeaderLeft>
                    <AddButton text={'Novo-Guichê'} icon={''} onClick={() => handleAddButton()}/>
                </HeaderLeft>
                <HeaderRight>
                    <InfoTooltip html={dashboardDesksHeaderInfo}/>
                    <HeaderFixedMenu/>
                </HeaderRight>
            </Header>
            {   
                loading ?
                <Page center>
                    <PulseLoader color={'#00BFA5'} loading={loading} size={10}/>
                </Page>
                :
                <Page>
                    <Cards ref={cards}>
                        {
                            desks.filter(el => el.placeId === placeId).sort((a,b) => a.number-b.number).map((desk, index) => {
                                return <DeskCard
                                    key={index}
                                    data={desk}
                                    calls={calls.filter(call => call.desk_id === desk._id)}
                                    setValue={(id, value, property) => handleChange(id, value, property)}
                                    onDelete={(id) => destroyDesk(id)}
                                    onDisconnect={(id) => manuallyDisconnectDesk(id)}
                                />
                            })
                        }
                    </Cards>
                    <Charts>
                        <DesksStats desks={desks.filter(el => el.placeId === placeId)}/>
                        <Spacer left={1.5}/>
                        <TotalCallsByDeskChart
                            desks={desks}
                            calls={calls.filter(call => call.placeId === placeId)}/>
                        <Spacer left={1.5}/>
                        <AverageTimeByDeskChart
                            desks={desks}
                            calls={calls.filter(call => call.placeId === placeId)}/>
                    </Charts>
                </Page>
            }
            <Modal isOpen={showNew}>
                <GeneralModalContainer onClose={() => setShowNew(false)}>
                    <DeskAddNewModal onCreate={(data) => addNewDesk(data)}/>
                </GeneralModalContainer>
            </Modal>
        </>
    )
}

export default DashboardDesks