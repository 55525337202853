import { Panel, Show, Side } from "./styles"
import SidePanel1 from "./SidePanel1"
import SidePanel2 from "./SidePanel2"
import Footer from "./Footer"
import ImageExhibition from "./exhibitions/ImageExhibition"
import VideoExhibition from "./exhibitions/VideoExhibition"
import YouTubeExhibition from "./exhibitions/YouTubeExhibition"
import NoExhibition from "./exhibitions/NoExhibition"

export default function PanelPreview1({config}){



    return(
        <Panel>
            <Show>
                {
                    config.exhibition === 'images' ? 
                    <ImageExhibition urls={config.gallery_urls}/>
                    :
                    config.exhibition === 'videos' ? 
                    <VideoExhibition urls={config.videos_urls}/>
                    :
                    config.exhibition === 'youtube' ? 
                    <YouTubeExhibition url={config.youtube_url}/>
                    :
                    config.exhibition === 'none' &&
                    <NoExhibition theme={config.theme}/> 
                }
            </Show>
            <Side>
                {
                    config.exhibition === 'none' ? 
                    <SidePanel2 theme={config.theme}/>
                    :
                    <SidePanel1 theme={config.theme}/>
                }
            </Side>
            <Footer config={config}/>
        </Panel>
    )
}