import styled from "styled-components";
import { useEffect, useState } from "react";
import { Column, Row, Icon, Text, Spacer, TextLink } from "../layout/Layout";
import { BiDollarCircle } from 'react-icons/bi'
import { IoMdBarcode } from 'react-icons/io'
import { getByID } from "../../API/queries";
import MoonLoader from "react-spinners/MoonLoader"
import { BsCheck2Circle } from 'react-icons/bs'

const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: fit-content;
    border: 1px solid #37474F;
    border-radius: 999px;
    font-size: 12px;
    font-weight: 400;
    color: #37474F;
    cursor: pointer;
    white-space: nowrap;
    background-color: white;

    &:hover{
        border: 1px solid #00BFA5;
    }

    &:active{
        background-color: #00BFA5;
        color: white;

        svg{
            color: white;
        }
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid #00BFA5;
    border-radius: 1rem;
    align-items: center;
    padding: 1rem;
`

export default function PaymentModal({invoice}){

    const [loading, setLoading] = useState(true)
    const [identificationData, setIdentificationData] = useState(null)
    const [fieldCopied, setFieldCopied] = useState(false)

    useEffect(() => {

        const getIdentificationField = () => {
            getByID('invoices/identificationfield', invoice.id).then((res) => {
                setIdentificationData(res.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => setLoading(false))
        }

        getIdentificationField()
    },[invoice])

    async function copyField(field) {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(field);
        } else {
          return document.execCommand('copy', true, field);
        }
    }

    const handleCopyClick = (field) => {
        copyField(field).then(() => {
            setFieldCopied(true)
        }).catch((err) => {
            console.log(err);
        });
    }

    return(
        <Column>
            <Row align={'center'}>
                <Icon size={32} color={'#00BFA5'}>
                    <BiDollarCircle/>
                </Icon>
                <Text weight={600} size={14} color={'#455A64'}>Pagar com Boleto</Text>
            </Row>
            <Spacer top={2}/>
            <Column align={'center'} width={'35rem'}>
                {
                    loading ?
                    <MoonLoader color={'black'} loading={true} size={16}/>
                    :
                    <>
                        <Text wrap={'wrap'} align={'center'}>Para pagar este boleto você pode utilizar a linha digitável abaixo, informando o valor da fatura, ou se desejar pode abrí-lo clicando no botão.</Text>
                        <Spacer top={1}/>
                        <Row align={'center'} bottom={6}>
                            <Icon size={20} color={'#00BFA5'}>
                                <IoMdBarcode/>
                            </Icon>
                            <Text weight={600}>Linha Digitável</Text>
                        </Row>
                        <Box>
                            <Text>{identificationData?.identificationField}</Text>
                            <Spacer left={1}/>
                            <Row width={'4rem'} align={'center'} justify={'center'} height={'2rem'}>
                                {
                                    fieldCopied ?
                                    <Icon size={18} color="#00C853">
                                        <BsCheck2Circle/>
                                    </Icon>
                                    :
                                    <TextLink onClick={() => handleCopyClick(identificationData?.identificationField)}>Copiar</TextLink>
                                }
                            </Row>
                        </Box>
                        <Row justify={'center'} top={24}>
                            <Button href={invoice.bankSlipUrl} target="_blank" rel="noreferrer">Abrir Boleto</Button>
                        </Row>
                    </>
                }
            </Column>
        </Column>
    )
}