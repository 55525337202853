import { ShowContainer, Video, Text } from "../styles"
import { MdOutlineVideocamOff } from 'react-icons/md'
import { Icon } from "../../../../../layout/Layout"
import { useState, useEffect, useRef } from "react"

export default function VideoExhibition({urls}){

    const [index, _setIndex] = useState(0)
    const indexRef = useRef(0)
    const intervalRef = useRef()

    const setIndex = (index) => {
        indexRef.current = index
        _setIndex(index)
    }

    const startInterval = () => {
        intervalRef.current = setInterval(() => {
            if(indexRef.current === urls.length-1) setIndex(0)
            else setIndex(indexRef.current + 1)
        }, 5000);
    }

    useEffect(() => {
        if(urls.length > 0) {
            startInterval()
            setIndex(0)
        }

        return () => clearInterval(intervalRef.current)
    },[urls])

    return(
        <ShowContainer>
            {
                urls.length === 0 ? 
                <>
                    <Icon size={40} color={'#90A4AE'}>
                        <MdOutlineVideocamOff/>
                    </Icon>
                    <Text size={1.3} color={'#90A4AE'} weight={600}>Carregue os vídeos</Text>
                    <Text size={1.3} color={'#90A4AE'} weight={600}>para exibir</Text>
                </>
                :
                <Video src={`${urls[index]}#t=10`}></Video>
            }
        </ShowContainer>
    )
}