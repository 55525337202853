import styled from "styled-components";
import { RiArrowLeftSLine, RiArrowRightSLine, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import { Row, Border, Text, Column, Icon, Spacer } from "../../layout/Layout";
import { useState } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";
import { DataContext } from "../../../contexts/DataProvider";
import { update } from "../../../API/queries";

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    height: ${props => props.height}rem;
    border: 1px solid #CFD8DC;
    border-radius: 0.5rem;
`

const PwType = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.6rem;
    width: 100%;
    border-radius: 0.5rem;
    padding: 0 0.5rem;
    background-color: ${props => props.selected && '#CFD8DC'};
    font-size: 1rem;
    color: #37474F;
    font-weight: 500;
    cursor: pointer;
`

function PwTypeSelect({type, selected, onSelect}){

    return(
        <PwType selected={selected} onClick={() => onSelect()}>
            {type}
        </PwType>
    )
}

export default function ServiceBoxPasswords({service, selected}){

    const { pwTypes: types, services, setServices } = useContext(DataContext)

    const [toInclude, setToInclude] = useState(null)
    const [toExclude, setToExclude] = useState(null)

    const handleChangePwTypes = (id, pwtypes) => {
        const temp = [...services]
        let index = temp.findIndex(temp => temp._id === service._id)

        update('service', id, {pw_types: pwtypes}).then((res) => {

            if(res.status === 200){
                setServices( prev => {
                    prev[index] = {...prev[index], pw_types: pwtypes}
                    return [...prev]
                })
            }
            else toast.error(res, {containerId: 'alert'})
        }).catch((err) => {
            toast.error(err, {containerId: 'alert'})
        })
    }

    const handleInclude = () => {

        const temp = [...service.pw_types]

        if(temp.length === 4) return toast.error('Você pode disponibilizar até 4 Tipos de Senha para cada Local', {containerId: 'alert'})

        temp.push(toInclude)

        setToInclude(null)

        handleChangePwTypes(service._id, temp)
    }

    const handleExclude = () => {

        const temp = [...service.pw_types]

        if(temp.length === 1) return toast.error('Você deve manter pelo menos um Tipo de Senha para o Local', {containerId: 'alert'})

        const index = temp.findIndex(el => el === toExclude)

        temp.splice(index, 1)

        setToExclude(null)
        
        handleChangePwTypes(service._id, temp)      
    }

    const handleMoveUp = () => {
        const temp = [...service.pw_types]

        const index = temp.findIndex(el => el === toExclude)

        if(index === 0) return

        temp.splice(index, 1)
        temp.splice(index-1, 0, toExclude)

        handleChangePwTypes(service._id, temp)      

    }

    const handleMoveDown = () => {
        const temp = [...service.pw_types]

        const index = temp.findIndex(el => el === toExclude)

        if(index === temp.length-1) return

        temp.splice(index, 1)
        temp.splice(index+1, 0, toExclude)

        handleChangePwTypes(service._id, temp)  

    }

    const getTypeName = (id) => {
        return types.find(type => type._id === id).name
    }

    return(
        <Row top={20} bottom={20}>
            <Column width={40}>
                <Border border={'#90A4AE'} padding={10}>
                    <Row height={25}>
                        <Column width={45} align={'center'}>
                            <Text weight={600}>Emitir</Text>
                        </Column>
                        <Column width={10}/>
                        <Column width={45} align={'center'}>
                            <Text weight={600} color={'#F34236'}>Não Emitir</Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column width={'fit-content'} justify={'space-around'}>
                            <Icon pointer size={20} margin={'0'} color={'#78909C'} hoverColor={'#00BFA5'} onClick={() => handleMoveUp()}>
                                <RiArrowUpSLine/>
                            </Icon>
                            <Icon pointer size={20} margin={'0'} color={'#78909C'} hoverColor={'#00BFA5'} onClick={() => handleMoveDown()}>
                                <RiArrowDownSLine/>
                            </Icon> 
                        </Column>
                        <Box height={types.length * 1.6}>
                            {
                                service.pw_types.map((e,i) => {
                                    return <PwTypeSelect key={i} type={getTypeName(e)} color={'green'} selected={toExclude === e} onSelect={() => {setToExclude(e); setToInclude(null)}}/>
                                })
                            }
                        </Box>
                        <Column width={10} justify={'center'} align={'center'}>
                            {
                                (!toExclude && toInclude) ?
                                <Icon pointer size={20} margin={'0'} color={'#78909C'} hoverColor={'#00BFA5'} onClick={() => handleInclude()}>
                                    <RiArrowLeftSLine/>
                                </Icon>
                                :
                                (toExclude && !toInclude) &&
                                <Icon pointer size={20} margin={'0'} color={'#78909C'} hoverColor={'#F34236'} onClick={() => handleExclude()}>
                                    <RiArrowRightSLine/>
                                </Icon>
                            }
                        </Column>
                        <Box height={types.length * 1.6}>
                            {
                                types.filter(el => !service.pw_types.includes(el._id)).map((e,i) => {
                                    return <PwTypeSelect key={i} type={e.name} selected={toInclude === e._id} onSelect={() => {setToInclude(e._id); setToExclude(null)}}/>
                                })
                            }
                        </Box>
                    </Row>
                </Border>
            </Column>
            <Spacer left={8}/>
            <Column width={60}>
                <Text weight={600} decoration={'underline'}>Observações:</Text>
                <Spacer top={1}/>
                <Text wrap={'wrap'}>- A tabela ao lado mostra os tipos de senhas que este serviço irá disponibilizar para o atendimento.</Text>
                <Spacer top={0.3}/>
                <Text wrap={'wrap'}>- As senhas são exibidas no Terminal de Senhas na ordem em que aparecem aqui. Para modificar essa ordem selecione uma senha e utilize as setas ao lado.</Text>
                <Spacer top={0.3}/>
                <Text wrap={'wrap'}>- Para incluir ou excluir uma senha, selecione-a e através das setas entre as listas mova-a para o campo que desejar.</Text>
                <Spacer top={0.3}/>
                <Text wrap={'wrap'}>- As senhas no campo da esquerda serão disponibilizadas para os usuários deste local, e as do campo "Não Emitir" (direita) não serão.</Text>
            </Column>
        </Row>
    )
}