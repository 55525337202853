import { Row, Spacer, Column, TextLink, Icon } from "../layout/Layout";
import CheckBox from "../form/CheckBox";
import RadioButton from "../form/RadioButton";
import { useContext } from "react";
import { ConfigContext } from "../../contexts/ConfigContext";
import { BsArrowCounterclockwise } from 'react-icons/bs'
import { toast } from 'react-toastify'
import { configAtendPermissionsInfo, configAtendRestartInfo } from '../../content/tooltips/configTooltips'
import FormTitle from "../form/FormTitle";
import OpeningHoursConfig from "./OpeningHoursConfig";

export default function AttendanceConfigBox({}){ 

    const { config, updateConfig } = useContext(ConfigContext)

    const standardDeskPermissions = {
        back_call: true,
        random_call: true,
        cancel_call: true,
        ring_alert: true,
        attend_others: true
    }

    const handleCheck = (state, property) => {
        const permissions = {...config.desk_permissions}

        permissions[property] = state
        
        updateConfig(permissions, 'desk_permissions')
    } 
    
    const restoreConfig = () => {
        updateConfig(standardDeskPermissions, 'desk_permissions')
        updateConfig('at_interval_end', 'restart_count')
        toast.success('Configurações restauradas para o padrão de fábrica', {containerId: 'alert'})
    }
        
    return(
        <>
            <Row>
                <Column paddingLeft={24}>
                    <FormTitle help={configAtendPermissionsInfo}>Permissões dos Atendentes</FormTitle>
                    <Spacer top={2}/>
                    <CheckBox id={'back_call'} label={'Voltar senhas'} checked={config.desk_permissions?.back_call} onCheck={(state) => handleCheck(state, 'back_call')}/>
                    <CheckBox id={'random_call'} label={'Atender senhas aleatórias'} checked={config.desk_permissions?.random_call} onCheck={(state) => handleCheck(state, 'random_call')}/>
                    <CheckBox id={'cancel_call'} label={'Cancelar senhas'} checked={config.desk_permissions?.cancel_call} onCheck={(state) => handleCheck(state, 'cancel_call')}/>
                    <CheckBox id={'ring_alert'} label={'Tocar sinal sonoro do display'} checked={config.desk_permissions?.ring_alert} onCheck={(state) => handleCheck(state, 'ring_alert')}/>
                    <CheckBox id={'attend_others'} label={'Atender outros tipos'} checked={config.desk_permissions?.attend_others} onCheck={(state) => handleCheck(state, 'attend_others')}/>
                </Column>
                <OpeningHoursConfig/>
                <Column borderLeft paddingLeft={24}>
                    <FormTitle help={configAtendRestartInfo}>Reinício da Contagem de Senhas</FormTitle>
                    <Spacer top={2}/>
                    <RadioButton id={'restart-count-by-interval'} name={'counting-restart-config'} label={'Reiniciar contagem apenas quando atingir o final do intervalo definido'} state={config.restart_count === 'at_interval_end'} onSelect={() => updateConfig('at_interval_end', 'restart_count')}/>
                    <RadioButton id={'restart-count-by-day'} name={'counting-restart-config'} label={'Reiniciar contagem no início do expediente'} state={config.restart_count === 'at_day_start'} onSelect={() => updateConfig('at_day_start', 'restart_count')}/>
                </Column>
            </Row>
            <Spacer top={3}/>
            <Row align={'center'}>
                <Icon size={18} color={'#37474F'}>
                    <BsArrowCounterclockwise/>
                </Icon>
                <TextLink onClick={() => restoreConfig()}>
                    Restaurar Padrões
                </TextLink>
            </Row>
        </>
    )
}