import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, Box, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { create } from '../../../API/queries';
import { useAttendanceReportData } from '../AttendantPerformanceProvider';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const formatTime = (ms) => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)));
    return `${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm ' : ''}${seconds}s`;
};

export default function AttendantChartModal({ open, onClose, attendantId, attendantName }){

    const { startDate, endDate } = useAttendanceReportData();

    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (open) {
            fetchChartData();
        }
    }, [open]);

    const fetchChartData = async () => {
        setLoading(true);

        try {
            const response = await create('/get-attendant-evolution', {
                attendantId,
                startDate,
                endDate
            });

            const data = response.data;

            const labels = data.map(d => d.period);
            const totalFinishedData = data.map(d => d.totalFinished);
            const avgCallDurationData = data.map(d => d.avgCallDuration);
            const workEfficiencyData = data.map(d => d.workEfficiency);

            setChartData({
                labels,
                datasets: [
                    {
                        label: 'Total de Atendimentos',
                        data: totalFinishedData,
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        fill: false,
                        yAxisID: 'y',
                    },
                    {
                        label: 'Duração média',
                        data: avgCallDurationData,
                        borderColor: 'rgba(153, 102, 255, 1)',
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        fill: false,
                        yAxisID: 'y1',
                    },
                    {
                        label: 'Eficiência (%)',
                        data: workEfficiencyData,
                        borderColor: 'rgba(255, 159, 64, 1)',
                        backgroundColor: 'rgba(255, 159, 64, 0.2)',
                        fill: false,
                        yAxisID: 'y2',
                    },
                ],
            });
        } catch (error) {
            console.error('Error fetching chart data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Typography variant="h6">Gráfico de Evolução do Atendente</Typography>
                <Typography variant="subtitle1">{attendantName}</Typography>
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                        <CircularProgress />
                        <Typography variant="body1" sx={{ ml: 2 }}>
                            Criando gráfico...
                        </Typography>
                    </Box>
                ) : (
                    chartData && <Line data={chartData} options={{
                        scales: {
                            y: {
                                type: 'linear',
                                display: true,
                                position: 'left',
                                title: {
                                    display: true,
                                    text: 'Total de Atendimentos'
                                },
                            },
                            y1: {
                                type: 'linear',
                                display: true,
                                position: 'right',
                                grid: {
                                    drawOnChartArea: false,
                                },
                                title: {
                                    display: true,
                                    text: 'Duração média'
                                },
                                ticks: {
                                    callback: function(value) {
                                        return formatTime(value);
                                    }
                                }
                            },
                            y2: {
                                type: 'linear',
                                display: true,
                                position: 'right',
                                grid: {
                                    drawOnChartArea: false,
                                },
                                title: {
                                    display: true,
                                    text: 'Eficiência (%)'
                                },
                                ticks: {
                                    callback: function(value) {
                                        return value + '%';
                                    }
                                }
                            },
                        },
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        let label = context.dataset.label || '';
                                        if (context.dataset.yAxisID === 'y1') {
                                            label += `: ${formatTime(context.raw)}`;
                                        } else {
                                            label += `: ${context.raw}`;
                                            if (context.dataset.yAxisID === 'y2') {
                                                label += '%';
                                            }
                                        }
                                        return label;
                                    }
                                }
                            }
                        }
                    }} />
                )}
            </DialogContent>
        </Dialog>
    );
};
