import { Row, Spacer, Column, TextLink, Icon, Text } from "../layout/Layout";
import HelpIcon from "../others/HelpIcon";
import CheckBox from "../form/CheckBox";
import FormTitle from "../form/FormTitle";
import CheckNumberBetween from "../form/CheckNumberBetween";
import ToggleLabel from "../form/ToggleLabel";
import { useContext } from "react";
import { ConfigContext } from "../../contexts/ConfigContext";
import { BsArrowCounterclockwise } from 'react-icons/bs'
import { toast } from 'react-toastify'
import { notifConfigNotifAtendInfo } from '../../content/tooltips/configTooltips'

export default function NotifConfigBox({}){

    const { config, updateConfig } = useContext(ConfigContext)

    const numberOpts = [
        'Avisar quando um atendente estiver a mais de-minutos sem atender',
        'Avisar quando uma senha estiver a mais de-minutos na espera',
        'Avisar quando houver mais de-pessoas na fila de espera'
    ]
    
    const avisosOpts = [
        'Avisar quando a bobina dos terminais estiverem no final',
        'Avisar se um terminal estiver fora de operação ou for desligado',
        'Avisar quando um terminal se conectar ao servidor',
        'Avisar se um display estiver fora de operação ou for desligado',
        'Avisar quando um display conectar ao servidor'
    ]

    const atividadesOpts = [
        'Avisar quando um atendente iniciar atendimento',
        'Avisar quando um atendente encerrar ou pausar o atendimento',
        'Avisar quando uma senha for emitida ',
        'Avisar quando uma senha for chamada'
    ]

    const standard = {
        attendantOut: 20,
        passwordWaiting: 20,
        longQueue: 20,
        paperRoll: true,
        terminalOff: true, 
        terminalOn: true,
        displayOff: true,
        displayOn: true,
        attendantOn: true,
        attendantOff: true,
        newCall: true,
        callAlerted: true,
        warningAlert: true,
        activityAlert: true,
        notify_attendants: true
    }

    const setConfigProperty = (property, state) => {
        const notif = {...config.notification}

        notif[property] = state
        
        updateConfig(notif, 'notification')
    } 
    
    const restoreConfig = () => {
        updateConfig(standard, 'notification')
        toast.success('Configurações restauradas para o padrão de fábrica', {containerId: 'alert'})
    }
        
    return(
        <>
            <Row>
                <Column>
                    <Text>Configure abaixo quais as notificações que você deseja receber no sistema para se manter informado dos fatos e acontecimentos durante o expediente de atendimento.</Text>
                    <Spacer top={3}/>
                    <Row>
                        <Column> 
                            <FormTitle>Avisos</FormTitle>
                            <CheckNumberBetween id={'main'} option={numberOpts[0]} min={1} max={100} value={config.notification.attendantOut} checked={config.notification.attendantOut !== null} onChange={(value) => setConfigProperty('attendantOut', value)} onCheck={(state) => setConfigProperty('attendantOut', state ? 20 : null)}/>
                            <CheckNumberBetween id={'main'} option={numberOpts[1]} min={1} max={100} value={config.notification.passwordWaiting} checked={config.notification.passwordWaiting !== null} onChange={(value) => setConfigProperty('passwordWaiting', value)} onCheck={(state) => setConfigProperty('passwordWaiting', state ? 20 : null)}/>
                            <CheckNumberBetween id={'main'} option={numberOpts[2]} min={1} max={100} value={config.notification.longQueue} checked={config.notification.longQueue !== null} onChange={(value) => setConfigProperty('longQueue', value)} onCheck={(state) => setConfigProperty('longQueue', state ? 20 : null)}/>
                            <CheckBox id={'warnings'} label={avisosOpts[0]} checked={config.notification.paperRoll} onCheck={(state) => setConfigProperty('paperRoll', state)}/>
                            <CheckBox id={'warnings'} label={avisosOpts[1]} checked={config.notification.terminalOff} onCheck={(state) => setConfigProperty('terminalOff', state)}/>
                            <CheckBox id={'warnings'} label={avisosOpts[2]} checked={config.notification.terminalOn} onCheck={(state) => setConfigProperty('terminalOn', state)}/>
                            <CheckBox id={'warnings'} label={avisosOpts[3]} checked={config.notification.displayOff} onCheck={(state) => setConfigProperty('displayOff', state)}/>
                            <CheckBox id={'warnings'} label={avisosOpts[4]} checked={config.notification.displayOn} onCheck={(state) => setConfigProperty('displayOn', state)}/>
                        </Column>
                        <Column>
                            <FormTitle>Atividades</FormTitle>
                            <CheckBox id={'activities'} label={atividadesOpts[0]} checked={config.notification.attendantOn} onCheck={(state) => setConfigProperty('attendantOn', state)}/>
                            <CheckBox id={'activities'} label={atividadesOpts[1]} checked={config.notification.attendantOff} onCheck={(state) => setConfigProperty('attendantOff', state)}/>
                            <CheckBox id={'activities'} label={atividadesOpts[2]} checked={config.notification.newCall} onCheck={(state) => setConfigProperty('newCall', state)}/>
                            <CheckBox id={'activities'} label={atividadesOpts[3]} checked={config.notification.callAlerted} onCheck={(state) => setConfigProperty('callAlerted', state)}/>
                        </Column>
                    </Row>
                </Column>
            </Row>
            <Spacer top={3}/>
            <Row>
                <Column>
                    <FormTitle>Notificar Atendentes</FormTitle>
                    <Row align={'center'}>
                        <ToggleLabel id={'notif-atendentes'} text={'Notificar Atendentes'} state={config.notification.notify_attendants} onToggle={state => setConfigProperty('notify_attendants', state)}/>
                        <HelpIcon content={notifConfigNotifAtendInfo}/>
                    </Row>
                </Column>
            </Row>
            <Spacer top={3}/>
            <Row align={'center'}>
                <Icon size={18} color={'#37474F'}>
                    <BsArrowCounterclockwise/>
                </Icon>
                <TextLink onClick={() => restoreConfig()}>
                    Restaurar Padrões
                </TextLink>
            </Row>
        </>
    )
}